<!-- vue snippet -->
<template>
	<nav class="navbar is-flex">
		<div class="navbar-brand">
			<a class="navbar-item pb-0 pt-0 pr-0" id="logoContainer" href="/">
				<img src="@/assets/Logo/CM-Horizontal.png" id="logo" alt="logo">
			</a>
			<a id="burger" role="button" type="button" class="navbar-burger burger-button" aria-label="menu" title="Menu" aria-expanded="false"
			:class="{ 'active': burgerState.isActive}"	
			@click="toggleBurger"
			>
				<button type="button" class="burger-button" title="Menu">
					<span class="burger-bar burger-bar--1"></span>
					<span class="burger-bar burger-bar--2"></span>
					<span class="burger-bar burger-bar--3"></span>
				</button>	
			</a>
		</div>

		<div class="navbar-menu is-mobile">
			<div class="navbar-start ml-auto">
				<router-link :to="{name:'Home', hash:'#home'}" class="navbar-item navLink">Home</router-link>
				<router-link :to="{name:'Home', hash:'#quem-somos'}" class="navbar-item navLink">Quem Somos</router-link>
				<router-link :to="{name:'Home', hash:'#beneficios'}" class="navbar-item navLink">Benefícios</router-link>
				<router-link :to="{name:'Home', hash:'#como-funciona'}" class="navbar-item navLink">Como Funciona</router-link>
				<router-link :to="{name:'Home', hash:'#nossos-precos'}" class="navbar-item navLink">Preços</router-link>
				<router-link :to="{name:'Artigos'}" class="navbar-item navLink">Nossa Expertise</router-link>
			</div>
			<div class="navbar-end ">
				<!-- <template v-if="authentication.isAuthenticated">
					<div class="navbar-item">
						<div class="buttons">
							<router-link to="/testpage" class="button is-info">Test Page</router-link>
							<router-link to="/dashboard/my-account" class="button is-light">My account</router-link>
						</div>
					</div>      
				</template> -->
					<div class="navbar-item" v-if="!authentication.isAuthenticated">
						<div class="buttons">
							<router-link to="/log-in" class="button is-info is-inverted"><strong>Login</strong></router-link>
							<router-link to="/sign-up" class="button is-info is-outlined"><strong>Registre-se</strong></router-link>
						</div>
					</div> 
					<div class="navbar-item" v-if="authentication.isAuthenticated">
						<div class="buttons">
							<router-link to="/dashboard/my-account" class="button is-info is-outlined"><strong>Acessar a Plataforma</strong></router-link>
						</div>
					</div> 

			</div>
		</div>
		<hr>
	</nav>
	<!-- <transition  @before-enter="beforeEnter" @enter="enter" @leave="leave" :css="false"> -->
			<div id="MenuOverlay" 
				:style="{display: burgerState.isActive ? 'block' : 'none'}"
			>
				<div class="columns is-multiline" style="margin-top:2rem">
					<router-link :to="{name:'Home', hash:'#home'}" class="navbar-item navLink has-text-centered column is-12" id="burgerItem" @click="toggleBurger">Home</router-link>
					<router-link :to="{name:'Home', hash:'#quem-somos'}" class="navbar-item navLink has-text-centered column is-12" id="burgerItem" @click="toggleBurger">Quem Somos</router-link>
					<router-link :to="{name:'Home', hash:'#beneficios'}" class="navbar-item navLink has-text-centered column is-12" id="burgerItem" @click="toggleBurger">Benefícios</router-link>
					<router-link :to="{name:'Home', hash:'#como-funciona'}" class="navbar-item navLink has-text-centered column is-12" id="burgerItem" @click="toggleBurger">Como Funciona</router-link>
					<router-link :to="{name:'Home', hash:'#nossos-precos'}" class="navbar-item navLink has-text-centered column is-12" id="burgerItem" @click="toggleBurger">Preços</router-link>
					<router-link :to="{name:'Artigos'}" class="navbar-item navLink has-text-centered column is-12" id="burgerItem" @click="toggleBurger">Nossa Expertise</router-link>
					<div class="has-text-centered column is-12" style="height: 6rem;"></div>
					<span></span>
					<div class="has-text-centered column is-12" v-if="!authentication.isAuthenticated">
						<router-link to="/log-in" class="button is-info is-outlined" id="burgerItem" style="background-color: rgb(255,255,255,0.9);color:rgb(0, 94, 184)" @click="toggleBurger"><strong>Login</strong></router-link>
					</div> 
					<div class="has-text-centered column is-12" v-if="!authentication.isAuthenticated">
						<router-link to="/sign-up" class="button is-info is-outlined" id="burgerItem" style="background-color: rgb(255,255,255);color:rgb(0, 94, 184)" @click="toggleBurger"><strong>Registre-se</strong></router-link>
					</div> 
					<div class="has-text-centered column is-12" v-if="authentication.isAuthenticated">
						<router-link to="/dashboard/my-account" class="button is-info is-outlined" id="burgerItem" @click="toggleBurger"><strong>Acessar a Plataforma</strong></router-link>
					</div> 
				</div>
				
			</div>
		<!-- </transition> -->
	<hr class="navbarSeparator">
</template>

<script>
import {useAuthenticationStore} from "@/stores/authentication.js"
import { onMounted } from "vue"
import {reactive} from "vue"

export default {
  name: 'Navbar',
  props: {
	deactivated: Boolean,
	default: false
  },
  setup() {
    //   const authentication = useAuthenticationStore()
	  const authentication = useAuthenticationStore()
	//   const windowWidth = ref(0)
	  onMounted(() => {
		authentication.initializeAuthenticationStore()
		// windowWidth.value = window.innerWidth
	  })
	//   authentication.initializeAuthenticationStore()

	const burgerState = reactive ({isActive:false})

	function toggleBurger() {
		burgerState.isActive = !burgerState.isActive
	}

      return {
		authentication,
		burgerState,
		toggleBurger,
	}
    }
}
</script>

<style scoped>

.navbar {
	position: fixed;
	left: 0;
	top: 0;
	width:100vw;
	min-height: 4rem;
	padding-right: 0.5rem;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.navbar-brand {
	width: 250px;
}
@media (max-width: 1023px) {
	.navbar-brand {
		width: 100%;
	}
}

#logoContainer {
	width:220px
}

#logo{
	/* width: 150px; */
	height: 90%;
	max-height: none;
}
.navbar-burger {
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 1rem;
}

.navLink {
  color: rgb(49,53,62);
  font-weight: 600;
  font-size: 1.1rem;

  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 1300px) {
	.navLink {
		padding: 0.5rem 1rem;
	}
}
@media (max-width: 1300px) {
	.navLink {
		padding: 1vw
	}
}


.navLink:hover {
  color: rgb(0, 94, 184);
  background-color: transparent;
  font-weight: 600;
  font-size: 1.1rem;
  /* padding: 0.5rem 1rem; */
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
}
.button {
	background-color: rgb(0, 94, 184);
	transition: all 0.3s ease-in-out;
}
.is-inverted:hover {
	background-color: rgb(0, 94, 184);
	transition: all 0.3s ease-in-out;
}
.navbarSeparator {
	background-color: rgba(146, 146, 146, 0.02);
	height: 2px;
	margin: 0;
}



/* ----------------------- START Burger button styling ----------------------- */
.burger-button {
        position: relative;
        height: 30px;
        width: 40px;
        z-index: 99;
        border: 0;
        border-radius: 0;
        background-color: transparent;
        pointer-events: all;
        transition: transform .6s cubic-bezier(.165, .84, .44, 1);
    }
.burger-bar {
	background-color: rgb(0,114,206);
	position: absolute;
	top: 50%;
	right: 6px;
	left: 6px;
	height: 3.5px;
	width: auto;
	margin-top: -1px;
	transition: transform .6s cubic-bezier(.165, .84, .44, 1), opacity .3s cubic-bezier(.165, .84, .44, 1), background-color .6s cubic-bezier(.165, .84, .44, 1);
}
.burger-bar--1 {
	-webkit-transform: translateY(-6px);
	transform: translateY(-6px);
	top: 40%;
}
.burger-bar--2 {
	transform-origin: 100% 50%;
	transform: scaleX(1);
}
.burger-button:hover .burger-bar--2 {
	transform: translateX(-4px);
}
.no-touchevents .burger-bar--2:hover {
	transform: scaleX(1);
}
.burger-bar--3 {
	transform: translateY(6px);
	top: 60%;
}
#burger.active .burger-button {
	transform: rotate(-0);
}
#burger.active .burger-bar {
	background-color: rgb(49,53,62,0.7);
}
#burger.active .burger-bar--1 {
	transform: rotate(45deg);
	top: 50%;
}
#burger.active .burger-bar--2 {
	transform: translateX(-30px);
	opacity: 0;
}
#burger.active .burger-bar--3 {
	transform: rotate(-45deg);
	top: 50%;
}
/* ----------------------- END Burger button styling ----------------------- */

@media (max-width: 1023px) {
    #MenuOverlay {
		position: fixed; /* Sit on top of the page content */
		/* display: none; Hidden by default */
		width: 100%; /* Full width (cover the whole page) */
		height: 92.5%; /* Full height (cover the whole page) */
		margin-top: 180px;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgb(0,114,206); /* Black background with opacity */
		z-index: 100; /* Specify a stack order in case you're using a different order for other elements */
		/* cursor: pointer; Add a pointer on hover */
    }
}

#burgerItem{
	padding:1.2rem;
	color: white;
	align-items: center;
	transition: all 0.2s ease-in-out;
}
#burgerItem:hover{
	color: rgb(255, 255, 255,0.5);
}
</style>

