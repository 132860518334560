import {defineStore} from "pinia"
import {ref, reactive, computed, watch} from "vue"
import {useLocalStorage} from "@vueuse/core"  
import axios from "axios"

export const useAuthenticationStore = defineStore("authentication", {
    state: ()=> {
        return {
            username: '',
            userId: '',
            userRoles: reactive([]),
            isAuthenticated: ref(useLocalStorage("isAuthenticated", false)),
            tier: 0,
            token: '',
            access:  ref(useLocalStorage("access", "")),
            refresh: ref(useLocalStorage("refresh", "")),
            email: '',
        }
    },
    actions: {
        jwtLogin(data) {
            this.access = data.access
            this.refresh = data.refresh
            localStorage.setItem("access", data.access)
            localStorage.setItem("refresh", data.refresh)
            this.isAuthenticated = true
            axios.defaults.headers.common["Authorization"] = "JWT " + data.access
        },
        jwtLogOut() {
            this.access = ''	
            this.refresh = ''
            this.isAuthenticated = false
            localStorage.removeItem('access')
            localStorage.removeItem('refresh')
            localStorage.removeItem('email')
            localStorage.removeItem('userid')
            localStorage.removeItem('userRoles')
            localStorage.setItem('isAuthenticated', 'false')
            axios.defaults.headers.common["Authorization"] = ""
        },
        initializeAuthenticationStore(state) {
            if (localStorage.getItem('access')) {
                this.access=localStorage.getItem('access')
                this.refresh=localStorage.getItem('refresh')
                this.isAuthenticated=true
            }
        },
        setUserDetails(data) {
            // console.log('stores> authentication: setUserDetails called with data:', data)
            if (data.roles.length > 0) {
                if (data.roles !== this.userRoles){
                    this.userRoles = data.roles
                }
                if (localStorage.userRoles !== data.roles){
                    localStorage.setItem("userRoles", data.roles)
                }
            }            
            localStorage.setItem('email', data.email)
            this.email = data.email
            this.tier = data.tier

            localStorage.setItem('userid', data.id)
            this.userId = data.id

            this.username = data.firstName+ ' ' + data.lastName
            // console.log('stores> authentication: setUserDetails finished:')
        }
    },
    getters: {

    }
})