<template>
    <div class="page-container" style="">
        <div class="section" style="width:100%;padding: 20px;">
			<div class="columns is-multiline is-centered">
				<div class="column is-12 has-text-centered">
					<h1 class="title is-1" id="sectionTitle">Nossa Expertise</h1>
				</div>
				<div class="column is-12 has-text-centered">
					<h2 class="subtitle is-2" id="sectionSubTitle">
						A equipe <strong style="color:rgb(0,114,206)">{{$companyName}}</strong> 
						frequentemente produz análises e conteúdo de Saúde Ocupacional para seus clientes e para o público em geral.
                        Nosso objetivo é compartilhar conhecimento e experiência para ajudar a melhorar a saúde dos trabalhadores.
                        <p></p>
                        Nossos artigos são categorizados por assunto e podem ser encontrados abaixo. 
                        <p></p>
                        Utilize o campo de busca para filtrar a lista.
					</h2>
				</div>
                <div class="column is-12 has-text-centered">
                    <hr style="background-color: rgba(49, 53, 62, 0.818);">
                </div>
            </div>
            <div class="has-text-centered">
                <label style="font-size: 24px;font-weight: 600;; margin-top:10px">Busca: </label>
                <input class="input" type="text" v-model="searchTerm" placeholder="Filtre os artigos que contém o termo buscado" style="border-color: black;">
            </div>
            <br>
            <div class="card-container">
                <div class="card" v-for="artigo in filteredArtigos" :key="artigo.id" @click="goToArticlePage(artigo.id)">
                <div class="card-header">
                    <h2 class="card-title">{{ artigo.title }}</h2>
                    <p class="card-subtitle">por {{ artigo.author }}</p>
                    <p class="card-date">{{ formatDate(artigo.created_at) }}</p>
                </div>
                <div class="card-body">
                    <p class="card-description">{{ artigo.short_description }}</p>
                    <div class="card-tags">
                    <span class="card-tag-title">Categorias:</span>
                    <span class="card-tag" v-for="(tag, index) in artigo.tags" :key="index">{{ tag }}</span>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    <!-- MODAL - POST DETAIL -->
    <!-- <div class="modal" :class="{ 'is-active': isModalActive('Modal_PostDetails') }" v-if="isModalActive('Modal_PostDetails')">
        <div class="modal-background" @click="closeModal"></div>
        <div class="modal-card" id="PostDetailsModalCard">
            <header class="modal-card-head">
                <p class="modal-card-title"></p>
            </header>
            <section class="modal-card-body pt-0" style="modal-content-width:1200px">
                <div class="modal-body">
                    <h2 class="modal-title">{{ artigo.title }}</h2>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                    <div class="modal-info">
                        <span class="modal-info-author">por {{ artigo.author }}</span>
                        <span class="modal-info-date">{{ formatDate(artigo.created_at) }}</span>
                    </div>
                    <hr style="margin-top:0.5rem">
                    <div style="text-align: justify;" v-for="paragrafo in paragrafos">
                        <p class="article-paragraph">{{ paragrafo }}</p>
                    </div>
                    <hr>
                    <div class="modal-tags">
                        <span class="modal-tag-title">Categorias: </span>
                        <span class="modal-tag" v-for="(tag, index) in artigo.tags" :key="index">{{ tag.name }}</span>
                    </div>
                </div>
            </section>
        </div>
    </div> -->
</template>
  
<script>
import axios from 'axios';

export default {
    name: 'Artigos',
    data() {
        return {
            artigo: null,
            artigos: [],
            currentModal: '',
            searchTerm: '',
        };
    },
    mounted() {
        this.getArtigos();
    },
    computed: {
        filteredArtigos() {
            if (this.artigos == []) {
                return [];
            }
            const lowerCaseSearchTerm = this.searchTerm.toLowerCase();
            return this.artigos.filter(artigo => {
                return (
                    artigo.title.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(lowerCaseSearchTerm.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) ||
                    artigo.author.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(lowerCaseSearchTerm.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) ||
                    artigo.short_description.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(lowerCaseSearchTerm.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) ||
                    artigo.content.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(lowerCaseSearchTerm.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) ||
                    artigo.created_at.includes(lowerCaseSearchTerm) ||
                    artigo.tags.some(tag => tag.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(lowerCaseSearchTerm.normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
                );
            });
        },
        // paragrafos() {
        //     const paragrafos = this.artigo.content.split("\n")
        //     return paragrafos;
        // }
    },
    methods: {
        closeModal() {
            this.currentModal = '';
            this.artigo = null;
        },
        // async fetchArticle(postID) {
        //     await axios
        //         .get('/api/v1/posts/' + postID)
        //         .then(response => {
        //             if (response.data) {
        //                 this.artigo = response.data;
        //             }
        //         })
        //         .catch(error => {
        //             console.log(error);
        //         });
        // },
        formatDate(date) {
            var date = new Date(date);
            return (date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear())
        },
        getArtigos() {
        axios
            .get('/api/v1/posts/')
            .then(response => {
                if (response.data) {
                    this.artigos = response.data;
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
        goToArticlePage(postID) {
            this.$router.push({ name: 'Artigo-Detalhe', params: { articleID: postID } });
        },
        // isModalActive(modalName) {
        //     return this.currentModal === modalName;
        // },
        // openModal(postID) {
        //     this.fetchArticle(postID).then(() => {
        //         this.currentModal = 'Modal_PostDetails';
        //     });
        // },
    }
};
</script>

<style scoped>
.page-container {
    padding-left:72px;
    padding-right: 72px;
    background-color: rgba(216, 233, 248, 0.473);
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width: 1200px) {
    .page-container {
        padding-left: 24px;
        padding-right: 24px;
    }
}
@media (max-width: 780px) {
    .page-container {
        padding-left: 6px;
        padding-right: 6px;
    }
}
.section {
    max-width: 1368px; 
}
@media (max-width: 1200px) {
    .section {
        padding: 10px;
    }
}
#sectionTitle {
	color: rgba(49, 53, 62, 0.818);
	font-size: 2.2rem;
	font-weight: 800;
}
#sectionSubTitle {
	padding-top: 0rem;
	padding-bottom: 0rem;
	color: rgb(94, 107, 117);
	font-size: 1.1rem;
	font-weight: 200;
	font-family: sans-serif;
}
.input {
    width: 30%;
    min-width: 350px;
}
@media (max-width: 400px) {
    .input {
        min-width: 285px;
    }
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  width: 32%;
  margin-bottom: 20px;
  background-color: white;
  border: 1px solid #171515;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
@media (min-width: 920px) and (max-width: 1400px) {
    .card {
        width: 48%;
    }
}
@media (max-width: 920px) {
    .card {
        width: 100%;
    }
}

.card:hover {
  box-shadow: 0 4px 8px rgba(50, 102, 190, 0.2);
  border: 1.5px solid rgb(0, 114, 206);
  cursor: pointer;
}
.card-header {
    box-shadow: none;
    display: flex;
    flex-direction: column;
    padding: 10px 16px;
}

.card-title {
    width:80%;
    font-size: 20px;
    font-weight: 600;
    color: rgb(0, 114, 206);
}

.card-subtitle {
    color: #4a4a4a;
    font-size: 14px;
    font-weight: 400;
    margin:  0;
}

.card-date {
    position: absolute;
    top: 5%;
    right: 10px;
    font-size: 14px;
    font-weight: 600;
    margin: 4px 0 0 auto;
}

.card-body {
  padding: 20px;
  padding-top: 0;
}

.card-description {
  font-size: 16px;
  font-weight: 400;
  color: #4a4a4a;
  margin: 0;
}
@media (max-width: 520px) {
    .card-title {
        font-size: 18px;
    }
    .card-description {
        font-size: 14px;
    }
}

.card-tags {
  margin-top: 10px;
}

.card-tag-title {
  font-size: 15px;
  font-weight: 600;
  color: #8a8a8a;
  margin-right: 5px;
}

.card-tag {
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  color: white;
  padding: 3px 8px;
  border-radius: 4px;
  border-color: #4a4a4a;
  background-color: rgba(0, 113, 206, 0.825);
  margin-right: 5px;
}

/* MODAL - POST DETAIL*/
.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
}
.modal-card-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1rem;
    padding: 0px;
    background-color: rgb(0, 114, 206);
    color: white;
}
.modal-body {
  padding: 24px;
  padding-bottom: 0;
}

.modal-title {
  font-size: 24px;
  font-weight: bold;
  color: rgb(0, 114, 206);
}

.modal-info {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}

.modal-info-author {
  font-size: 14px;
  font-weight: bold;
  color: #666;
}

.modal-info-date {
  font-size: 14px;
  font-weight: bold;
  color: #666;
}

.delete {
    position: absolute;
    top: 5%;
    right: 2%;
}

.modal-content {
  margin-top: 16px;
  margin: 1rem;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}
.post-content {
    margin:3rem;
    margin-left:2rem;
    padding-left: 2rem
}
.article-paragraph {
    margin:1rem;
    margin-left:3rem;
    margin-right:3rem;
}

.modal-tags {
  margin-top: 16px;
}

.modal-tag-title {
  font-size: 14px;
  font-weight: bold;
  color: #666;
  padding-right:1rem
}

.modal-tag {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 6px 12px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background-color: rgb(0, 114, 206);
  border-radius: 4px;
}
</style>
  