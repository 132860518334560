<template>
      <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title pb-0 mb-0">{{company.nome}}</h1>

                <div class="buttons">
                    <!-- <router-link :to="{ name: 'EditLead', params: { id: lead.id }}" class="button is-light">Edit</router-link> -->
                    <!-- <button class="button is-info">Convert to client</button>
                    <button class="button is-danger">Delete</button> -->
                </div>
            </div>
            <hr>
            <div class="column is-6">
                <div class="box">
                    <h2 class="subtitle">Detalhes Próprios</h2>
                    <p><strong>Razão Social: </strong> {{company.razaoSocial}} </p>
                    <p><strong>CNPJ: </strong> {{company.cnpj}} </p>
                    <p><strong>Código Interno: </strong>{{company.codigo}}</p>
                </div>
            </div>

            <div class="column is-6">
                <div class="box">
                    <h2 class="subtitle">Detalhes Organizacionais</h2>
                    <p><strong>Grupo Corporativo: </strong>{{company.corporateGroup}}</p>
                    <p><strong>Criado em: </strong>Data</p>
                    <p><strong>Criado por: </strong>Pessoa</p>
                </div>
            </div>

            <div class="column is-12 is-centered">
                <div class="box is-narrow">
                    <div class="columns is-multiline">
                        <div class="column is-6">
                            <h2 class="subtitle"> <strong>Unidades de Negócio</strong> </h2> 
                        </div>
                        <div class="column is-6">
                            <router-link to="/organizacao" class="button is-info"><strong> Gerenciar Unidades </strong></router-link>
                        </div>
                        <div class="column is-12">
                            <!-- DEBUGGING CompanyDetails - Prop: company.pk:{{company.pk}}
                            CompanyDetails - Argument to be passed: tableAPIParams:{{tableAPIParams}} -->
                            <!-- apiParams: {{tableAPIParams}}
                            companyID: {{companyId}}
                            innerID: {{innerID}} -->
                            <DataTable apiURL="/api/v1/bus/" :apiParams="{'companyId': this.innerID}" ref="dataTableRef2" :hasActionColumn="false"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'

import DataTable from '@/components/dataTable/DataTable.vue'



export default {
    name: 'CompanyDetailsComponent',
    props: ['company','companyId']
    // {
    //     companyId: Integer
    // }
    ,
    components: {
        DataTable
    },
    data() {
        return {
            companyInner: '',
            tableAPIParams: {
                'companyId': this.innerID
            },
            // testeID: 52
        }
    },
    computed: {
            innerID() {
                return this.companyId
            }
    },
    created() {
        // console.log('CACILDA!')
        // console.log('props inside CompanyDetails:',this.companyID)
        // console.log(this.tableAPIParams)
        // this.tableAPIParams.companyId = this.companyId
    },
    methods: {

    }

}
</script>

<style>

</style>