// Usage: <DropdownAutoComplete label='LABELTEXT' :optionsList="VARIABLENAME-FROM-DATA()" @optionSelected="setOption"/> 
// methods: {
//     setOption(option) {
//         this.VARIABLENAME-FOR-THE-SELECTION = option
//     }
<template>
  <div class="field">
    <label class="label is-large">{{label}}</label>
      <div>
        <input
          class="is-info"
          :class="{'input':is_bulma_input}"
          type="text"
          placeholder="Digite o município"
          @input="onChange"
          v-model="search"
          @keydown.down="onArrowDown"
          @keydown.up="onArrowUp"
          @keydown.enter="onEnter"
        />
        <ul
          id="autocomplete-results"
          v-show="isOpen"
          class="autocomplete-results"
        >
            <li
                class="loading"
                v-if="isLoading"
            >
                Carregando resultados...
            </li>
            <li
                v-else
                v-for="(result, i) in results"
                :key="i"
                @click="setResult(result)"
                class="autocomplete-result"
                :class="{ 'is-active': i === arrowCounter }"
            >
                {{ result }}
            </li>
        </ul>
      </div>
  </div>
</template>

<script>
import {useExamSearch} from '@/stores/examSearch.js'

export default {
  props: {
    label: String,
    optionsList: Array,
    is_bulma_input: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
      const examQueryStore = useExamSearch()
      return {examQueryStore}
  },
  data() {
    return {
        isOpen: false,
        results: [],
        items: this.optionsList,
        search: this.examQueryStore.municipio,
        isLoading: false,
        arrowCounter: -1,
      };
    },
    watch: {
      items: function (value, oldValue) {
        if (value.length !== oldValue.length) {
          this.results = value;
          this.isLoading = false;
        }
      },
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside)
    },
    destroyed() {
      document.removeEventListener('click', this.handleClickOutside)
    },
    methods: {
      setResult(result) {
        this.search = result;
        this.isOpen = false;
        this.$emit('optionSelected', this.search)
      },
      filterResults() {
        this.results = this.items.filter((item) => {
          return item.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(this.search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1;
        });
      },
      onChange() {
        if (this.search.length>2) {
            this.$emit('input', this.search);

            if (this.isAsync) {
            this.isLoading = true;
            } else {
            this.filterResults();
            this.isOpen = true;
            // <---------------------------------------- ADD TIMER HERE TO CLOSE THE RESULTS BOX AFTER IDLENESS
            //setTimeout(() => { this.isOpen = false; }, 7000)
            }
        }
      },
      handleClickOutside(event) {
        if (!this.$el.contains(event.target)) {
          this.isOpen = false;
          this.arrowCounter = -1;
        }
      },
      onArrowDown() {
        if (this.arrowCounter < this.results.length) {
          this.arrowCounter = this.arrowCounter + 1;
        }
      },
      onArrowUp() {
        if (this.arrowCounter > 0) {
          this.arrowCounter = this.arrowCounter - 1;
        }
      },
      onEnter() {
        this.search = this.results[this.arrowCounter];
        this.isOpen = false;
        this.arrowCounter = -1;
      },
    },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .autocomplete {
    position: relative;
  }

  .autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    height: 120px;
    overflow: auto;
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
  }

  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: #4AAE9B;
    color: white;
  }
</style>