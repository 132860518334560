<template>
      <div class="container">
          <div class="columns is-multiline">
              <div class="column is-12">
                  <h1 class="title pb-0 mb-0 has-text-danger has-background-warning has-text-centered"><strong>Atenção!</strong></h1>
                  <h2 class="has-text-danger has-background-warning has-text-centered"><strong>A ação que você está prestes a realizar é irreversível.</strong></h2>
                </div>
                <hr>
                <div class="column is-12">
                    <div class="box">
                        <div class="content">
                            <!-- {{ offeredExam }} -->
                            <h5 class="" style="margin-bottom:0"><strong> Exame:</strong> {{offeredExam.nome}}</h5>
                            <h5 class="" style="margin-top:0;margin-bottom:0"><strong> Contido na Oferta: </strong>{{ examOfferName }}</h5>
                            <h5 class="" style="margin-top:0;margin-bottom:0"><strong> Preço: </strong>{{ offeredExam.preco }}</h5>
                            <h5 class="" style="margin-top:0"><strong> SLA (Prazo): </strong>{{ offeredExam.sla }}</h5>
                            <h5 class="has-text-centered has-text-danger has-background-warning"><strong>Ao remover este exame da oferta, clientes da plataforma não serão mais capazes de agendá-lo em suas requisições.</strong></h5>
                        </div>
                    </div>
                </div>

            <div class="column is-12 is-centered">
                <label class="checkbox pb-2">
                    <input type="checkbox" v-model="checkConfirm">
                    Eu entendo que ao deletar o exame acima, todos os itens dependentes serão excluídos, e desejo continuar.
                </label>
                <p class="has-text-danger pb-4" v-show="submitDeclined">Você deve confirmar na caixa acima antes de prosseguir.</p> 
                <div class="buttons"> <!-----------------------------------------------------------Make buttons centered -->
                    <button class="button is-info" @click="$emit('closeDeleteModal')">Cancelar e Retornar</button>
                    <button class="button is-danger is-outlined" @click="submitDelete">Confirmar e Deletar</button>
                </div>
            </div>
        </div>
    </div></template>

<script>
import axios from 'axios'
import { ref } from 'vue'

// import DataTable from '@/components/dataTable/DataTable.vue'



export default {
    name: 'OfferedExamDelete',
    props: ['offeredExam','modalIsActive', 'examOfferName']
    // {
    //     companyId: Integer
    // }
    ,
    components: {
        // DataTable
    },
    data() {
        return {
            examOfferInner: '',
            // tableAPIParams: {
            //     'examOfferPK': this.offeredExam.pk
            // },
            // numeroUnidadesNegocio: 0,
            // numeroWorkAreas: 0,
            // numeroRelacoesDeTrabalho: 0,
            responseBeforeDelete: ref({}),
            checkConfirm: false,
            submitDeclined: false
        }
    },
    computed: {

    },
    watch: {

    },
    created() {
        // console.log('CACILDA!')
        // console.log('props inside CompanyDetails:',this.companyID)
        // console.log(this.tableAPIParams)
        // this.tableAPIParams.companyId = this.companyId
        this.getDataBeforeDelete()
        
    },
    methods: {
        getDataBeforeDelete() {
            axios
                .get('/api/v1/clinics/exams-offered/', { params: {'examOfferedPK': this.offeredExam.exam_offered_pk} })
                .then(response => {
                    // console.log('getDataBeforeDelete response.data:',response.data)
                    this.responseBeforeDelete = response.data
                    // console.log('responseBeforeDelete:',this.responseBeforeDelete)
                })
                .catch(error => {
                    console.log(error.request.response)
                })
        },
        async submitDelete() {
            if (this.checkConfirm) {
                // console.log('submitting delete request for:',this.offeredExam.pk)
                axios
                    .delete('/api/v1/clinics/exams-offered/', { params: {'examOfferedPK': this.offeredExam.pk} })
                    // .delete('/api/v1/clinics/exams-offered/',this.offeredExam.pk)

                    .then(response => {
                        // this.$router.push('/organizacao')
                        // this.companies.unshift(response.data)
                        // this.$refs.dataTableRef.table.rows.unshift(response.data)
                        // console.log('CompanyDelete_submitDelete called.')
                        // Emit event to parent component to close modal
                        this.$emit('closeDeleteModalWithReload')
                    })
                    .catch(error => {
                        console.log(error.request.response)
                    })
            }
            else {
                this.submitDeclined = !this.submitDeclined
            }
        },

    }

}
</script>

<style>

</style>