<template>
    <div class="container">
        <div class="columns is-multiline"> 
            <!-- <div class="column is-12"> 
                <h4 class="title is-4" style="padding-top:0;margin-bottom:0.5rem">Titulo?</h4>
                <hr style="margin-bottom:0;margin-top:0">
            </div> -->
            <div class="column is-12"> <!------------------------ Detalhes do Paciente ------------------------>
                <div class="box">
                    <div class="content">
                        <div class="columns">
                            <div class="column is-12">
                                <h4 class="subtitle"><strong>Detalhes do Agendamento</strong></h4>
                            </div>
                        </div>
                        <table>
                            <tr>
                                <th>Paciente</th>
                                <td>{{ modalObject.patient }}</td>
                            </tr>
                            <tr>
                                <th>Clínica</th>
                                <td>{{ modalObject.clinica }}</td>
                            </tr>
                            <tr>
                                <th>Exame</th>
                                <td>{{ modalObject.requisicao_exame_padrao }}</td>
                            </tr>
                            <tr>
                                <th>Situação</th>
                                <td>{{ modalObject.status_agendamento }}</td>
                            </tr>
                            <tr>
                                <th>Data e Horário</th>
                                <td>{{ modalObject.data_agendamento }}</td>
                            </tr>
                        </table>
                        <br>
                        <!-- {{ modalObject }} -->
                        <div class="columns mb-0">
                            <div class="column is-12 pb-0">
                                <h4 class="subtitle"><strong>Reagendar exame</strong></h4>
                            </div>
                        </div>
                        <table style="margin-bottom: 0;">
                            <tr>
                                <th>Nova Data:</th>
                                <td style="padding-right:0">
                                    <Datepicker
                                        v-model="datePickerDate"
                                        :min-date="new Date()"
                                        :auto-position="true"
                                        :format="format"
                                        ></Datepicker>
                                </td>
                            </tr>
                        </table>
                        <button class="button is-info is-outlined" style="float:right;" @click="submitRescheduleRequest()">Solicitar Reagendamento</button>
                        <br>
                        <br>
                    </div>
                </div>
            </div>
            <div class="column is-1">
            </div>
            <div class="column is-5">
                <div class="buttons" style="float:left">
                    <button class="button is-danger is-outlined" @click="activateModal('Modal_DeleteMedicalRequest')">Cancelar Agendamento</button>
                </div>
            </div>
            <div class="column is-5">
                <div class="buttons" style="float:right">
                    <button class="button is-info" @click="$emit('closeModal')">Retornar</button>
                </div>
            </div>
            <div class="column is-1">
            </div>
        </div>
    </div>
    <section><!------------------------ MODAL: DELETAR AGENDAMENTO ------------------------> 
        <!-- <div class="modal" :class="{ 'is-active': isModalActive('Modal_DeleteMedicalRequest') }" v-if="isModalActive('Modal_DeleteMedicalRequest')">
            <div class="modal-background"></div>
            <div class="modal-card" id="Modal_DeleteMedicalRequest">
                <header class="modal-card-head">
                    <p class="modal-card-title">Cancelar Requisição</p>
                    <button class="delete" aria-label="close" @click="closeModal"></button>
                </header>
                <section class="modal-card-body" style="modal-content-width:1200px">
                    <MedicalRequestDelete
                        :object = "medicalRequestData"
                        @closeModal = "closeModal"
                        @closeDeleteModal="$emit('closeModal')"
                    />
                </section>
            </div>
        </div> -->
    </section>
</template>

<script>
import axios from 'axios'

// import DataTable from '@/components/dataTable/DataTable.vue'
// import MedicalRequestDelete from '@/components/viewComponents/MedicalRequestDelete.vue'
// import ExamOfferDelete from '@/components/clinics/ExamOfferDelete.vue'
// import FormGenericSerializer from '@/components/FormGenericSerializer.vue'
// import EditGenericSerializer from '@/components/EditGenericSerializer.vue'
// import OfferedExamDelete from '@/components/clinics/OfferedExamDelete.vue'
import { toRaw , ref} from 'vue'
import { toast } from 'bulma-toast'
// import Datepicker from '@/internalizedLibraries/@vuepic/vue-datepicker'
// import '@/internalizedLibraries/@vuepic/vue-datepicker/dist/main.css'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    name: 'EditarAgendamento',
    props: ['modalObject'],
    components: {
        Datepicker,
        // DataTable,
        // MedicalRequestDelete,
    },
    emits: ['closeModal'],
    data() {
        return {
            activeModal: '',
            datePickerDate: new Date(),
            // ExamRequestsDataTableKey: ref(0),
            // medicalRequestData: {},
        }
    },
    computed: {
        format() {
            // if (this.datePickerDate == null) {
            //     return ''
            // }
            // else {
                return  this.datePickerDate.getDate()+ '/'+(this.datePickerDate.getMonth()+1)+'/'+this.datePickerDate.getFullYear()+' '+this.datePickerDate.getHours()+':'+this.datePickerDate.getMinutes()
            // }
        },
    },
    watch: {

    },
    created() {
        // this.prepareDate()
    },
    methods: {
        activateModal(modalName, data='') {
            // console.log('ExamOfferDetails_activateModal Called for modal:', modalName, ' and data:',data)
            const rawData = toRaw(data)
            if (data!='') {
                // console.log('activateModal data recognised. Received data:',data)
                this.modalInputData = rawData
                // console.log('modalInputData:',this.modalInputData)
                this.modalInputID = rawData.pk
                // console.log('modalInputID:',this.modalInputID)
            }
            this.activeModal = modalName
            // console.log('activeModal set to:',this.activeModal)
        },
        closeModal() {
            this.activeModal = ''
        },
        isModalActive(modalName) {
            return this.activeModal === modalName
        },

        // prepareDate() {
        //     // console.log('prepareDate:',toRaw(this.modalObject.data_agendamento))
        //     this.datePickerDate = toRaw(this.modalObject.data_agendamento)
        // },
        submitRescheduleRequest() {
            if (this.datePickerDate == this.modalObject.data_agendamento) {
                toast({
                    message: 'A data selecionada é a mesma que a data atual do agendamento. Favor selecionar uma data diferente.',
                    type: 'is-warning',
                    position: 'top-center',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                })
                return
            }
            axios 
            .put('/api/v1/examrequests/corporate/', {
                'examRequestPK': this.modalObject.meta_exam_request.pk,
                'appointmentPK': this.modalObject.pk,
                'intendedDate': this.datePickerDate,
                'intendedDate_year': this.datePickerDate.getFullYear(),
                'intendedDate_month': this.datePickerDate.getMonth()+1,
                'intendedDate_day': this.datePickerDate.getDate(),
                'intendedDate_hour': this.datePickerDate.getHours(),
                'intendedDate_minute': this.datePickerDate.getMinutes(),
            })
            .then (response => {
                this.$emit('closeModal')
            })
            .catch (error => {
                console.log(error)
            })
        }

    }
}
</script>

<style scoped>
.modal-card {
    width:60%
}

</style>