<template>
	<section class="section is-medium">
		<div class="container">
			<div class="columns is-multiline is-centered">
				<div class="column is-12 has-text-centered">
					<h1 class="title is-1" id="sectionTitle">Uma plataforma única para a gestão de exames ocupacionais</h1>
				</div>
				<div class="column is-12 has-text-centered">
					<h2 class="subtitle is-2" id="sectionSubTitle">A <strong style="color:rgb(0,114,206)">{{$companyName}}</strong> é uma plataforma web que fornece a integração de uma suíte completa de serviços e produtos para saúde ocupacional, conectando de forma transparente desde grandes empresas até prestadores de serviço individuais. </h2>
				</div>
				<div class="column is-4 has-text-centered" id="USPSides">
					<div class="">
						<div class="card-image">
							<figure class="image">
								<img src="@/assets/hexagono.webp" alt="Placeholder image" >
							</figure>
						</div>
						<div class="card-content">
							<div class="media mb-0">
								<div class="media-content">
									<p class="title is-4 mb-0">Controle</p>
								</div>
							</div>
							<div class="content mt-0">
							Compare individualmente produtos e serviços com seus prestadores atuais e contrate apenas o que melhor se adequa, pelo tempo que precisar. Sem multas por cancelamento e sem necessidade de longas (e caras) implantações.
							</div>
						</div>
					</div>
				</div>
				<div class="column is-4 has-text-centered" id="USPMiddle">
					<div class="">
						<div class="card-image">
							<figure class="image">
								<img src="@/assets/eficienciaPrompt.webp" alt="Placeholder image">
							</figure>
						</div>
						<div class="card-content">
							<div class="media mb-0">
								<div class="media-content">
									<p class="title is-4 mb-0">Eficiência</p>
								</div>
							</div>
							<div class="content mt-0">
							A escolha de fornecedores na plataforma permite que sua equipe compare não só aqueles com o melhor preço, mas com os melhores indicadores de entrega. Assim você contrata exatamente o que otimiza sua eficiência.
							</div>
						</div>
					</div>
				</div>
				<div class="column is-4 has-text-centered" id="USPSides">
					<div class="">
						<div class="card-image">
							<figure class="image">
								<img src="@/assets/transparenciaPrompt.webp" alt="Placeholder image">
							</figure>
						</div>
						<div class="card-content">
							<div class="media mb-0">
								<div class="media-content">
									<p class="title is-4 mb-0">Transparência</p>
								</div>
							</div>
							<div class="content mt-0">
							Nós operamos um modelo de margem fixa e 100% transparente com nossos clientes, como um verdadeiro parceiro de negócios deve ser. Quaisquer ganhos de eficiência e economia são repassados diretamente para nossos clientes.
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
		
	</section>
</template>

<script>

export default {
  name: 'USPSection',
  components: {
  },
  setup() {
    },
  data () {
    return {

    }
  }
}
</script>

<style scoped>
.section {
	background-color: white;
	padding-top: 2rem;
	padding-bottom: 2rem;
}
#sectionTitle {
	color: rgb(66,85,99);
	font-size: 2.2rem;
	font-weight: 800;
}
#sectionSubTitle {
	padding-top: 0rem;
	padding-bottom: 2rem;
	color: rgb(49,53,62,0.7);
	font-size: 1.1rem;
	font-weight: 200;
	font-family: sans-serif;
}

#USPMiddle {
	padding-top: 6rem;
}

.title{
	color: rgb(49,53,62);
	font-weight: 700;
	position: relative;
	/* top: 20%; */
	padding-bottom: 1rem;
}

.content {
	color: rgb(49,53,62,0.7);
	font-weight: 400;
	font-family: sans-serif;
}

.image {
	max-width: 100%;
	margin: auto;
}

@media (max-width: 770px) {
	.image {
		max-width: 50%;
		margin: auto;
	}
}
</style>

