<template>
    <div class="page-login">
        <div class="columns">
            <div class="column is-4 is-offset-4">
                <h1 class="title" >Esqueceu sua senha?</h1>
                <hr>
                <h2> Forneça seu email de cadastro abaixo. Caso nossos sistemas o reconheçam, você receberá um email com instruções para alterar sua senha.</h2>
                <!-- Insert email input field -->
                <div class="field">
                    <br>
                    <p class="control has-icons-left has-icons-right">
                        <input class="input" type="email" placeholder="Email" v-model="email">
                        <span class="icon is-small is-left">
                            <i class="fas fa-envelope"></i>
                        </span>
                        
                    </p>
                    <br>
                    <button class="button is-info is-right" @click="resetPassword()">Confirmar</button>
                    <!-- Add button to send -->
                    
                    
                </div>
                <hr>
                <p>Lembrou de sua senha?<router-link to="/log-in">Clique aqui</router-link> para ir à pagina de Login.</p>
                
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {toast} from 'bulma-toast'

export default {
    name: 'ResetPasswordCall',
    data() {
        return {
            email: '',
            errors:[]
        }
    },
    mounted() {

    },
    methods: {
        resetPassword() {
            const resetData = {
                'email': this.email
            }
            axios
                .post("/auth/users/reset_password/", resetData)
                .then(response => {
                    toast({
                        position: 'top-center',
                        message: 'Solicitação realizada. Verifique sua caixa de entrada. Se o email for válido, deverá receber um email com instruções.',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 5000,
                    })
                    this.$router.push('/log-in')
                })
                .catch(error => {
                    toast({
                        position: 'top-center',
                        message: 'Solicitação realizada. Verifique sua caixa de entrada. Se o email for válido, deverá receber um email com instruções.',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                    })
                    this.$router.push('/log-in')
                })
        }
    }
}
</script>

<style scoped>
.page-login {
    min-height: 50vh;
}
.columns {
    margin-top: 6rem;
    margin-bottom: auto;
}
</style>