<template>
    <!DOCTYPE html>
    <div class="box" v-if="markers.length<1" >
        <div class="block" v-show="responseIsLoaded">
            <div>
                <p class="title">Resumo dos Resultados</p>
            </div>
            <hr>

            <p class="ml-4"><strong> Infelizmente não há clínicas credenciadas na região de interesse que ofereçam os exames buscados.</strong></p>
            <p>  </p>
            <p class="ml-4">Tente outra busca, ou solicite o credenciamento de clínicas na região. </p>
            <hr>
            <div class="columns is-multiline">
                <div class="column is-3"></div>
                <div class="column is-3">
                    <router-link :to="{ name: 'BuscaExamesResults'}" @click="window.scrollTo(0,0)">
                        <button class="button is-outlined is-static"><strong>Solicitar Credenciamento</strong></button>
                    </router-link>
                </div>
                <div class="column is-3">
                    <router-link :to="{ name: 'BuscaExames'}" @click="window.scrollTo(0,0)">
                        <button class="button is-info"><strong>Nova Busca</strong></button>
                    </router-link>
                </div>
                <div class="column is-3"></div>
            </div>
        </div>
    </div>
    <div class="box" v-else>
        <div>
            <p class="title">Resumo dos Resultados
                <router-link :to="{ name: 'BuscaExames'}" class="is-pulled-right">
                    <button class="button is-info"><strong>Nova Busca</strong></button>
                </router-link>
            </p>
        </div>
        <!-- Pinia store test: {{examQueryStore}}
        <p>ResponseData test:</p>
        {{responseData}} -->
        <hr>
        <div class="columns is-multiline">
            <div class="column is-5">
                <div class="card pl-4 pr-4 pt-2" id="cardLeft">
                    <div class="content is-small">
                        <h1 class="ml-4 is-medium">Faixa de Preço Local dos Exames</h1>
                        <hr>
                        <table class="table is-small">
                            <thead>
                                <tr>
                                    <th class="has-text-centered">Disp</th>
                                    <th>Exame</th>
                                    <th>R$ Mín</th>
                                    <th>R$ Máx</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="exame in resumoExames" :key="exame.nome">
                                    <td v-if="exame.disponibilidade=='S'" class="has-text-centered"><i class="fas fa-check"></i></td>
                                    <td v-else class="has-text-centered"><i class="fas fa-xmark"></i></td>
                                    <td>{{exame.nome}}</td>
                                    <td>{{exame.preçoMin}}</td>
                                    <td>{{exame.preçoMax}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="column is-7 is-multiline">
                <div class="card pl-4 pr-4 pt-2 pb-4" id="cardRight">
                    <div class="content is-small">
                        <h1 class="ml-4 is-medium">{{markers.length}} Clínicas Disponíveis</h1>
                        <hr>
                        <GoogleMap
                            :mapCenter="mapCenter"
                            :markers="markers"
                            :zoomRequired="zoomRequired"
                        />                
                    </div>
                </div>
            </div>
        </div>
        <card-pacote-clinica titulo="Pacotes de Exames - Uma única clínica" @openSchedulingModal="openSchedulingModal" />
        <card-pacote-clinica-multi titulo="Pacotes de Exames - Duas ou mais clínicas" v-if="this.examQueryStore.examesDemandados.length>1" @openSchedulingModal="openSchedulingModal"/>
    </div>
    <!-- MODAL - Criar Requisicao -->
    <div class="modal" :class="{ 'is-active': isModalActive('Modal_CreateMedicalRequest') }" v-if="isModalActive('Modal_CreateMedicalRequest')">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Criar Requisição Médica e Solicitar Agendamento</p>
            <button class="delete is-medium" aria-label="close" @click="closeModal()"></button>
        </header>
        <section class="modal-card-body">
            <CriarRequisicao 
                :object = "schedulingModalData"
            />
        </section>
        <footer class="modal-card-foot">
            <div class="buttons" style="margin-left:auto;margin-right: auto;">
                <button class="button is-danger" @click="closeModal()">Cancelar</button>
                <button class="button is-success" @click="submitCreateRequest()">Confirmar e Criar Requisição</button>
            </div>
        </footer>
    </div>
    </div>
</template>

<script>
import GoogleMap from '@/components/GoogleMap'
import CardPacoteClinica from '@/components/CardPacoteClinica'
import CardPacoteClinicaMulti from '@/components/CardPacoteClinicaMulti'
import CriarRequisicao from '@/components/scheduling/CriarRequisicao'
import axios from 'axios'
import {useExamSearch} from '@/stores/examSearch.js'
import { toRaw } from 'vue'
import { toast } from 'bulma-toast'


export default {
    name: 'BuscaExamesResults',
    components: {
        GoogleMap, CardPacoteClinica, CardPacoteClinicaMulti, CriarRequisicao
    },
    setup() {
      const examQueryStore = useExamSearch()
      return {examQueryStore}
    },
    data() {
        return {
            activeModal: '',
            examPackages: [],
            mapCenter: {},
            markers: [],
            markerLabelDictionary: {},
            responseData: [],
            responseIsLoaded: false,
            resumoExames: [],
            searchquery: {
                municipio: this.examQueryStore.municipio,
                raioBusca: this.examQueryStore.raioBusca,
                examesDemandados: this.examQueryStore.examesDemandados
                // municipio: 'São Paulo, SP',
                // raioBusca: 2,
                // examesDemandados: ['Exame Clínico - Admissional', 'Audiometria Ocupacional', 'Coprocultura', 'Glicemia']
            },
            schedulingModalData: {},
        }
    },
    computed: {
        zoomRequired() {
            if (this.examQueryStore.raioBusca <= 3) {
                return 12
            } else if (this.examQueryStore.raioBusca <= 5) {
                return 11
            } else if (this.examQueryStore.raioBusca <= 10) {
                return 10
            } else {
                return 10
            }
        }
    },
    mounted() {
        this.getPrices()
    },
    methods: {
        activateModal(modalName) {
            // console.log('BuscaExamesResults activateModal called for modal:', modalName)
            this.activeModal = modalName
        },
        closeModal() {
            this.activeModal = ''
        },
        getPrices() {
            //API Call
            axios
                .get('api/v1/clinics/test', {params: {data: this.searchquery}})
                .then(response=> {
                    this.responseData = response.data
                    this.markers = response.data.markers
                    this.mapCenter = response.data.mapCenter
                    this.resumoExames = response.data.priceRanges
                    this.examQueryStore.pacotesExamesClinicaUnica = response.data.individualPackages
                    this.examQueryStore.pacotesExamesMultiClinica = response.data.multiClinicPackages
                    this.responseIsLoaded = true
                    //Post API-Call processing
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                });
        },
        isModalActive(modalName) {
            return this.activeModal === modalName
        },
        openSchedulingModal(object) {
            this.schedulingModalData = toRaw(object)
            this.activateModal('Modal_CreateMedicalRequest')
            // console.log('schedulingModalData: ', this.schedulingModalData)
        },
        submitCreateRequest() {
            // console.log('submitCreateRequest called')
            // console.log('schedulingModalData: ', this.schedulingModalData)
            axios
                .post('api/v1/medicalrequests/api/', this.schedulingModalData.newRequestData)
                .then(response=> {
                    // console.log('Medical Request created successfully')
                    this.closeModal()
                    //Redirect the user to Manage Medical Requests page
                    toast({
                        message: 'Requisição criada com sucesso!',
                        type: 'is-success',
                        position: 'bottom-right',
                        dismissible: true,
                        pauseOnHover: false,
                        duration: 2000,
                    })
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                    toast({
                        message: 'Erro ao criar requisição.',
                        type: 'is-danger',
                        position: 'bottom-right',
                        dismissible: true,
                        pauseOnHover: false,
                        duration: 2000,
                        // animate: { in: 'fadeIn', out: 'fadeOut' },
                    })
                });
        }
    }
}
</script>

<style scoped>
.card {
    height: minmax(25rem,auto)
}

#cardLeft {
    height: 100%;
}
#cardRight {
    height: 100%;
}

.modal-card {
    width: 75%;
}

</style>