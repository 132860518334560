<template>
    <div class="page-login">
        <div class="columns">
            <div class="column is-4 is-offset-4">
                <h1 class="title" v-if="!activationComplete">Realizando ativação...</h1>
                <h1 class="title" v-else>Sua conta foi ativada com sucesso!</h1>
                 
                <hr>
                <p v-if="activationComplete" >Você já pode realizar o login na plataforma Daedalus.</p>
                <p v-if="activationComplete" ><router-link to="/log-in">Clique aqui</router-link> para ir à pagina de Login.</p>
                
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'ActivateAccount',
    data() {
        return {
            activationComplete: false,
            erros:[]
        }
    },
    mounted() {
        this.activateAccount()
    },
    methods: {
        activateAccount() {
            const activationData = {
                uid: this.$route.params.uid,
                token: this.$route.params.token,
            }
            axios
                .post("/auth/users/activation/", activationData)
                .then(response => {
                    console.log(response)
                    this.activationComplete=true
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                        console.log(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.log(JSON.stringify(error.message))
                    } else {
                        console.log(JSON.stringify(error))
                    }
                })
        }
    }
}
</script>

<style scoped>
.page-login {
    min-height: 50vh;
}
.columns {
    margin-top: 6rem;
    margin-bottom: auto;
}
</style>