<template>
    <div>
        <h1 class="title" style="margin-bottom:24px">Buscar Exames</h1>
        <!-- <div class="box" > -->
        <div class="box" v-if="searchCredits.current_search_credits>0">
            <!--<div class="box">
            Pinia store test: {{examQueryStore}}
            </div>
            <div class="box">
            listaAssetsTopExames: {{listaAssetsTopExames}}
            </div>-->  
            <!-- {{requiredFieldsAreFilled}} -->
            <div class="columns">
                <div class="column is-6">
                    <title>Busca Exames</title>
                    <DropdownAutoComplete 
                        label='Município'
                        :optionsList="listaMunicipios"
                        @optionSelected="setOptionMunicipio"
                        :key="municipioDropDownKey"
                    />
                </div>
                <div class="column is-4">
                    <label class="label is-large">Distância</label>
                    <div class="select is-link is-small">
                        <select v-model="distanciaSelecionada" @change="setOptionRaio(distanciaSelecionada)">
                            <option v-for="option in selectOptions" v-bind:value="option.value" :key="option.value">
                                {{option.text}}
                            </option>
                        </select>
                    <!-- <h3>Selected: {{distanciaSelecionada}}</h3> -->
                    </div>
                </div>
                <div class="column is-2">
                    <div class=" is-pulled-right">
                        <button class="button is-small is-primary is-outlined" @click="resetSearch()"><strong>Reiniciar Busca</strong></button>               
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column is-12 column--align-center">
                    <MultiSelector :key="multiSelectorKey"/>
                </div>
            </div>
            <div class="buttons is-right">
                <!-- <router-link :to="{ name: 'BuscaExamesResults'}" @click="confirmSearch"> -->
                <button class="button is-success is-large" @click="confirmSearch"><strong>Confirmar</strong></button>
                <!-- </router-link> -->
            </div>
        </div>
        <div class="box" v-else style="">
            <div class="columns is-multiline">
                <div class="column is-12">
                    <p class="title has-text-centered">Sua quota de buscas expirou!</p>

                </div>
                <div class="column is-4">
                </div>
                <div class="column is-4">
                    <progress class="progress"  :value="searchCredits.current_search_credits" :max="searchCredits.total_search_credits" style="margin-top:5px"></progress>
                    <hr>
                    <p class="has-text-centered">
                        <strong class="has text-centered">
                            A próxima busca ficará disponível em {{Math.trunc(searchCredits.wait_time)}}h{{ Math.trunc((searchCredits.wait_time-Math.trunc(searchCredits.wait_time))*60) }}min.
                        </strong>
                    </p>
                </div>
                <div class="column is-4">
                    <strong class="ml-1">{{ searchCredits.current_search_credits }}/{{ searchCredits.total_search_credits }} </strong>
                </div>
                <div class="column is-3">
                </div>
                <div class="column is-6" v-if="authentication.tier<2">
                    <div class="content has-text-centered" >
                        <p>Seu perfil possui uma quota diária de buscas, associada à sua organização. Para realizar mais buscas, você pode esperar a renovação de sua quota (cada busca é renovada a cada 24 horas), ou fazer um upgrade da conta.</p>
                        <p>Para realizar um upgrade da conta e ter acesso a <strong>50 buscas diárias</strong>, complete um curto questionário (3 minutos) que nos permitirá entender melhor o perfil de sua organização. </p>
                        <p>Ao preencher o questionário, você terá acesso aos seguintes benefícios:</p>
                        <ul>
                            <li>Acesso à funcionalidade Gerir Organização, demonstrando como configurar sua empresa é fácil</li>
                            <li>Acesso à funcionalidade Gerir Time, demonstrando como é fácil gerir sua equipe na plataforma </li>
                            <li>Acesso à funcionalidade Requisições, demonstrando como é fácil gerir agendamento de exames na plataforma</li>
                            <li>Sua empresa será candidata a participar gratuitamente de nosso próximo projeto piloto.</li>
                        </ul>
                        <hr>
                    </div>                        
                    <div class="button is-info" style="margin-left: auto;margin-right: auto;display: block; width: fit-content;"> Preencher questionário</div>
                </div>
                <div class="column is-6" v-else>
                    <div class="content has-text-centered">
                        <p>Seu perfil possui uma quota diária de buscas, associada à sua organização. Para realizar mais buscas, você pode esperar a renovação de sua quota (cada busca é renovada a cada 24 horas), ou fazer um upgrade da conta.</p>
                        <p>Para realizar um upgrade da conta e ter acesso a buscas ilimitadas, sua organização precisa ser selecionada para nosso próximo projeto piloto! </p>
                        <p>Clique abaixo para indicar interesse. Um dos membros de nosso time fundador deverá entrar em contato por email nas próximas 24 horas.</p>
                    </div>
                    <div class="button is-info" style="margin-left: auto;margin-right: auto;display: block; width: fit-content;"> Estou interessado!</div>
                </div>
                <div class="column is-3">
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import {lista} from '@/assets/localidades.js'
import {topExames} from '@/assets/topExames.js'
import DropdownAutoComplete from '@/components/DropdownAutoComplete.vue'
import MultiSelector from '@/components/MultiSelector.vue'
import {useExamSearch} from '@/stores/examSearch.js'
import {useAuthenticationStore} from "@/stores/authentication"
import {toast} from 'bulma-toast'
import axios from 'axios'

export default {
    name: 'BuscaExames',
    components: {
        DropdownAutoComplete, MultiSelector
    },
    setup() {
      const examQueryStore = useExamSearch()
      const authentication = useAuthenticationStore()
      return {examQueryStore,authentication}
    },
    data() {
        return {
            listaMunicipios: lista,
            listaAssetsTopExames: topExames,
            multiSelectorKey: 0,
            municipioSelecionado: "",
            municipioDropDownKey: 0,
            searchCredits: {
                total_search_credits: 0,
                current_search_credits: 0,
                wait_time: 0
            },
            selectOptions: [
                { text: '1km', value: 1 },
                { text: '2km', value: 2 },
                { text: '3km', value: 3 },
                { text: '5km', value: 5 },
                { text: '10km', value: 10 },
                { text: '30km', value: 30 }
            ],
            distanciaSelecionada: this.examQueryStore.raioBusca
        }
    },
    computed: {
        requiredFieldsAreFilled() {
            let filledFields = 0
            let messages = []
            if (this.examQueryStore.examesDemandados.length > 0) {
                filledFields++
            } else {messages.push('Favor selecionar pelo menos um exame.')}
            if (this.examQueryStore.municipio != '') {
                filledFields++
            } else {messages.push('Favor selecionar um município.')}
            if (this.examQueryStore.raioBusca > 0) {
                filledFields++
            } else {messages.push('Favor selecionar uma distância de busca.')}
            return [filledFields, messages]
        }
    },
    beforeMount() {
        this.getSearchCredits()
    },
    methods: {
        async getSearchCredits() {
            await axios
                .get('/api/v1/user-credits/exam-search/')
                .then(response => {
                    // console.log('getSearchCredits response.data:', response.data)
                    this.searchCredits = {
                        total_search_credits: response.data.total_search_credits,
                        current_search_credits: response.data.current_search_credits,
                        wait_time: response.data.wait_time_for_next_credit_in_hours
                    }
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        resetSearch() {
            //Transfer all selected exams back to exam list, and sort the list
            for (let i = 0; i < this.examQueryStore.examesDemandados.length; i++) {
                this.examQueryStore.listaTopExames.push(this.examQueryStore.examesDemandados[i]) 
            }
            this.examQueryStore.listaTopExames.sort((a, b) => {
              let fa = a.valor.toLowerCase(),
                  fb = b.valor.toLowerCase();
              if (fa < fb) {
                  return -1;
              }
              if (fa > fb) {
                  return 1;
              }
              return 0;
            })
            //Clear other variables
            this.examQueryStore.examesDemandados = []
            //Reset this.examQueryStore
            this.examQueryStore.setRaioBusca(null)
            this.examQueryStore.setMunicipio('')
            this.examQueryStore.resetExames()
            //Reset variables on this page
            this.municipioSelecionado = ''
            this.distanciaSelecionada = null
            //Reload component using component key
            this.municipioDropDownKey++
            this.multiSelectorKey++

        },
        setOptionMunicipio(option) {
            this.municipioSelecionado = option
            this.examQueryStore.setMunicipio(option)
            // console.log("Município selecionado: "+this.municipioSelecionado)
        },
        setOptionRaio(option) {
            // console.log('Function setOptionRaio called with argument', option)
            this.examQueryStore.setRaioBusca(option)
        },
        confirmSearch() {
            if (this.requiredFieldsAreFilled[0] == 3) {
                window.scrollTo(0,0);
                this.$router.push({ name: 'BuscaExamesResults'})
            }
            else {
                let messages = this.requiredFieldsAreFilled[1]
                // for message in messages toast the message
                for (let i = 0; i < messages.length; i++) {
                    toast({
                        message: messages[i],
                        type: "is-danger",
                        position: "bottom-right",
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                    })
                }
                window.scrollTo(0,0);
                // toast({
                //     position:'bottom-right',
                //     message: 'Favor preencher os campos de acordo com os requisitos.',
                //     type: 'is-danger',
                //     dismissible: true,
                //     pauseOnHover: true,
                //     duration: 2000
                // })
            }
        },
    }
}

</script>

<style>

</style>