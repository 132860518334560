<template>

    <slot v-if="userTier >= requiredTier"></slot>
    <button v-else :class="buttonClass+' blocked-button' " style="opacity: 0.5;" @click="accessBlocked">{{ buttonText }}</button>

    <!-- v-bind="{ class: buttonClass, style: buttonStyle, click: buttonClick, text: buttonText }" -->
</template>

<script>
import { useAuthenticationStore } from "@/stores/authentication"
import { useSlots } from 'vue'
import {toast} from 'bulma-toast'

export default {
    props: {
        requiredTier: {
            type: [String, Number],
            required: true,
        },
    },
    setup(props, context) {
        const authenticationStore = useAuthenticationStore()
        const userTier = authenticationStore.tier

        const slots = useSlots()
        let buttonClass = ''
        // console.log('context.attrs',context.attrs)
        // console.log('context.slots.default()',context.slots.default())
        // console.log('slots',slots[0])
        // console.log('slots.default()',slots.default())
        // console.log('slots.default()[0]',slots.default()[0])
        if (slots.default()[0].props === null) {
          buttonClass = 'button'
        }
        else {
          buttonClass = slots.default()[0].props.class
        }
        // console.log(buttonClass)
        let buttonText = ''
        if (typeof slots.default()[0].children === 'string') {
          buttonText = slots.default()[0].children
        }
        else {
          buttonText = slots.default()[0].children[0].children
        }


        function accessBlocked() {
          toast({
            message: 'Funcionalidade disponível apenas para usuários com maior nível de acesso.',
            type: 'is-warning',
            position: 'top-center',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
          })
        }

        return {
            userTier,
            buttonText,
            buttonClass,
            accessBlocked,
        }
    },
};
</script>

<style scoped>
/* .blocked-button {
  cursor: not-allowed;
} */

.XYZblocked-button::before {
  content: 'Funcionalidade disponível para usuários com maior nível de acesso.'; /*attr(title); /* Display title attribute as tooltip */
  position: absolute;
  bottom: 100%; /* Position tooltip above button */
  left: 50%; /* Center tooltip horizontally */
  transform: translateX(-50%); /* Center tooltip horizontally */
  padding: 2px 4px;
  background-color: #333333; /* Dark background color for tooltip */
  color: #fff; /* Light text color for tooltip */
  font-size: 16px;
  width: 200%;
  white-space: pre-wrap /* Wrap tooltip text */;
  opacity: 0; /* Hide tooltip by default */
}

.XYZblocked-button:hover::before {
  opacity: 1; /* Change tooltip opacity to 1 (visible) when button is hovered */
}
</style>