<template>
    <div class="page-login">
        <div class="columns">
            <div class="column is-4 is-offset-4">
                <h1 class="title">Login</h1>

                <form @submit.prevent="submitForm">
                    <div class="field">
                        <label>Email</label>
                        <div class="control">
                            <input type="email" name="email" class="input" v-model="email">
                        </div>
                    </div>

                    <div class="field">
                        <label>Senha</label>
                        <div class="control">
                            <input type="password" name="password" class="input" v-model="password">
                        </div>
                    </div>

                    <div class="notification is-danger" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button class="button is-success">Log in</button>
                        </div>
                    </div>
                    Esqueceu sua senha? <router-link to="/reset-senha">Clique aqui</router-link> para obter uma nova!
                </form>

                <hr>
                <p></p>
                <div>
                    <p>Ainda não tem uma conta? <router-link to="/sign-up">Clique aqui</router-link> para se cadastrar!</p>

                </div>
                <br>
                
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {useAuthenticationStore} from "@/stores/authentication"



export default {
    name: 'LogIn',
    setup() {
        const authentication = useAuthenticationStore()
        return {
            authentication
        } 
    },
    data() {
        return {
            email: '',
            password: '',
            errors: []
        }
    },
    methods: {
        async submitForm(e) {
            this.authentication.jwtLogOut()
            const formData = {
                email: this.email,
                password: this.password
            }
            await axios
                .post("/auth/jwt/create/", formData)
                .then(response => {
                    // console.log(response)
                    if (response.status !=401) {
                        this.authentication.jwtLogin(response.data)
                        this.$router.push('/dashboard/my-account')
                    }
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                        console.log(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.log(JSON.stringify(error.message))
                    } else {
                        console.log(JSON.stringify(error))
                    }
                })
            axios
                .get("/api/v1/users/me/")
                .then(response => {
                    this.authentication.username = response.data.first_name+' '+response.data.last_name
                    this.authentication.userId = response.data.id
                    this.authentication.email = response.data.email
                    this.$router.push('/dashboard/my-account')
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        }
    }
}
</script>

<style scoped>
.title {
    margin-top: 2rem;
}
@media (max-width: 769px) {
    .field {
        max-width: 50%;
        margin-right: auto;
        margin-left: auto;
    }
    .title {
        margin-left: 25%;
    }
}
</style>