<template>
      <div class="container">
        <div class="columns is-multiline">
            <!-- {{exam}} -->
            <div class="column is-12">
                <h1 class="title pb-0 mb-0 has-text-danger has-background-warning has-text-centered"><strong>Atenção!</strong></h1>
                <h2 class="has-text-danger has-background-warning has-text-centered"><strong>A ação que você está prestes a realizar é irreversível.</strong></h2>
            </div>
            <hr>
            <div class="column is-6">
                <div class="box">
                    <h2 class="subtitle"><strong>{{exam.Exame}}</strong></h2>
                    <hr>
                    <p><strong>Data de realização: </strong> {{exam['Data Realização']}} </p>
                    <p><strong>Data máx. de renovação: </strong> {{exam['Data Máx. Renovação']}} </p>
                </div>
            </div>

            <div class="column is-6">
                <div class="box">
                    <div class="content">
                        <label class="checkbox pb-2">
                            <input type="checkbox" v-model="checkConfirm">
                            Eu entendo que deletar o exame ao lado é uma ação irreversível e que um novo exame deverá ser registrado caso necessário.
                        </label>
                        <p class="has-text-danger pb-4" v-show="submitDeclined">Você deve confirmar na caixa acima antes de prosseguir.</p> 
                        <div class="buttons"> <!-----------------------------------------------------------Make buttons centered -->
                            <button class="button is-info" @click="$emit('closeDeleteModal')">Cancelar e Retornar</button>
                            <button class="button is-danger is-outlined" @click="submitDelete">Confirmar e Deletar</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import axios from 'axios'

// import DataTable from '@/components/dataTable/DataTable.vue'



export default {
    name: 'PastExamDeleteComponent',
    props: ['exam','examId','modalIsActive']
    ,
    components: {

    },
    data() {
        return {
            examInner: '',
            tableAPIParams: {
                'examId': this.examId
            },
            numeroexams: 0,
            numeroRelacoesDeTrabalho: 0,
            responseBeforeDelete: {},
            checkConfirm: false,
            submitDeclined: false
        }
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    methods: {
        async submitDelete() {
            if (this.checkConfirm) {
                // console.log('CompanyDelete_submitDelete called. Axios delete request about to be sent.')
                axios
                    .delete('/api/v1/corporate/employee/pastexams/', { params: {'examId': this.exam.pk} })
                    .then(response => {
                        this.$emit('closeDeleteModal')
                    })
                    .catch(error => {
                        console.log(error.request.response)
                    })
            }
            else {
                this.submitDeclined = !this.submitDeclined
            }
        },
    }

}
</script>

<style scoped>

.box {
    height: 100%;
}

</style>