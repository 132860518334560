<template>
    <div class="page-my-account">
        <!-- <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li class="is-active"><router-link to="/dashboard/my-account" aria-current="true">My account</router-link></li>
            </ul>
        </nav> -->                    
        <div class="columns is-multiline">
            <div class="column is-12 is-multiline pt-0">
                <!-- TAB EXAMES DISPONÍVEIS -->
                <section class="tab" v-show="isTabActive('ExamesDisponiveis')">
                    <div style="margin:2rem;margin-top: 0;">
                        <div class="columns is-multiline">
                            <div class="column is-6">
                                <h4 class="title is-4" style="margin-bottom:0">Gerir Ofertas de Exames</h4>
                            </div>
                            <div class="column is-6">
                                <button 
                                    class="button is-info is-centered strong"
                                    @click="activateModal('Modal_AddExamOffer')"
                                    style="float:right"
                                >
                                    <strong> Criar Nova Oferta </strong>
                                </button>
                            </div>
                            <div class="column is-12">
                                <DataTable
                                    apiURL='/api/v1/clinics/exam-offers/'
                                    :apiParams="{}"
                                    ref="dataTableExamOfferRef"
                                    :hasSearchField="true"
                                    :hasActionsColumn="true"
                                    :actionButtons="{
                                        'Info': 'Gerenciar',
                                        // 'Edit': 'Editar',
                                        // 'Delete': 'Deletar'
                                    }"
                                    :fieldsToHide="['pk']"
                                    :key="dataTableKey"
                                    @toggleDetailsModal="activateModalExamOfferDetails"
                                    @toggleDeleteModal="activateModalExamOfferDelete"
                                />

                            </div>
                        </div>
                    </div>
                    <br>
                </section>             
            </div>
            
        </div>
        <hr>
    </div>
    <!-- MODAL - ADD EXAM OFFER -->
    <div class="modal" :class="{ 'is-active': isModalActive('Modal_AddExamOffer') }" v-if="isModalActive('Modal_AddExamOffer')">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Adicionar Oferta de Exames</p>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body">
                <form-generic-serializer
                    ref="Modal_AddExamOffer_Form"
                    :object="examOfferToAdd"
                    apiURL='/api/v1/clinics/exam-offers/'
                    formValidationSchema="addExamOfferSchema"
                    @schemaIsValidChanged="formEvent_ExamOfferChanged"
                />
                <!-- <h5>* Apenas exames que ainda não estejam disponíveis podem ser adicionados.</h5> -->
            </section>
            <footer class="modal-card-foot">
                <div class="buttons"> 
                    <button class="button is-danger" @click="closeModal()">Cancelar</button>
                    <button class="button is-success" @click="submitAddExamOfferForm">Criar Oferta</button>
                </div>
            </footer>
        </div>
    </div>
    <!-- MODAL - MANAGE EXAM OFFER -->
    <div class="modal" :class="{ 'is-active': isModalActive('Modal_ExamOfferDetails') }" v-if="isModalActive('Modal_ExamOfferDetails')">
        <div class="modal-background"></div>
        <div class="modal-card" id="Modal_ManageExamOffer">
            <header class="modal-card-head">
                <p class="modal-card-title">Gerenciar Oferta de Exames</p>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body" style="modal-content-width:1200px">
                <ExamOfferDetails
                    :modalObject="modalInputData"
                    :modalIsActive="isModalActive('Modal_ExamOfferDetails')"
                    @closeManageOfferModalWithReload="closeModal_ManageExamOffer()"
                    />
                    <!-- @closeDeleteModal="closeModal()"
                    @closeDeleteModalWithReload="closeModal_ExamOfferDelete()" -->
            </section>
        </div>
    </div>    
    <!-- MODAL - DELETE EXAM OFFER -->
    <!-- <div class="modal" :class="{ 'is-active': isModalActive('Modal_ExamOfferDelete') }" v-if="isModalActive('Modal_ExamOfferDelete')">
        <div class="modal-background"></div>
        <div class="modal-card" id="Modal_ExamOfferDelete">
            <header class="modal-card-head">
                <p class="modal-card-title">Deletar Oferta de Exames</p>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body" style="modal-content-width:1200px">
                <ExamOfferDelete
                    :examOffer="modalInputData"
                    :modalIsActive="isModalActive('Modal_ExamOfferDelete')"
                    @closeDeleteModal="closeModal()"
                    @closeDeleteModalWithReload="closeModal_ExamOfferDelete()"
                />
            </section>
        </div>
    </div> -->

</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import { Field, Form, ErrorMessage } from 'vee-validate';
import { ref } from "vue"
// import yup from 'yup';

import EditGenericSerializer from '../EditGenericSerializer.vue'
import FormGenericSerializer from '../FormGenericSerializer.vue'
import ExamOfferDelete from '../clinics/ExamOfferDelete.vue';
import ExamOfferDetails from '../clinics/ExamOfferDetails.vue';
import {useAuthenticationStore} from "@/stores/authentication"
import { validationSchemaDictionary } from '@/utilities/validations.js'
import DataTable from '@/components/dataTable/DataTable.vue'
import { toRaw} from 'vue'

export default {
    name: 'ClinicaPerfil',
    components: {
        DataTable,
        Field, Form, ErrorMessage,
        EditGenericSerializer,
        FormGenericSerializer,
        ExamOfferDelete,
        ExamOfferDetails,
    },
    setup() {
        const authentication = useAuthenticationStore()
        return {
            authentication
        } 
    },
    data() {
        // let schema = yup.object().shape({
        //     nome: yup.string().required('O nome é obrigatório'),
        //     sobreNome: yup.string().required('O sobrenome é obrigatório'),
        //     cpf: yup.string().required().length(11, 'O CPF deve conter 11 dígitos'),
        //     email_corporativo: yup.string().required().email('Email inválido'),
        //     email_pessoal: yup.string().required('O email pessoal é obrigatório').email('Email inválido'),
        // });
        // let clinicFormData = {}
        return {
            activeModal: '',
            activeTab: 'ExamesDisponiveis',
            examOfferToAdd: {},
            examOffer_HasValidSchema: false,
            changePassword: {'current_password': '', 'new_password': '', 're_new_password': ''},
            clinicFormKey: ref(0),
            clinicFormData: {},
            dataTableKey: ref(0),
            formValidationSchema: 'addExamOfferSchema',
            modalInputData: {},
            modalInputID: null,
        }
    },
    async created() {
        // make an axios call to get the clinic's details
        // this.getClinicDetails()
        
    },
    computed: {
        schemaIsValid() {
            return validationSchemaDictionary[this.formValidationSchema].isValidSync(this.clinicFormData)
        },
    },
    methods: {
        activateModal(modalName, data='') {
            // console.log('Organizacao_activateModal Called for modal:', modalName, ' and data:',data)
            const rawData = toRaw(data)
            if (data!='') {
                // console.log('activateModal data recognised. Received data:',data)
                this.modalInputData = rawData
                // console.log('modalInputData:',this.modalInputData)
                this.modalInputID = rawData.pk
                // console.log('modalInputID:',this.modalInputID)
            }
            this.activeModal = modalName
            // console.log('activeModal set to:',this.activeModal)
        },
        activateModalExamOfferDelete(data) {
            this.activateModal('Modal_ExamOfferDelete',data)
        },
        activateModalExamOfferDetails(data) {
            // console.log('activateModalExamOfferDetails Called with data:', data)
            this.activateModal('Modal_ExamOfferDetails',data)
        },
        closeModal() {
            this.activeModal = ''
        },
        closeModal_ExamOfferDelete() {
            this.dataTableKey++
            this.closeModal()
        },
        closeModal_ManageExamOffer() {
            this.dataTableKey++
            this.closeModal()
        },
        formEvent_ExamOfferChanged(data) {
            this.examOffer_HasValidSchema = data
        },
        // getClinicDetails() {
        //     axios
        //         .get('/api/v1/clinics/v0/')
        //         .then(response => {
        //             // console.log('getClinicDetails response.data:',response.data)
        //             this.clinicFormData = response.data
        //         })
        //         .catch(error => {
        //             console.log(JSON.stringify(error))
        //         })
        // },
        isModalActive(modalName) {
            return this.activeModal === modalName
        },
        isTabActive(tabItem) {
            return this.activeTab === tabItem
        },
        setActiveTab(tabItem) {
            this.activeTab = tabItem
        },
        submitAddExamOfferForm() {
            if (this.examOffer_HasValidSchema) {
                axios
                    .post('/api/v1/clinics/exam-offers/', this.examOfferToAdd)
                    .then(response => {
                        // this.$router.push('/organizacao')
                        // console.log(response)
                        // this.businessUnits.unshift(response.data)
                        this.$refs.dataTableExamOfferRef.table.rows.unshift(response.data)
                    })
                    .catch(error => {
                        // console.log(JSON.stringify(error))
                        console.log(error)
                    })
                    .then( () => { 
                        this.dataTableKey++
                        this.closeModal()
                    })
            }
            else {
                this.$refs.Modal_AddExamOffer_Form.$refs.form.validate()
                toast({
                    position:'bottom-right',
                    message: 'Favor preencher os campos de acordo com os requisitos.',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000
                })
            }
        },

        submitEditClinic() {
            // console.log('this.formValidationSchema:', this.formValidationSchema)
            // console.log('this.clinicFormData:', this.clinicFormData)
            // console.log('this.schemaIsValid:', this.schemaIsValid)
            if (this.schemaIsValid) {
                axios
                    .put('/api/v1/clinics/v0/', this.clinicFormData)
                    .then(response => {
                        // console.log(response)
                        this.clinicFormKey++
                        toast({
                            position:'bottom-right',
                            message: 'Dados da clínica atualizados com sucesso!',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000
                        })
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                    })
            }
            else {
                console.log('this.refs:',this.$refs)
                this.$refs.clinicForm.form.validate()
                toast({
                    position:'bottom-right',
                    message: 'Favor preencher os campos de acordo com os requisitos.',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000
                })
            }
        }
    }
}
</script>

<style scoped>
.columns {
    margin-top: 0px;
}

.tabs {
    margin-bottom: 0px;
}
.tabs a {
    color: rgba(128, 128, 128, 0.802);
}

.tabs li.is-active a {
    color: #000;
    font-weight: 600;
}
.tab {
    /* padding-top: 2rem; */
    padding-bottom: 2rem;
    /* padding-right: 1rem; */
    /* border-color: hsl(0deg, 0%, 86%);
    border-style: solid;
    border-width: 1px;
    border-top-color: transparent; */
    margin-bottom: 0;
}

hr {
      width: 95%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 2px;
      margin-bottom: 1rem;
    }

#box-meuPerfil {
    height: 100%;
}

li {
    color: gray;
}

/* .tabs.is-boxed li.is-active a {
    font-weight: 500;
} */

#box-perfilCorporativo {
    height: 100%;
}

#Modal_ManageExamOffer {
    width: 75%;
}
</style>