<template>
	<section class="hero is-large is-info" id="heroBanner">
		<div class="hero-body background" :style="{'background-image':'url('+ require('@/assets/connections.webp') +')'}">
			<div class="columns is-multiline" >
				<div class="column is-1 is-multiline"></div>
				<div class="column is-5 is-multiline pl-6" id="HeroLeft" style="height:fit-content;">
					<!-- <div class="column is-12">
						<p class="title" style="color:black">
							Controle, Transparência, Eficiência.
						</p>
					</div> -->
					
					<div class="column is-12 pb-0">
						<p class="title">
							O FUTURO DA SAÚDE OCUPACIONAL COMEÇA AQUI
							<!-- O Futuro da Saúde Ocupacional Começa Aqui -->
						</p>
					</div>
					<!-- <ScrollParallax down="true" speed="0.1"> -->
					<div class="column is-12">
						<p class="subtitle mb-2" id="HeroSubTitle">
							A plataforma {{$companyName}} representa um ecosistema sem paralelos na gestão de exames ocupacionais.
						</p>
						<p class="subtitle" id="HeroSubTitle">
							Da previsão de demanda para grandes empresas até a automação de tarefas para pequenas clínicas, em um só lugar.
						</p>
					</div>
					<div class="column is-12 pt-6">
						<router-link :to="{name:'SignUp'}" class="button is-info">
							<strong>EXPERIMENTE O FUTURO  >></strong> 
						</router-link>	
					</div>
					<!-- </ScrollParallax> -->
				</div>
				<div class="column is-6 is-multiline" id="imageContainer" style="max-height:100%;">
					<!-- <ScrollParallax down="true" speed="0.1"> -->
						<div style="width:fit-content;margin:auto"><img src="@/assets/ImageLanding.webp" alt="TelasDoSoftware" id="heroimg" style="max-height: 376px;max-width:376px;margin:0 auto;width:fit-content;"></div>
					<!-- </ScrollParallax> -->
				</div>

			</div>
		</div>
	</section>
</template>

<script>
import axios from 'axios'
import ScrollParallax from 'vue3-parallax/src/components/ScrollParallax.vue'

export default {
	name: 'HeroSection',
	components: {
		ScrollParallax
	},
	//   inject: ['companyName2'],
	setup() {

		},
	created() {
		this.dataStream()
	},
	data () {
		return {
			// companyName2: this.companyName2
			
		}
	},
	methods: {
		dataStream() {
			// console.log(window.navigator.userAgent)
			// console.log(window.innerHeight)
			// console.log(window.innerWidth)
			try {
				axios
				.get('/api/v1/datastream/', {params: {
					'datastream': 'Landing_Page_Access:Hero_Section',
					'window': {
						'userAgent': window.navigator.userAgent,
						'innerHeight': window.innerHeight,
						'innerWidth': window.innerWidth,
					}
				}})
				.then(response => {
				})
				.catch(error => {
					console.log(error)
				})
			}
			catch {

			}
		}
	}
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=AlfaSlabOne&display=swap');
.hero {
	padding-left: 0;
	
}
.hero.is-large .hero-body {
	padding: 0;
	padding: 3rem 0;
}
#heroimg{
	width: 100%;
}
.background {
	background-color: black;
	background-size: fill;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	/* height: 100%;
	width: 100%; */
}
/* #HeroLeft {
	margin-top: 5rem;
} */
.title {
	font-family: sans-serif;
	font-weight: 800;
	color: white;
}
.subtitle {
	font-family: sans-serif;
	font-size: 1.1rem;
}

#HeroSubTitle {
	color: rgba(255, 255, 255, 0.6);
	font-weight: 400;
	width: 75%;
	text-align: justify;
}

#imageContainer {
  align-items: center;
  display: table-cell;
  justify-content: center;
  height: 100%;
  margin:auto;
}
</style>

