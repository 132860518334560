

export const requiredTiers = {
    card_pacote_clinica: 2,
    card_pacote_clinica_multi: 10,
    organizacao_adicionar_companhia: 3,
    organizacao_adicionar_unidade_negocio: 3,
    organizacao_adicionar_workarea: 3,
    organizacao_workarea_details_gerenciar_exames: 3,
    datatables_info: 2,
    datatables_edit: 3,
    datatables_delete: 3,
    time_adicionar_funcionario: 3,
    time_employee_details_adicionar_exame: 3,
    requisicoes_gerenciar_requisicao_cancelar_requisicao: 3,
}