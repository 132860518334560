<template>
    <div class="page-my-account">
        <!-- <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li class="is-active"><router-link to="/dashboard/my-account" aria-current="true">My account</router-link></li>
            </ul>
        </nav> -->
        <h1 class="title" style="margin-bottom:24px">Perfil Usuário</h1>                       
        <div class="columns is-multiline">
            <div class="column is-6 is-multiline pt-0">
                <div class="box pt-0 pb-4" id="box-meuPerfil"> <!-- BOX MEU PERFIL  -->
                    <h1 class="subtitle is-4 mb-4"><strong>Meu Perfil</strong></h1>
                    <hr />
                    <!-- <p>userInfo: {{userInfo}} </p>  -->
                    <!-- Create a display for the userInfo information -->
                    <div class="has-text-centered">
                        <h1 class="subtitle is-5 is-underlined"><strong>{{userInfo.person.first_name}} {{userInfo.user.last_name}}</strong></h1> 
                    </div>
                    <p class="ml-4">
                        <strong>CPF:</strong> {{userInfo.person.cpf}}<br />
                    </p>
                    <p class="ml-4">
                        <strong>Email Corporativo:</strong> {{userInfo.user.email}}<br />
                    </p>
                    <p class="ml-4">
                        <strong>Email Pessoal:</strong> {{userInfo.person.personalEmail}}<br />
                    </p>
                    <p class="ml-4">
                        <strong>Data de Início:</strong> {{userInfo.user.start_date}}<br />
                    </p>
                    <p class="ml-4">
                        <strong>Funções:</strong>
                        <ul>
                            <li class="ml-6" v-for="(role,index) in userInfo.user.roles" :key="index">
                                {{role}}
                            </li>
                        </ul>
                    </p>
                    <div class="buttons is-right">
                        <button class="button is-info" @click="openModal('EditPersonalProfile')">Editar Perfil Pessoal</button>
                    </div>
                </div>                
            </div>
            <div class="column is-6 pt-0">
                <!-- {{ userInfo }} -->
                <!-- <hr> -->
                <!-- {{ authentication }} -->
                <div class="box pt-0 pb-4" id="box-perfilCorporativo" v-if="organisationalProfile=='Corporate'"> <!-- BOX PERFIL EMPRESA  -->
                    <h1 class="subtitle is-4"><strong>Perfil da Organização</strong></h1>
                    <hr />
                    <div class="has-text-centered">
                        <h1 class="subtitle is-5 is-underlined"><strong>{{userInfo.corporateProfile.corporateGroup}}</strong></h1> 
                    </div>
                    <p class="ml-4"><strong>Estrutura do Grupo:</strong> </p>
                    <p class="ml-4">
                        <strong># Companhias:</strong> {{userInfo.corporateProfile.numberOf_Companies}}<br />
                    </p>
                    <p class="ml-4">
                        <strong># Unidades de Negócio:</strong> {{userInfo.corporateProfile.numberOf_BusinessUnits}}<br />
                    </p>
                    <p class="ml-4">
                        <strong># Work Areas:</strong> {{userInfo.corporateProfile.numberOf_WorkAreas}}<br />
                    </p>
                    <p class="ml-4">
                        <strong># Funcionários:</strong> {{userInfo.corporateProfile.numberOf_Employments}}<br />
                    </p>
                    <hr>

                    <div class="columns ml-4">
                        <div class="column is-3" style="padding-left:0rem;padding-right:0rem" >     
                            <strong>Créditos de busca:</strong>                    
                        </div>
                        <div class="column is-8" style="padding-left:0rem;padding-right:0rem" v-if="searchCreditBarLoading">
                            <progress class="progress is-dark"  max="100" style="margin-top:5px"></progress>                        
                        </div>
                        <div class="column is-8" style="padding-left:0rem;padding-right:0rem" v-else>
                            <progress class="progress" :class="searchBarColour"  :value="searchCredits.current_search_credits" :max="searchCredits.total_search_credits" style="margin-top:5px"></progress>                        
                        </div>
                        <div class="column is-1" style="padding-left:0rem;padding-right:0rem" >
                            <strong class="ml-1">{{ searchCredits.current_search_credits }}/{{ searchCredits.total_search_credits }} </strong>                            
                        </div>
                    </div>

                </div>
                <div class="box pt-0 pb-4" id="box-perfilCorporativo" v-if="organisationalProfile=='Clinic'"> <!-- BOX PERFIL CLINICA  -->
                    <h1 class="subtitle is-4"><strong>Perfil da Organização</strong></h1>
                    <hr />
                    <div class="has-text-centered">
                        <h1 class="subtitle is-5 is-underlined"><strong>{{userInfo.corporateProfile.name}}</strong></h1> 
                    </div>
                    <!-- <p class="ml-4"><strong>Estrutura da Clínica</strong> </p> -->
                    <br>
                    <p class="ml-4">
                        <strong>Localidade:</strong> {{userInfo.corporateProfile.municipio}}<br />
                    </p>
                    <p class="ml-4">
                        <strong>Tipo de Agendamento:</strong> {{userInfo.corporateProfile.tipo_agendamento}}<br />
                    </p>
                    <p class="ml-4">
                        <strong># Exames Disponíveis:</strong> {{userInfo.corporateProfile.number_of_exames_disponiveis}}<br />
                    </p>
                    <p class="ml-4">
                        <strong># Tabelas de Oferta:</strong> {{userInfo.corporateProfile.number_of_offers}}<br />
                    </p>
                    <div class="buttons is-right" style="margin-top:1rem">
                        <button class="button is-info" @click="">Editar Perfil Clínica</button>
                    </div>
                </div>
                <div class="box pt-0 pb-4" id="box-perfilCorporativo" v-if="organisationalProfile=='None'"> <!-- BOX ALTERNATIVO PERFIL CORPORATIVO  -->
                    <h1 class="subtitle is-4"><strong>Perfil da Organização</strong></h1> 
                    <hr />
                    <div class="has-text-centered">
                        <h1 class="subtitle is-5 is-underlined"><strong>Perfil ainda não associado a nenhuma organização.</strong></h1>
                        <h2>Favor contatar o administrador do sistema para alocação adequada.</h2> 
                    </div>
                </div>   
            </div>
        </div>
        <!-- MODAL - EDIT PROFILE -->
        <div class="modal" :class="{ 'is-active': isModalActive('EditPersonalProfile') }" v-if="isModalActive('EditPersonalProfile')">
            <div class="modal-background"></div>
            <div class="modal-card" id="EditPersonalProfile">
                <header class="modal-card-head">
                    <p class="modal-card-title">Editar Perfil Pessoal</p>
                    <button class="delete" aria-label="close" @click="closeModalWithReload()"></button>
                </header>
                <section class="modal-card-body">
                    <!-- {{schema}} -->
                    <!-- <Form class="form" @submit="submit" :validation-schema="schema">
                        <div class="field">
                            <label class="label">Email</label>
                            <p class="control has-icons-left has-icons-right">
                                <Field name="email" class="input" placeholder="Email" v-model="userInfo.person.personalEmail" />
                                <span class="icon is-small is-left">
                                    <i class="fas fa-user"></i>
                                </span>
                                <span class="icon is-small is-right">
                                    <i class="fas fa-check"></i>
                                </span>
                            </p>
                        </div>
                        <ErrorMessage name="email" style="color:red"/>
                        <div class="field">
                            <label class="label">Password</label>
                            <p class="control has-icons-left has-icons-right">
                                <Field name="password" type="password" class="input" placeholder="Password" />
                                <span class="icon is-small is-left">
                                    <i class="fas fa-user"></i>
                                </span>
                                <span class="icon is-small is-right">
                                    <i class="fas fa-check"></i>
                                </span>
                            </p>
                        </div>
                        <ErrorMessage name="password" style="color:red"/>
                        <div><button>Submit</button></div>
                        
                    </Form>
                    <hr> -->
                    <Form ref="form" class="form" :validation-schema="schema">
                        <div class="field">
                            <label class="label">Nome</label>
                            <p class="control has-icons-left has-icons-right">
                                <Field name="nome" class="input" type="text" placeholder="Nome" v-model="userInfo.person.first_name" />
                                <span class="icon is-small is-left">
                                    <i class="fas fa-user"></i>
                                </span>
                                <span class="icon is-small is-right">
                                    <i class="fas fa-check"></i>
                                </span>
                            </p>
                            <ErrorMessage name="nome" style="color:red"/>
                        </div>
                        <div class="field">
                            <label class="label">Sobrenome</label>
                            <p class="control has-icons-left has-icons-right">
                                <Field name="sobreNome" class="input" type="text" placeholder="Sobrenome" v-model="userInfo.user.last_name" />
                                <span class="icon is-small is-left">
                                    <i class="fas fa-user"></i>
                                </span>
                                <span class="icon is-small is-right">
                                    <i class="fas fa-check"></i>
                                </span>
                            </p>
                            <ErrorMessage name="sobreNome" style="color:red"/>
                        </div>
                        <div class="field">
                            <label class="label">CPF</label>
                            <p class="control has-icons-left has-icons-right">
                                <Field name="cpf" class="input" type="text" placeholder="CPF" v-model="userInfo.person.cpf" />
                                <span class="icon is-small is-left">
                                    <i class="fas fa-id-card"></i>
                                </span>
                                <span class="icon is-small is-right">
                                    <i class="fas fa-check"></i>
                                </span>
                            </p>
                            <ErrorMessage name="cpf" style="color:red"/>
                        </div>
                        <div class="field">
                            <label class="label">Email Corporativo</label>
                            <p class="control has-icons-left has-icons-right">
                                <Field name="email_corporativo" class="input" type="text" placeholder="Email Corporativo" v-model="userInfo.user.email" />
                                <span class="icon is-small is-left">
                                    <i class="fas fa-envelope"></i>
                                </span>
                                <span class="icon is-small is-right">
                                    <i class="fas fa-check"></i>
                                </span>
                            </p>
                            <ErrorMessage name="email_corporativo" style="color:red"/>
                        </div>
                        <div class="field">
                            <label class="label">Email Pessoal</label>
                            <p class="control has-icons-left has-icons-right">
                                <Field name="email_pessoal" class="input" type="email" placeholder="Email Pessoal" v-model="userInfo.person.personalEmail" />
                                <span class="icon is-small is-left">
                                    <i class="fas fa-envelope"></i>
                                </span>
                                <span class="icon is-small is-right">
                                    <i class="fas fa-check"></i>
                                </span>
                            </p>
                            <ErrorMessage name="email_pessoal" style="color:red"/>
                        </div>
                    </Form>                    
                </section>
                <footer class="modal-card-foot">
                    <div class="buttons"> <!-----------------------------------------------------------XYZ Make buttons centered -->
                        <button class="button is-danger" @click="closeModalWithReload()">Cancelar</button>
                        <button class="button is-success" @click="submitEditPersonalProfile()">Confirmar</button>
                    </div>
                </footer>
            </div>
        </div>
        <hr>
        <div class="buttons">
            <button @click="openModal('ChangePassword')" class="button is-info">Trocar Senha</button>
            <button @click="logout()" class="button is-danger">Log out</button>
        </div>
        <!-- MODAL - CHANGE PASSWORD -->
        <div class="modal" :class="{ 'is-active': isModalActive('ChangePassword') }" v-if="isModalActive('ChangePassword')">
            <div class="modal-background"></div>
            <div class="modal-card" id="ChangePassword">
                <header class="modal-card-head">
                    <p class="modal-card-title">Alterar Senha</p>
                    <button class="delete" aria-label="close" @click="closeModalWithReload()"></button>
                </header>
                <section class="modal-card-body">
                    <div class="field">
                        <label class="label">Senha Atual</label>
                        <p class="control has-icons-left has-icons-right">
                            <input class="input" type="password" placeholder="SenhaAtual" v-model="changePassword.current_password">
                            <span class="icon is-small is-right">
                                <i class="fas fa-check"></i>
                            </span>
                        </p>
                    </div>
                    <div class="field">
                        <label class="label">Nova Senha</label>
                        <p class="control has-icons-left has-icons-right">
                            <input class="input" type="password" placeholder="SenhaNova" v-model="changePassword.new_password">
                            <span class="icon is-small is-right">
                                <i class="fas fa-check"></i>
                            </span>
                        </p>
                    </div>
                    <div class="field">
                        <label class="label">Confirmar Nova Senha</label>
                        <p class="control has-icons-left has-icons-right">
                            <input class="input" type="password" placeholder="SenhaNova2" v-model="changePassword.re_new_password">
                            <span class="icon is-small is-right">
                                <i class="fas fa-check"></i>
                            </span>
                        </p>
                    </div>
                    
                </section>
                <footer class="modal-card-foot">
                    <div class="buttons"> <!-----------------------------------------------------------XYZ Make buttons centered -->
                        <button class="button is-danger" @click="closeModal()">Cancelar</button>
                        <button class="button is-success" @click="setPassword()">Confirmar</button>
                    </div>
                </footer>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

import EditGenericSerializer from '../../components/EditGenericSerializer.vue'
import {useAuthenticationStore} from "@/stores/authentication"

export default {
    name: 'MyAccount',
    components: {
        Field, Form, ErrorMessage,
        EditGenericSerializer
    },
    setup() {
        const authentication = useAuthenticationStore()
        return {
            authentication
        } 
    },
    data() {
        let schema = yup.object().shape({
            nome: yup.string().required('O nome é obrigatório'),
            sobreNome: yup.string().required('O sobrenome é obrigatório'),
            cpf: yup.string().required().length(11, 'O CPF deve conter 11 dígitos'),
            email_corporativo: yup.string().required().email('Email inválido'),
            email_pessoal: yup.string().required('O email pessoal é obrigatório').email('Email inválido'),
        });
        return {
            schema,
            userInfo: {
                'person': {},
                'user': {
                    'roles': []
                },
                'corporateProfile': {
                    'corporateGroup': '',
                    'numberOf_Companies': '',
                    'numberOf_BusinessUnits': '',
                    'numberOf_WorkAreas': '',
                    'numberOf_Employments': '',
                }
            },
            activeModal: '',
            changePassword: {'current_password': '', 'new_password': '', 're_new_password': ''},
            searchCreditBarLoading: false,
            searchCredits: {},
            searchBarColour: '',
        }
    },
    async created() {
        await this.getUserInfo()
        await this.getUserDetails()
        if (this.organisationalProfile === 'Corporate') {
            await this.getSearchCredits()
        }
    },
    computed: {
        schemaIsValid() {
            return this.schema.isValidSync({
                nome: this.userInfo.person.first_name,
                sobreNome: this.userInfo.user.last_name,
                cpf: this.userInfo.person.cpf,
                email_corporativo: this.userInfo.user.email,
                email_pessoal: this.userInfo.person.personalEmail,                
            })
        },
        organisationalProfile() {
            // console.log(this.userInfo)
            if (this.authentication.userRoles.includes(7)) {
                return 'Corporate'
            }
            if (this.authentication.userRoles.includes(8)) {
                return 'Clinic'
            }
            // if (!this.userInfo.corporateProfile) {
            return 'None'
            // }
        }
    },
    methods: {
        async getUserInfo() {
            // console.log('getUserInfo()')
            await axios
                .get("/api/v1/users/me/")
                .then(response => {
                    // console.log(response)
                    // this.authentication.setUserDetails({'email': response.data.email, 'id': response.data.id, 'firstName': response.data.first_name,'lastName': response.data.last_name})
                    this.authentication.setUserDetails(response.data)
                })
                .catch(error => {
                    console.log(JSON.stringify(error.message))
                })
            // await axios
            //     .get('/api/v1/userdetails/')
            //     .then(response => {
            //         this.userInfo = response.data
            //     })
            //     .catch(error => {
            //         console.log(JSON.stringify(error))
            //     })
        },
        async getUserDetails() {
            // console.log('getUserDetails()')
            await axios
                .get('/api/v1/userdetails/')
                .then(response => {
                    // console.log('getUserDetails response.data:', response.data)
                    this.userInfo = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        async getSearchCredits() {
            this.searchCreditBarLoading = true
            await axios
                .get('/api/v1/user-credits/exam-search/')
                .then(response => {
                    // console.log('getSearchCredits response.data:', response.data)
                    this.searchCredits = {
                        total_search_credits: response.data.total_search_credits,
                        current_search_credits: response.data.current_search_credits
                    }
                    let ratio_of_current_to_total = this.searchCredits.current_search_credits / this.searchCredits.total_search_credits
                    if (ratio_of_current_to_total < 0.2) {
                        this.searchBarColour = 'is-danger'
                    } else if (ratio_of_current_to_total < 0.5) {
                        this.searchBarColour = 'is-warning'
                    } else {
                        this.searchBarColour = 'is-info'
                    }
                    // console.log('response.data:', response.data)
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })

            this.searchCreditBarLoading = false
        },
        setPassword() {
            axios
            .post('/auth/users/set_password/', this.changePassword)
            .then(response => {
                if (response.status == 204) {
                    this.changePassword.current_password=''
                    this.changePassword.new_password=''
                    this.changePassword.re_new_password=''
                    this.closeModal()
                    toast({
                        position: 'top-center',
                        message: 'Senha alterada com sucesso.',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                    })
                } else {
                    this.closeModal()
                    toast({
                        position: 'top-center',
                        message: 'Erro. Sua senha não foi alterada. Tente novamente mais tarde.',
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                    })
                }
            })
            .catch(error => {
                console.log(JSON.stringify(error))
            })
        },
        logout() {
            this.authentication.jwtLogOut()
            this.$router.push('/')
        },
        openModal(modal) {
            console.log('Opening modal: ' + modal)
            this.activeModal = modal
        },
        closeModal() {
            this.activeModal = ''
        },
        closeModalWithReload() {
            // reload data
            this.getUserDetails()
            this.closeModal()
        },
        isModalActive(modalName) {
            return this.activeModal === modalName
        },
        submitEditPersonalProfile() {
            if (this.schemaIsValid) {
                axios
                    .put('/api/v1/userdetails/', this.userInfo)
                    .then(response => {
                        console.log(response)
                        this.closeModal()
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                    })
            }
            else {
                this.$refs.form.validate()
                toast({
                    position:'bottom-right',
                    message: 'Favor preencher os campos de acordo com os requisitos.',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000
                })
            }
        }
    }
}
</script>

<style scoped>
.columns {
    margin-top: 0px;
}

hr {
      width: 95%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 2px;
      margin-bottom: 1rem;
    }

#box-meuPerfil {
    height: 100%;
}

#box-perfilCorporativo {
    height: 100%;
}
</style>