import * as yup from 'yup'; // <-- This can be optimised

function emptyStringToNull(value, originalValue) {
    if (typeof originalValue === 'string' && originalValue === '') {
      return null;
    }
    return value;
}

function commasToPoints(value, originalValue) {
    // Function that converts the commas to points in the originalValue string
    if (typeof originalValue === 'string' && originalValue.includes(',')) {
        return parseFloat(originalValue.replace(',', '.'));
    }
    return value;

}

const addAvailableExamSchema = yup.object().shape({ // Repeats businessUnitEditSchema
    availableExam: yup.number().required('Favor selecionar um exame').moreThan(0, 'Favor selecionar um dos exames'),
});

const addBusinessUnitSchema = yup.object().shape({ // Repeats businessUnitEditSchema
    CompanhiaPK: yup.number().required('Favor selecionar uma companhia').moreThan(0, 'Favor selecionar uma das companhias associadas'),
    Nome: yup.string().required('O nome é um campo obrigatório'),
    Código: yup.string().required('O código é um campo obrigatório').min(3, 'O código deve ter no mínimo 3 caracteres/dígitos'),
});

const addCompanySchema = yup.object().shape({ // Repeats companyEditSchema
    corporateGroup: yup.number().required('Favor selecionar um grupo corporativo').moreThan(0, 'Favor selecionar um dos grupos associados'),
    nome: yup.string().required('O nome é um campo obrigatório'),
    codigo: yup.string().required('O código é um campo obrigatório').min(3, 'O código deve ter no mínimo 3 caracteres/dígitos'),
    razaoSocial: yup.string().required('A razão social é um campo obrigatório').min(5, 'A razão social deve ter no mínimo 5 dígitos'),
    cnpj: yup.number().required('O CNPJ é um campo obrigatório').lessThan(100000000000, 'O CNPJ deve ter 11 dígitos').moreThan(9999999999, 'O CNPJ deve ter 11 dígitos'),
});

const addEmployeeSchema = yup.object().shape({ // Repeats employeeEditSchema
    // Employments Fields
    WorkAreaPK: yup.number().required('Favor selecionar uma work area').moreThan(0, 'Favor selecionar uma das work areas associadas'),
    // Função: ,
    InícioEmpregoAtual: yup.string().required('A data de início é um campo obrigatório').length(10, 'A data deve seguir o formato DD/MM/AAAA'),
    EmailCorporativo: yup.string().email('O email corporativo deve ser um endereço válido'),
    // Person Fields
    PrimeiroNome: yup.string().required('O nome é um campo obrigatório'),
    Sobrenomes: yup.string().required('Pelo menos um sobrenome deve ser fornecido'),
    CPF: yup.string().required('O CPF é um campo obrigatório').length(11, 'O CPF deve conter apenas 11 dígitos/letras'),
    EmailPessoal: yup.string().email('O email pessoal deve ser um endereço válido'),
});

const addExamOfferSchema = yup.object().shape({ // Repeats employeeEditSchema
    nome: yup.string().required('O nome é um campo obrigatório'),
    condicoes_pgto: yup.number().required('Favor informar a condição de pagamento').moreThan(0, 'A condição deve ser um número que represente o prazo para pagamento dias após o faturamento'),
});

const addOfferedExamSchema = yup.object().shape({
    exame: yup.number().required('Favor selecionar um exame').moreThan(0, 'Favor selecionar um dos exames disponíveis'),
    preco: yup.number('O preço em reais deve possuir duas casas decimais').required('O preço é um campo obrigatório').transform(commasToPoints),
    sla: yup.number('O SLA (prazo) deve ser fornecido em número de dias').required('Favor fornecer o SLA (prazo do resultado) em dias'),
})

const addRequiredExamSchema = yup.object().shape({
    Exame: yup.number().required('Favor selecionar um exame').moreThan(0, 'Favor selecionar um dos exames associados'),
    PeríodoRenovação: yup.number('O período de renovação deve ser dado em um NÚMERO de meses').required('Favor informar o período de renovação exigido, em meses').moreThan(0, 'O período de renovação deve ser maior que 0').lessThan(48, 'O período de renovação deve ser menor que 48 meses'),
});

const addWorkAreaSchema = yup.object().shape({ // Repeats workAreaEditSchema
    UnidadePK: yup.number().required('Favor selecionar uma unidade de negócio').moreThan(0, 'Favor selecionar uma das unidades associadas'),
    MunicipioPK: yup.number().required('Favor selecionar um município').moreThan(0, 'Favor selecionar um dos municípios associados'),
    WorkArea: yup.string().required('O nome é um campo obrigatório'),
    Cód: yup.string().required('O código é um campo obrigatório').min(3, 'O código deve ter no mínimo 3 caracteres/dígitos'),
});

const businessUnitEditSchema = yup.object().shape({
    CompanhiaPK: yup.number().required('Favor selecionar uma companhia').moreThan(0, 'Favor selecionar uma das companhias associadas'),
    Nome: yup.string().required('O nome é um campo obrigatório'),
    Código: yup.string().required('O código é um campo obrigatório').min(3, 'O código deve ter no mínimo 3 caracteres/dígitos'),
});

const clinicEditSchema = yup.object().shape({
    nome: yup.string().required('O nome é um campo obrigatório'),	
    razaoSocial: yup.string().required('A razão social é um campo obrigatório').min(5, 'A razão social deve ter no mínimo 5 catacteres'),
    cnpj: yup.string().required('O CNPJ é um campo obrigatório').min(18, 'O CNPJ deve seguir o formato ##.###.###/####-##').max(18, 'O CNPJ deve seguir o formato ##.###.###/####-##'),
    cep: yup.number('O CEP deve ser um número de 8 dígitos').optional().lessThan(100000000, 'O CEP deve ter 8 dígitos').moreThan(9999999, 'O CEP deve ter 8 dígitos').transform(emptyStringToNull).nullable(),
    endereco_logradouro: yup.string().required('O logradouro/nome da rua é um campo obrigatório'),
    endereco_numero: yup.string().required('O número é um campo obrigatório'),
    // endereco_bairro: yup.string().optional(),
    // endereco_complemento: yup.string().optional(),
    endereco_municipio: yup.number().required('Favor selecionar um município').moreThan(0, 'Favor selecionar um dos municípios listados'),
    // telefone_regiao: yup.string().optional(),
    // telefone_numero: yup.string().optional(),
    email: yup.string().email('O email corporativo deve ser um endereço válido'),
    tipo_agendamento: yup.number().required('Favor selecionar um tipo de agendamento').moreThan(0, 'Favor selecionar um dos tipos de agendamento listados'),
    duracao_agendamentos: yup.number().required('Favor selecionar uma duração de agendamento').moreThan(0, 'Favor selecionar uma das durações de agendamento listadas'),
});

const companyEditSchema = yup.object().shape({
    corporateGroup: yup.number().required('Favor selecionar um grupo corporativo').moreThan(0, 'Favor selecionar um dos grupos associados'),
    nome: yup.string().required('O nome é um campo obrigatório'),
    codigo: yup.string().required('O código é um campo obrigatório').min(3, 'O código deve ter no mínimo 3 caracteres/dígitos'),
    razaoSocial: yup.string().required('A razão social é um campo obrigatório').min(5, 'A razão social deve ter no mínimo 5 dígitos'),
    cnpj: yup.number().required('O CNPJ é um campo obrigatório').lessThan(100000000000, 'O CNPJ deve ter 11 dígitos').moreThan(9999999999, 'O CNPJ deve ter 11 dígitos'),
});

const EditPastExamSchema = yup.object().shape({
    Exame: yup.number().required('Favor selecionar um exame').moreThan(0, 'Favor selecionar um dos exames associados'),
    DataRealização: yup.string().required('A data de realização é obrigatória').length(10, 'A data deve seguir o formato DD/MM/AAAA'),
});

const employeeEditSchema = yup.object().shape({
    // Employments Fields
    WorkAreaPK: yup.number().required('Favor selecionar uma work area').moreThan(0, 'Favor selecionar uma das work areas associadas'),
    // Função: ,
    InícioEmpregoAtual: yup.string().length(10, 'A data deve seguir o formato DD/MM/AAAA'),
    EmailCorporativo: yup.string().email('O email corporativo deve ser um endereço válido'),
    // Person Fields
    PrimeiroNome: yup.string().required('O nome é um campo obrigatório'),
    Sobrenomes: yup.string().required('Pelo menos um sobrenome deve ser fornecido'),
    CPF: yup.string().required('O CPF é um campo obrigatório').length(11, 'O CPF deve conter apenas 11 dígitos/letras'),
    EmailPessoal: yup.string().email('O email pessoal deve ser um endereço válido'),
});

const workAreaEditSchema = yup.object().shape({
    UnidadePK: yup.number().required('Favor selecionar uma unidade de negócio').moreThan(0, 'Favor selecionar uma das unidades associadas'),
    MunicipioPK: yup.number().required('Favor selecionar um município').moreThan(0, 'Favor selecionar um dos municípios associados'),
    WorkArea: yup.string().required('O nome é um campo obrigatório'),
    Cód: yup.string().required('O código é um campo obrigatório').min(3, 'O código deve ter no mínimo 3 caracteres/dígitos'),
});


//////////
export const validationSchemaDictionary = {
    'addCompanySchema': addCompanySchema,
    'addBusinessUnitSchema': addBusinessUnitSchema,
    'addWorkAreaSchema': addWorkAreaSchema,
    'businessUnitEditSchema': businessUnitEditSchema,
    'clinicEditSchema': clinicEditSchema,
    'companyEditSchema': companyEditSchema,
    'employeeEditSchema': employeeEditSchema,
    'workAreaEditSchema': workAreaEditSchema,
    'addRequiredExamSchema': addRequiredExamSchema,
    'addEmployeeSchema': addEmployeeSchema,
    'EditPastExamSchema': EditPastExamSchema,
    'addAvailableExamSchema': addAvailableExamSchema,
    'addExamOfferSchema': addExamOfferSchema,
    'addOfferedExamSchema': addOfferedExamSchema,
}