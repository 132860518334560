<template>
    <div class="page-login">
        <div class="columns">
            <div class="column is-4 is-offset-4">
                <h1 class="title">Reset Senha</h1>
                <h2>Preencha os campos abaixo com uma nova senha para completar o processo de renovação.</h2>
                <hr>
                <form @submit.prevent="submitForm">
                    <div class="field">
                        <label>Nova Senha</label>
                        <div class="control">
                            <input type="password" name="password" class="input" v-model="password">
                        </div>
                    </div>

                    <div class="field">
                        <label>Confirmação Nova Senha</label>
                        <div class="control">
                            <input type="password" name="password" class="input" v-model="rePassword">
                        </div>
                    </div>

                    <div class="notification is-danger" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button class="button is-success">Confirmar</button>
                        </div>
                    </div>
                </form>

                <hr>
                <p></p>                
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {useAuthenticationStore} from "@/stores/authentication"
import {toast} from 'bulma-toast'


export default {
    name: 'LogIn',
    setup() {
        const authentication = useAuthenticationStore()
        return {
            authentication
        } 
    },
    data() {
        return {
            uid: this.$route.params.uid,
            token: this.$route.params.token,
            password: '',
            rePassword: '',
            errors: [],
        }
    },
    methods: {
        async submitForm(e) {
            this.authentication.jwtLogOut()
            const formData = {
                uid: this.uid,
                token: this.token,
                new_password: this.password,
                re_new_password: this.rePassword
            }
            await axios
                .post("/auth/users/reset_password_confirm/", formData)
                .then(response => {
                    console.log(response)
                    if (response.status != 400 ) {
                    toast({
                        position: 'top-center',
                        message: 'Troca de senha realizada com sucesso. Favor realizar novo login.',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 5000,
                    })
                        this.$router.push('/log-in')
                    }
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                        console.log(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.log(JSON.stringify(error.message))
                    } else {
                        console.log(JSON.stringify(error))
                    }
                })
        }
    }
}
</script>

<style scoped>
.page-login {
    min-height: 50vh;
}
.columns {
    margin-top: 6rem;
    margin-bottom: auto;
}
</style>