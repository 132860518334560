<template>
    <!-- <h1 class="title">{{survey.title}}</h1> -->
    <!-- Copilot, add a horizontally centered title followed below by an HR and then below that a justified div which will contain the description -->
    <div class="survey-header">
        <h1 class="title is-3 has-text-centered" style="margin-bottom: 1.5rem">{{ surveyDescription.title }}</h1>
        <div class="survey-description" style="display:flex; justify-content:center;">
            <p class="has-text-justified" style="text-align: justify;" id="surveyDescription">{{ surveyDescription.description }}</p>
        </div>
    </div>
    <div class="line-container"> <!-- Progress Indicator -->
        <div class="line-item" style="color:#3273dc">INÍCIO</div>
        <div class="line-item">
            <span
                v-for="(question, index) in questions"
                :key="index"
                :class="{ 'is-active': activeQuestionNumber === index }"
            >
                <span v-if="index > 0" class="dot-separator"></span>
                <span class="dot" :class="{ 'is-active': activeQuestionNumber === index+1 , 'is-past':activeQuestionNumber>index+1}"></span>
            </span>
        </div>
        <div class="line-item" :class="(last_page_active ? 'colour-FIM' : '')">FIM</div>
    </div>
    <TransitionGroup :name="back ? 'slide-fade' : 'slide-fade-reverse'" > <!-- class="transition-container" -->
        <div class="card" v-for="question, index in filteredQuestions" :key="index">
            <div class="card-content">
                <div class="content" >
                    <p class="title is-5 mb-0 pb-0">{{ 'Q' + question.number + '. ' + question.prompt }}</p>
                    <hr class="mt-3">
                    <div v-if="question.type === 'input'">
                        <input class="input" type="text" placeholder="Sua resposta" v-model="question.answer">
                    </div>
                    <div v-else-if="question.type === 'select'">
                        <div v-for="(option, index) in question.options" :key="index">
                            <label class="radio" style="margin-bottom:5px">
                                <input type="radio" :value="option" v-model="question.answer">
                                {{ option.option_text }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </TransitionGroup>
    <div class="card" style="height:fit-content">
        <div class="card-content" v-if="last_page_active">
            <div class="content">
                <p class="title is-5 mb-0 pb-0">Obrigado por participar de nossa pesquisa</p>
                <hr class="mt-3">
                <div>
                    A equipe CliqueMed valoriza seu tempo. Por isso, preparamos um pacote de benefícios para você, quando se cadastrar em nossa plataforma:
                    <ul>
                        <li>Acesso antecipado à novas funcionalidades na plataforma CliqueMed</li>
                        <li>Um volume expandido de créditos para busca de clínicas e exames em todo o território nacional</li>
                        <li>Capacidade de interagir com nossa equipe para moldar nosso produto de acordo com suas necessidades</li>
                        <li>Possibilidade de ser convidado para o próximo projeto piloto</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="question-navigation buttons" v-if="!last_page_active">
      <button class="button is-info is-outlined" :disabled="activeQuestionNumber === 1" @click="advance()"><i class="fas fa-chevron-left"></i>Anterior</button>
      <button class="button is-info is-outlined" :disabled="activeQuestionNumber > questions.length " @click="advance(true)">Próxima<i class="fas fa-chevron-right"></i></button>
    </div>
    <div class="question-navigation buttons" v-else>
        <button class="button is-info is-outlined" :disabled="activeQuestionNumber === 1" @click="advance()"><i class="fas fa-chevron-left"></i>Anterior</button>
        <button class="button is-info is-outlined" :disabled="activeQuestionNumber > questions.length " @click="advance(true)">Próxima<i class="fas fa-chevron-right"></i></button>
        <button class="button is-info" @click="submitSurvey">Enviar questionário</button>
    </div>

    <!-- <hr>
    {{ questions }} -->
</template>
  
<script>

export default {
    name: 'Survey',
    props: {
        questions: {
            type: Array,
            required: true,
            validator: (value) => {
                return value.every((question) => {
                    return (
                        question.hasOwnProperty('number') &&
                        question.hasOwnProperty('prompt') &&
                        (question.type === 'input' ||
                        (question.type === 'select' && question.hasOwnProperty('options')))
                    );
                });
            },
        },
        surveyDescription: {
            type: Object,
            required: true,
        }
    },
    emits: ['submitSurvey'],
    data() {
        return {
            activeQuestionNumber: 1,
            transitioning: false,
            back: false,
        };
    },
    computed: {
        filteredQuestions() {
            if (this.transitioning) {
                return []   
            }
            else if (this.last_page_active) {
                return []
            }
            else {
                return this.questions.filter((question) => question.number === this.activeQuestionNumber)
            }
        },
        last_page_active() {
            return this.activeQuestionNumber === this.questions.length+1;
        }
    },
    methods: {
        advance(plus) {
            this.back = !!plus;
            this.transitioning = true;
            this.activeQuestionNumber = (this.activeQuestionNumber + (plus ? 1 : -1))
            setTimeout(() => {
                this.transitioning = false;
            }, 100)
        },
        submitSurvey() {
            this.$emit('submitSurvey');
        }
    }
};
</script>
  
<style scoped>
.card {
    border-color: #3273dc67;
    border-width: 1px;
    border-style: solid;
}

@media only screen and (min-width: 1950px) {
    .card {
        height: 310px;
    }
}
@media only screen and (min-width: 1440px) {
    .card {
        height: 350px;
    }
    #surveyDescription {
    width: 60%;
}
}
@media (max-width: 1440px) {
    .card {
        height: fit-content;
    }
    #surveyDescription {
        width: 90%;
    }
}



hr {
    border:solid 1px rgba(128, 128, 128, 0.466);
    width: 100%;
    height: 2px;
}

/* PAGE INDICATOR */
.line-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #999;
}

.line-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 4px;
  background-color: #ccc;
  transition: all 0.3s ease-in-out;
}

.dot.is-active {
  width: 12px;
  height: 12px;
  background-color: #3273dc;
}
.dot.is-past {
  width: 12px;
  height: 12px;
  background-color: #3273dc;
}

.dot-separator {
    display: inline-block;
    width: 1px;
    height: 8px;
    margin: 0 4px;
    background-color: #ccc;
}
@media (max-width: 640px) {
    .dot-separator {
        margin-left: 2px;
        margin-right: 2px;
    }
    .dot {
        margin-left: 2px;
        margin-right: 2px;
    }
    .dot.is-past {
        width: 10px;
        height: 10px;
    }
}
@media (max-width: 470px) {
    .dot-separator {
        margin-left: 0px;
        margin-right: 0px;
    }
    .dot {
        margin-left: 1px;
        margin-right: 1px;
    }
}

.colour-FIM {
    color:#3273dc
}

/* Buttons */
.question-navigation {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}


/* ANIMATIONS */
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-fade-enter-from {
  transform: translateX(100%); 
  opacity: 1;
}

.slide-fade-leave-to {
  transform: translateX(-100%);
  opacity: 1;
}

/* Back Scrolling */
.slide-fade-reverse-enter-active,
.slide-fade-reverse-leave-active {
  transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}
.slide-fade-reverse-enter-from {
  transform: translateX(-100%);
  opacity: 1;
}

.slide-fade-reverse-leave-to {
  transform: translateX(100%);
  opacity: 1;
}
.card + .card {
  position: absolute;
}

.transition-container {
  display: flex;
  align-items: center;
  justify-content: center;
}



</style>
  