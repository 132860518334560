<template>
      <div class="container">
        <div class="columns is-multiline">
            <!-- {{employee}} -->
            <div class="column is-12">
                <h1 class="title pb-0 mb-0 has-text-danger has-background-warning has-text-centered"><strong>Atenção!</strong></h1>
                <h2 class="has-text-danger has-background-warning has-text-centered"><strong>A ação que você está prestes a realizar é irreversível.</strong></h2>
            </div>
            <hr>
            <div class="column is-6">
                <div class="box">
                    <h2 class="subtitle"><strong>{{employee.Nome}}</strong></h2>
                    <hr>
                    <p><strong>CPF: </strong> {{employee['CPF']}} </p>
                    <p><strong>Função: </strong> {{employee['Função']}} </p>
                    <p><strong>Registrado na Companhia: </strong> {{employee['Companhia']}} </p>
                    <p><strong>Unidade de Negócio: </strong>{{employee['Un.Negócio']}}</p>
                    <p><strong>Ativo na Work Area: </strong>{{employee['WorkArea']}}</p>
                    <p><strong>Com data de registro : </strong>{{employee['Data do Registro de Trabalho']}}</p>
                </div>
            </div>

            <div class="column is-6">
                <div class="box">
                    <div class="content">
                        <label class="checkbox pb-2">
                            <input type="checkbox" v-model="checkConfirm">
                            Eu entendo que deletar o registro do funcionário ao lado é uma ação irreversível e que um novo registro deverá ser registrado caso necessário.
                        </label>
                        <p class="has-text-danger pb-4" v-show="submitDeclined">Você deve confirmar na caixa acima antes de prosseguir.</p> 
                        <div class="buttons"> <!-----------------------------------------------------------Make buttons centered -->
                            <button class="button is-info" @click="$emit('closeDeleteModal')">Cancelar e Retornar</button>
                            <button class="button is-danger is-outlined" @click="submitDelete">Confirmar e Deletar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

// import DataTable from '@/components/dataTable/DataTable.vue'



export default {
    name: 'EmployeeDeleteComponent',
    props: ['employee','employmentId'],
    components: {

    },
    data() {
        return {
            employeeInner: '',
            tableAPIParams: {
                'companyId': this.employmentId
            },
            numeroWorkAreas: 0,
            numeroWorkAreas: 0,
            numeroRelacoesDeTrabalho: 0,
            responseBeforeDelete: {},
            checkConfirm: false,
            submitDeclined: false
        }
    },
    computed: {

    },
    watch: {

    },
    created() {
        // console.log('CACILDA!')
        // console.log('props inside CompanyDetails:',this.companyID)
        // console.log(this.tableAPIParams)
        // this.tableAPIParams.companyId = this.companyId
        // this.getDataBeforeDelete()
    },
    methods: {
        async submitDelete() {
            if (this.checkConfirm) {
                // console.log('CompanyDelete_submitDelete called. Axios delete request about to be sent.')
                axios
                    .delete('/api/v1/corporate/employments/', { params: {'employmentId': this.employmentId} })
                    .then(response => {
                        this.$emit('closeDeleteModal')
                    })
                    .catch(error => {
                        console.log(error.request.response)
                    })
            }
            else {
                this.submitDeclined = !this.submitDeclined
            }
        },
        getDataBeforeDelete() {
            axios
                .get('/api/v1/bus/', { params: {'employmentId': this.employmentId, 'querydependences':true} })
                .then(response => {
                    console.log('Corporate_employeeDelete_getDataBeforeDelete: Get response.data:',response.data)
                    this.numeroWorkAreas = response.data.length
                    let numRT = 0
                    for (let i = 0; i < response.data.length; i++) {
                        numRT += response.data[i]['Func.']
                    }
                    this.numeroRelacoesDeTrabalho = numRT
                    this.responseBeforeDelete = response
                    // console.log('CompanyDelete_getDataBeforeDelete called.')
                    // console.log('response:',response)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

}
</script>

<style scoped>

.box {
    height: 100%;
}

</style>