// Landing Page & Registration
import Home from '../views/Home.vue'
import SignUp from '../views/authentication/SignUp.vue'
import SignedUp from '../views/authentication/SignedUp.vue'
import ActivateAccount from '../views/authentication/ActivateAccount.vue'
import LogIn from '../views/authentication/LogIn.vue'
// Survey
import SurveyLanding from '@/components/survey/SurveyLanding.vue'
// Blog
import Artigos from '@/components/blog/Artigos.vue'
import Artigo from '@/components/blog/Artigo.vue'
// Platform Views - Profile & General
import Dashboard from '../views/dashboard/Dashboard.vue'
import MyAccount from '../views/dashboard/MyAccount.vue'
// Platform Views - Corporate
import BuscaExames from '../views/clinicas/BuscaExames.vue'
import BuscaExamesResults from '../views/clinicas/BuscaExamesResults.vue'
import Organizacao from '../views/corporate/Organizacao.vue'
import Time from '../views/corporate/Time.vue'
import Requisicoes from '../views/corporate/Requisicoes.vue'
import TestPage from '../views/TestPage.vue'
import PlanejarExames from '../views/corporate/PlanejarExames.vue'
import ResetPasswordCall from '../views/authentication/ResetPasswordCall.vue'
import ResetPasswordSet from '../views/authentication/ResetPasswordSet.vue'
// Platform Views - Clinica
import ClinicaPerfil from '../views/clinicas/ClinicaPerfil.vue'
import GerenciarExames from '../views/clinicas/GerenciarExames.vue'
import GerenciarAgendamentos from '../views/clinicas/GerenciarAgendamentos.vue'

// import Details from '../views/corporate/company/Details.vue'

// Functionality
import { createRouter, createWebHistory } from 'vue-router'
import {useAuthenticationStore} from "@/stores/authentication"
import axios from 'axios'

const routes = [
  // -------------------------------------------------------------------------Landing Page & Registration
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/sobreNos',
        name: 'SobreNos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/SobreNos.vue')
      },
    ]
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/signed-up',
    name: 'SignedUp',
    component: SignedUp
  },
  {
    path: '/reset-senha',
    name: 'ResetPasswordCall',
    component: ResetPasswordCall
  },
  {
    path: '/reset-senha/confirm/:uid/:token',
    name: 'ResetPasswordSet',
    component: ResetPasswordSet
  },
  {
    path: '/ativacao-de-conta/:uid/:token',
    name: 'ActivateAccount',
    component: ActivateAccount
  },
  {
    path: '/log-in',
    name: 'LogIn',
    component: LogIn
  },
  // ------------------------------------------------------------------------- Survey Page
  {
    path: '/pesquisa/:usid?',
    name: 'Survey',
    component: SurveyLanding,
    props: true 
  },
  // ------------------------------------------------------------------------- Blog Page
  {
    path: '/artigos',
    name: 'Artigos',
    component: Artigos,
  },
  {
    path: '/artigos/:articleID',
    name: 'Artigo-Detalhe',
    component: Artigo,
    props: route => ({
      articleID: parseInt(route.params.articleID)
    }),
    // Add a custom validator for the articleID prop
    beforeEnter: (to, from, next) => {
      const articleID = parseInt(to.params.articleID)
      if (isNaN(articleID)) {
        next(false)
      } else {
        next()
      }
    }
  },
  // ------------------------------------------------------------------------- Platform Views - Profile & General
  {
    path: '/dashboard/my-account',
    name: 'MyAccount',
    component: MyAccount,
    meta: {
      requireLogin: true,
      authorisedRoles: [7,8]
    }
  },
  {
    path: '/testpage',
    name: 'TestPage',
    component: TestPage,
    meta: {
      requireLogin: true,
      authorisedRoles: [7,8]
    }
  },
  // ------------------------------------------------------------------------- Platform Views - Corporate
  {
    path: '/buscaexames',
    name: 'BuscaExames',
    component: BuscaExames,
    meta: {
      requireLogin: true,
      authorisedRoles: [7]
    }
  },
  {
    path: '/buscaexamesresults',
    name: 'BuscaExamesResults',
    component: BuscaExamesResults,
    meta: {
      requireLogin: true,
      authorisedRoles: [7]
    }
  },
  // {
  //   path: '/company/:id',
  //   name: 'Company',
  //   component: Details,
  //   meta: {
  //     requireLogin: true,
  //     authorisedRoles: [7]
  //   }
  // },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requireLogin: true,
      authorisedRoles: [7]
    }
  },
  {
    path: '/organizacao',
    name: 'Organizacao',
    component: Organizacao,
    meta: {
      requireLogin: true,
      authorisedRoles: [7]
    }
  },
  {
    path: '/planejar-exames',
    name: 'PlanejarExames',
    component: PlanejarExames,
    meta: {
      requireLogin: true,
      authorisedRoles: [7]
    }
  },
  {
    path: '/time',
    name: 'Time',
    component: Time,
    meta: {
      requireLogin: true,
      authorisedRoles: [7]
    }
  },
  {
    path: '/requisicoes',
    name: 'Requisições',
    component: Requisicoes,
    meta: {
      requireLogin: true,
      authorisedRoles: [7]
    }
  },
  // ------------------------------------------------------------------------- Platform Views - Clinic
  {
    path: '/perfil-clinica',
    name: 'PerfilClinica',
    component: ClinicaPerfil,
    meta: {
      requireLogin: true,
      authorisedRoles: [8]
    }
  },
  {
    path: '/gerenciar-exames',
    name: 'GerenciarExames',
    component: GerenciarExames,
    meta: {
      requireLogin: true,
      authorisedRoles: [8]
    }
  },
  {
    path: '/gerenciar-agendamentos',
    name: 'GerenciarAgendamentos',
    component: GerenciarAgendamentos,
    meta: {
      requireLogin: true,
      authorisedRoles: [8]
    }
  },
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      const element = document.getElementById(to.hash.replace(/#/, ''))
      if (element && element.scrollIntoView) {
        element.scrollIntoView({ block: 'end', behavior: 'smooth' })
      }
      let topPosition = 50
      // console.log(to.hash)
      if (to.hash == '#home') {
        topPosition = 50
      }
      return { el: to.hash, top: topPosition }
    } else if (savedPosition) {
      return savedPosition
    }
    return { top: 0 }
  }
})

async function getUserTier() {
  await axios
  .get('/api/v1/user/tier/')
  .then((response) => {
    return response.data
  })
  .catch((error) => {
    console.log(error)
  })
}

router.beforeEach((to, from, next)=> {
  const authentication = useAuthenticationStore()
  // Check if path requires authentication.
  if (to.matched.some(record => record.meta.requireLogin)) {
    // If it does:
    // Make an axios call to users/me to check if the user is logged in
    axios.get('/auth/users/me')
    .then((response) => {
      // console.log('Router auth check response.data>>>>',response.data)
      // console.log(axios.defaults)
      if (response.status !== 200) { 
        authentication.jwtLogOut()
        next({ name: 'LogIn' })
      }
      else { // If the user is logged in, Process and continue to the requested path.
        authentication.setUserDetails(response.data)
        let userHasRoleForRoute = false
        for (let i = 0; i<response.data.roles.length; i++) { // Check if the user has any of the roles required to access the route
          userHasRoleForRoute = to.meta.authorisedRoles.includes(response.data.roles[i])
          if (userHasRoleForRoute) {
            next()
          }
          else {
            next({ name: 'LogIn' })
          }
        }
      }
    })
    .catch((error) => {
      authentication.jwtLogOut()
      next({ name: 'LogIn' })
    })
  }
  else {
  // If it doesn't:
    // Just continue to the route
    next()
  }
  // if (to.matched.some(record => record.meta.requireLogin) && !authentication.isAuthenticated) {
  //   next('/log-in')
  // } else {
  //   next()
  // }
})

export default router
