<template>
    <div class="generic-form">
        <!-- Object: {{object}} -->
        <!-- formFieldsSelect: {{formFieldsSelect}} DEBUG -->
        <!-- formFieldsText: {{formFieldsText.length}} -->
        <!-- <br> -->
        <!-- schemaIsValid: {{schemaIsValid}} -->
        <!-- <hr> -->
        <div class="columns is-multiline">
            <!-- {{schema}} -->
            <div class="column is-2"></div>
            <div class="column is-8">
                <!-- <hr>
                {{formFieldsSelect}}
                <hr> -->
                <!-- {{formFieldsText}}
                <hr> -->
                    <Form ref="form" class="form" :validation-schema="schema">
                        <div v-for="requiredField in formFieldsSelect" :key="requiredField.id" class="mb-3">
                            <div class="field" v-if="requiredField.type='select'">
                                <!-- <hr>
                                DEBUG INFO
                                <hr>
                                requiredField.name: {{requiredField.name}} -> This is a parameter which must exist in the modalObject (object)
                                <hr>
                                Current ModalObject: {{object}}
                                <hr>
                                If the requiredField.name is in the modalObject, but with another name, you can duplicate its value in
                                the activateModalEditOfferedExam method called when the user clicks the DT action button. If it is not available, you must add it to the 
                                DataTable API response.
                                <hr>
                                Select an option below and see the value as a reference: objectRequiredField value: {{object[requiredField.name]}}
                                <hr> -->
                                <!-- selected:  -->
                                <label>{{requiredField.label}}</label>
                                <div >
                                    <div class="select is-info" >
                                        <Field :name="requiredField.name" as="select" v-model="object[requiredField.name]">
                                            <option
                                            v-for="option in requiredField.options" :key="option.id"
                                            :value= "option.value"
                                            >
                                                {{option.name}}
                                            </option>
                                        </Field>
                                    </div>
                                </div>
                                <ErrorMessage :name="requiredField.name" style="color:red"/>
                            </div>
                        </div>
                        <div class="field" v-for="field in formFieldsText" :key="field.id">
                            <label>{{field.label}}</label>
                            <!-- requiredField.name:{{field.name}} -> {{object[field.name]}} DEBUG -->
                            <div class="control">
                                <Field 
                                    :name="field.name"
                                    as="input"
                                    :type= "field.type"
                                    class= "input is-info"
                                    :placeholder= "field.placeholder"
                                    :disabled= "field.disabled"
                                    v-model="object[field.name]"
                                />
                                <ErrorMessage :name="field.name" style="color:red"/>
                            </div>
                        </div>
                    </Form>
            </div>
        </div>
        <!-- DEBUG - EditGenericSerializer 'withButtons' prop: {{ withButtons }} -->
        <div class="buttons" v-if="withButtons==true"> <!-----------------------------------------------------------Make buttons centered -->
            <button class="button is-danger" @click="$emit('closeEditModalWithReload')">Cancelar</button>
            <button class="button is-success" @click="submitEditForm(apiURL)">Confirmar</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toRaw } from '@vue/reactivity'
//Form validation
import { toast } from 'bulma-toast'
import { Field, Form, ErrorMessage } from 'vee-validate';
import { validationSchemaDictionary } from '@/utilities/validations.js'

export default {
    name: 'EditGenericSerializer',
    props: {
        object: Object,
        apiURL: String,
        submitMethod: String,
        formValidationSchema: String,
        withButtons: {
            type: Boolean,
            default: true
        },
        extraRequestParams: Object,
    },
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            schema: {},
            formFieldsText: [],
            formFieldsSelect: [],
            objectBackup: toRaw(this.object),
        }
    },
    computed: {
        schemaIsValid() {
            return validationSchemaDictionary[this.formValidationSchema].isValidSync(this.object)
        },
    },
    mounted() {
        this.queryFormFields(this.apiURL)
        setTimeout(() => {
            this.schema = validationSchemaDictionary[this.formValidationSchema]
        }, 200) // If the schema loads before the queryFormFields, the reactive property will identify a change and trigger the validation
        // this.schema = validationSchemaDictionary[this.formValidationSchema]
    },
    methods: {
        queryFormFields(apiURL) {
            axios
                .post(apiURL, 'queryFormFields')
                .then(response => {
                    for (var i = 0; i < response.data.length; i++) {
                        if (response.data[i].type == 'text') {
                            this.formFieldsText.push(response.data[i])
                        } else if (response.data[i].type == 'select') {
                            this.formFieldsSelect.push(response.data[i])
                        }
                    }
                })
                .catch(error => {
                    // console.log(JSON.stringify(error))
                    console.log(error)
                })
        },
        async submitEditForm(apiURL) {
            if (this.schemaIsValid) {
                const objectID = this.object.pk
                if (this.submitMethod=='patch') {
                    await axios
                        .patch(apiURL+`${objectID}/`, this.object)
                        .catch(error => {
                            console.log(JSON.stringify(error))
                        })
                } else if (this.submitMethod=='put') {
                    await axios
                        .put(apiURL, this.object)
                        .catch(error => {
                            console.log(JSON.stringify(error))
                        })
                }
                this.$emit('closeEditModal')
            }
            else {
                this.$refs.form.validate()
                toast({
                    position:'bottom-right',
                    message: 'Favor preencher os campos de acordo com os requisitos.',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000
                })
            }
        },
        closeEditModal() {
            this.object = this.objectBackup
            this.$emit('closeEditModal')
        },
    }
}
</script>

<style>

</style>