<template>
    <div class="page-signup" v-observe-visibility="{callback: dataStream, once: true, throttle: 300}">
        <div class="columns">
            <div class="column is-4 is-offset-4">
                <h1 class="title">Registre-se em 3 passos:</h1>
                <div class="layered">
                    <Transition name="slide-fade"> <!-- Page 1 -->
                        <div class="card" v-if="activePageNumber == 1" :key="1">
                            <div class="card-content" >
                                <div class="content" >
                                    <p class="title is-5 mb-0 pb-0 mt-0">1. Selecione o tipo de sua organização: </p>
                                    <hr class="mt-3">
                                    <div class="control" style="margin-left:25%">
                                        <label class="radio" >
                                            <input type="radio" name="answer" value="corporate" v-model="organisation_type">
                                            <strong class="ml-2">Empresa (Contratante)</strong> 
                                        </label>
                                        <br>
                                        <br>
                                        <label class="radio">
                                            <input type="radio" name="answer" value="clinic" v-model="organisation_type">
                                            <strong class="ml-2">Clínica (Fornecedor)</strong> 
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                    <Transition name="slide-fade"> <!-- Page 2 -->
                        <div class="card" v-if="activePageNumber == 2" :key="2">
                            <div class="card-content">
                                <div class="content" v-if="organisation_type == 'corporate'">
                                    <p class="title is-5 mb-0 pb-0 mt-0">Sua Organização</p>
                                    <hr class="mt-3">
                                    <div>
                                        <div class="field">
                                            <label style="font-weight: 700;">Nome da Empresa</label>
                                            <div class="control">
                                                <input name="nome" class="input" v-model="organisation_name">
                                            </div>
                                        </div>
                                        <br>
                                        <label style="font-weight: 700;">Número de Funcionários</label>
                                        <br>
                                        <div class="select is-fullwidth">
                                            <select v-model="organisation_number_of_employees">
                                            <option disabled value="">Favor selecionar uma categoria:</option>
                                            <option>Até 100 funcionários</option>
                                            <option>De 101 a 500 funcionários</option>
                                            <option>De 501 a 1.000 funcionários</option>
                                            <option>De 1.001 a 2.000 funcionários</option>
                                            <option>De 2.001 a 3.000 funcionários</option>
                                            <option>De 3.001 a 4.000 funcionários</option>
                                            <option>De 4.001 a 5.000 funcionários</option>
                                            <option>De 5.001 a 10.000 funcionários</option>
                                            <option>Mais de  10.000 funcionários</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                                <div class="content" v-if="organisation_type == 'clinic'">
                                    <p class="title is-5 mb-0 pb-0 mt-0">Sua Organização</p>
                                    <hr class="mt-3">
                                    <div>
                                        <div class="field">
                                            <label style="font-weight: 700;">Nome da Clínica</label>
                                            <div class="control">
                                                <input name="nome" class="input" v-model="organisation_name">
                                            </div>
                                        </div>
                                        <br>
                                        <label style="font-weight: 700;">Município</label>
                                        <DropdownAutoComplete 
                                            label=''
                                            :optionsList="lista_municipios"
                                            :is_bulma_input="true"
                                            @optionSelected="setOptionMunicipio"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                    <Transition name="slide-fade" > <!-- Page 3 -->
                        <div class="card" v-if="activePageNumber == 3" :key="3">
                            <div class="card-content">
                                <div class="content" >
                                    <p class="title is-5 mb-0 pb-0 mt-0">Seu Usuário</p>
                                    <hr class="mt-3">
                                    <div>
                                        <form> <!-- Registration Form -->
                                            <div class="field">
                                                <label style="font-weight: 700;">E-mail</label>
                                                <div class="control">
                                                    <input type="email" name="email" class="input" v-model="email">
                                                </div>
                                            </div>

                                            <div class="field">
                                                <label style="font-weight: 700;">Primeiro Nome</label>
                                                <div class="control">
                                                    <input name="nome" class="input" v-model="nome">
                                                </div>
                                            </div>

                                            <div class="field">
                                                <label style="font-weight: 700;">Sobrenome</label>
                                                <div class="control">
                                                    <input name="sobreNome" class="input" v-model="sobreNome">
                                                </div>
                                            </div>

                                            <div class="field">
                                                <label style="font-weight: 700;">Senha</label>
                                                <div class="control">
                                                    <input type="password" name="password" class="input" v-model="password">
                                                </div>
                                            </div>

                                            <div class="field">
                                                <label style="font-weight: 700;">Confirmação da Senha</label>
                                                <div class="control">
                                                    <input type="password" name="re_password" class="input" v-model="re_password">
                                                </div>
                                            </div>

                                            <div class="notification is-danger" v-if="errors.length">
                                                <p 
                                                    v-for="error in errors" 
                                                    v-bind:key="error"
                                                >
                                                    {{ error }}
                                                </p>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
                <div class="question-navigation buttons" v-if="activePageNumber<3">
                    <button class="button is-info is-outlined" :disabled="activePageNumber === 1" @click="advance()"><i class="fas fa-chevron-left"></i>Retornar</button>
                    <button class="button is-info is-outlined" :disabled="activePageNumber === 3 " @click="advance(true)">Avançar<i class="fas fa-chevron-right"></i></button>
                </div>
                <div class="question-navigation buttons" v-else>
                    <button class="button is-info is-outlined" :disabled="activePageNumber === 1" @click="advance()"><i class="fas fa-chevron-left"></i>Retornar</button>
                    <button class="button is-info is-outlined" :disabled="activePageNumber === 3 " @click="advance(true)">Avançar<i class="fas fa-chevron-right"></i></button>
                    <button class="button is-success" @click="submitForm">Registrar-se</button>
                </div>
                <hr>
                <div>
                    Já possui cadastro? <router-link to="/log-in">Clique aqui</router-link> para fazer o login!
                </div>
                <br>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import {lista} from '@/assets/localidades.js'
import DropdownAutoComplete from '@/components/DropdownAutoComplete.vue'

export default {
    name: 'SignUp',
    components: {
        DropdownAutoComplete
    },
    data () {
        return {
            activePageNumber: 1,
            email: '',
            errors: [],
            lista_municipios: lista,
            nome: '',
            organisation_type: '',
            organisation_city: '',
            organisation_name: '',
            organisation_number_of_employees: '',
            password: '',
            re_password: '',
            sobreNome: '',
            tipoSelecionado: false,
        }
    },
    methods: {
        advance(plus) {
            if (this.organisation_type != '') {
                this.activePageNumber = (this.activePageNumber + (plus ? 1 : -1))
                // this.back = !!plus;
            }
            else {
                toast({
                    message: 'Por favor, selecione o tipo de sua organização!',
                    type: 'is-warning',
                    position: 'top-center',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                })
            }
        },
		dataStream(argument) {
			if (argument) {
				axios
				.get('/api/v1/datastream/', {params: {
                    'datastream': 'SignUp_Page_Access:Loaded',
                    'window': {
						'userAgent': window.navigator.userAgent,
						'innerHeight': window.innerHeight,
						'innerWidth': window.innerWidth,
					}
                }})
				.then(response => {
				})
				.catch(error => {
					console.log(error)
				})
		    }
        },
        setOptionMunicipio(option) {
            this.organisation_city = option
            // console.log("Município selecionado: "+this.municipioSelecionado)
        },
        showCard(cardName) {
            this.cardShowing = cardName
        },
        submitForm(e) {
            const formData = {
                email: this.email,
                first_name: this.nome,
                last_name: this.sobreNome,
                password: this.password,
                re_password: this.re_password,
                registration_form: {
                    organisation_type: this.organisation_type,
                    organisation_city: this.organisation_city,
                    organisation_name: this.organisation_name,
                    organisation_number_of_employees: this.organisation_number_of_employees,
                }
            }
            axios
                .post("/auth/users/", formData)
                .then(response => {
                    // console.log(response)
                    this.$router.push('/signed-up') // XYZ Change redirect to activate account page template
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                        console.log(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.log(JSON.stringify(error.message))
                    } else {
                        console.log(JSON.stringify(error))
                    }
                })
        }
    }
}
</script>

<style scoped>
.title {
    margin-top:2rem
}
@media (max-width: 769px) {
    .field {
        max-width: 50%;
        margin-right: auto;
        margin-left: auto;
    }
    .title {
        margin-left: 25%;
    }
}
/* CARD STRUCTURE */
.card {
    border-color: #3273dc67;
    border-width: 1px;
    border-style: solid;
    height: 480px;
}


hr {
    border:solid 1px rgba(128, 128, 128, 0.466);
    width: 100%;
    height: 2px;
}



/* Buttons */
.question-navigation {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}

/* ANIMATIONS */
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-fade-enter-from {
  transform: translateX(100%); 
  opacity: 1;
}

.slide-fade-leave-to {
  transform: translateX(-100%);
  opacity: 1;
}

/* Back Scrolling */
.slide-fade-reverse-enter-active,
.slide-fade-reverse-leave-active {
  transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}
.slide-fade-reverse-enter-from {
  transform: translateX(-100%);
  opacity: 1;
}

.slide-fade-reverse-leave-to {
  transform: translateX(100%);
  opacity: 1;
}
.card + .card {
  position: absolute;
}

.transition-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Layering cards */
.layered {
  display: grid;
}

.layered > * {
  grid-column-start: 1;
  grid-row-start: 1;
}

/* or for inline elements */
.inline-layered {
  display: inline-grid;
}

.inline-layered > * {
  grid-column-start: 1;
  grid-row-start: 1;
}
</style>