<template>
    <div class="page-my-account">
        <!-- <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li class="is-active"><router-link to="/dashboard/my-account" aria-current="true">My account</router-link></li>
            </ul>
        </nav> -->
        <h1 class="title" style="margin-bottom:24px">Perfil Clínica</h1>                       
        <div class="columns is-multiline">
            <div class="column is-12 is-multiline pt-0">
                <div class="box pt-0 pb-4" id="box-meuPerfil"> <!-- BOX MEU PERFIL  -->
                    <h1 class="subtitle is-4 mb-0"><strong>{{clinicFormData.nome}}</strong></h1>
                    <br>
                    <hr />
                    <!-- TAB HEADERS -->
                    <div class="tabs is-boxed is-fullwidth is-centered is-medium">
                        <ul>
                            <!-- <li :class="{ 'is-active': isTabActive('Vizualizacao') }" @click="setActiveTab('Vizualizacao')"><a>Vizualização Geral</a></li> -->
                            <li :class="{ 'is-active': isTabActive('DadosCadastrais') }" @click="setActiveTab('DadosCadastrais')"><a>Dados Cadastrais</a></li>
                            <li :class="{ 'is-active': isTabActive('ExamesDisponiveis') }" @click="setActiveTab('ExamesDisponiveis')"><a>Exames Disponíveis</a></li>
                        </ul>
                    </div>
                    <!-- TAB DADOS CADASTRAIS -->
                    <section class="tab" v-show="isTabActive('DadosCadastrais')">
                        <EditGenericSerializer
                            ref="clinicForm"
                            :key="clinicFormKey"
                            :object="clinicFormData"
                            apiURL='/api/v1/clinics/v0/'
                            submitMethod = 'put'
                            formValidationSchema = 'clinicEditSchema'
                            :withButtons = "false"
                        >
                        </EditGenericSerializer>
                        <br>
                        <div class="buttons is-right">
                            <button class="button is-info" @click="submitEditClinic()" style="margin-right:1rem">Salvar Alterações</button>
                        </div>
                    </section>
                    <!-- TAB EXAMES DISPONÍVEIS -->
                    <section class="tab" v-show="isTabActive('ExamesDisponiveis')">
                        <div style="margin:2rem;margin-top: 0;">
                            <div class="columns is-multiline">
                                <div class="column is-6">
                                    <h4 class="title is-4" style="margin-bottom:0">Exames Disponíveis</h4>
                                </div>
                                <div class="column is-6">
                                    <button 
                                        class="button is-info is-centered strong"
                                        @click="activateModal('Modal_AddAvailableExam')"
                                        style="float:right"
                                    >
                                        <strong> Disponibilizar Novo Exame </strong>
                                    </button>
                                </div>
                                <div class="column is-12">
                                    <DataTable
                                        apiURL='/api/v1/clinics/available-exams/'
                                        :apiParams="{}"
                                        ref="dataTableExamesDisponiveisRef"
                                        :hasSearchField="true"
                                        :hasActionsColumn="true"
                                        :actionButtons="{
                                            'Delete': 'Deletar'
                                        }"
                                        :fieldsToHide="['pk']"
                                        :key="dataTableKey"
                                        @toggleDeleteModal="activateModalAvailableExamDelete"
                                    />

                                </div>
                            </div>
                        </div>
                        <br>
                    </section>
                </div>                
            </div>
            
        </div>
        <hr>
    </div>
    <!-- MODAL - ADD AVAILABLE EXAM -->
    <div class="modal" :class="{ 'is-active': isModalActive('Modal_AddAvailableExam') }" v-if="isModalActive('Modal_AddAvailableExam')">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Adicionar Exame Disponível</p>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body">
                <form-generic-serializer
                    ref="Modal_AddAvailableExam_Form"
                    :object="availableExamToAdd"
                    apiURL='/api/v1/clinics/available-exams/'
                    formValidationSchema="addAvailableExamSchema"
                    @schemaIsValidChanged="formEvent_AvailableExamChanged"
                />
                <h5>* Apenas exames que ainda não estejam disponíveis podem ser adicionados.</h5>
            </section>
            <footer class="modal-card-foot">
                <div class="buttons"> <!-----------------------------------------------------------Make buttons centered -->
                    <button class="button is-danger" @click="closeModal()">Cancelar</button>
                    <button class="button is-success" @click="submitAddAvailableExamForm">Adicionar</button>
                </div>
            </footer>
        </div>
    </div>
    <!-- MODAL - DELETE AVAILABLE EXAM -->
    <div class="modal" :class="{ 'is-active': isModalActive('Modal_AvailableExamDelete') }" v-if="isModalActive('Modal_AvailableExamDelete')">
        <div class="modal-background"></div>
        <div class="modal-card" id="Modal_AvailableExamDelete">
            <header class="modal-card-head">
                <p class="modal-card-title">Deletar Exame Disponível</p>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body" style="modal-content-width:1200px">
                <AvailableExamDelete
                    :availableExam="modalInputData"
                    :modalIsActive="isModalActive('Modal_AvailableExamDelete')"
                    @closeDeleteModal="closeModal()"
                    @closeDeleteModalWithReload="closeModal_AvailableExamDelete()"
                />
            </section>
        </div>
    </div>

</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import { Field, Form, ErrorMessage } from 'vee-validate';
import { ref } from "vue"
// import yup from 'yup';

import EditGenericSerializer from '../../components/EditGenericSerializer.vue'
import FormGenericSerializer from '../../components/FormGenericSerializer.vue'
import AvailableExamDelete from '../../components/clinics/AvailableExamDelete.vue';
import {useAuthenticationStore} from "@/stores/authentication"
import { validationSchemaDictionary } from '@/utilities/validations.js'
import DataTable from '@/components/dataTable/DataTable.vue'
import { toRaw} from 'vue'

export default {
    name: 'ClinicaPerfil',
    components: {
        DataTable,
        Field, Form, ErrorMessage,
        EditGenericSerializer,
        FormGenericSerializer,
        AvailableExamDelete,
    },
    setup() {
        const authentication = useAuthenticationStore()
        return {
            authentication
        } 
    },
    data() {
        // let schema = yup.object().shape({
        //     nome: yup.string().required('O nome é obrigatório'),
        //     sobreNome: yup.string().required('O sobrenome é obrigatório'),
        //     cpf: yup.string().required().length(11, 'O CPF deve conter 11 dígitos'),
        //     email_corporativo: yup.string().required().email('Email inválido'),
        //     email_pessoal: yup.string().required('O email pessoal é obrigatório').email('Email inválido'),
        // });
        // let clinicFormData = {}
        return {
            activeModal: '',
            activeTab: 'DadosCadastrais',
            availableExamToAdd: {},
            availableExam_HasValidSchema: false,
            changePassword: {'current_password': '', 'new_password': '', 're_new_password': ''},
            clinicFormKey: ref(0),
            clinicFormData: {},
            dataTableKey: ref(0),
            formValidationSchema: 'clinicEditSchema',
            modalInputData: {},
            modalInputID: null,
        }
    },
    async created() {
        // make an axios call to get the clinic's details
        this.getClinicDetails()
        
    },
    computed: {
        schemaIsValid() {
            return validationSchemaDictionary[this.formValidationSchema].isValidSync(this.clinicFormData)
        },
    },
    methods: {
        activateModal(modalName, data='') {
            // console.log('Organizacao_activateModal Called for modal:', modalName, ' and data:',data)
            const rawData = toRaw(data)
            if (data!='') {
                // console.log('activateModal data recognised. Received data:',data)
                this.modalInputData = rawData
                // console.log('modalInputData:',this.modalInputData)
                this.modalInputID = rawData.pk
                // console.log('modalInputID:',this.modalInputID)
            }
            this.activeModal = modalName
            // console.log('activeModal set to:',this.activeModal)
        },
        activateModalAvailableExamDelete(data) {
            this.activateModal('Modal_AvailableExamDelete',data)
        },
        closeModal() {
            this.activeModal = ''
        },
        closeModal_AvailableExamDelete() {
            this.dataTableKey++
            this.closeModal()
        },
        formEvent_AvailableExamChanged(data) {
            this.availableExam_HasValidSchema = data
        },
        getClinicDetails() {
            axios
                .get('/api/v1/clinics/v0/')
                .then(response => {
                    // console.log('getClinicDetails response.data:',response.data)
                    this.clinicFormData = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        isModalActive(modalName) {
            return this.activeModal === modalName
        },
        isTabActive(tabItem) {
            return this.activeTab === tabItem
        },
        setActiveTab(tabItem) {
            this.activeTab = tabItem
        },
        submitAddAvailableExamForm() {
            if (this.availableExam_HasValidSchema) {
                axios
                    .post('/api/v1/clinics/available-exams/', this.availableExamToAdd)
                    .then(response => {
                        // this.$router.push('/organizacao')
                        // console.log(response)
                        // this.businessUnits.unshift(response.data)
                        this.$refs.dataTableExamesDisponiveisRef.table.rows.unshift(response.data)
                    })
                    .catch(error => {
                        // console.log(JSON.stringify(error))
                        console.log(error)
                    })
                    .then( () => { 
                        this.dataTableKey++
                        this.closeModal()
                    })
            }
            else {
                this.$refs.Modal_AddAvailableExam_Form.$refs.form.validate()
                toast({
                    position:'bottom-right',
                    message: 'Favor preencher os campos de acordo com os requisitos.',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000
                })
            }
        },

        submitEditClinic() {
            // console.log('this.formValidationSchema:', this.formValidationSchema)
            // console.log('this.clinicFormData:', this.clinicFormData)
            // console.log('this.schemaIsValid:', this.schemaIsValid)
            if (this.schemaIsValid) {
                axios
                    .put('/api/v1/clinics/v0/', this.clinicFormData)
                    .then(response => {
                        // console.log(response)
                        this.clinicFormKey++
                        toast({
                            position:'bottom-right',
                            message: 'Dados da clínica atualizados com sucesso!',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000
                        })
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                    })
            }
            else {
                console.log('this.$refs.clinicForm.$refs:',this.$refs.clinicForm.$refs)
                this.$refs.clinicForm.$refs.form.validate()
                toast({
                    position:'bottom-right',
                    message: 'Favor preencher os campos de acordo com os requisitos.',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000
                })
            }
        }
    }
}
</script>

<style scoped>
.columns {
    margin-top: 0px;
}

.tabs {
    margin-bottom: 0px;
}
.tabs a {
    color: rgba(128, 128, 128, 0.802);
}

.tabs li.is-active a {
    color: #000;
    font-weight: 600;
}
.tab {
    padding-top: 2rem;
    padding-bottom: 2rem;
    /* padding-right: 1rem; */
    border-color: hsl(0deg, 0%, 86%);
    border-style: solid;
    border-width: 1px;
    border-top-color: transparent;
    margin-bottom: 0;
}

hr {
      width: 95%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 2px;
      margin-bottom: 1rem;
    }

#box-meuPerfil {
    height: 100%;
}

li {
    color: gray;
}

/* .tabs.is-boxed li.is-active a {
    font-weight: 500;
} */

#box-perfilCorporativo {
    height: 100%;
}
</style>