<template>
    <div>
        <!-- DEBUGGING - Table refs:
        apiURL:{{apiURL}}
        apiParams:{{apiParams}}
        hasActionColumn: -->
        <!-- DataTable: {{actionButtons}} -->
        <!-- <div class="columns is-multiline">
            SearchTerm: {{searchTerm}}
            <hr>
            Columns:{{table.columns}}
            <hr>
            Table.unfilteredRows: {{table.unfilteredRows}}
            <hr>
            Table Rows: {{table.rows}}
            <hr>
        </div> -->
        
        <!-- This search field used to be here, outside the TableLite2 component. 
            However, the need to align both the field and the table arised, and that required to transfer the field to inside the component -->
        <!-- <hr v-if="hasSearchField" class="mt-0 mb-3">
        <div style="text-align: left" v-if="hasSearchField">
            <div class="columns is-multiline">
                <div class="column is-3">
                    <label><strong> Filtro de Busca: </strong>  </label>
                    <input
                        class="input is-info is-normal"
                        type="text"
                        placeholder="Buscar em qualquer campo da tabela."
                        v-model="searchTerm"
                    >
                </div>
            </div>
        </div>
        <br v-if="hasSearchField"> -->
        <TableLite2
            :is-static-mode="true"
            :is-loading="table.isLoading"
            :columns="table.columns"
            :rows="table.rows"  
            :total="table.totalRecordCount"
            :sortable="table.sortable"
            :actionableButtons="actionButtons"
            @is-finished="table.isLoading = false"
            :hasActionColumn ="hasActionsColumn"
            :hasSearchField="hasSearchField"
            @search_input_changed="searchInputChanged"
            @toggleDetailsModal="emitDetailsData"
            @toggleEditModal="emitEditData"
            @toggleDeleteModal="emitDeleteData"
        ></TableLite2>
    </div>
</template>

<script>
import { reactive, ref } from '@vue/reactivity';
import { computed, defineComponent, onMounted } from '@vue/runtime-core';
import axios from 'axios';
import TableLite2 from '@/components/dataTable/TableLite2.vue'

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default defineComponent({
    name: "DataTable",
    components: { TableLite2 },
    props: {
        apiURL: String,
        apiParams: Object,
        hasActionsColumn: Boolean,
        actionButtons: {
            type: Object,
            default: {
                'Info': 'Detalhes',
                'Edit': 'Editar',
                'Delete': 'Deletar'
            }
            // default: ['Detalhes','Editar','Deletar'] -> Old version, deprecated when the actionButtons text became editable by DT prop
        },
        hasSearchField: {
            type: Boolean,
            default: false
        },
        fieldsToHide: {
            type: Array,
            default: ['pk']
        },
        fieldNameManager: {
            type: Object,
            default: {}
        },
    },
    components: {
        TableLite2
    },
    setup(props) {
        const searchTerm = ref("")
        

        const getData = () => {
            const objectArrayComparisonCallback = (arrayItemA, arrayItemB) => {
                const objectKeys = Object.keys(arrayItemA)
                const secondKey = objectKeys[1]
                // console.log('objectArrayComparisonCallback')
                // console.log('arrayItemA',arrayItemA)
                // console.log('arrayItemB',arrayItemB)
                // console.log('objectKeys',objectKeys)
                // console.log('secondKey',secondKey)
                if (arrayItemA[secondKey] < arrayItemB[secondKey]) {
                    return -1
                }
                if (arrayItemA[secondKey] > arrayItemB[secondKey]) {
                    return 1
                }
                return 0
            }

            axios
                .get(props.apiURL, { params: props.apiParams })
                .then(response=> {
                    // table.rows = response.data
                    // console.log('table.rows defined')
                    // Before setting the unfilteredRows we need to sort the data by the second key (the first key is the pk)
                    table.unfilteredRows = response.data.sort(objectArrayComparisonCallback)
                    // console.log('DataTable.getData() table.unfilteredRows:',table.unfilteredRows)
                    // unfilteredRowsInsideSetup = response.data
                    // console.log('DataTable_Setup_getData-response.data[0]:',response.data[0])
                    for (const key in response.data[0]) {
                        // console.log('props.fieldsToHide.contains(key):', props.fieldsToHide.includes(key))
                        // console.log('Fields to hide:',props.fieldsToHide,' key:',key)
                        // Logic to manage the field names
                        if (props.fieldNameManager.hasOwnProperty(key)) {
                            table.columns.push(
                                {
                                    label: props.fieldNameManager[key],
                                    field: key,
                                    hideField: props.fieldsToHide.includes(key),
                                    sortable: true,
                                }
                            )
                            continue
                        }
                        table.columns.push(
                            {
                                label: capitalize(key),
                                field: key,
                                hideField: props.fieldsToHide.includes(key),
                                // width: Object.keys(response.data[0]).length.toString()+"%",
                                sortable: true,
                            }
                        )
                    }
                })
                .catch(error => {
                    console.log(error)
                    console.log(JSON.stringify(error))
                });
        }
        
        getData()

        const standardizeForFilter  = (str) =>{
            return str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        }

        // Init Your table settings
        const table = reactive({
            isLoading: false,
            columns: [],
            // rows: [],
            unfilteredRows: [],
            rows: computed(() => {
                if (props.hasSearchField) {
                    return table.unfilteredRows.filter(
                        function(row) {
                            for (const key in row) {
                                if (typeof(row[key]) == 'number') {
                                    if (row[key].toString().includes(searchTerm.value)) {
                                        return true
                                    }
                                }
                                else if (typeof(row[key]) == 'string') {
                                    // if (row[key].toUpperCase().includes(searchTerm.value.toUpperCase())) { // Old version, without standardizing accents
                                    if (standardizeForFilter(row[key]).includes(standardizeForFilter(searchTerm.value))) {
                                        return true
                                    }
                                }
                            }
                            return false
                            // return  row.nome.toUpperCase().includes(searchTerm.value.toUpperCase()) ||
                            // row.razaoSocial.toUpperCase().includes(searchTerm.value.toUpperCase())
                        }
                    );
                }
                else {
                    return table.unfilteredRows
                }
            }),
            totalRecordCount: computed(() => {
                return table.rows.length;
            }),
            sortable: {
                order: "Nome",
                sort: "asc",
            },
        });

        // console.log('Marker of code line, inside setup')
        // console.log('unfilteredRowsInsideSetup.value.length:', table.unfilteredRows.length)
  
        return {
        table,
        searchTerm,
        };
    },
    data() {
        return {
            unfilteredRowsData: []
        }
    },
    created() {
        // this.getData()
    },
    methods: {
        emitDetailsData(data) {
            // console.log('DataTable_emitDetailsData called with data:',data)
            this.$emit('toggleDetailsModal',data)
        },
        emitEditData(data) {
            this.$emit('toggleEditModal',data)
        },
        emitDeleteData(data) {
            // console.log('DataTable_emitDeleteData CALLED')
            this.$emit('toggleDeleteModal',data)
        },
        searchInputChanged(data) {
            this.searchTerm = data
        }
    }
})
    
</script>

<style>

</style>