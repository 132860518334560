<template>
    <GMapMap
      class = "map"
      :center="mapCenter"
      :zoom="zoomRequired"
      map-type-id="terrain"
    >
        <GMapMarker
        v-for="(marker, index) in markers"
        :key="index"
        :position="marker.position"
        :label ="marker.label"
        :clickable="true"
        @click="openMarker(index)"
        :draggable="false"
        :optimized="false"
        >
            <GMapInfoWindow
                :opened="openedMarkerID === index"
            >
                <div class="content px-2 py-2">
                    <strong>{{marker.infotext}}</strong>
                </div>
            </GMapInfoWindow>
        </GMapMarker>
    </GMapMap>
</template>

<script>
export default {
    props: {
        mapCenter:Object,
        markers:Array,
        zoomRequired:Number, 
    },
    data(){
        return {
            openedMarkerID: null,
        }
    },
    watch: {

    },
    methods: {
        openMarker(index) {
            this.openedMarkerID = index
        },

    },  
    mounted(){

    }
}
</script>

<style scoped>
.map {
    height: 19rem;
}
</style>

