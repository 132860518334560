<template>
    <div class="page-container" style="">
        <div class="section" style="width:100%;padding: 20px;">
			<div class="columns is-multiline is-centered">
				<div class="column is-12 has-text-centered">
					<h1 class="title is-1" id="sectionTitle">Nossa Expertise</h1>
				</div>
                <!-- <div class="column is-12 has-text-centered">
                    <hr style="background-color: rgba(49, 53, 62, 0.818);">
                </div> -->
                <div class="section" id="PostDetailsModalCard">
                    <header class="modal-card-head">
                        <p class="modal-card-title"></p>
                    </header>
                    <section class="modal-card-body pt-0" style="modal-content-width:1200px">
                        <div class="modal-body content">
                            <h2 class="modal-title" id="modalTitle">{{ artigo.title }}</h2>
                            <!-- <button class="delete" aria-label="close" @click="closeModal()"></button> -->
                            <div class="modal-info">
                                <span class="modal-info-author">por {{ artigo.author }}</span>
                                <span class="modal-info-date">{{ formatDate(artigo.created_at) }}</span>
                            </div>
                            <hr style="margin-top:0.5rem">
                            <div style="text-align: justify;" v-for="paragrafo in paragrafos">
                                <p class="article-paragraph">{{ paragrafo }}</p>
                            </div>
                            <hr>
                            <div class="modal-tags">
                                <span class="modal-tag-title">Categorias: </span>
                                <span class="modal-tag" v-for="(tag, index) in artigo.tags" :key="index">{{ tag.name }}</span>
                            </div>
                            <div style="display: flex; justify-content: center;">
                                <hr style="width: 10%;">
                            </div>
                            <div style="display: flex; justify-content: center;">
                                <button class="button is-info is-outlined"><router-link :to="{name:'Artigos'}" >Retornar à página de artigos</router-link></button>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            
        </div>
    </div>
</template>
  
<script>
import axios from 'axios';

export default {
    name: 'Artigo-Detalhe',
    props: {
        articleID: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            artigo: {
                'content': '',
            },
        };
    },
    mounted() {
        const articleID = this.$route.params.articleID;
        this.fetchArticle(articleID);
    },
    computed: {
        paragrafos() {
            const paragrafos = this.artigo.content.split("\n")
            return paragrafos;
        }
    },
    methods: {
        fetchArticle(articleID) {
            axios
                .get('/api/v1/posts/' + articleID)
                .then(response => {
                    if (response.data) {
                        this.artigo = response.data;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        formatDate(date) {
            var date = new Date(date);
            return (date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear())
        },
    }
};
</script>

<style scoped>
.page-container {
    /* padding-left:72px;
    padding-right: 72px; */
    background-color: rgba(216, 233, 248, 0.473);
    display: flex;
    justify-content: center;
    align-items: center;
}
.section {
    max-width: 1368px;
}
@media (max-width: 768px) {
    .section {
        padding: 8px;
    }
}
#sectionTitle {
	color: rgba(49, 53, 62, 0.818);
	font-size: 2.2rem;
	font-weight: 800;
}
@media (max-width: 768px) {
    #sectionTitle {
        font-size: 1.8rem;
    }
    #modalTitle {
        font-size: 20px;
        text-align: justify
    }

}
#sectionSubTitle {
	padding-top: 0rem;
	padding-bottom: 0rem;
	color: rgb(94, 107, 117);
	font-size: 1.1rem;
	font-weight: 200;
	font-family: sans-serif;
}

.section {
    width: 100%;
    height: fit-content;
    padding-top: 1rem;
}
.modal-card-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4px;
    padding: 0px;
    background-color: rgba(49, 53, 62, 0.818);
    color: white;
}
.modal-card-body {
    height: fit-content;
}
@media (max-width: 768px) {
    .modal-card-body {
        padding: 8px;
    }
}
.modal-body {
    height: fit-content;
    padding: 8px;
    padding-bottom: 0;
}

.modal-title {
  font-size: 24px;
  font-weight: bold;
  color: rgb(0, 114, 206);
}

.modal-info {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}

.modal-info-author {
  font-size: 14px;
  font-weight: bold;
  color: #666;
}

.modal-info-date {
  font-size: 14px;
  font-weight: bold;
  color: #666;
}

.modal-content {
  margin-top: 16px;
  margin: 1rem;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}
.post-content {
    margin:3rem;
    margin-left:2rem;
    padding-left: 2rem
}
.article-paragraph {
    margin:8px;
    /* margin-left:3rem;
    margin-right:3rem; */
}

.modal-tags {
  margin-top: 16px;
}

.modal-tag-title {
  font-size: 14px;
  font-weight: bold;
  color: #666;
  padding-right:1rem
}

.modal-tag {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 6px 12px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background-color: rgb(0, 114, 206);
  border-radius: 4px;
}
</style>
  