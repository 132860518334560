<template>
    <div class="generic-form" style="min-height:10rem">
        <div class="columns is-multiline">
            <!-- object: {{object}} -->
            <br>
            <!-- objectBackup: {{objectBackup}} -->
            <!-- <br> -->
            <!-- schemaIsValid: {{schemaIsValid}} -->
            <!-- {{formFieldsText}} -->
            <!-- {{formFieldsSelect}} -->
            <div class="column is-2">
            </div>
            <div class="column is-8">
                <Form ref="form" class="form" :validation-schema="schema" :validateOnValueUpdate="false">
                    <div v-for="requiredField in formFieldsSelect" :key="requiredField.id">
                        <div class="field" v-if="requiredField.type='select'">
                            <label>{{requiredField.label}}</label>
                            <div >
                                <div class="select is-info" >
                                    <!-- <select v-model="object[requiredField.name]"> -->
                                    <Field :name="requiredField.name" as="select" v-model="object[requiredField.name]">
                                        <option
                                        v-for="option in requiredField.options" :key="option.id"
                                        :value= "option.value"
                                        >
                                            {{option.name}}
                                        </option>
                                    <!-- </select> -->
                                    </Field>
                                </div>
                            </div>
                            <ErrorMessage :name="requiredField.name" style="color:red"/>
                        </div>
                    </div>
                    <div v-for="requiredField in formFieldsText" :key="requiredField.id">
                        <div v-if="requiredField.type='text'">
                            <div class="field" >
                                <label>{{requiredField.label}}</label>
                                <div class="control" >
                                    <!-- <input 
                                    :type= "requiredField.type"
                                    :name= "requiredField.name"
                                    class= "input is-info"
                                    :placeholder= "requiredField.placeholder"
                                    :disabled= "requiredField.disabled"
                                    v-model="object[requiredField.name]"
                                    > -->
                                    <Field 
                                        :name="requiredField.name"
                                        as="input"
                                        :type= "requiredField.type"
                                        class= "input is-info"
                                        :placeholder= "requiredField.placeholder"
                                        :disabled= "requiredField.disabled"
                                        v-model="object[requiredField.name]"
                                    />
                                </div>
                                <ErrorMessage :name="requiredField.name" style="color:red"/>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toRaw } from '@vue/reactivity'
//Form validation
import { Field, Form, ErrorMessage } from 'vee-validate';
import { validationSchemaDictionary } from '@/utilities/validations.js'

export default {
    name: 'FormGenericSerializer',
    props: {
        object: Object,
        apiURL: String,
        formValidationSchema: String,
    },
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            schema: {},
            formFieldsText: [],
            formFieldsSelect: [],
            objectBackup: toRaw(this.object),
        }
    },
    created() {
        this.queryFormFields(this.apiURL)
        setTimeout(() => {
            this.schema = validationSchemaDictionary[this.formValidationSchema]
        }, 200) // If the schema loads before the queryFormFields, the reactive property will identify a change and trigger the validation
    },
    computed: {
        schemaIsValid() {
            return validationSchemaDictionary[this.formValidationSchema].isValidSync(this.object)
        },
    },
    watch: {
        schemaIsValid(newValue, oldValue) {
            // console.log('schemaIsValid changed from', oldValue, 'to ' ,newValue)
            this.$emit('schemaIsValidChanged', newValue)
        },
    },
    methods: {
        async queryFormFields(apiURL) {
            // console.log('FormGenericSerializer_queryFormFields called for apiURL:',apiURL)
            await axios
                .post(apiURL, 'queryFormFields')
                .then(response => {
                    // console.log('response.data:',response.data)
                    for (var i = 0; i < response.data.length; i++) {
                        if (response.data[i].type == 'text') {
                            this.formFieldsText.push(response.data[i])
                        } else if (response.data[i].type == 'select') {
                            this.formFieldsSelect.push(response.data[i])
                        }
                    } 
                    // this.formFieldsText = response.data
                })
                .catch(error => {
                    // console.log(JSON.stringify(error))
                    console.log(error.request.response)
                })
        }
    }
}
</script>

<style>

</style>