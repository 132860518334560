<template>
    <div class="card pl-4 pr-4 pt-2 mb-3 pb-4" id="card-wrapper">
        <div class="content is-small">
            <h1>{{titulo}}</h1>
            <hr>
            <div class="box" v-for="(item,index) in dataArraySingle" :key="index">
                <div class="box-upper mb-0 pb-0" >
                    <div class="column">
                        <div><strong>{{item.markerLabel}}) {{item.nome}}</strong> </div>
                        <div>{{item.endereco}}</div>
                    </div>
                    <div class="column is-vcentered">
                        {{item.telefone}}
                    </div>
                    <div class="column is-vcentered">
                        {{item.distancia}}
                    </div>
                    <div class="column is-vcentered has-text-centered">
                        <strong>R$ {{item.totalexames.toFixed(2)}} </strong>
                    </div>
                    <div class="column is-vcentered has-text-right" @click="toggleLowerBox(index)">
                        <font-awesome-icon v-if="!item.open" :icon="['fas', 'chevron-down']" style='font-size:25px;color:gray' />
                        <font-awesome-icon v-else :icon="['fas', 'chevron-up']" style='font-size:25px;color:gray' />
                    </div>
                </div>
                <div class="box-lower mb-2 mt-0 pt-0" v-if="item.open">
                    <hr class="mt-0 pt-0 mb-0 pb-0">
                    <hr class="mt-0 pt-0 mb-0 pb-0">
                    <hr class="mt-0 pt-0 mb-0 pb-0">
                    <hr class="mt-0 pt-0 mb-0 pb-0">
                    <div class="column is-vcentered mt-0 pt-0" style="line-height:0px">
                        <strong>Imagem da Clinica</strong> 
                    </div>
                    <div class="column is-vcentered mt-0 pt-0 has-background-light" style="line-height:0px">
                        <strong>Horarios de abertura</strong>
                    </div>
                    <div class="column mt-0 pt-0">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Exame</th>
                                    <th class="has-text-centered">SLA</th>
                                    <th class="has-text-right">Preço</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(exam, key, index2) in item.exames" :key="index2">
                                    <td >{{key}}</td>
                                    <td class="has-text-centered">{{exam.sla}}</td>
                                    <td class="has-text-right">R$ {{exam.preco.toFixed(2)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="column is-vcentered mt-0 pt-0" style="line-height:0px">
                        <!-- add button with text "Solicitar Agendamento" -->
                        <ControlledButton :requiredTier="requiredTiers.card_pacote_clinica">
                            <button class="button is-info" @click="emitOpenModal(item)">Agendar</button>
                        </ControlledButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useExamSearch} from '@/stores/examSearch.js'
import { toRaw } from 'vue'

import ControlledButton from '@/components/ControlledButton.vue'
import {requiredTiers} from '@/utilities/requiredTiers.js'

export default {
    name: 'CardPacoteClinica',
    components: {
        ControlledButton
    },
    props: {
        titulo: String,
    },
    setup() {
      const examQueryStore = useExamSearch()
      return {examQueryStore, ControlledButton, requiredTiers}
    },
    data() {
        return {
            // dataArraySingle: this.examQueryStore,
            // [
            //     {
            //         nome: "Clinica UM",
            //         markerLabel: "A",
            //         endereco: "Rua Deodoro da Fonseca, 123",
            //         distancia: "1,2 km",
            //         telefone: "(17) 3225-2254",
            //         exames: {
            //             "Admissional": {
            //                 'nome': 'Admissional',
            //                 'preco': 'R$ 999,99',
            //                 'sla': '2'
            //             } ,
            //             "Audiometria Ocupacional": {
            //                 'nome': 'Audiometria',
            //                 'preco': 'R$ 999,99',
            //                 'sla': '2'
            //             } ,
            //             "Eletrocardiograma (ECG)": {
            //                 'nome': 'Eletrocardiograma',
            //                 'preco': 'R$ 999,99',
            //                 'sla': '2'
            //             } ,
            //             // "R$ 36,50",
            //             // "Audiometria Ocupacional": "R$ 28,50",
            //             // "Eletrocardiograma (ECG)": "R$ 93,50",
            //         },
            //         totalexames: "R$ 3157,50",
            //         open: false,
            //         counter: 0
            //     }
            // ],
            dataArrayMultiple: [
                [
                    {
                        nome: "Clinica Dois",
                        markerLabel: "B",
                        endereco: "Rua Priscila Amaral, 82",
                        distancia: "1,9 km",
                        telefone: "(17) 3225-2254",
                        exames: {
                            "Admissional": "R$ 38,50",
                            "Audiometria Ocupacional": "R$ 23,50",
                            "Eletrocardiograma (ECG)": "R$ 108,50",
                        },
                        totalexames: "R$ 137,50",
                        open: false,
                        counter: 0
                    },
                    {
                        nome: "Clinica TRES",
                        markerLabel: "C",
                        endereco: "Rua Cacilda Gonçalves, 1200",
                        distancia: "0,3 km",
                        telefone: "(17) 3225-2254",
                        exames: {
                            "Admissional": "R$ 42,50",
                            "Audiometria Ocupacional": "R$ 31,50",
                            "Eletrocardiograma (ECG)": "R$ 87,00",
                        },
                        totalexames: "R$ 197,50",
                        open: false,
                    }  
                ],
                [
                    {
                        nome: "Clinica UM",
                        markerLabel: "A",
                        endereco: "Rua Deodoro da Fonseca, 123",
                        distancia: "1,2 km",
                        telefone: "(17) 3225-2254",
                        exames: {
                            "Admissional": "R$ 36,50",
                            "Audiometria Ocupacional": "R$ 28,50",
                            "Eletrocardiograma (ECG)": "R$ 93,50",
                        },
                        totalexames: "R$ 157,50",
                        open: false,
                        counter: 0
                    },
                    {
                        nome: "Clinica Dois",
                        markerLabel: "B",
                        endereco: "Rua Priscila Amaral, 82",
                        distancia: "1,9 km",
                        telefone: "(17) 3225-2254",
                        exames: {
                            "Admissional": "R$ 38,50",
                            "Audiometria Ocupacional": "R$ 23,50",
                            "Eletrocardiograma (ECG)": "R$ 108,50",
                        },
                        totalexames: "R$ 137,50",
                        open: false,
                        counter: 0
                    }
                ]
            ]
        }
    },
    methods: {
        emitOpenModal(item) {
            // console.log('CardPacoteClinica emitOpenModal with item: ', toRaw(item))
            this.$emit('openSchedulingModal', item)
        },
        toggleLowerBox(index) {
            this.dataArraySingle[index].open = !this.dataArraySingle[index].open
        }
    },
    computed: {
        dataArraySingle() {
            return this.examQueryStore.pacotesExamesClinicaUnica
        }
    }
}
</script>

<style scoped>
.column.is-vcentered {
  display: flex;
  flex-wrap: wrap;
  align-content: center; /* used this for multiple child */
  align-items: center; /* if an only child */
}

#card-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* grid-auto-rows: minmax(200px,auto); */
}

.content.is-small {
    margin-bottom: 0rem;
    padding-bottom: 0rem;
}

.box {
    box-shadow:0em;
    background-color: rgb(255, 255, 255);
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(238, 238, 238);
    padding-bottom: 0rem;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 0rem;
}

.box-upper {
    box-shadow:0em;
    background-color: rgb(255, 255, 255);
    width: 100%;
    margin-top: 0rem;
    margin-left: 0rem;
    margin-right: 0rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 0.25fr;
}

.box-lower {
    box-shadow:0em;
    background-color: rgb(255, 255, 255);
    width: 100%;
    margin-left: 0rem;
    margin-right: 0rem;
    margin-top: 0rem;
    padding-top: 0rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr 0.5fr;
}

</style>