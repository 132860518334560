<template>
  <div class="sidebar" :style="{ width: sidebarWidth }">
    <h1 class="mb-4">
      <Transition name="fade">
        <span v-if="collapsed">
        <!-- Add an image with a transition effect in case the v-if changes -->
          <!-- <img src="@/assets/logo.png" alt="logo" /> -->

          <div><img src="@/assets/CM-Vertical.webp" alt="Logo-V"></div>
        </span>
        <span v-else><img src="@/assets/CM-Horizontal.png" alt="Logo-H"></span>
      </Transition>
    </h1>
    
    <!-- <button @click="authenticationStore.userRoles=[7]">Perfil Empresa</button>
    <button @click="authenticationStore.userRoles=[8]">Perfil Clinica</button> -->

    <SidebarLink to="/" icon="fas fa-home" class="mt-4">Home</SidebarLink>
    <SidebarLink to="/dashboard/my-account" icon="fa-regular fa-id-badge" class="mt-4">Gerir Perfil</SidebarLink>
    
    <!-- User is Corporate -->
    <div v-if="authenticationStore.userRoles.includes(7)">
      <!-- User tier 1 -->
      <section v-if="authenticationStore.tier==1">
        <SidebarLink to="" icon="fas fa-sitemap" class="mt-4" @click="restrictedAccess" style="opacity:0.5">Gerenciar Org.</SidebarLink>
        <SidebarLink to="" icon="fa-solid fa-users" class="mt-4" @click="restrictedAccess" style="opacity:0.5">Gerenciar Time</SidebarLink>
        <SidebarLink to="/planejar-exames" icon="fas fa-chart-gantt" class="mt-4">Planejar Exames</SidebarLink>
        <SidebarLink to="/buscaexames" icon="fas fa-magnifying-glass" class="mt-4">Buscar Exames</SidebarLink>
        <SidebarLink to="" icon="fa fa-list-alt" class="mt-4" @click="restrictedAccess" style="opacity:0.5">Requisições</SidebarLink>
      </section>
      <!-- User tier 2 -->
      <section v-if="authenticationStore.tier>=2">
        <SidebarLink to="/organizacao" icon="fas fa-sitemap" class="mt-4">Gerenciar Org.</SidebarLink>
        <SidebarLink to="/time" icon="fa-solid fa-users" class="mt-4">Gerenciar Time</SidebarLink>
        <SidebarLink to="/planejar-exames" icon="fas fa-chart-gantt" class="mt-4">Planejar Exames</SidebarLink>
        <SidebarLink to="/buscaexames" icon="fas fa-magnifying-glass" class="mt-4">Buscar Exames</SidebarLink>
        <SidebarLink to="/requisicoes" icon="fa fa-list-alt" class="mt-4">Requisições</SidebarLink>
      </section>

      <!-- <SidebarLink to="/organizacao" icon="fas fa-sitemap" class="mt-4">Gerenciar Org.</SidebarLink>
      <SidebarLink to="/time" icon="fa-solid fa-users" class="mt-4">Gerenciar Time</SidebarLink>
      <SidebarLink to="/planejar-exames" icon="fas fa-chart-gantt" class="mt-4">Planejar Exames</SidebarLink>
      <SidebarLink to="/buscaexames" icon="fas fa-magnifying-glass" class="mt-4">Buscar Exames</SidebarLink>
      <SidebarLink to="/requisicoes" icon="fa fa-list-alt" class="mt-4">Requisições</SidebarLink> -->
    </div>
    <!-- User is Clinic -->
    <div v-if="authenticationStore.userRoles.includes(8)">
      <!-- <SidebarLink to="/dashboard/my-account" icon="fa-regular fa-id-badge" class="mt-4">Gerir Perfil</SidebarLink> -->
      <SidebarLink to="/perfil-clinica" icon="fas fa-sitemap" class="mt-4">Perfil Clínica</SidebarLink>
      <SidebarLink to="/gerenciar-exames" icon="fa fa-list-alt" class="mt-4">Gerir Exames</SidebarLink>
      <SidebarLink to="/gerenciar-agendamentos" icon="fa fa-calendar" class="mt-4">Agendamentos</SidebarLink>
    </div>

    <span
      class="collapse-icon"
      :class="{ 'rotate-180': collapsed }"
      @click="toggleSidebar"
    >
      <i class="fas fa-angle-double-left" />
    </span>
  </div>
</template>

<script>
import SidebarLink from './SidebarLink'
import { collapsed, toggleSidebar, sidebarWidth } from './state'
// import { watch } from 'vue'
// import authentication from pinia '@/stores/authentication'
import { useAuthenticationStore } from "@/stores/authentication"
import { storeToRefs } from 'pinia'
import {toast} from 'bulma-toast'

export default {
  name: 'Sidebar',
  props: {},
  components: { SidebarLink },
  setup() {
    // watch for changes in the authentication store's userRoles
    const authenticationStore = useAuthenticationStore()
    // const userTier = authenticationStore.tier
    // const storeRefs = storeToRefs(authenticationStore)
    // const storage = localStorage
    // console.log(storeRefs.userRoles.value)
    // watch(
    //   () => authenticationStore.userRoles,
    //   (userRoles,previousUserRoles) => {
    //     //if newValue is different from old value, then do something
    //     // console.log({...userRoles})
    //     if ({...userRoles} != {...previousUserRoles}) {
    //       console.log('userRoles Changed:', {...userRoles},{...previousUserRoles})
    //     }
    //   },
    //   { deep: true }
    // )
    // watch(
    //   () => localStorage.userRoles,
    //   (userRoles,previousUserRoles) => {
    //     //if newValue is different from old value, then do something
    //     console.log({userRoles})
    //     if ({userRoles} !== {previousUserRoles}) {
    //       console.log('userRoles Changed:', {...userRoles},{...previousUserRoles})
    //     }
    //   }
    // )
    function restrictedAccess() {
      toast({
        message: 'Essa funcionalidade está disponível apenas para usuários com maior nível de acesso.',
        type: 'is-warning',
        position: 'top-center',
        dismissible: true,
        pauseOnHover: true,
        duration: 2500,
      })
    }

    return { collapsed, toggleSidebar, sidebarWidth, authenticationStore , restrictedAccess}
  }
}
</script>

<style>
:root {
  --sidebar-bg-color: #95abb5;
  --sidebar-item-hover: #648fc4ad;
  --sidebar-item-active: #6490c4;
}
</style>

<style scoped>
.sidebar {
  color: white;
  background-color: var(--sidebar-bg-color);
  float: left;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0.5em;
  transition: 0.3s ease;
  display: flex;
  flex-direction: column;
}
.sidebar h1 {
    height: 2.5em;
}
.collapse-icon {
  position: absolute;
  bottom: 0;
  padding: 0.75em;
  color: rgba(255, 255, 255, 0.7);
  transition: 0.2s linear;
}
.rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s linear;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.05s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>