<template>
    <div class="container">
        <div class="columns is-multiline"> 
            <!-- <div class="column is-12"> 
                <h4 class="title is-4" style="padding-top:0;margin-bottom:0.5rem">Titulo?</h4>
                <hr style="margin-bottom:0;margin-top:0">
            </div> -->
            <div class="column is-5"> <!------------------------ Detalhes do Paciente ------------------------>
                <div class="box">
                    <div class="content">
                        <div class="columns">
                            <div class="column is-12">
                                <h4 class="subtitle"><strong>Detalhes do Paciente</strong></h4>
                            </div>
                        </div>
                        <table>
                            <tr>
                                <th>Paciente</th>
                                <td>{{ medicalRequestData.employee }}</td>
                            </tr>
                            <tr>
                                <th>Companhia</th>
                                <td>{{ medicalRequestData.company }}</td>
                            </tr>
                            <tr>
                                <th>Un. de Negócio</th>
                                <td>{{ medicalRequestData.business_unit }}</td>
                            </tr>
                            <tr>
                                <th>Work Area</th>
                                <td>{{ medicalRequestData.work_area }}</td>
                            </tr>
                            <tr>
                                <th>Localização</th>
                                <td>{{ medicalRequestData.location }}</td>
                            </tr>
                            <tr>
                                <th>Exames vencem em</th>
                                <td>{{ medicalRequestData.exam_expiring_on }}</td>
                            </tr>
                            <tr>
                                <th>Próx. Agendamento </th>
                                <td>{{ medicalRequestData.next_exam_scheduled_on }}</td>
                            </tr>
                        </table>
                        <!-- <br>
                        <div class="buttons" style="float:right">
                            <button class="button is-warning" @click="restoreExamOffer()">Cancelar Alterações</button>
                            <button class="button is-info"  @click="submitEditOfferForm()">Salvar Alterações</button>
                        </div>
                        <br> -->
                    </div>
                </div>
            </div>
            <div class="column is-7"> <!------------------------ Detalhes da Requisição ------------------------>
                <div class="box">
                    <div class="content">
                        <h4 class="subtitle"><strong>Detalhes da Requisição</strong></h4>
                        <table>
                            <tr>
                                <th>Data de Criação</th>
                                <td>{{ medicalRequestData.data_criacao }}</td>
                            </tr>
                            <tr>
                                <th>Cód. Referência</th>
                                <td>{{ medicalRequestData.referencia }}</td>
                            </tr>
                            <tr>
                                <th>Clínica Responsável</th>
                                <td>{{ medicalRequestData.clinica_responsavel }}</td>
                            </tr>
                            <tr>
                                <th>Status Médico</th>
                                <td>{{ medicalRequestData.status_medico }}</td>
                            </tr>
                            <tr>
                                <th>Status Financeiro</th>
                                <td>{{ medicalRequestData.status_financeiro }}</td>
                            </tr>
                            <tr>
                                <th>N. de Exames Concluídos</th>
                                <td>{{ medicalRequestData.numero_de_exames_concluidos }}</td>
                            </tr>
                            <tr>
                                <th>Última Atualização</th>
                                <td>{{ medicalRequestData.ultima_atualizacao }}</td>
                            </tr>
                            <tr>
                                <th>Est. de Conclusão</th>
                                <td>{{ medicalRequestData.estimativa_de_finalizacao }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="column is-12 is-centered"> <!------------------------ Exames Contidos (DataTable) ------------------------>
                <div class="box">
                        <div style="margin:2rem;margin-top: 0;">
                            <div class="columns is-multiline">
                                <div class="column is-6"> <!-- Box Title -->
                                    <h4 class="title is-4" style="margin-bottom:0">Exames contidos nesta requisição</h4>
                                </div>
                                <div class="column is-6"> <!-- Box Button -->
                                    <!-- <button 
                                        class="button is-info is-centered strong"
                                        @click="activateModal('Modal_AddOfferedExam')"
                                        style="float:right"
                                    >
                                        <strong> Adicionar Exame à Requisição </strong>
                                    </button> -->
                                </div>
                                <div class="column is-12"> <!-- Box Data Table -->
                                    <DataTable
                                        :apiParams="{ 'medicalRequestPK': modalObject.pk}"
                                        apiURL='/api/v1/examrequests/corporate/'
                                        ref="dataTableExamesDaRequisicao"
                                        :hasSearchField="true"
                                        :hasActionsColumn="false"
                                        :actionButtons="{
                                            'Edit': 'Gerenciar',
                                        }"
                                        :fieldsToHide="[
                                            'tipo_agendamento',
                                            'oferta_de_exames',
                                            'paciente',
                                            'data_criação',
                                            'data_alteração',
                                            'agendamento',
                                        ]"
                                        :fieldNameManager = "{
                                            'pk': 'Ref.',
                                            'tipo_exame': 'Tipo',
                                            'exame_padrao': 'Exame',
                                            'clinica': 'Clínica',
                                            'preco': 'Preço',
                                        }"
                                        :key="ExamRequestsDataTableKey"
                                        />
                                        <!-- @toggleEditModal="activateModalEditOfferedExam" -->
                                        <!-- @toggleDeleteModal="activateModalExamOfferedDelete" -->
                                        <!-- 'Delete': 'Deletar' -->
                                </div>
                            </div>
                        </div>
                        <br>
                </div>
            </div>
            <div class="column is-1">
            </div>
            <div class="column is-5">
                <div class="buttons" style="float:left">
                    <ControlledButton :requiredTier="requiredTiers.requisicoes_gerenciar_requisicao_cancelar_requisicao">
                        <button class="button is-danger is-outlined" @click="activateModal('Modal_DeleteMedicalRequest')">Cancelar Requisição</button>
                    </ControlledButton>
                </div>
            </div>
            <div class="column is-5">
                <div class="buttons" style="float:right">
                    <button class="button is-info" @click="$emit('closeModal')">Concluir</button>
                </div>
            </div>
            <div class="column is-1">
            </div>
        </div>
    </div>
    <section><!------------------------ MODAL: DELETAR REQUISICAO MEDICA ------------------------> 
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_DeleteMedicalRequest') }" v-if="isModalActive('Modal_DeleteMedicalRequest')">
            <div class="modal-background"></div>
            <div class="modal-card" id="Modal_DeleteMedicalRequest">
                <header class="modal-card-head">
                    <p class="modal-card-title">Cancelar Requisição</p>
                    <button class="delete" aria-label="close" @click="closeModal"></button>
                </header>
                <section class="modal-card-body" style="modal-content-width:1200px">
                    <MedicalRequestDelete
                        :object = "medicalRequestData"
                        @closeModal = "closeModal"
                        @closeDeleteModal="$emit('closeModal')"
                    />
                </section>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'

import DataTable from '@/components/dataTable/DataTable.vue'
import MedicalRequestDelete from '@/components/viewComponents/MedicalRequestDelete.vue'
// import ExamOfferDelete from '@/components/clinics/ExamOfferDelete.vue'
// import FormGenericSerializer from '@/components/FormGenericSerializer.vue'
// import EditGenericSerializer from '@/components/EditGenericSerializer.vue'
// import OfferedExamDelete from '@/components/clinics/OfferedExamDelete.vue'
import { toRaw , ref} from 'vue'
import { toast } from 'bulma-toast'

import ControlledButton from '@/components/ControlledButton.vue'
import {requiredTiers} from '@/utilities/requiredTiers.js'

export default {
    name: 'GerenciarRequisicao',
    props: ['modalObject','modalIsActive'],
    components: {
        DataTable,
        MedicalRequestDelete,
        ControlledButton
    },
    emits: ['closeModal'],
    data() {
        return {
            activeModal: '',
            ExamRequestsDataTableKey: ref(0),
            medicalRequestData: {},
            requiredTiers: requiredTiers,
        }
    },
    computed: {

    },
    watch: {

    },
    created() {
        this.fetchMedicalRequestData()
    },
    methods: {
        activateModal(modalName, data='') {
            // console.log('ExamOfferDetails_activateModal Called for modal:', modalName, ' and data:',data)
            const rawData = toRaw(data)
            if (data!='') {
                // console.log('activateModal data recognised. Received data:',data)
                this.modalInputData = rawData
                // console.log('modalInputData:',this.modalInputData)
                this.modalInputID = rawData.pk
                // console.log('modalInputID:',this.modalInputID)
            }
            this.activeModal = modalName
            // console.log('activeModal set to:',this.activeModal)
        },
        closeModal() {
            this.activeModal = ''
        },
        isModalActive(modalName) {
            return this.activeModal === modalName
        },
        fetchMedicalRequestData() {
            // Copilot, make an axios get request to the url 'medicalrequests/api/' with a parameter 'medicalRequestPK' equal to the modalObject.pk then set the medicalRequestData to the response.data
            axios
                .get('/api/v1/medicalrequests/api/', { params: {'medicalRequestPK': this.modalObject.pk} })
                .then(response => {
                    this.medicalRequestData = response.data
                    // console.log('fetchMedicalRequestData response.data:',response.data)
                })
                .catch(error => {
                    console.log(error.request.response)
                })
        },
    }
}
</script>

<style>
.modal-card {
    width:60%
}
</style>