<template>
    <div class="survey-page">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-two-thirds" v-if="respondentExists && !respondendAlreadyCompleted">
            <h1 class="title has-text-centered">{{ pageTitle }}</h1>
            <h2 class="subtitle has-text-centered">{{ pageSubTitle }}</h2>
            <div class="benefits">
                <h3 class="benefits-title">Benefícios de participar:</h3>
                <ul class="benefits-list">
                    <li>Acesso antecipado à plataforma para experimentar o quão fácil é configurar e desfrutar dos benefícios de uma solução de gerenciamento totalmente digital</li>
                    <li>Habilidade de realizar pesquisas de exame para preços de referência, permitindo negociação de preços melhores com os fornecedores atuais</li>
                    <li>Habilidade de se envolver com a equipe de desenvolvimento de produto para ajudar a moldar o produto de acordo com suas necessidades</li>
                    <li>Possibilidade de ser convidado para o próximo projeto piloto</li>
                </ul>
            </div>
            <div class="start-survey" >
              <button class="button is-info is-large" @click="activateModal('Modal_Survey')">Iniciar Pesquisa</button>
            </div>
          </div>
          <div class="column is-two-thirds" v-else-if="!respondentExists">
            <h1 class="title has-text-centered">Seja bem-vindo(a)!</h1>
            <h2 class="subtitle has-text-centered">O time CliqueMed lhe convida a participar de uma pesquisa de mercado para orientar nosso desenvolvimento de produto.</h2>
            <div class="benefits">
                <h3 class="benefits-title">Seus benefícios ao participar:</h3>
                <ul class="benefits-list">
                    <li>Acesso antecipado à plataforma para experimentar o quão fácil é configurar e desfrutar dos benefícios de uma solução de gerenciamento totalmente digital</li>
                    <li>Habilidade de realizar pesquisas de exame para preços de referência, permitindo negociação de preços melhores com os fornecedores atuais</li>
                    <li>Habilidade de se envolver com a equipe de desenvolvimento de produto para ajudar a moldar o produto de acordo com suas necessidades</li>
                    <li>Possibilidade de ser convidado para o próximo projeto piloto</li>
                </ul>
            </div>
            <div class="start-survey" >
              <button class="button is-info is-large" @click="activateModal('Modal_Survey')">Iniciar Pesquisa</button>
            </div>
          </div>
          <div class="column is-two-thirds" v-else>
            <h1 class="title has-text-centered">Olá, {{ respodentName }}, seja bem-vindo de volta.</h1>
            <h2 class="subtitle has-text-centered">Parece que você já preencheu nossa pesquisa. Agradecemos pela sua contribuição!</h2>
            <div class="benefits">
                <h3 class="benefits-title">Caso você não se lembre de ter preenchido a pesquisa, entre em contato com nossa equipe pelo nosso <a href="mailto:contato@cliquemed.app">email</a>.</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL - Survey -->
    <div class="modal" :class="{ 'is-active': isModalActive('Modal_Survey') }" v-if="isModalActive('Modal_Survey')">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title"></p>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body">
                <Survey :questions="survey.questions" :surveyDescription="surveyDescription" @submitSurvey="submitSurvey"/>
            </section>
        </div>
    </div>
</template>
  
<script>
import Survey from '@/components/survey/Survey.vue';
import axios from 'axios';
import {toast} from 'bulma-toast';
import { toRaw } from 'vue';
import {useAuthenticationStore} from "@/stores/authentication"
import * as yup from 'yup';

export default {
name: 'SurveyPage',
props: {
    usid: {
        type: String,
        required: false,
    },
},
components: {
    Survey
},
data() {
    return {
      activeModal: '',
      authentication: useAuthenticationStore(),
      pageTitle: 'Você foi convidado para nossa pesquisa!',
      pageSubTitle: 'O time CliqueMed agradece sua participação',
      questions: [],
      respondendAlreadyCompleted: false,
      respondentExists: false,
      respondentID: null,
      respodentName: '',
      survey: {},
      surveyId: 'exampleSurveyId',
      surveyDescription: {
        title: 'Pesquisa de introdução CliqueMed',
        description: 'Obrigado por participar da nossa pesquisa! Seu feedback é incrivelmente valioso para nós, pois nos esforçamos para melhorar nossos serviços. Esta pesquisa deve levar aproximadamente de 10 a 15 minutos para ser concluída. Nesta pesquisa, nosso objetivo é entender suas experiências com nosso produto e serviços. Agradecemos suas opiniões e feedbacks sinceros sobre vários aspectos de nossas ofertas. Suas respostas serão mantidas confidenciais e usadas exclusivamente com o propósito de melhorar nossos serviços. Por favor, responda cada pergunta da melhor maneira possível. Obrigado novamente por dedicar seu tempo para concluir esta pesquisa.',
      },
      surveySubmitted: false,
      unansweredQuestions: [],
    };
},
mounted() {
    this.checkSurveyID();
},
methods: {
    activateModal(str) {
        this.activeModal = str;
    },
    closeModal() {
        this.activateModal('');
    },
    addPrimerQuestions() {
      // console.log('SURVEYLANDING.vue>> addPrimerQuestions: Adding primer questions to survey');
      const primerQuestions = [
        {
          number: 1,
          prompt: 'Para começar, diga-nos seu nome e sobrenome.',
          question_pk: 0,
          type: 'input',
          is_required: true,
        }
      ]
      const authStore = useAuthenticationStore();
      // If the user is authenticated, there is no need for Primer Questions
      if (!authStore.isAuthenticated) {
        this.survey.questions.forEach(element => {
          element.number = element.number + primerQuestions.length;        
        });
        primerQuestions.forEach(element => {
          this.survey.questions.unshift(element);
        });
      }
    },
    checkSurveyID() {
        // console.log('SURVEYLANDING.vue>> Unique Survey ID:', this.$route.params.usid)
        const survey_id = this.$props.usid;
        if (survey_id) {
            this.surveyId = survey_id;
        }
        axios
        .get('/api/v1/surveys/checkrespondent/', {
            params: {
              survey_id: this.surveyId,
            },
        })
        .then ((response) => {
            // console.log('SURVEYLANDING.vue>> checkSurveyID: Response.data:', response.data);
            if (response.data.respondent_exists && response.data.current_survey_active) {
              this.respondentExists = true;
              this.respodentName = response.data.respondent_name;
              this.respondentID = response.data.respondent_pk;
              this.fetchSurveyQuestions(response.data.current_survey_pk);
            }
            else if (response.data.respondent_exists) {
              this.respondentExists = true;
              this.respondendAlreadyCompleted = true;
              this.respodentName = response.data.respondent_name;

            }
            else {
              this.respondentExists = false
              const authStore = useAuthenticationStore();
              if (authStore.isAuthenticated) {
                this.respondentID = 'uninvited_user';
              }
              else {
                this.respondentID = 'uninvited_respondent';
              }
              this.fetchSurveyQuestions(0)
              .then(
                this.addPrimerQuestions
              )
            }
        })
        .catch((error) => {
            console.log('SURVEYLANDING.vue>> checkSurveyID: Error:', error);
        });
    },
    async fetchSurveyQuestions(survey_pk) {
        await axios
        .get('/api/v1/surveys/getquestions/', {
            params: {
              survey_pk: survey_pk,
            },
        })
        .then ((response) => {
          if (response.status === 400) {
            console.log('SURVEYLANDING.vue>> fetchSurveyQuestions: BAD REQUEST:', response.data);
            return;
          }
          // console.log('SURVEYLANDING.vue>> fetchSurveyQuestions: Response.data:', response.data);
          this.survey = response.data;
          this.surveyDescription.title = this.survey.survey_name;
          this.surveyDescription.description = this.survey.survey_description;
          // console.log(this.survey)
        })
        .catch((error) => {
            console.log('SURVEYLANDING.vue>> fetchSurveyQuestions: Error:', error);
        });
    },
    isModalActive(str) {
        return this.activeModal === str;
    },
    submitSurvey() {
        if (this.validateAnswers()) {
          this.closeModal();
          // console.log(this.survey)
          let submitPaylod = {
            survey_respondent_pk: this.respondentID,
            survey_pk: this.survey.survey_pk,
            questions: toRaw(this.survey.questions),
          }
          const authStore = toRaw(useAuthenticationStore());
          if (authStore.isAuthenticated) {
            // console.log(authStore)
            submitPaylod['user_email'] = authStore.email;
          }
          // console.log('submitSurvey payload',submitPaylod)
          axios
          .post('/api/v1/surveys/submitanswers/', submitPaylod)
          .then ((response) => {
            if (response.status != 201) {
              // console.log('SURVEYLANDING.vue>> submitSurvey: BAD REQUEST:', response.data);
              toast({
                message: 'Erro ao enviar a pesquisa. Por favor, tente novamente.',
                type: 'is-danger',
                position: 'top-center',
                dismissible: true,
                pauseOnHover: true,
                duration: 2000,
              })
            }
            // console.log('SURVEYLANDING.vue>> submitSurvey: Response.data:', response.data);
            this.surveySubmitted = true;
            toast({
              message: 'Pesquisa enviada com sucesso!',
              type: 'is-success',
              position: 'top-center',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
            })
          })

        }
    },
    validateAnswers() {
      // console.log('surveys>> validateAnswers: survey.questions:', this.survey.questions)
      let formIsValid = true;
      this.unansweredQuestions= []
      this.survey.questions.forEach(element => {
        if (element.is_required) {
          if (element.answer === null || element.answer === '' || element.answer === undefined) {
            formIsValid = false;
            this.unansweredQuestions.push(element.number);
          }
        }
      })
      // console.log('formIsValid', formIsValid)
      if (!formIsValid) {
        let unansweredString = ''
        this.unansweredQuestions.forEach(element => {
          // console.log('element', element)
          unansweredString += element.toString() + ', '
        })
        unansweredString = unansweredString.slice(0, -2);
        let messageInner = ''
        console.log('this.unansweredQuestions.length', this.unansweredQuestions.length)
        if (this.unansweredQuestions.length > 1) {
          messageInner = 'Por favor, responda todas as perguntas obrigatórias. As questões ['+unansweredString+'] são obrigatórias.'
        }
        else {
          messageInner = 'Por favor, responda todas as perguntas obrigatórias. A questão ['+unansweredString+'] é obrigatória.'
        }
        toast({
          message: messageInner,
          type: 'is-danger',
          position: 'top-center',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
        });
      }
      return formIsValid;

    }
},
};
</script>

<style scoped>

.survey-page {
    min-height: 100vh;
    padding-top: 4rem;
}
.parent-component {
  max-width: 800px;
  margin: auto;
  padding: 40px 20px;
}

.columns {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.column {
  margin-left: 5%;
  margin-right: 5%;
  /* padding-left: 5%; */
  /* padding-right: 5%; */
}


h1 {
  font-size: 2.5rem;
  text-align: center;
}

h2 {
  font-size: 2rem;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li:before {
  content: "•";
  color: #0d6efd;
  font-size: 1.2em;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.benefits {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.benefit-item {
  text-align: center;
  margin-bottom: 20px;
}

.start-survey {
  display: flex;
  justify-content: center;
  align-items: center;
}
.survey-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0d6efd;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.survey-button:hover {
  background-color: #0b5ed7;
}

@media (min-width:1080px) {
  .modal-card {
    width: 60%;
  }
}
@media (min-width:810px) and (max-width:1080px) {
  .modal-card {
    width: 80%;
  }
}
@media (max-width:810px) {
.modal-card {
    width: 95%;
  }
}

</style>
  