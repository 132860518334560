<template>
	<nav class="level">
		<!-- {{this.elementIsInViewPort(this.$refs.counter2.$el)}} -->
		<div class="level-item has-text-centered" v-observe-visibility="{callback: startCounters, once: false,}">
			<div>
				<p class="title"><vue3-autocounter ref='counter1' :startAmount='0' :endAmount='914' :duration='1.5' prefix='' suffix='' separator='.' decimalSeparator='' :decimals='0' :autoinit='false' @finished=''/></p>
				<p class="heading">Clínicas</p>
			</div>
		</div>
		<div class="level-item has-text-centered">
			<div>
				<p class="title"><vue3-autocounter ref='counter2' :startAmount='0' :endAmount='435' :duration='1.5' prefix='' suffix='' separator='.' decimalSeparator='' :decimals='0' :autoinit='false' @finished=''/></p>
				<p class="heading">Municípios Atendidos </p>
			</div>
		</div>
		<div class="level-item has-text-centered">
			<div>
				<p class="title"><vue3-autocounter ref='counter3' :startAmount='0' :endAmount='37263' :duration='1.5' prefix='' suffix='' separator='.' decimalSeparator='' :decimals='0' :autoinit='false' @finished=''/></p>
				<p class="heading">Ofertas de Exames</p>
			</div>
		</div>
		<div class="level-item has-text-centered">
			<div>
				<p class="title"><vue3-autocounter ref='counter4' :startAmount='0' :endAmount='29.5' :duration='1.5' prefix='' suffix='%' separator='.' decimalSeparator=',' :decimals='1' :autoinit='false' @finished=''/></p>
				<p class="heading">Economia Média para Empresas</p>
			</div>
		</div>
	</nav>
</template>

<script>
import Vue3autocounter from 'vue3-autocounter';

export default {
  name: 'CounterSection',
  components: {
	'vue3-autocounter': Vue3autocounter
  },
  setup() {
    },
  data () {
    return {

    }
  },
  methods: {
	startCounters() {
    	this.$refs.counter1.start();
		this.$refs.counter2.start();
		this.$refs.counter3.start();
		this.$refs.counter4.start();
	},
  },
  computed: {

	},
}
</script>

<style scoped>
.level {
	background-color: rgb(0,114,206);
	padding-top: 4rem;
	padding-bottom: 4rem;
	font-family: sans-serif;
	color: rgb(255,255,255,0.7);
	margin-bottom: 0;
}
.title {
	color: white;
}
.heading {
	font-weight: 600;
}
</style>

