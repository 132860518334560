<template>
	<section class="section is-medium">
		<div class="container">
			<div class="columns is-multiline is-centered">
				<div class="column is-12 has-text-centered">
					<h1 class="title is-1" id="sectionTitle">Um ecosistema integrado com benefícios para todos</h1>
				</div>
				<div class="column is-12 has-text-centered">
					<h2 class="subtitle is-2" id="sectionSubTitle">A <strong style="color:rgb(0,114,206)">{{$companyName}}</strong> está comprometida em trazer os benefícios da tecnologia de ponta à gestão da saúde ocupacional. Ao operar unicamente como uma plataforma de integração, todos os benefícios são integralmente dos usuários. </h2>
				</div>
				<div class="column is-4 has-text-centered" id="USPSides"> <!--PARA EMPRESAS-->
					<div class="">
						<div class="media mb-0">
							<div class="media-content">
								<p class="title is-4 mb-4">Para Empresas</p>
							</div>
						</div>
						<div class="card-image">
							<figure class="image is-inline-block">
								<img src="@/assets/companyTeamRounded.webp" alt="Foto de time da empresa" height="300px" width="40px">
							</figure>
						</div>
						<div class="card-content">
							<div class="content mt-0 mb-0 pb-0">
							Planejamento, gestão e automação de exames, com integração operacional e financeira
							</div>
							<hr class="hrElement">
							<div class="content mt-0 mb-0 pb-0">
							Integração e otimização de políticas de saúde ocupacional entre unidades e departamentos
							</div>
							<hr class="hrElement">
							<div class="content mt-0 mb-0 pb-0">
							Reutilização de exames, com redução de custos e tempo de contratação
							</div>
							<hr class="hrElement">
							<div class="content mt-0 mb-0 pb-0">
							Pesquisa de preços na ponta final e controle sobre negociação direta de planos com clínicas de saúde
							</div>
							<hr class="hrElement">
						</div>
					</div>
				</div>
				<div class="column is-4 has-text-centered" id="USPSides"> <!--PARA CLÍNICAS-->
					<div class="">
						<div class="media mb-0">
							<div class="media-content">
								<p class="title is-4 mb-4">Para Clínicas</p>
							</div>
						</div>
						<div class="card-image">
							<figure class="image is-inline-block">
								<img src="@/assets/clinicTeamRounded.png" alt="Foto de time da empresa" height="300px" width="40px">
							</figure>
						</div>
						<div class="card-content">
							<div class="content mt-0 mb-0 pb-0">
							Gestão e negociação de preços diretamente com as empresas
							</div>
							<hr class="hrElement">
							<div class="content mt-0 mb-0 pb-0">
							Relatórios de preços e demanda de exames no município
							</div>
							<hr class="hrElement">
							<div class="content mt-0 mb-0 pb-0">
							Fácil integração com os maiores sistemas de gestão de clínicas do país
							</div>
							<hr class="hrElement">
							<div class="content mt-0 mb-0 pb-0">
							Gestão de resultados de exames e centralização de envio para empresas e pacientes
							</div>
							<hr class="hrElement">
						</div>
					</div>
				</div>
				<div class="column is-4 has-text-centered" id="USPSides"> <!--PARA COLABORADORES-->
					<div class="">
						<div class="media mb-0">
							<div class="media-content">
								<p class="title is-4 mb-4">Para Colaboradores</p>
							</div>
						</div>
						<div class="card-image">
							<figure class="image is-inline-block">
								<img src="@/assets/workerRounded.webp" alt="Foto de time da empresa" height="300px" width="40px">
							</figure>
						</div>
						<div class="card-content">
							<div class="content mt-0 mb-0 pb-0">
							Notificações de agendamentos e controle de agenda
							</div>
							<hr class="hrElement">
							<div class="content mt-0 mb-0 pb-0">
							Acesse resultados de exames e histórico de consultas
							</div>
							<hr class="hrElement">
							<div class="content mt-0 mb-0 pb-0">
							Acompanhe sua saúde e compartilhe resultados ocupacionais com seu médico de confiança
							</div>
							<hr class="hrElement">
							<div class="content mt-0 mb-0 pb-0">
							Pesquisa de preços na ponta final e controle sobre negociação direta de planos com clínicas de saúde
							</div>
							<hr class="hrElement">
						</div>
					</div>
				</div>
			</div>
		</div>	
	</section>
</template>

<script>

export default {
  name: 'OfferSection',
  components: {
  },
  setup() {
    },
  data () {
    return {

    }
  }
}
</script>

<style scoped>
.section {
	background-color: white;
	padding-top: 2rem;
	padding-bottom: 2rem;
}
#sectionTitle {
	color: rgb(66,85,99);
	font-size: 2.2rem;
	font-weight: 800;
}
#sectionSubTitle {
	padding-top: 0rem;
	padding-bottom: 2rem;
	color: rgb(49,53,62,0.7);
	font-size: 1.1rem;
	font-weight: 200;
	font-family: sans-serif;
}

.title {
	color: rgb(49,53,62);
}
.image img {
  display: auto;
  height: 200px;
  width: 300px;
}

.content {
	color: rgb(49,53,62,0.7);
	font-weight: 400;
	font-family: sans-serif;
	width: 80%;
	min-height: fit-content;
	margin: auto;
} 
@media (min-width:1410px) {
	.content {
	min-height: 4.2rem;
} 
}
@media (min-width:1010px) and (max-width:1410px){
	.content {
	min-height: 5.8rem;
} 
}
@media (min-width:780px) and (max-width:1010px) {
	.content {
	min-height: 7.4rem;
} 
}

.hrElement {
	background-color: rgb(0,48,135,0.3);
	width: 80%;
	margin-left:auto;
	margin-right:auto;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}
</style>

