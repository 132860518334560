<template>
      <div class="container">
        <div class="columns is-multiline">
            <!-- {{object}} -->
            <div class="column is-12">
                <h1 class="title pb-0 mb-0 has-text-danger has-background-warning has-text-centered"><strong>Atenção!</strong></h1>
                <h2 class="has-text-danger has-background-warning has-text-centered"><strong>A ação que você está prestes a realizar é irreversível.</strong></h2>
            </div>
            <hr>
            <div class="column is-6">
                <div class="box">
                    <div class="content">
                        <table>
                            <tr>
                                <th>Cód. Referência</th>
                                <td>{{ object.referencia }}</td>
                            </tr>
                            <tr>
                                <th>Paciente</th>
                                <td>{{ object.employee }}</td>
                            </tr>
                            <tr>
                                <th>Companhia</th>
                                <td>{{ object.company }}</td>
                            </tr>
                            <tr>
                                <th>Clínica Responsável</th>
                                <td>{{ object.clinica_responsavel }}</td>
                            </tr>
                            <tr>
                                <th>Data de Criação</th>
                                <td>{{ object.data_criacao }}</td>
                            </tr>
                            <tr>
                                <th>Exames Concluídos</th>
                                <td>{{ object.numero_de_exames_concluidos }}</td>
                            </tr>
                            <tr>
                                <th>Última Atualização</th>
                                <td>{{ object.ultima_atualizacao }}</td>
                            </tr>
                            <tr>
                                <th>Est. de Conclusão</th>
                                <td>{{ object.estimativa_de_finalizacao }}</td>
                            </tr>
                        </table>

                    </div>
                </div>
            </div>

            <div class="column is-6">
                <div class="box">
                    <div class="content">
                        <label class="checkbox pb-2">
                            <input type="checkbox" v-model="checkConfirm">
                            Eu entendo que deletar o exame ao lado é uma ação irreversível e que os exames e agendamentos associados serão deletados em conjunto. Um novo requerimento médico deverá ser registrado caso necessário.
                        </label>
                        <p class="has-text-danger pb-4" v-show="submitDeclined">Você deve confirmar na caixa acima antes de prosseguir.</p> 
                        <hr>
                        <br>
                        <br>
                        <br>
                        <br>
                        <div class="buttons" style="float:bottom"> <!-----------------------------------------------------------Make buttons centered -->
                            <button class="button is-info" @click="$emit('closeModal')">Cancelar e Retornar</button>
                            <button class="button is-danger is-outlined" @click="submitDelete">Confirmar e Deletar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

// import DataTable from '@/components/dataTable/DataTable.vue'



export default {
    name: 'MedicalRequestDeleteComponent',
    props: ['object']
    ,
    components: {

    },
    data() {
        return {
            examInner: '',
            tableAPIParams: {
                // 'examId': this.examId
            },
            numeroexams: 0,
            numeroRelacoesDeTrabalho: 0,
            responseBeforeDelete: {},
            checkConfirm: false,
            submitDeclined: false
        }
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    methods: {
        async submitDelete() {
            if (this.checkConfirm) {
                // console.log('CompanyDelete_submitDelete called. Axios delete request about to be sent.')
                axios
                    .delete('/api/v1/medicalrequests/api/', { params: {'medicalRequestPK': this.object.referencia} })
                    .then(response => {
                        this.$emit('closeDeleteModal')
                        // window.location.reload()
                        toast({
                            message: 'Requerimento médico deletado com sucesso!',
                            type: 'is-success',
                            position: 'top-center',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            animate: { in: 'fadeIn', out: 'fadeOut' }
                        })
                    })
                    .catch(error => {
                        console.log(error.request.response)
                    })
            }
            else {
                this.submitDeclined = true
            }
        },
    }

}
</script>

<style scoped>

.box {
    height: 100%;
}

</style>