<!-- vue snippet -->
<template>
	<footer class="footer section pb-10">
		<div>
			<div class="columns is-multiline">
				<div class="column is-3-widescreen is-6-tablet">
					<h1><strong class="sectionTitle">{{$companyName}}</strong></h1>
					<p>Ocupados construindo o futuro da saúde, começando pelo Brasil.</p>
				</div>
				<div class="column is-2-widescreen is-6-desktop is-6-tablet ml-auto">
					<h1><strong class="sectionTitle">Companhia</strong></h1>
					<ul class="list-unstyled footer-menu lh-35">
						<li><router-link :to="{name:'Home', hash:'#como-funciona'}">Como Funciona</router-link></li>
						<li><router-link :to="{name:'Artigos'}">Nossa Expertise</router-link></li>
						<!-- <li><a href="#">Quem Somos</a></li>
						<li><a href="#">Propósito</a></li> -->
						<!-- <li><a href="#">Contact</a></li> -->
					</ul>
				</div>
				<div class="column is-3-widescreen is-6-desktop is-6-tablet">
					<h1><strong class="sectionTitle">Suporte</strong></h1>
					<ul class="list-unstyled footer-menu lh-35">
						<li @click="openModal('Modal_TermsOfUse')"><a>Termos &amp; Condições</a></li>
						<li @click="openModal('Modal_PrivacyPolicy')"><a>Política de Privacidade</a></li>
						<li><a href="mailto:suporte@cliquemed.app">Suporte</a></li>
						<!-- <li><a href="#">Perguntas Frequentes</a></li> -->
					</ul>
				</div>
				<div class="column is-3-widescreen is-6-desktop is-6-tablet">
					<h1><strong class="sectionTitle">Entre em Contato</strong></h1>
					<h6><a href="mailto:support@gmail.com">contato@{{$companyName}}.app</a></h6>
					<!-- <h6><a href="tel:+551131234567"> <i class="ti-mobile mr-3"></i>(11) 3123.4567</a></h6> -->
				</div>
			</div>
			<hr class="navbarSeparator">
		</div>
		<div>
			<p class="copyright">
				© {{ new Date().getFullYear() }} Copyright <strong><span class="brand">{{$companyName}}</span></strong>  
			</p>
		</div>
	</footer>
	<!-- MODAL - Terms of Use -->
	<div class="modal" :class="{ 'is-active': isModalActive('Modal_TermsOfUse') }" v-if="isModalActive('Modal_TermsOfUse')">
	<div class="modal-background" @click="closeModal()"></div>
	<div class="modal-card">
		<header class="modal-card-head">
			<p class="modal-card-title">Termos de Uso & Serviço CliqueMed v1.03 - 12/02/2023</p>
			<button class="delete" aria-label="close" @click="closeModal()"></button>
		</header>
		<section class="modal-card-body">
			<div class="content">
				<h2>1. Introdução</h2>
				<p>Bem-vindo(a) à plataforma CliqueMed! Antes de utilizar nossa plataforma, pedimos que você leia atentamente os termos de uso que regem o seu uso da plataforma. Ao utilizar a plataforma, você concorda com estes termos de uso e todas as políticas nele inclusas, regras e orientações incorporadas a eles. Se você não concorda com estes termos, não pedimos que não utilize nossa plataforma, uma vez que o uso, em especial através do registro de usuário, implica em consentimento com esses termos.</p>
				<p>Nós nos reservamos o direito de modificar estes termos de uso a qualquer momento. Sempre que o fizermos, notificaremos todos aqueles usuários que nos forneceram dados de contato. Ao continuar a utilizar a plataforma após a modificação dos termos, você concorda em estar vinculado à versão atualizada dos termos de uso.</p>
				<p>O uso da nossa plataforma está sujeito a todas as leis e regulamentos aplicáveis. Se você tiver dúvidas sobre estes termos de uso, entre em contato conosco pelo endereço de e-mail fornecido no final deste documento.</p>
				<br>
				<h2>2. Condições de uso</h2>
				<p>Ao utilizar nossa plataforma, você concorda em cumprir estes termos de uso, bem como todas as leis e regulamentos aplicáveis. Você concorda em não utilizar a plataforma para:</p>
				<ul>
					<li class="pb-0">Registrar qualquer conteúdo ilegal, ofensivo, ameaçador, difamatório, obsceno, pornográfico ou que viole os direitos de terceiros.</li>
					<li class="pb-0">Fazer engenharia reversa, descompilar ou desmontar qualquer parte da plataforma ou do software associado.</li>
					<li class="pb-0">Fazer uso não autorizado de qualquer material protegido por direitos autorais ou propriedade intelectual da plataforma.</li>
					<li class="pb-0">Acessar ou buscar acessar qualquer informação cuja privacidade possa ser inferida, e cuja autorização de acesso não tenha lhe sido concedida.</li>
					<li class="pb-0">Violar a segurança ou integridade da plataforma ou do software associado.</li>
					<li class="pb-0">Fornecer informações falsas ou enganosas ao se registrar na plataforma ou ao utilizar os serviços oferecidos pela plataforma.</li>
				</ul>
				<p>Reservamo-nos o direito de suspender ou encerrar o acesso à plataforma de qualquer usuário. Para todos os nossos usuários pagantes, disponibilizamos um serviço de backup de informações em caso de desligamento de contas.</p>
				<br>
				<h2>3. Direitos de propriedade intelectual</h2>
				<p>Todo o conteúdo disponível em nossa plataforma, incluindo textos, imagens, gráficos, logotipos, ícones, fotos, vídeos, software e quaisquer outros materiais são protegidos por direitos autorais, marcas registradas, patentes e outros direitos de propriedade intelectual.</p>
				<p>Você reconhece e concorda que a plataforma e seus respectivos proprietários são os proprietários exclusivos de todos os direitos, títulos e interesses em e para a plataforma, incluindo todos os direitos de propriedade intelectual relacionados.</p>
				<p>Você não pode copiar, modificar, distribuir, exibir, reproduzir, publicar, licenciar, criar obras derivadas, transferir ou vender qualquer conteúdo, software ou serviços obtidos através da plataforma, sem o consentimento prévio e expresso da nossa empresa.</p>
				<p>Se você acredita que algum conteúdo da plataforma infringe seus direitos autorais ou outros direitos de propriedade intelectual, entre em contato conosco imediatamente para resolver o problema.</p>
				<br>
				<h2>4. Limitação de Responsabilidade</h2>
				<p>Nós nos esforçamos para oferecer a melhor experiência possível aos nossos usuários, mas não podemos garantir que a plataforma estará sempre livre de erros, interrupções, atrasos ou outras falhas. Ao utilizar nossa plataforma, você reconhece e concorda que a plataforma é fornecida "como está" e "conforme disponível".</p>
				<p>Não somos responsáveis por quaisquer danos diretos, indiretos, incidentais, especiais, punitivos ou consequentes, incluindo lucros cessantes, perda de dados, perda de negócios ou qualquer outro tipo de perda resultante do uso ou da impossibilidade de usar a plataforma, mesmo que tenhamos sido avisados da possibilidade de tais danos.</p>
				<p>Em nenhum caso nossa responsabilidade total por quaisquer danos, perdas ou causas de ação excederá o valor que você pagou, se houver, pelo uso da plataforma nos últimos seis meses.</p>
				<br>
				<h2>5. Política de privacidade</h2>
				<p>A sua privacidade é muito importante para nós. Por favor, leia nossa política de privacidade cuidadosamente para entender como coletamos, usamos e protegemos as informações que você nos fornece. Ao utilizar nossa plataforma, você concorda com a coleta e uso de suas informações de acordo com nossa política de privacidade.</p>
				<p>Você pode acessar nossa política de privacidade através da barra inferior de nossa página principal. Nossa política de privacidade é parte integrante destes termos de uso e fornece informações importantes sobre como usamos as informações pessoais que coletamos dos usuários.</p>
				<br>
				<h2>6. Modificações nos termos de uso</h2>
				<p>Reservamo-nos o direito de modificar estes termos de uso a qualquer momento, sem aviso prévio. Quaisquer modificações entrarão em vigor imediatamente após a publicação dos termos de uso atualizados na plataforma.</p>
				<p>É sua responsabilidade verificar periodicamente os termos de uso para quaisquer alterações. Seu uso contínuo da plataforma após a publicação de qualquer alteração nos termos de uso constitui sua aceitação dos termos de uso atualizados</p>
				<p>Se você não concorda com os termos de uso atualizados, você deve parar de utilizar a plataforma imediatamente.</p>
				<br>
				<h2>7. Encerramento da conta</h2>
				<p>Você pode encerrar sua conta a qualquer momento entrando em contato conosco pelo endereço de e-mail fornecido na plataforma para suporte. Reservamo-nos o direito de suspender ou encerrar sua conta caso você viole estes termos de uso ou qualquer lei ou regulamento aplicável.</p>
				<p>Ao encerrar sua conta, você concorda que todas as informações associadas à sua conta poderão ser excluídas após um prazo de 30 dias corridos. Caso deseje realizar um backup de seus dados, entre com contato com nossa equipe através do endereço de e-mail de suporte.</p>
				<p>Reservamo-nos o direito de manter algumas informações associadas à sua conta para fins de conformidade legal e administrativa.</p>
			</div>
		</section>
	</div>
    </div>
	<!-- MODAL - Privacy Policy -->
	<div class="modal" :class="{ 'is-active': isModalActive('Modal_PrivacyPolicy') }" v-if="isModalActive('Modal_PrivacyPolicy')">
	<div class="modal-background" @click="closeModal()"></div>
	<div class="modal-card">
		<header class="modal-card-head">
			<p class="modal-card-title">Política de Privacidade CliqueMed v1.02 - 12/02/2023</p>
			<button class="delete" aria-label="close" @click="closeModal()"></button>
		</header>
		<section class="modal-card-body">
			<div class="content">
				<p>Na CliqueMed, sua privacidade e segurança são prioridades e nos comprometemos com a transparência do tratamento de dados pessoais dos nossos usuários/clientes. Por isso, esta presente Política de Privacidade estabelece como é feita a coleta, uso e transferência de informações de clientes ou outras pessoas que acessam ou usam nosso site.</p>
				<p>Ao utilizar nossos serviços, você entende que coletaremos e usaremos suas informações pessoais nas formas descritas nesta Política, sob as normas da Constituição Federal de 1988 (art. 5º, LXXIX; e o art. 22º, XXX – incluídos pela EC 115/2022), das normas de Proteção de Dados (LGPD, Lei Federal 13.709/2018), das disposições consumeristas da Lei Federal 8078/1990 e as demais normas do ordenamento jurídico brasileiro aplicáveis.</p>
				<p>Dessa forma, a CliqueMed Sistemas de Saúde, doravante denominada simplesmente como “CliqueMed”, no papel de Controladora de Dados, obriga-se ao disposto na presente Política de Privacidade.</p>
				<h2>1. Quais dados coletamos sobre você e para qual finalidade?</h2>
				<p>Nosso site coleta e utiliza alguns dados pessoais seus, de forma a viabilizar a prestação de serviços e aprimorar a experiência de uso.</p>
				<h3>1.1. Dados pessoais fornecidos pelo titular</h3>
				<ul>
					<li>Informações, como seu endereço de e-mail, que você digita quando se registra em nosso site; </li>
					<li>Informações que você digita ao criar um perfil em nosso site – por exemplo, seu nome, data de nascimento, função e de emprego; </li>
					<li>Informações que você digita durante o uso dos serviços em nosso site, incluindo, mas não se limitando a: informações a respeito de sua organização, sua estrutura organizacional, seus colaboradores, as funções de cada colaborador, e de sua ocupação; </li>
					<li>Informações contidas em quaisquer comunicações que você nos envia por e-mail ou através de nosso site, incluindo o conteúdo e os metadados da comunicação; </li>
					<li>Qualquer outra informação pessoal ou corporativa que você nos enviar. </li>
				</ul>
				<h3>1.2. Dados pessoais coletados automaticamente</h3>
				<ul>
					<li>Informações sobre o seu computador, incluindo seu endereço IP, localização geográfica, tipo e versão do navegador e sistema operacional; </li>
					<li>Informações sobre suas visitas e uso deste site, incluindo fonte de referência, duração da visita, visualizações de página e caminhos de navegação no site; </li>
					<li>Informações geradas ao usar nossa plataforma, incluindo quando, com que frequência e em que circunstâncias você o utiliza; </li>
				</ul>
				<h2>2. Consentimento</h2>
				<p>É a partir do seu consentimento que tratamos os seus dados pessoais. O consentimento é a manifestação livre, informada e inequívoca pela qual você autoriza a CliqueMed a tratar seus dados.</p>
				<p>Assim, em consonância com a Lei Geral de Proteção de Dados, seus dados só serão coletados, tratados e armazenados mediante prévio e expresso consentimento. </p>
				<p>Ao utilizar os serviços da CliqueMed e fornecer seus dados pessoais e corporativos, você está ciente e consentindo com as disposições desta Política de Privacidade, além de conhecer seus direitos e como exercê-los.</p>
				<p>A qualquer tempo e sem nenhum custo, você poderá revogar seu consentimento.</p>
				<p>É importante destacar que a revogação do consentimento para o tratamento dos dados pode implicar a impossibilidade da performance adequada de alguma funcionalidade do site que dependa da operação. Tais consequências serão informadas previamente.</p>
				<h2>3. Quais são os seus direitos?</h2>
				<p>A CliqueMed assegura a seus usuários/clientes seus direitos de titular previstos no artigo 18 da Lei Geral de Proteção de Dados. Dessa forma, você pode, de maneira gratuita e a qualquer tempo:</p>
				<ul>
					<li>Confirmar a existência de tratamento de dados, de maneira simplificada ou em formato claro e completo.</li>
					<li>Acessar seus dados, podendo solicitá-los em uma cópia legível sob forma impressa ou por meio eletrônico, seguro e idôneo.</li>
					<li>Corrigir seus dados, ao solicitar a edição, correção ou atualização destes.</li>
					<li>Limitar seus dados quando desnecessários, excessivos ou tratados em desconformidade com a legislação através da anonimização, bloqueio ou eliminação.</li>
					<li>Solicitar a portabilidade de seus dados, através de um relatório de dados cadastrais que a CliqueMed trata a seu respeito.</li>
					<li>Eliminar seus dados tratados a partir de seu consentimento, exceto nos casos previstos em lei.</li>
					<li>Revogar seu consentimento, desautorizando o tratamento de seus dados.</li>
					<li>Informar-se sobre a possibilidade de não fornecer seu consentimento e sobre as consequências da negativa.</li>
				</ul>
				<h2>4. Como você pode exercer seus direitos de titular?</h2>
				<p>Para exercer seus direitos de titular, você deve entrar em contato com a CliqueMed através dos seguintes meios disponíveis:</p>
				<ul>
					<li>privacidade@cliquemed.app</li>
				</ul>
				<p>De forma a garantir a sua correta identificação como titular dos dados pessoais objeto da solicitação, é possível que solicitemos documentos ou demais comprovações que possam comprovar sua identidade. Nessa hipótese, você será informado previamente.</p>
				<h2>5. Como e por quanto tempo seus dados serão armazenados?</h2>
				<p>Seus dados pessoais coletados pela CliqueMed serão utilizados e armazenados durante o tempo necessário para a prestação do serviço ou para que as finalidades elencadas na presente Política de Privacidade sejam atingidas, considerando os direitos dos titulares dos dados e dos controladores.</p>
				<p>De modo geral, seus dados serão mantidos enquanto a relação contratual entre você e a (nome empresarial simplificado) perdurar. Findado o período de armazenamento dos dados pessoais, estes serão excluídos de nossas bases de dados ou anonimizados, ressalvadas as hipóteses legalmente previstas no artigo 16 lei geral de proteção de dados, a saber:</p>
				<ol>
					<li>Cumprimento de obrigação legal ou regulatória pelo controlador;</li>
					<li>Estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;</li>
					<li>Transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos na Lei; ou</li>
					<li>Uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.</li>
				</ol>
				<p>Isto é, informações pessoais sobre você que sejam imprescindíveis para o cumprimento de determinações legais, judiciais e administrativas e/ou para o exercício do direito de defesa em processos judiciais e administrativos serão mantidas, a despeito da exclusão dos demais dados. </p>
				<p>O armazenamento de dados coletados pela CliqueMed reflete o nosso compromisso com a segurança e privacidade dos seus dados. Empregamos medidas e soluções técnicas de proteção aptas a garantir a confidencialidade, integridade e inviolabilidade dos seus dados, como backups, criptografia avançada, e controle de acesso multi-etapas. Além disso, também contamos com medidas de segurança apropriadas aos riscos e com controle de acesso às informações armazenadas.</p>
				<h2>6. O que fazemos para manter seus dados seguros?</h2>
				<p>Para mantermos suas informações pessoais seguras, usamos ferramentas físicas, eletrônicas e gerenciais orientadas para a proteção da sua privacidade.</p> 
				<p>Aplicamos essas ferramentas levando em consideração a natureza dos dados pessoais coletados, o contexto e a finalidade do tratamento e os riscos que eventuais violações gerariam para os direitos e liberdades do titular dos dados coletados e tratados.</p>
				<p>Entre as medidas que adotamos, destacamos as seguintes:</p>
				<ul>
					<li>Apenas pessoas autorizadas têm acesso a seus dados pessoais</li>
					<li>O acesso a seus dados pessoais é feito somente após o compromisso de confidencialidade</li>
					<li>Seus dados pessoais são armazenados em ambiente seguro e idôneo. Só utilizamos os serviços dos maiores provedores globais de tecnologia globais, com ferramentas de segurança avançada.</li>
				</ul>
				<p>A CliqueMed se compromete a adotar as melhores posturas para evitar incidentes de segurança. Contudo, é necessário destacar que nenhuma página virtual é inteiramente segura e livre de riscos. É possível que, apesar de todos os nossos protocolos de segurança, problemas de culpa exclusivamente de terceiros ocorram, como ataques cibernéticos de hackers, ou também em decorrência da negligência ou imprudência do próprio usuário/cliente.</p>
				<p>Em caso de incidentes de segurança que possam gerar risco ou dano relevante para você ou qualquer um de nossos usuários/clientes, comunicaremos aos afetados e a Autoridade Nacional de Proteção de Dados sobre o ocorrido, em consonância com as disposições da Lei Geral de Proteção de Dados.</p>
				<h2>7. Com quem seus dados podem ser compartilhados?</h2>
				<p>Tendo em vista a preservação de sua privacidade, a CliqueMed não compartilhará seus dados pessoais com nenhum terceiro não autorizado. </p>
				<p>Seus dados poderão ser compartilhados com nossos parceiros comerciais, através de nosso Ecosistema CliqueMed: Esse compartilhamento deverá ser solicitado e autorizado por você, ou pelo responsável pela sua organização. A CliqueMed nunca irá iniciar o compartilhamento não solicitado de informações de seus clientes e usuários.</p>
				<p>Eventuais parceiros comerciais recebem seus dados apenas na medida de sua autorização e do necessário para a prestação dos serviços contratados. Nossos contratos com eles são orientados pelas normas de proteção de dados do ordenamento jurídico brasileiro.</p>
				<p>Todavia, nossos parceiros têm suas próprias Políticas de Privacidade, que podem divergir desta. Recomendamos a leitura desses documentos, que você poderá acessar antes de autorizar qualquer compartilhamento.</p>
				<p>Além disso, também existem outras hipóteses em que seus dados poderão ser compartilhados, que são:</p>
				<ul>
					<li>Determinação legal, requerimento, requisição ou ordem judicial, com autoridades judiciais, administrativas ou governamentais competentes.</li>
					<li>Caso de movimentações societárias, como fusão, aquisição e incorporação, de forma automática</li>
					<li>Proteção dos direitos da CliqueMed em qualquer tipo de conflito, inclusive os de teor judicial.</li>
				</ul>
				<h3>7.1. Transferência internacional de dados</h3>
				<p>Alguns dos terceiros com quem compartilhamos seus dados podem ser localizados ou ou possuir instalações localizadas em países estrangeiros, em especial bancos de dados onde será realizada a preservação de backups. Nessas condições, de toda forma, seus dados pessoais estarão sujeitos à Lei Geral de Proteção de Dados e às demais legislações brasileiras de proteção de dados.</p>
				<p>Nesse sentido, a CliqueMed se compromete a sempre adotar eficientes padrões de segurança cibernética e de proteção de dados, nos melhores esforços de garantir e cumprir as exigências legislativas.</p>
				<p>Ao concordar com essa Política de Privacidade na forma de utilização do serviços ofertados, você concorda com esse compartilhamento, que se dará conforme as finalidades descritas no presente instrumento.</p>
				<h2>8. Cookies ou dados de navegação</h2>
				<p>A CliqueMed pode fazer uso de Cookies, que são arquivos de texto enviados pela plataforma ao seu computador e que nele se armazenam, que contém informações relacionadas à navegação do site. Em suma, os Cookies são utilizados para aprimorar a experiência de uso.</p>
				<p>Ao se registrar em nossa plataforma, você manifesta conhecer e aceitar a utilização de um sistema de coleta de dados de navegação com o uso de Cookies em seu dispositivo.</p>
				<p>Você pode, a qualquer tempo e sem nenhum custo, alterar as permissões, bloquear ou recusar os Cookies por meio de bloqueios em seu navegador. Todavia, a revogação do consentimento de determinados Cookies pode inviabilizar o funcionamento correto de alguns recursos da plataforma.</p>
				<p>Para gerenciar os cookies do seu navegador, basta fazê-lo diretamente nas configurações do navegador, na área de gestão de Cookies. Você pode acessar tutoriais sobre o tema diretamente nas páginas de cada navegador.</p>
				<h2>9. Alteração desta Política de Privacidade</h2>
				<p>A atual versão 1.02 da Política de Privacidade foi formulada e atualizada pela última vez em: 12/02/2023.</p>
				<p>Reservamos o direito de modificar essa Política de Privacidade a qualquer tempo, principalmente em função da adequação a eventuais alterações feitas em nosso site ou em âmbito legislativo. Recomendamos que você a revise com frequência, e tome nota da última versão revisada, ou de sua data.</p>
				<p>Eventuais alterações entrarão em vigor a partir de sua publicação em nosso site e sempre lhe notificaremos por e-mail acerca das mudanças significativas ocorridas.</p>
				<p>Ao utilizar nossos serviços e fornecer seus dados pessoais após tais modificações, você consente com elas.</p>
				<h2>10. Responsabilidade</h2>
				<p>A CliqueMed prevê a responsabilidade dos agentes que atuam nos processos de tratamento de dados, em conformidade com os artigos 42 ao 45 da Lei Geral de Proteção de Dados.</p>
				<p>Nos comprometemos em manter esta Política de Privacidade atualizada, observando suas disposições e zelando por seu cumprimento.</p>
				<p>Além disso, também assumimos o compromisso de buscar condições técnicas e organizacionais seguramente aptas a proteger todo o processo de tratamento de dados.</p>
				<p>Caso a Autoridade Nacional de Proteção de Dados exija a adoção de providências em relação ao tratamento de dados realizado pela CliqueMed, comprometemo-nos a seguí-las.</p>
				<h3>10.1 Isenção de responsabilidade</h3>
				<p>Conforme mencionado no Tópico 6, embora adotemos elevados padrões de segurança a fim de evitar incidentes, não há nenhuma página virtual inteiramente livre de riscos. Nesse sentido, a CliqueMed não se responsabiliza por:</p>
				<ul>
					<li>Quaisquer consequências decorrentes da negligência, imprudência ou imperícia dos usuários em relação a seus dados individuais. Garantimos e nos responsabilizamos apenas pela segurança dos processos de tratamento de dados e do cumprimento das finalidades descritas no presente instrumento.</li>
				</ul>
				<p>Destacamos que a responsabilidade em relação à confidencialidade dos dados de acesso é do usuário.</p>
				<ul>
					<li>Ações maliciosas de terceiros, como ataques de hackers, exceto se comprovada conduta culposa ou deliberada da CliqueMed.</li>
				</ul>
				<p>Destacamos que em caso de incidentes de segurança que possam gerar risco ou dano relevante para você ou qualquer um de nossos usuários/clientes, comunicaremos aos afetados e a Autoridade Nacional de Proteção de Dados sobre o ocorrido e cumpriremos as providências necessárias.</p>
				<ul>
					<li>Inveracidade das informações inseridas pelo usuário/cliente nos registros necessários para a utilização dos serviços da CliqueMed; quaisquer consequências decorrentes de informações falsas ou inseridas de má-fé são de inteiramente responsabilidade do usuário/cliente.</li>
				</ul>
			</div>
		</section>
	</div>
    </div>
	<div id="fillBLue">
	</div>
</template>

<script>
export default {
  name: 'Footer',
  components: {
  },
  setup() {
    },
  data () {
    return {
      currentModal: '',
    }
  },
  methods: {
	isModalActive(modalName) {
		return this.currentModal === modalName;
	},
	openModal(modalName) {
		this.currentModal = modalName;
	},
	closeModal() {
		this.currentModal = '';
	},
  }
}
</script>

<style scoped>
.footer {
	/* background-color: rgb(66, 85, 99); Cinza */
	background-color: black;
	color: rgb(255, 255, 255,0.7);
	padding-top: 5%;
	padding-left: 5%;
	padding-bottom: 40px;
}
h1 {
	padding-bottom: 15px;
}
.sectionTitle {
	/* line-height: 30px; */
	font-family: 'Montserrat', sans-serif;
	font-size: 1.5rem;
	color: white;
	font-weight: 600;
}
a {
	color: rgb(255, 255, 255,0.7);

}
a:hover {
	color: rgb(0, 94, 184);
	transition: 0.4s;
}
li{
	padding-bottom: 3%;
}

.brand{
	color: rgb(0, 114, 216);
	font-size: 1.rem;
	font-family: Montserrat, sans-serif;
}
.navbarSeparator {
	background-color: rgb(146, 146, 146, 0.5);
	height: 1px;
	margin-top: 4%;
	margin-bottom: 2px;
	width: 95%;
}

.copyright {
	padding-top: 4px;

	font-size: 0.8rem;
	color: rgb(255, 255, 255,0.7);
}

#fillBLue {
	background-color: rgb(0,94,184);
	color: rgb(0,94,184);
	height: 3px;
}
</style>

