<template>
      <div class="container">
        <div class="columns is-multiline">
            <!-- {{employee}} -->
            <!-- {{employeeDetails}} -->
            <div class="column is-12">
                <h1 class="title pb-0 mb-0">{{employeeDetails.PrimeiroNome}} {{employeeDetails.Sobrenomes}}</h1>
                <div class="buttons">
                    <!-- <router-link :to="{ name: 'EditLead', params: { id: lead.id }}" class="button is-light">Edit</router-link> -->
                    <!-- <button class="button is-info">Convert to client</button>
                    <button class="button is-danger">Delete</button> -->
                </div>
            </div>
            <hr>
            <div class="column is-6">
                <div class="box">
                    <h2 class="subtitle">Detalhes Próprios</h2>
                    <p><strong>CPF: </strong>{{employeeDetails['CPF']}}</p>
                    <p><strong>Email Pessoal: </strong>{{employeeDetails['EmailPessoal']}}</p>
                    <p><strong>Função: </strong>{{employeeDetails['Função']}}</p>
                </div>
            </div>

            <div class="column is-6">
                <div class="box">
                    <h2 class="subtitle">Detalhes Organizacionais (Registro)</h2>
                    <p><strong>Email  Corporativo: </strong>{{employeeDetails['EmailCorporativo']}}</p>
                    <p><strong>Companhia: </strong>{{employeeDetails['Companhia']}}</p>
                    <p><strong>Unidade de Negócio: </strong>{{employeeDetails['UnidadeNegócio']}}</p>
                    <p><strong>Work Area: </strong>{{employeeDetails['WorkArea']}}</p>
                    <p><strong>Data Registro: </strong>{{employeeDetails['InícioEmpregoAtual']}}</p>
                </div>
            </div>

            <div class="column is-12 is-centered">
                <div class="box is-narrow">
                    <div class="columns is-multiline">
                        <div class="column is-6">
                            <h2 class="subtitle"> <strong>Histórico de Exames</strong> </h2> 
                        </div>
                        <div class="column is-6">
                            <ControlledButton :requiredTier="requiredTiers.time_employee_details_adicionar_exame">
                                <div class="button is-info is-outlined" @click="activateModalExamAdd(employeeDetails.pkPerson)">
                                    <strong>Adicionar Exame</strong>
                                </div>
                            </ControlledButton>
                        </div>
                        <!-- ALTERNATIVE TABLE - SIMPLER
                            <div class="table is-fullwidth is-hoverable is-striped" v-if="typeof employeeDetails['HistoricoExames']=='object'">
                            <thead>
                                <tr>
                                <th>Exame</th>
                                <th>Data Realização</th>
                                <th>Data Máx. Renovação</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="exame in employeeDetails['HistoricoExames']" :key="exame['Exame']">
                                    <td>{{exame['Exame']}}</td>
                                    <td class="has-text-centered">{{exame['DataExame']}}</td>
                                    <td class="has-text-centered">{{exame['RenovaçãoExame']}}</td>
                                </tr>
                            </tbody>
                        </div>
                        <div v-else>
                            Sem histórico de exames registrado.
                        </div> -->
                        <div class="column is-12">
                            <DataTable
                                apiURL="/api/v1/corporate/employee/pastexams/"
                                :apiParams="{'employeeId':employeeId}"
                                ref="employeePastExamsDataTableRef"
                                :key = "DataTableKeyIndex"
                                :hasActionsColumn="true"
                                :actionButtons="{
                                    'Edit': 'Editar',
                                    'Delete': 'Deletar'
                                }"
                                :hasSearchField="false"
                                :fieldsToHide="['pk','IndexExam']"
                                @toggleDeleteModal="activateModalExamDelete"
                                @toggleEditModal="activateModalExamEdit"
                                />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- MODAL - PASTEXAM ADD -->
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_PastExamAdd') }" v-if="isModalActive('Modal_PastExamAdd')">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Adicionar Exame Realizado ao Histórico Individual</p>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                </header>
                <section class="modal-card-body">
                    <form-generic-serializer
                        ref="AddPastExam_Form"
                        :object="pastExamToAdd"
                        apiURL='/api/v1/corporate/employee/pastexams/'
                        formValidationSchema="EditPastExamSchema"
                        @schemaIsValidChanged="formEvent_AddPastExam"
                    />
                </section>
                <footer class="modal-card-foot">
                    <div class="buttons"> <!-----------------------------------------------------------Make buttons centered -->
                        <button class="button is-danger" @click="closeModal()">Cancelar</button>
                        <button class="button is-success" @click="submitPastExamForm()">Confirmar</button>
                    </div>
                </footer>
            </div>
        </div>
        <!-- MODAL - PASTEXAM EDIT -->
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_PastExamEdit') }" v-if="isModalActive('Modal_PastExamEdit')">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Editar Exame Realizado</p>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                </header>
                <section class="modal-card-body">
                    <EditGenericSerializer
                        :object="pastExamEditFormData"
                        apiURL='/api/v1/corporate/employee/pastexams/'
                        ref = 'EditPastExamForm'
                        submitMethod = 'put'
                        formValidationSchema = 'EditPastExamSchema' 
                        @closeEditModal="closeEditModal()"
                        @closeEditModalWithReload="closePastExamEdit()"
                        />
                </section>
            </div>
        </div>
        <!-- MODAL - PASTEXAM DELETE -->
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_PastExamDelete') }" v-if="isModalActive('Modal_PastExamDelete')">
            <div class="modal-background"></div>
            <div class="modal-card" id="PastExamDeleteModalCard">
                <header class="modal-card-head">
                    <p class="modal-card-title">Deletar Exame</p>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                </header>
                <section class="modal-card-body" style="modal-content-width:1200px">
                    <PastExamDeleteComponent
                    :exam="modalInputData"
                    :examId="modalInputData.pk"
                    :modalIsActive="isModalActive('Modal_PastExamDelete')"
                    @closeDeleteModal="closeModal()"
                    />
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import { toRaw } from '@vue/reactivity'
import {ref} from "vue"
import axios from 'axios'
import { toast } from 'bulma-toast'
import DataTable from '@/components/dataTable/DataTable.vue'
import EditGenericSerializer from '../../components/EditGenericSerializer.vue'
import PastExamDeleteComponent from '../../components/viewComponents/PastExamDelete.vue'
import FormGenericSerializer from '../../components/FormGenericSerializer.vue'

import ControlledButton from '@/components/ControlledButton.vue'
import {requiredTiers} from '@/utilities/requiredTiers.js'

export default {
    name: 'EmployeeDetailsComponent',
    props: ['employee','employeeId']
    ,
    components: {
        DataTable, 
        EditGenericSerializer, FormGenericSerializer,
        PastExamDeleteComponent,
        ControlledButton
    },
    data() {
        return {
            companyInner: '',
            tableAPIParams: {
                'employeeId': this.innerID
            },
            employeeDetails: {},
            activeModal: '',
            modalInputData: {},
            pastExamModalInputData: {},
            pastExamModalInputID: 0,
            pastExamToAdd: {},
            pastExamToAdd_hasValidSchema: false,
            pastExamEditFormData:{},
            DataTableKeyIndex: ref(0),
            requiredTiers: requiredTiers
        }
    },
    computed: {
            innerID() {
                return this.employeeId
            }
    },
    created() {
        // console.log('CACILDA!')
        // console.log('props inside CompanyDetails:',this.companyID)
        // console.log(this.tableAPIParams)
        // this.tableAPIParams.companyId = this.companyId
        this.getEmployeeData()
    },
    methods: {
        getEmployeeData() {
            // console.log('DataTable_getData being called with apiURL||params:',this.apiURL,'||',{ params: this.apiParams })
            axios
                .get('/api/v1/corporate/employments/details/', { params: {'employeeId':this.employeeId} })
                .then(response=> {
                    this.employeeDetails = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                });
        },
        activateModal(modalName, data='') {
            // console.log('Time_activateModal Called for modal:', modalName, ' and data:',data)
            const rawData = toRaw(data)
            if (data!='') {
                this.modalInputData = rawData
                // console.log('modalInputData:',this.modalInputData)
                this.modalInputID = rawData.pk
                // console.log('modalInputID:',this.modalInputID)
            }
            this.activeModal = modalName
        },
        activateModalExamAdd(employeePK) {
            // console.log('EmployeeDetails_DataTable_emitted_data:',data)
            this.activateModal('Modal_PastExamAdd',employeePK)
        },
        activateModalExamEdit(data) {
            console.log('EmployeeDetails_DataTable_emitted_data:',data)
            // this.activateModal('Modal_PastExamEdit',data) XYZ
            this.activeModal = 'Modal_PastExamEdit'
            const rawData = toRaw(data)
            console.log('EmployeeDetails_DataTable_rawData:',rawData)
            this.pastExamEditFormData = rawData
            this.pastExamEditFormData.Exame = rawData.IndexExam

        },
        activateModalExamDelete(data) {
            // console.log('EmployeeDetails_activateModalExamDelete_data:',data)
            this.activateModal('Modal_PastExamDelete',data)
        },
        closeModal() {
            this.DataTableKeyIndex++
            this.activeModal = ''
        },
        closeEditModal() {
            this.DataTableKeyIndex++
            this.activeModal = ''
        },
        closePastExamEdit() {
            this.DataTableKeyIndex++
            this.activeModal = ''
        },
        formEvent_AddPastExam(data) {
            this.pastExamToAdd_hasValidSchema = data
        },
        isModalActive(modalName) {
            return this.activeModal === modalName
        },
        pageReload() {
            window.location.reload()
        },
        submitPastExamForm() {
            if (this.pastExamToAdd_hasValidSchema) {
                this.pastExamToAdd['PersonPk'] = this.employeeDetails.pkPerson
                this.pastExamToAdd['EmploymentPk'] = this.employeeDetails.pkEmployment
                axios
                    .post('/api/v1/corporate/employee/pastexams/', this.pastExamToAdd)
                    .then(response => {
                        // this.$router.push('/organizacao')
                        // this.companies.unshift(response.data)
                        // console.log('response from submitExamForm:',response.data)
                        // this.workArea[]
                        this.$refs.employeePastExamsDataTableRef.table.unfilteredRows.unshift(response.data[0])
                        this.pastExamToAdd = {}
                        // console.log(this.$refs.employeePastExamsDataTableRef.table.unfilteredRows)
                    })
                    .catch(error => {
                        // console.log(JSON.stringify(error))
                        console.log(error.request.response)
                    })
                    .then( () => { 
                        this.closeModal()
                    })
            }
            else {
                this.$refs.AddPastExam_Form.$refs.form.validate()
                toast({
                    position:'bottom-right',
                    message: 'Favor preencher os campos de acordo com os requisitos.',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000
                })
            }
        },
    }

}
</script>

<style scoped>

.box {
    height: 100%;
}


.modal-card {
    position: inherit;
    z-index: 100;
    width: 65%;
}
</style>