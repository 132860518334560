<template>
      <div class="container">
        <!-- {{ examOffer }} -->
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title pb-0 mb-0 has-text-danger has-background-warning has-text-centered"><strong>Atenção!</strong></h1>
                <h2 class="has-text-danger has-background-warning has-text-centered"><strong>A ação que você está prestes a realizar é irreversível.</strong></h2>
            </div>
            <hr>
            <div class="column is-6">
                <div class="box">
                    <h2 class="subtitle"><strong>{{objectToDelete.nome}}</strong></h2>
                    <p><strong>Criado em: </strong>{{ objectToDelete['created_at'] }}</p>
                    <p><strong>Oferta Pública: </strong>{{ objectToDelete['Publica'] }}</p>
                    <p><strong>Condição de Pagamento: </strong>{{ objectToDelete['condicoes_pgto'] }} dias</p>
                </div>
            </div>

            <div class="column is-6">
                <div class="box">
                    <div class="content">
                        <h3 class="subtitle"><strong>A oferta ao lado possui:</strong></h3>
                        <ul>
                            <li>{{objectToDelete['Nº exames']}} Exames ofertados</li>
                            <li>XYZ Clientes registrados</li>
                        </ul>
                        <h5 class="has-text-centered has-text-danger has-background-warning"><strong>Ao excluir a oferta, todos os exames ali contidos ficarão indisponíveis para agendamentos.</strong></h5>
                    </div>
                </div>
            </div>

            <div class="column is-12 is-centered">
                <label class="checkbox pb-2">
                    <input type="checkbox" v-model="checkConfirm">
                    Eu entendo que ao deletar a oferta acima, clientes não mais terão acesso aos exames nela contidos, e desejo continuar.
                </label>
                <p class="has-text-danger pb-4" v-show="submitDeclined">Você deve confirmar na caixa acima antes de prosseguir.</p> 
                <div class="buttons"> <!-----------------------------------------------------------Make buttons centered -->
                    <button class="button is-info" @click="$emit('closeDeleteModal')">Cancelar e Retornar</button>
                    <button class="button is-danger is-outlined" @click="submitDelete">Confirmar e Deletar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

// import DataTable from '@/components/dataTable/DataTable.vue'



export default {
    name: 'ExamOfferDeleteComponent',
    props: ['examOffer','modalIsActive']
    // {
    //     companyId: Integer
    // }
    ,
    components: {
        // DataTable
    },
    data() {
        return {
            examOfferInner: '',
            tableAPIParams: {
                'examOfferPK': this.examOffer.pk
            },
            numeroUnidadesNegocio: 0,
            numeroWorkAreas: 0,
            numeroRelacoesDeTrabalho: 0,
            objectToDelete: {},
            checkConfirm: false,
            submitDeclined: false
        }
    },
    computed: {

    },
    watch: {

    },
    created() {
        // console.log('CACILDA!')
        // console.log('props inside CompanyDetails:',this.companyID)
        // console.log(this.tableAPIParams)
        // this.tableAPIParams.companyId = this.companyId
        this.getDataBeforeDelete()
    },
    methods: {
        async submitDelete() {
            if (this.checkConfirm) {
                console.log('submitting delete request for:',this.examOffer.pk)
                axios
                    .delete('/api/v1/clinics/exam-offers/', { params: {'examOfferPK': this.examOffer.pk} })
                    .then(response => {
                        // this.$router.push('/organizacao')
                        // this.companies.unshift(response.data)
                        // this.$refs.dataTableRef.table.rows.unshift(response.data)
                        // console.log('CompanyDelete_submitDelete called.')
                        // Emit event to parent component to close modal
                        this.$emit('closeDeleteModalWithReload')
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
            else {
                this.submitDeclined = !this.submitDeclined
            }
        },
        getDataBeforeDelete() {
            axios
                .get('/api/v1/clinics/exam-offers/', { params: {'examOfferPK': this.examOffer.pk} })
                .then(response => {
                    // console.log('prop examOffer.pk :',this.examOffer.pk)
                    console.log('getDataBeforeDelete response.data:',response.data)
                    this.objectToDelete = response.data
                    // this.numeroUnidadesNegocio = response.data.length
                    // let numWAs = 0
                    // let numRT = 0
                    // for (let i = 0; i < response.data.length; i++) {
                    //     numWAs += response.data[i]['#WAs']
                    //     numRT += response.data[i]['#Func.']
                    // }

                    // this.numeroWorkAreas = numWAs
                    // this.numeroRelacoesDeTrabalho = numRT
                    // this.responseBeforeDelete = response
                    // console.log('response:',response)
                    // console.log('CompanyDelete_getDataBeforeDelete called.')
                })
                .catch(error => {
                    console.log(error.request.response)
                })
        }
    }

}
</script>

<style>

</style>