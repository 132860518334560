export const topExames = [
    {valor: 'Exame Clínico - Admissional', codigo: 75, isTopExam: true},
    {valor: 'Exame Clínico - Demissional', codigo: 76, isTopExam: true},
    {valor: 'Exame Clínico - Mudança de Função', codigo: 79, isTopExam: true},
    {valor: 'Exame Clínico - Periódico', codigo: 77, isTopExam: true},
    {valor: 'Exame Clínico - Retorno ao Trabalho', codigo: 78, isTopExam: true},
    {valor: 'Acuidade Visual', codigo: 16, isTopExam: true},
    {valor: 'Audiometria Ocupacional', codigo: 24, isTopExam: true},
    {valor: 'Colesterol Total', codigo: 55, isTopExam: true},
    {valor: 'Coprocultura', codigo: 59, isTopExam: true},
    {valor: 'Creatinina', codigo: 60, isTopExam: true},
    {valor: 'Eletrocardiograma (ECG)', codigo: 68, isTopExam: true},
    {valor: 'Eletroencefalograma (EEG)', codigo: 70, isTopExam: true},
    {valor: 'Espirometria', codigo: 73, isTopExam: true},
    {valor: 'Gama GT', codigo: 89, isTopExam: true},
    {valor: 'Glicemia', codigo: 90, isTopExam: true},
    {valor: 'Hemograma Completo', codigo: 95, isTopExam: true},
    {valor: 'Parasitológico', codigo: 119, isTopExam: true},
    {valor: 'Raio X Coluna Lombo - Sacra', codigo: 129, isTopExam: true},
    {valor: 'Raio-X Tórax PA/ Perfil', codigo: 131, isTopExam: true},
    {valor: 'Raio-X Tórax Padrão PA - Padrão OIT', codigo: 132, isTopExam: true},
    {valor: 'Triglicérides', codigo: 148, isTopExam: true},
    {valor: 'Urina', codigo: 152, isTopExam: true},
    {valor: 'VDRL', codigo: 136, isTopExam: true}
]