<template>
  <div class="home">
    <LandingPage/>
    <!-- <Navbar></Navbar> -->
    <!-- <hr>
    <strong>DEBUG</strong>
    <br>
    process.env: {{environ}}
    <br>
    VUE_APP_AXIOS_ROOT_API: {{VUE_APP_AXIOS_ROOT_API}} -->
  </div>
</template>

<script>
// @ is an alias to /src
import LandingPage from '@/components/LandingPage.vue'
export default {
  name: 'Home',
  components: {
    LandingPage,
},
  data() {
    return {
      // environ: process.env,
      // VUE_APP_AXIOS_ROOT_API: process.env.VUE_APP_AXIOS_ROOT_API
    };
  }

}
</script>
