<template>
    <div class="wrapper">
        <div class="page-organizacao">
            <!-- <nav class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                    <li><router-link to="/dashboard">Dashboard</router-link></li>
                    <li class="is-active"><router-link to="/dashboard/clients" aria-current="true">Clients</router-link></li>
                </ul>
            </nav> -->
            <h1 class="title" style="margin-bottom:24px">Requisições</h1>
            <div class="box pt-0 mt-0">
                <!-- TAB HEADERS -->
                <div class="tabs is-boxed is-centered is-fullwidth is-medium" id="tabs">
                    <ul>
                        <li :class="{ 'is-active': isTabActive('Requisições') }" @click="setActiveTab('Requisições')"><a>Requisições</a></li>
                        <li :class="{ 'is-active': isTabActive('Agendamento') }" @click="setActiveTab('Agendamento')"><a>Agendamentos</a></li>
                    </ul>
                </div>
                <!-- TAB REQUISIÇÕES -->
                <div class="columns is-multiline" v-show="isTabActive('Requisições')">
                    <div class="column is-6">
                        <h1 class="subtitle is-4"><strong>Gerenciar Requisições</strong></h1>
                    </div>
                    <div class="column is-6">
                        <!-- <button class="button is-info is-centered strong" @click="activateModal('Modal_CompanyAdd')">
                            <strong> Adicionar Companhia </strong>
                        </button> -->
                    </div>
                    <div class="column is-12">
                        <DataTable
                            apiURL="/api/v1/medicalrequests/api/"
                            ref="dataTableRef_medicalrequests"
                            :apiParams="{}"
                            :hasActionsColumn="true"
                            :actionButtons="{
                                'Info': 'Gerenciar',
                                // 'Edit': 'Gerir',
                                // 'Delete': 'Deletar'
                            }"
                            :hasSearchField="true"
                            :fieldsToHide="[
                                'corporate_pk',
                                // 'pk',
                                'corporate_name',
                                'paciente_pk',
                                'clinica_responsavel_pk',
                                'aberta',
                                'statusFinanceiro',
                                'numero_de_exames_concluidos',
                                ]"
                            :fieldNameManager="{
                                'pk': 'Ref.',
                                'data_criação': 'Data',
                                'paciente_name': 'Paciente',
                                'clinica_nome': 'Clínica Responsável',
                                'numero_de_exames_total': 'N. Exames',
                                'precoTotal': 'Preço Total',
                            }"
                            :key="medicalRequestsDTKey"
                            @toggleDetailsModal="activateModalGerirRequisicao"
                            />
                            <!-- @toggleDeleteModal="activateModalCompanyDelete" -->
                            <!-- @toggleEditModal="activateModalCompanyEdit" -->
                    </div>
                </div>
                <!-- TAB AGENDAMENTOS -->
                <div class="columns is-multiline" v-show="isTabActive('Agendamento')">
                    <div class="column is-6">
                        <h1 class="subtitle is-4"><strong>Gerenciar Agendamentos</strong></h1>
                    </div>
                    <div class="column is-6">
                        <!-- <button class="button is-info is-centered strong" @click="activateModal('Modal_BUAdd')">
                            <strong> Adicionar Unid. de Negócio </strong>
                        </button> -->
                    </div>
                    <div class="column is-12">
                        <DataTable
                            apiURL="/api/v1/appointments/"
                            ref="dataTableAppointmentsRef"
                            :hasActionsColumn="true"
                            :actionButtons="{
                                // 'Info': 'Editar',
                                'Edit': 'Editar',
                                // 'Delete': 'Deletar'
                            }"
                            :fieldsToHide="[
                                'data_dia',
                                'hora_inicio',
                                'hora_fim',
                                'tipo_exame',
                                'corporate',
                                'requisicao_medica',
                                'tipo_agendamento',
                                'data_criacao',
                                'data_alteracao',
                                'meta_exam_request',
                            ]"
                            :fieldNameManager="{
                                'pk': 'Ref.',
                                'patient': 'Paciente',
                                'clinica': 'Clínica',
                                'requisicao_exame_padrao': 'Exame',
                                'data_agendamento': 'Data',
                                'status_agendamento': 'Status',
                            }"
                            :hasSearchField="true"
                            :key="dataTableAppointmentsKey"
                            @toggleEditModal="activateModalEditarAgendamento"
                        />
                            <!-- @toggleDeleteModal="activateModalBusinessUnitDelete"
                            @toggleDetailsModal="activateModalBusinessUnitDetails" -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section><!------------------------ MODAL: GERENCIAR REQUISICAO (DT) ------------------------> 
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_Gerir_Requisicao') }" v-if="isModalActive('Modal_Gerir_Requisicao')">
            <div class="modal-background"></div>
            <div class="modal-card" id="Modal_Gerir_Requisicao">
                <header class="modal-card-head">
                    <p class="modal-card-title">Gerenciar Requisição</p>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                </header>
                <section class="modal-card-body" style="modal-content-width:1200px">
                    <GerenciarRequisicao 
                        :modalObject="modalInputData"
                        :modalIsActive="modalRequisicaoIsActive"
                        @closeModal="closeModal"
                    />
                </section>
                <!-- <footer class="modal-card-foot" style="clear: both">
                    <div>
                        <button class="button is-danger is-outlined" style="float:left" @click="activateModal('Modal_DeleteMedicalRequest')">Cancelar Requisição</button>
                        <button class="button is-info is-right" style="float:right" @click="$emit('closeModal')">Retornar</button>
                    </div>
                </footer> -->
            </div>
        </div>
    </section>
    <section><!------------------------ MODAL: EDITAR AGENDAMENTO (DT) ------------------------> 
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_Editar_Agendamento') }" v-if="isModalActive('Modal_Editar_Agendamento')">
            <div class="modal-background"></div>
            <div class="modal-card" id="Modal_Editar_Agendamento">
                <header class="modal-card-head">
                    <p class="modal-card-title">Editar Agendamento</p>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                </header>
                <section class="modal-card-body" style="modal-content-width:1200px">
                    <EditarAgendamento 
                        :modalObject="modalInputData"
                        @closeModal="closeModalEditAppointment"
                    />
                </section>
                <!-- <footer class="modal-card-foot" style="clear: both">
                    <div>
                        <button class="button is-danger is-outlined" style="float:left" @click="activateModal('Modal_DeleteMedicalRequest')">Cancelar Requisição</button>
                        <button class="button is-info is-right" style="float:right" @click="$emit('closeModal')">Retornar</button>
                    </div>
                </footer> -->
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import DataTable from '@/components/dataTable/DataTable.vue'
// import FormGenericSerializer from '../../components/FormGenericSerializer.vue'
// import EditGenericSerializer from '../../components/EditGenericSerializer.vue'
import GerenciarRequisicao from '../../components/viewComponents/GerenciarRequisicao.vue'
import EditarAgendamento from '../../components/viewComponents/EditarAgendamento.vue'
import { toRaw } from '@vue/reactivity'
import { toast } from 'bulma-toast'

export default {
    name: 'Requisicoes',
    components: {
        DataTable,
        GerenciarRequisicao,
        EditarAgendamento,
        // FormGenericSerializer, EditGenericSerializer,
    },
    data() {
        return {
            activeTab: 'Requisições',
            activeModal: '',
            dataTableAppointmentsKey: 0,
            dataTableAppointmentsRef: '',
            medicalRequestsDTKey: 0,    
            modalInputData: {},
            modalInputID: 0,
            modalRequisicaoIsActive: false,
        }
    },
    mounted() {

    },
    methods: {
        // -----------------------------PAGE STRUCTURE METHODS START-----------------------------
        isModalActive(modalName) {
            return this.activeModal === modalName
        },
        activateModal(modalName, data='') {
            // console.log('Organizacao_activateModal Called for modal:', modalName, ' and data:',data)
            const rawData = toRaw(data)
            if (data!='') {
                // console.log('activateModal data recognised. Received data:',data)
                this.modalInputData = rawData
                // console.log('modalInputData:',this.modalInputData)
                this.modalInputID = rawData.pk
                // console.log('modalInputID:',this.modalInputID)
            }
            this.activeModal = modalName
            // console.log('activeModal set to:',this.activeModal)
        },
        closeModal() {
            this.activeModal = ''
            this.medicalRequestsDTKey++
            this.dataTableAppointmentsKey++
        },
        closeModalEditAppointment() {
            this.activeModal = ''
            this.dataTableAppointmentsKey++
        },
        isTabActive(tabItem) {
            return this.activeTab === tabItem
        },
        setActiveTab(tabItem) {
            this.activeTab = tabItem
        },
        // -----------------------------PAGE STRUCTURE METHODS END-----------------------------
        activateModalGerirRequisicao(data) {
            // console.log('activateModalGerirRequisicao Called with data:', data)
            this.activateModal('Modal_Gerir_Requisicao', data)
            this.modalRequisicaoIsActive = true
        },
        activateModalEditarAgendamento(data) {
            // console.log('activateModalEditAppointment Called with data:', data)
            this.activateModal('Modal_Editar_Agendamento', data)
        },
    }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#tabs {
    margin-left: -20px;
    margin-right: -20px;
}

.modal-card {
    width:80%
}

#Modal_Editar_Agendamento {
    width: 40%;
}
</style>