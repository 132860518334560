<template>
    <div class="wrapper">
        <div class="page-organizacao">
            <!-- <nav class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                    <li><router-link to="/dashboard">Dashboard</router-link></li>
                    <li class="is-active"><router-link to="/dashboard/clients" aria-current="true">Clients</router-link></li>
                </ul>
            </nav> -->
            <h1 class="title" style="margin-bottom:24px">Gerenciar Agendamentos</h1>
            <div class="box pt-0 mt-0">
                <!-- TAB HEADERS -->
                <!-- <div class="tabs is-boxed is-centered is-fullwidth is-medium" id="tabs">
                    <ul>
                        <li :class="{ 'is-active': isTabActive('Visão_Geral') }" @click="setActiveTab('Visão_Geral')"><a>Visão Geral</a></li>
                        <li :class="{ 'is-active': isTabActive('Funcionários') }" @click="setActiveTab('Funcionários')"><a>Funcionários</a></li>
                    </ul>
                </div> -->
                <!-- TAB VISÃO GERAL -->
                <!-- <div class="columns is-multiline mt-0 pt-0" v-show="isTabActive('Visão_Geral')">
                    <div class="column is-6">
                        <h1 class="subtitle is-4"><strong>Visão Geral</strong></h1>
                    </div>
                    <div class="column is-6">
                        Column is-6
                    </div>
                    <div class="column is-12">
                        Column is-12
                    </div>
                </div> -->
                <!-- TAB AGENDAMENTO -->
                <div class="columns is-multiline" v-show="isTabActive('Agendamentos')">
                    <div class="column is-6">
                        <h1 class="subtitle is-4"><strong>Funcionários Registrados</strong></h1>
                    </div>
                    <div class="column is-6">
                        <button class="button is-info is-centered strong" @click="activateModal('Modal_EmployeeAdd')">
                            <strong> Adicionar Funcionário </strong>
                        </button>
                    </div>
                    <div class="column is-12">
                        <DataTable
                            apiURL="/api/v1/appointments/"
                            ref="dataTableAppointmentsRef"
                            :hasActionsColumn="true"
                            :actionButtons="{
                                'Info': 'Gerir',
                                // 'Edit': 'Gerir',
                                // 'Delete': 'Deletar'
                            }"
                            :fieldsToHide="[
                                'data_dia',
                                'hora_inicio',
                                'hora_fim',
                                'clinica',
                                'tipo_exame',
                                // 'requisicao_medica',
                                'tipo_agendamento',
                                'data_criacao',
                                'data_alteracao',
                                'meta_exam_request',
                            ]"
                            :fieldNameManager="{
                                'requisicao_medica': 'Req.',
                                'pk': 'Ref.',
                                'corporate': 'Cliente',
                                'patient': 'Paciente',
                                'clinica': 'Clínica',
                                'requisicao_exame_padrao': 'Exame',
                                'data_agendamento': 'Data',
                                'status_agendamento': 'Status',
                            }"
                            :key="dataTableAppointmentsKey"
                            :hasSearchField="true"
                            @toggleDetailsModal="activateModalEditarAgendamento"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import DataTable from '@/components/dataTable/DataTable.vue'
// import FormGenericSerializer from '../../components/FormGenericSerializer.vue'
// import EditGenericSerializer from '../../components/EditGenericSerializer.vue'
// import DetailedEditGenericForm from '../../components/DetailedEditGenericForm.vue'
import { toRaw } from '@vue/reactivity'
import { toast } from 'bulma-toast'


export default {
    name: 'GerenciarAgendamentos',
    components: {
        DataTable,
        // FormGenericSerializer, EditGenericSerializer, DetailedEditGenericForm,

    },
    data() {
        return {
            activeTab: 'Agendamentos',
            activeModal: '',
            dataTableAPIParams: {},
            dataTableAppointmentsKey: 0,
            modalInputData: {},
            modalInputID: 0,
        }
    },
    mounted() {

    },
    methods: {
        activateModal(modalName, data='') {
            // console.log('Time_activateModal Called for modal:', modalName, ' and data:',data)
            const rawData = toRaw(data)
            if (data!='') {
                this.modalInputData = rawData
                // console.log('modalInputData:',this.modalInputData)
                this.modalInputID = rawData.pk
                // console.log('modalInputID:',this.modalInputID)
            }
            this.activeModal = modalName
        },
        activateModalEditarAgendamento(data) {
            console.log('activateModalEditarAgendamento Called with data:',data)
        },
        closeModal() {
            this.employeesDTKeys++
            this.activeModal = ''
        },
        isModalActive(modalName) {
            return this.activeModal === modalName
        },
        isTabActive(tabItem) {
            return this.activeTab === tabItem
        },
        setActiveTab(tabItem) {
            this.activeTab = tabItem
        },
    }
}
</script>

<style scoped>
#employeeDetailModalCard {
    width:75%
}

#EmployeeDeleteModalCard {
    width:75%
}

.columns {
    margin-bottom: 0px;
    margin-top: 0px;
}
/*
#businessUnitDetailModalCard {
    width: 75%;
}

#WorkAreaDeleteModalCard {
    width: 75%;
}

#workAreaDetailModalCard {
    width: 75%;
}

#BusinessUnitDeleteModalCard {
    width:75%
}

#tabs {
    margin-left: -20px;
    margin-right: -20px;
} */
</style>