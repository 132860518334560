<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title pb-0 mb-0">{{workArea['WorkArea']}}</h1>
                <!-- {{workArea}} -->
                <div class="buttons">
                    <!-- <router-link :to="{ name: 'EditLead', params: { id: lead.id }}" class="button is-light">Edit</router-link> -->
                    <!-- <button class="button is-info">Convert to client</button>
                    <button class="button is-danger">Delete</button> -->
                </div>
            </div>
            <hr>
            <div class="column is-6"><!---------------------- Details box ---------------------->
                <div class="box" id="box-detalhes">
                    <h2 class="subtitle">Detalhes Próprios</h2>
                    <p><strong>Código Interno: </strong>{{workArea['Cód.']}}</p>
                    <p><strong>Núm. Exames Associados: </strong>{{workArea['Exames']}}</p>
                    <p><strong>Núm. Funcionários Registrados: </strong>{{workArea['Func.']}}</p>
                    <p><strong>Localização: </strong>{{workArea['Municipio']}}</p>
                    <hr>
                    <h2 class="subtitle">Detalhes Organizacionais</h2>
                    <p><strong>Pertence à Companhia: </strong>{{workArea.Companhia}}</p>
                    <p><strong>Registrada na Unidade de Negócio: </strong>{{workArea.Unidade}}</p>
                    <p><strong>Criado em: </strong>{{workArea['Dt.Reg.']}}</p>
                    <p><strong>Criado por: </strong>Pessoa</p>
                </div>
            </div>

            <div class="column is-6"><!---------------------- Required Exams box ---------------------->
                <div class="box" id="box-exames">
                    <h2 class="subtitle"> <strong>Exames demandados dos funcionários</strong> </h2>
                    <div class="table is-fullwidth is-hoverable is-striped" v-if="listaExames.length > 0">
                        <thead>
                            <tr>
                            <th>Exame</th>
                            <th>Renovação a cada (meses)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="exame in listaExames" :key="exame.nome">
                                <td>{{exame.nome}}</td>
                                <td class="has-text-centered">{{exame['PeríodoRenovação']}}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div v-else>
                        Sem requerimento de exames registrado.
                    </div>
                    <br>
                    <ControlledButton :requiredTier="requiredTiers.organizacao_workarea_details_gerenciar_exames">
                        <button class="button is-info is-outlined" @click="activateExamModal(workAreaId)">
                            <strong>Gerenciar Exames</strong>
                        </button>
                    </ControlledButton>
                </div>
            </div>

            <div class="column is-12 is-centered"><!---------------------- Registered Employees box ---------------------->
                <div class="box is-narrow">
                    <div class="columns is-multiline">
                        <div class="column is-6">
                            <h2 class="subtitle"> <strong>Funcionários registrados na W.A.</strong> </h2>
                        </div>
                        <div class="column is-6">
                            <router-link to="/time" class="button is-info"><strong> Gerenciar Time </strong></router-link>
                        </div>
                        <div class="column is-12">
                            <DataTable apiURL="/api/v1/work-areas/funcionarios/" :apiParams="{'workAreaId': this.innerID, 'simple':true}" ref="dataTableFuncionariosDetails" :hasActionColumn="false"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- MODAL - MANAGE EXAMS -->
        <div class="modal" :class="{ 'is-active': examModalIsActive }" v-if="examModalIsActive">
            <div class="modal-background"></div>
            <div class="modal-card" id="manageExamsModalCard">
                <header class="modal-card-head">
                    <p class="modal-card-title">{{workArea.WorkArea}} - Gerenciar Exames</p>
                    <button class="delete" aria-label="close" @click="closeExamModal()"></button>
                </header>
                <section class="modal-card-body" style="modal-content-width:1200px">
                    <div class="columns is-multiline">
                        <div class="column is-6">
                            Detalhes dos exames
                        </div>
                        <div class="column is-6">
                            <button class="button is-info is-centered strong" @click="genericActivateExamModal('Modal_AddExam')">
                                <strong> Adicionar Exame </strong>
                            </button>
                        </div>
                            <!-- :actionButtons = ['Editar','Deletar'] -->
                        <div class="column is-12" style="{justify-content: center}">
                            <DataTable
                            ref="dataTableGerenciarExames"
                            apiURL="/api/v1/work-areas/exames/"
                            :actionButtons="{
                                            // 'Info': 'Gerenciar',
                                            'Edit': 'Editar',
                                            'Delete': 'Deletar'
                                        }"
                            :apiParams="{'workAreaId': this.innerID, 'simple':true}"
                            :hasActionsColumn="true"
                            :fieldsToHide="['pk','examIndex']"
                            :key = "employeePastExamsDataTableKey"
                            @toggleDeleteModal="activateModalExamDelete"
                            @toggleEditModal="activateModalExamEdit"
                            />
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <div class="buttons"> <!-----------------------------------------------------------Make buttons centered -->
                        <button class="button is-info" @click="closeExamModal()">Concluir</button>
                    </div>
                </footer>
            </div>
        </div>
        <!-- MODAL - ADD EXAM -->
        <div class="modal" :class="{ 'is-active': isExamModalActive('Modal_AddExam') }" v-if="isExamModalActive('Modal_AddExam')">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Adicionar Exame</p>
                    <button class="delete" aria-label="close" @click="closeGenericExamModal()"></button>
                </header>
                <section class="modal-card-body">
                    <form-generic-serializer
                        ref="Modal_AddRequiredExam_Form"
                        :object="examToAdd"
                        apiURL='/api/v1/work-areas/exames/'
                        formValidationSchema="addRequiredExamSchema"
                        @schemaIsValidChanged="formEvent_WA_AddRequiredExam"
                    />
                </section>
                <footer class="modal-card-foot">
                    <div class="buttons"> <!-----------------------------------------------------------Make buttons centered -->
                        <button class="button is-danger" @click="closeGenericExamModal()">Cancelar</button>
                        <button class="button is-success" @click="submitExamForm()">Confirmar</button>
                    </div>
                </footer>
            </div>
        </div>
        <!-- MODAL - EDIT EXAM -->
        <div class="modal" :class="{ 'is-active': isExamModalActive('Modal_EditExam') }" v-if="isExamModalActive('Modal_EditExam')">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Editar Exame Exigido</p>
                    <button class="delete" aria-label="close" @click="closeGenericExamModal()"></button>
                </header>
                <section class="modal-card-body">
                    <!-- {{examModalInputData}} -->
                    <edit-generic-serializer
                        :object="examModalInputData"
                        apiURL='/api/v1/work-areas/exames/'
                        submitMethod = 'put'
                        formValidationSchema = 'addRequiredExamSchema' 
                        @closeEditModal="closeGenericExamModal()"
                        @closeEditModalWithReload="closeGenericExamModal()"
                        />
                </section>
            </div>
        </div>
        <!-- MODAL - DELETE EXAM -->
        <div class="modal" :class="{ 'is-active': isExamModalActive('Modal_DeleteExam') }" v-if="isExamModalActive('Modal_DeleteExam')">
            <div class="modal-background"></div>
            <div class="modal-card" id="ExamDeleteModalCard">
                <header class="modal-card-head">
                    <p class="modal-card-title">Deletar Exame</p>
                    <button class="delete" aria-label="close" @click="closeGenericExamModal()"></button>
                </header>
                <section class="modal-card-body" style="modal-content-width:1200px">
                    <ExamDeleteComponent
                    :exam="examModalInputData"
                    :examId="examModalInputID"
                    :modalIsActive="isExamModalActive('Modal_DeleteExam')"
                    @closeDeleteModal="closeGenericExamModal()"
                    />
                </section>
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'

import DataTable from '@/components/dataTable/DataTable.vue'
import FormGenericSerializer from '../../components/FormGenericSerializer.vue'
import EditGenericSerializer from '../../components/EditGenericSerializer.vue'
import ExamDeleteComponent from '../../components/viewComponents/ExamDelete.vue'
import axios from 'axios'
import { toast } from 'bulma-toast'
import { toRaw } from '@vue/reactivity'

import ControlledButton from '@/components/ControlledButton.vue'
import {requiredTiers} from '@/utilities/requiredTiers.js'

export default {
    name: 'WorkAreaDetailsComponent',
    props: ['workArea','workAreaId']
    ,
    components: {
        DataTable,
        FormGenericSerializer, EditGenericSerializer,
        ExamDeleteComponent,
        ControlledButton,
    },
    data() {
        return {
            companyInner: '',
            tableAPIParams: {
                'workAreaId': this.innerID
            },
            listaExames: [],
            examToAdd: {},
            examToAdd_HasValidSchema: false,
            examModalIsActive: false,
            activeExamModal: '',
            examModalInputData: {},
            examModalInputID: 0,
            employeePastExamsDataTableKey: 0,
            requiredTiers: requiredTiers,
        }
    },
    computed: {
            innerID() {
                return this.workAreaId
            }
    },
    created() {
        this.getExames(this.workAreaId)
    },
    methods: {
        getExames(waID) {
            this.listaExames = []
            // console.log('DataTable_getData being called with apiURL||params:',this.apiURL,'||',{ params: this.apiParams })
            axios
                .get('/api/v1/work-areas/exames/', { params: {'workAreaId':waID, 'simple':true} })
                .then(response=> {
                    this.listaExames = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                });
        },
        activateExamModal(waID) {
            // console.log('open Exam modal, with ID',waID)
            this.examModalIsActive = !this.examModalIsActive
        },
        closeExamModal() {
            this.getExames(this.workAreaId)
            this.examModalIsActive = !this.examModalIsActive
        },
        activateModalExamDelete(data) {
            this.genericActivateExamModal('Modal_DeleteExam',data)
            this.examModalInputData['workArea']=this.workArea
        },
        activateModalExamEdit(data) {
            // this.genericActivateExamModal('Modal_EditExam',data) <- Before fix to header in english
            const rawData = toRaw(data)
            rawData['Exame'] = rawData['examIndex']
            rawData['PeríodoRenovação'] = rawData['PeríodoRenovação']
            this.examModalInputData = rawData
            this.ExamModalInputID = rawData.pk
            this.activeExamModal = 'Modal_EditExam'

        },
        closeGenericExamModal() {
            setTimeout(()=>{
                this.employeePastExamsDataTableKey++
                this.activeExamModal = ''
            },20)
            
            
            
        },
        isExamModalActive(modalName) {
            return this.activeExamModal === modalName
        },
        formEvent_WA_AddRequiredExam(data) {
            this.examToAdd_HasValidSchema = data
        },
        genericActivateExamModal(modalName, data='') {
            // console.log('WorkAreaDetails_genericActivateExamModal Called for modal:', modalName, ' and data:',data)
            const rawData = toRaw(data)
            if (data!='') {
                // console.log('activateModal data recognised. Received data:',data)
                this.examModalInputData = rawData
                // console.log('modalInputData:',this.examModalInputData)
                this.ExamModalInputID = rawData.pk
                // console.log('modalInputID:',this.examModalInputID)
            }
            this.activeExamModal = modalName
        },
        pageReload() {
            window.location.reload()
        },
        submitExamForm() {
            this.examToAdd['workArea'] = this.workAreaId
            if (this.examToAdd_HasValidSchema) {
                axios
                    .post('/api/v1/work-areas/exames/', this.examToAdd)
                    .then(response => {
                        // this.$router.push('/organizacao')
                        // this.companies.unshift(response.data)
                        // console.log('response from submitExamForm:',response.data)
                        // this.workArea[]
                        this.$refs.dataTableGerenciarExames.table.rows.unshift(response.data)
                    })
                    .catch(error => {
                        // console.log(JSON.stringify(error))
                        console.log(error.request.response)
                    })
                    .then( () => { 
                        this.closeGenericExamModal()
                    })
            }
            else {
                this.$refs.Modal_AddRequiredExam_Form.$refs.form.validate()
                toast({
                    position:'bottom-right',
                    message: 'Favor preencher os campos de acordo com os requisitos.',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000
                })
            }
        },

    }

}
</script>

<style scoped>
#box-detalhes {
    height: 100%;
}
#box-exames {
    height: 100%;
}

#manageExamsModalCard {
    width:75%
}
.modal-background {
    position: inherit;
    z-index: 10;
}
.modal-card {
    position: inherit;
    z-index: 100;
    width: 65%;
}
</style>