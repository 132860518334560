<template>
    <div class="wrapper">
        <div class="page-organizacao">
            <!-- <nav class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                    <li><router-link to="/dashboard">Dashboard</router-link></li>
                    <li class="is-active"><router-link to="/dashboard/clients" aria-current="true">Clients</router-link></li>
                </ul>
            </nav> -->
            <h1 class="title" style="margin-bottom:24px">Gerenciar Organização</h1>
            <div class="box pt-0 mt-0">
                <!-- TAB HEADERS -->
                <div class="tabs is-boxed is-centered is-fullwidth is-medium" id="tabs">
                    <ul>
                        <!-- <li :class="{ 'is-active': isTabActive('Vizualizacao') }" @click="setActiveTab('Vizualizacao')"><a>Vizualização Geral</a></li> -->
                        <li :class="{ 'is-active': isTabActive('Companhias') }" @click="setActiveTab('Companhias')"><a>Companhias</a></li>
                        <li :class="{ 'is-active': isTabActive('UnidadesDeNegocio') }" @click="setActiveTab('UnidadesDeNegocio')"><a>Unidades de Negócio</a></li>
                        <li :class="{ 'is-active': isTabActive('WorkAreas') }" @click="setActiveTab('WorkAreas')"><a>Work Areas</a></li>
                    </ul>
                </div>
                <!-- TAB ESTRUTURA ORGANIZACIONAL -->
                <div class="columns is-multiline" v-if="isTabActive('Vizualizacao')">
                    <div class="column is-6">
                        <h1 class="subtitle is-4"><strong>Estrutura Organizacional</strong></h1>
                    </div>
                    <div class="column is-6">
                        <!-- Button to activate zoomIn -->
                        <button class="button is-info is-pulled-right " @click="zoomIn()">
                            <span class="icon is-small">
                                <i class="fas fa-search-plus"></i>
                            </span>
                        </button>
                        <!-- Button to activate zoomRestore -->
                        <button class="button is-info is-pulled-right" @click="zoomRestore">
                            <span class="icon is-small">
                                <i class="fas fa-search"></i>
                            </span>
                        </button>
                        <!-- button to activate zoomOut -->
                        <button class="button is-info is-pulled-right" @click="zoomOut">
                            <span class="icon is-small">
                                <i class="fas fa-search-minus"></i>
                            </span>
                        </button>
                    </div>
                    <div class="column is-12">
                        <vue-tree
                        ref="tree"
                        style="width: full; height: 600px; border: 1px solid gray"
                        :dataset="treeData"
                        :config="treeConfig"
                        linkStyle="straight"
                        >
                            <template v-slot:node="{ node, collapsed }">
                                <div
                                    class="box"
                                    :style="{ border: collapsed ? '2px solid blue' : '1px solid grey' }"
                                >
                                    <span style="padding: 4px 0; font-weight: bold;">
                                        <p class="has-text-centered">{{node.name}}</p>
                                        <p class="has-text-centered"><i class="fa-solid fa-users" aria-hidden="true"></i> {{node.numberOfEmployees}}</p> 
                                    </span>
                                </div>
                            </template>
                        </vue-tree>
                    </div>
                </div>
                <!-- TAB COMPANHIAS -->
                <div class="columns is-multiline" v-show="isTabActive('Companhias')">
                    <div class="column is-6">
                        <h1 class="subtitle is-4"><strong>Gerenciar Companhias Registradas</strong></h1>
                    </div>
                    <div class="column is-6">
                        <ControlledButton :requiredTier="requiredTiers.organizacao_adicionar_companhia">
                            <button class="button is-info is-centered strong" @click="activateModal('Modal_CompanyAdd')">
                                <strong> Adicionar Companhia </strong>
                            </button>
                        </ControlledButton>
                        <!-- <button class="button is-info is-centered strong" @click="activateModal('Modal_CompanyAdd')">
                            <strong> Adicionar Companhia </strong>
                        </button> -->
                    </div>
                    <div class="column is-12">
                        <DataTable
                            apiURL="/api/v1/companies/"
                            ref="dataTableRef"
                            :apiParams="dataTableAPIParams"
                            :hasActionsColumn="true"
                            :hasSearchField="false"
                            :fieldsToHide="['pk', 'corporateGroup']"
                            :key="companiesDTKey"
                            @toggleDeleteModal="activateModalCompanyDelete"
                            @toggleDetailsModal="activateModalCompanyDetails"
                            @toggleEditModal="activateModalCompanyEdit"
                        />
                    </div>
                </div>
                <!-- TAB UNIDADES DE NEGOCIO -->
                <div class="columns is-multiline" v-show="isTabActive('UnidadesDeNegocio')">
                    <div class="column is-6">
                        <h1 class="subtitle is-4"><strong>Gerenciar Unidades de Negócio Registradas</strong></h1>
                    </div>
                    <div class="column is-6">
                        <ControlledButton :requiredTier="requiredTiers.organizacao_adicionar_unidade_negocio">
                            <button class="button is-info is-centered strong" @click="activateModal('Modal_BUAdd')">
                                <strong> Adicionar Unid. de Negócio </strong>
                            </button>
                        </ControlledButton>
                    </div>
                    <div class="column is-12">
                        <DataTable
                            apiURL="/api/v1/bus/"
                            :apiParams="dataTableAPIParams"
                            ref="dataTableBUsRef"
                            :hasActionsColumn="true"
                            :fieldsToHide="['pk','CompanhiaPK']"
                            :key="businessUnitsDTKey"
                            :hasSearchField="true"
                            @toggleDeleteModal="activateModalBusinessUnitDelete"
                            @toggleDetailsModal="activateModalBusinessUnitDetails"
                            @toggleEditModal="activateModalBusinessUnitEdit"
                        />
                    </div>
                </div>
                <!-- TAB WORK AREAS -->
                <div class="columns is-multiline" v-show="isTabActive('WorkAreas')">
                    <div class="column is-6">
                        <h1 class="subtitle is-4"><strong>Gerenciar Work Areas</strong></h1>
                    </div>
                    <div class="column is-6">
                        <ControlledButton :requiredTier="requiredTiers.organizacao_adicionar_workarea">
                            <button class="button is-info is-centered strong" @click="activateModal('Modal_WorkAreaAdd')">
                                <strong> Adicionar Work Area </strong>
                            </button>
                        </ControlledButton>
                    </div>
                    <div class="column is-12">
                        <DataTable
                            apiURL="/api/v1/work-areas/"
                            :apiParams="dataTableAPIParams"
                            ref="dataTableWAsRef"
                            :hasSearchField="true"
                            :hasActionsColumn="true"
                            :fieldsToHide="['pk','Municipio', 'MunicipioPK', 'UnidadePK']"
                            :key="workAreasDTKey"
                            @toggleDeleteModal="activateModalWorkAreaDelete"
                            @toggleDetailsModal="activateModalWorkAreaDetails"
                            @toggleEditModal="activateModalWorkAreaEdit"
                        />
                    </div>
                </div>
            </div>
        </div>
        <!-- MODAL - ADD COMPANY -->
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_CompanyAdd') }">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Adicionar Companhia</p>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                </header>
                <section class="modal-card-body">
                    <form-generic-serializer
                        ref="Modal_AddCompany_Form"
                        :object="company"
                        apiURL='/api/v1/companies/'
                        formValidationSchema="addCompanySchema"
                        @schemaIsValidChanged="formEvent_Company"
                    />
                </section>
                <footer class="modal-card-foot">
                    <div class="buttons"> <!-----------------------------------------------------------Make buttons centered -->
                        <button class="button is-danger" @click="closeModal()">Cancelar</button>
                        <button class="button is-success" @click="submitCompanyForm">Confirmar</button>
                    </div>
                </footer>
            </div>
        </div>
        <!-- MODAL - COMPANY DETAILS -->
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_CompanyDetails') }" v-if="isModalActive('Modal_CompanyDetails')">
            <div class="modal-background"></div>
            <div class="modal-card" id="companyDetailModalCard">
                <header class="modal-card-head">
                    <p class="modal-card-title">Detalhes da Companhia</p>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                </header>
                <section class="modal-card-body" style="modal-content-width:1200px">
                    <CompanyDetailsComponent
                    :company="modalInputData"
                    :companyId="modalInputID"
                    />
                </section>
                <footer class="modal-card-foot">
                    <div class="buttons"> <!-----------------------------------------------------------Make buttons centered -->
                        <button class="button is-info" @click="closeModal()">Concluir</button>
                    </div>
                </footer>
            </div>
        </div>
        <!-- MODAL - EDIT COMPANY -->
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_CompanyEdit') }" v-if="isModalActive('Modal_CompanyEdit')">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Editar Companhia</p>
                    <button class="delete" aria-label="close" @click="closeModal_EditCompany()"></button>
                </header>
                <section class="modal-card-body">
                    <!-- <DetailedEditGenericForm
                        :object="modalInputData"
                        apiURL='/api/v1/companies/'
                        submitMethod = 'put'
                        :getData="true"
                        :getDataURL="('/api/v1/companies/'+modalInputData['pk']+'/')"
                        @closeEditModal="closeModal()"
                        @closeEditModalWithReload="reloadPage()"
                    /> -->
                    <edit-generic-serializer
                        :object="modalInputData"
                        apiURL='/api/v1/companies/'
                        submitMethod = 'patch'
                        formValidationSchema = 'companyEditSchema' 
                        @closeEditModal="closeModal_EditCompany()"
                        @closeEditModalWithReload="closeModal_EditCompany()"
                    />
                </section>
            </div>
        </div>
        <!-- MODAL - DELETE COMPANY -->
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_CompanyDelete') }" v-if="isModalActive('Modal_CompanyDelete')">
            <div class="modal-background"></div>
            <div class="modal-card" id="companyDeleteModalCard">
                <header class="modal-card-head">
                    <p class="modal-card-title">Deletar Companhia</p>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                </header>
                <section class="modal-card-body" style="modal-content-width:1200px">
                    <CompanyDeleteComponent
                    :company="modalInputData"
                    :companyId="modalInputID"
                    :modalIsActive="isModalActive('Modal_CompanyDelete')"
                    @closeDeleteModal="closeDeleteModal()"
                    @closeDeleteModalWithReload="closeModal_EditCompany()"
                    />
                </section>
            </div>
        </div>
        <!-- MODAL - ADD Business Unit -->
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_BUAdd') }" v-if="isModalActive('Modal_BUAdd')">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Adicionar Unidade de Negócio</p>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                </header>
                <section class="modal-card-body">
                    <form-generic-serializer
                        ref="Modal_AddBusinessUnit_Form"
                        :object="businessUnit"
                        apiURL='/api/v1/bus/'
                        formValidationSchema="addBusinessUnitSchema"
                        @schemaIsValidChanged="formEvent_BusinessUnit"
                    />
                </section>
                <footer class="modal-card-foot">
                    <div class="buttons"> <!-----------------------------------------------------------Make buttons centered -->
                        <button class="button is-danger" @click="closeModal()">Cancelar</button>
                        <button class="button is-success" @click="submitBUForm()">Confirmar</button>
                    </div>
                </footer>
            </div>
        </div>
        <!-- MODAL - BUSINESS UNIT DETAILS -->
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_BusinessUnitDetails') }" v-if="isModalActive('Modal_BusinessUnitDetails')">
            <div class="modal-background"></div>
            <div class="modal-card" id="businessUnitDetailModalCard">
                <header class="modal-card-head">
                    <p class="modal-card-title">Detalhes da Unidade de Negócio</p>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                </header>
                <section class="modal-card-body" style="modal-content-width:1200px">
                    <BusinessUnitDetailsComponent
                    :businessUnit="modalInputData"
                    :businessUnitId="modalInputID"
                    />
                </section>
                <footer class="modal-card-foot">
                    <div class="buttons"> <!-----------------------------------------------------------Make buttons centered -->
                        <button class="button is-info" @click="closeModal()">Concluir</button>
                    </div>
                </footer>
            </div>
        </div>
        <!-- MODAL - EDIT BUSINESS UNIT -->
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_BusinessUnitEdit') }" v-if="isModalActive('Modal_BusinessUnitEdit')">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Editar Unidade de Negócio</p>
                    <button class="delete" aria-label="close" @click="closeModal_EditBusinessUnit()"></button>
                </header>
                <section class="modal-card-body">
                    <edit-generic-serializer
                        :object="modalInputData"
                        apiURL='/api/v1/bus/'
                        submitMethod = 'put'
                        formValidationSchema = 'businessUnitEditSchema' 
                        @closeEditModal="closeModal_EditBusinessUnit()"
                        @closeEditModalWithReload="closeModal_EditBusinessUnit()"
                        />
                </section>
            </div>
        </div>
        <!-- MODAL - DELETE Business Unit -->
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_BusinessUnitDelete') }" v-if="isModalActive('Modal_BusinessUnitDelete')">
            <div class="modal-background"></div>
            <div class="modal-card" id="BusinessUnitDeleteModalCard">
                <header class="modal-card-head">
                    <p class="modal-card-title">Deletar Unidade de Negócio</p>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                </header>
                <section class="modal-card-body" style="modal-content-width:1200px">
                    <BusinessUnitDeleteComponent
                    :businessUnit="modalInputData"
                    :businessUnitId="modalInputID"
                    :modalIsActive="isModalActive('Modal_BusinessUnitDelete')"
                    @closeDeleteModal="closeModal()"
                    @closeDeleteModalWithReload="closeModal_EditBusinessUnit()"
                    />
                </section>
            </div>
        </div>
        <!-- MODAL - ADD WorkArea -->
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_WorkAreaAdd') }" v-if="isModalActive('Modal_WorkAreaAdd')">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Adicionar Work Area</p>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                </header>
                <section class="modal-card-body">
                    <form-generic-serializer 
                        ref="Modal_AddWorkArea_Form"
                        :object="workArea"
                        apiURL='/api/v1/work-areas/'
                        formValidationSchema="addWorkAreaSchema"
                        @schemaIsValidChanged="formEvent_WorkArea"
                    />
                </section>
                <footer class="modal-card-foot">
                    <div class="buttons"> <!-----------------------------------------------------------Make buttons centered -->
                        <button class="button is-danger" @click="closeModal()">Cancelar</button>
                        <button class="button is-success" @click="submitWAForm()">Confirmar</button>
                    </div>
                </footer>
            </div>
        </div>
        <!-- MODAL - WORK AREA DETAILS -->
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_WorkAreaDetails') }" v-if="isModalActive('Modal_WorkAreaDetails')">
            <div class="modal-background"></div>
            <div class="modal-card" id="workAreaDetailModalCard">
                <header class="modal-card-head">
                    <p class="modal-card-title">Detalhes da Work Area</p>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                </header>
                <section class="modal-card-body" style="modal-content-width:1200px">
                    <WorkAreaDetailsComponent
                    :workArea="modalInputData"
                    :workAreaId="modalInputID"
                    />
                </section>
                <footer class="modal-card-foot">
                    <div class="buttons"> <!-----------------------------------------------------------Make buttons centered -->
                        <button class="button is-info" @click="closeModal()">Concluir</button>
                    </div>
                </footer>
            </div>
        </div>
        <!-- MODAL - EDIT WORK AREA -->
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_WorkAreaEdit') }" v-if="isModalActive('Modal_WorkAreaEdit')">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Editar Work Area</p>
                    <button class="delete" aria-label="close" @click="closeModal_EditWorkArea()"></button>
                </header>
                <section class="modal-card-body">
                        <!-- :object="
                        {
                            'pk': modalInputData.pk,
                            'Companhia': modalInputData.Companhia,
                            'Unidade': modalInputData.Unidade,
                            'Nome': modalInputData.WorkArea,
                            'Código': modalInputData['Cód.'],
                            'Exames': modalInputData.Exames,
                            'Func.': modalInputData['Func.'],
                            'Dt.Reg.': modalInputData['Dt.Reg.'],
                        }"  -->
                    <edit-generic-serializer
                        :object="modalInputData"
                        apiURL='/api/v1/work-areas/'
                        submitMethod = 'put'
                        formValidationSchema = 'workAreaEditSchema'
                        @closeEditModal="closeModal_EditWorkArea()"
                        @closeEditModalWithReload="closeModal_EditWorkArea()"
                        />
                </section>
            </div>
        </div>
        <!-- MODAL - DELETE WORK AREA -->
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_WorkAreaDelete') }" v-if="isModalActive('Modal_WorkAreaDelete')">
            <div class="modal-background"></div>
            <div class="modal-card" id="WorkAreaDeleteModalCard">
                <header class="modal-card-head">
                    <p class="modal-card-title">Deletar Work Area</p>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                </header>
                <section class="modal-card-body" style="modal-content-width:1200px">
                    <WorkAreaDeleteComponent
                    :workArea="modalInputData"
                    :workAreaId="modalInputID"
                    :modalIsActive="isModalActive('Modal_WorkAreaDelete')"
                    @closeDeleteModal="closeModal()"
                    @closeDeleteModalWithReload="closeModal_EditWorkArea()"
                    />
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import DataTable from '@/components/dataTable/DataTable.vue'
import FormGenericSerializer from '../../components/FormGenericSerializer.vue'
import EditGenericSerializer from '../../components/EditGenericSerializer.vue'
// import DetailedEditGenericForm from '../../components/DetailedEditGenericForm.vue'
import CompanyDetailsComponent from '@/components/viewComponents/CompanyDetails.vue'
import CompanyDeleteComponent from '@/components/viewComponents/CompanyDelete.vue'
import BusinessUnitDetailsComponent from '@/components/viewComponents/BusinessUnitDetails.vue'
import BusinessUnitDeleteComponent from '@/components/viewComponents/BusinessUnitDelete.vue'
import WorkAreaDetailsComponent from '@/components/viewComponents/WorkAreaDetails.vue'
import WorkAreaDeleteComponent from '@/components/viewComponents/WorkAreaDelete.vue'

import ControlledButton from '@/components/ControlledButton.vue'
import {requiredTiers} from '@/utilities/requiredTiers.js'

import { toRaw } from '@vue/reactivity'
import VueTree from "@ssthouse/vue3-tree-chart"
import { toast } from 'bulma-toast'
import "@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css"


export default {
    name: 'Organizacao',
    components: {
        DataTable,
        VueTree,
        FormGenericSerializer, EditGenericSerializer, //DetailedEditGenericForm,
        CompanyDetailsComponent, CompanyDeleteComponent,
        BusinessUnitDetailsComponent, BusinessUnitDeleteComponent,
        WorkAreaDetailsComponent, WorkAreaDeleteComponent,
        ControlledButton, requiredTiers
    },
    data() {
        return {
            activeTab: 'Companhias',
            activeModal: '',
            addCompanyModalIsActive: false,
            businessUnit: {},
            businessUnit_HasValidSchema: false,
            workArea: {},
            workArea_HasValidSchema: false,
            companies: [],
            companiesDTKey: 0,
            businessUnitsDTKey: 10,
            workAreasDTKey: 100,
            company: {},
            company_HasValidSchema: false,
            dataTableAPIParams: {},
            modalInputData: {},
            modalInputID: 0,
            requiredTiers: requiredTiers,
            treeData: {
                value: "1",
                children: [
                    { value: "2", children: [
                        { value: "4" },
                        { value: "5" }
                        ]},
                    { value: "3" },
                ],
            },
            treeConfig: { nodeWidth: 150, nodeHeight: 80, levelHeight: 160 },
        }
    },
    mounted() {
        // this.getTreeData()
    },
    methods: {
        formEvent_BusinessUnit(data) {
            this.businessUnit_HasValidSchema = data
        },
        formEvent_Company(data) {
            // console.log('formEvent_Company Received',data)
            this.company_HasValidSchema = data
        },
        formEvent_WorkArea(data) {
            this.workArea_HasValidSchema = data
        },
        getTreeData() {
            axios.get('/api/v1/corporate/organisation/orgChartData/')
            .then(response => {
                this.treeData = response.data
            })
            .catch(error => {
                console.log(error)
            })
        },
        zoomIn() {
            this.$refs.tree.zoomIn()
        },
        zoomOut() {
            this.$refs.tree.zoomOut()
        },
        zoomRestore() {
            this.$refs.tree.restoreScale()
        },
        submitCompanyForm() {
            if (this.company_HasValidSchema) {
                axios
                    .post('/api/v1/companies/', this.company)
                    .then(response => {
                        this.$router.push('/organizacao')
                        // this.companies.unshift(response.data)
                        this.$refs.dataTableRef.table.rows.unshift(response.data)
                    })
                    .catch(error => {
                        // console.log(JSON.stringify(error))
                        console.log(error.request.response)
                    })
                    .then( () => { 
                        this.closeModal_EditCompany()
                    })
            }
            else {
                this.$refs.Modal_AddCompany_Form.$refs.form.validate()
                toast({
                    position:'bottom-right',
                    message: 'Favor preencher os campos de acordo com os requisitos.',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000
                })
            }
        },
        submitBUForm() {
            if (this.businessUnit_HasValidSchema) {
                axios
                    .post('/api/v1/bus/', this.businessUnit)
                    .then(response => {
                        this.$router.push('/organizacao')
                        // console.log(response)
                        // this.businessUnits.unshift(response.data)
                        this.$refs.dataTableBUsRef.table.rows.unshift(response.data)
                    })
                    .catch(error => {
                        // console.log(JSON.stringify(error))
                        console.log(error)
                    })
                    .then( () => { 
                        this.closeModal_EditBusinessUnit()
                    })
            }
            else {
                this.$refs.Modal_AddBusinessUnit_Form.$refs.form.validate()
                toast({
                    position:'bottom-right',
                    message: 'Favor preencher os campos de acordo com os requisitos.',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000
                })
            }
        },
        submitWAForm() {
            if (this.workArea_HasValidSchema) {
                axios
                    .post('/api/v1/work-areas/', this.workArea)
                    .then(response => {
                        this.$router.push('/organizacao')
                        // console.log(response)
                        // this.businessUnits.unshift(response.data)
                        this.$refs.dataTableWAsRef.table.rows.unshift(response.data)
                    })
                    .catch(error => {
                        // console.log(JSON.stringify(error))
                        console.log(error)
                    })
                    .then( () => { 
                        this.closeModal_EditWorkArea()
                    })
            }
            else {
                this.$refs.Modal_AddWorkArea_Form.$refs.form.validate()
                toast({
                    position:'bottom-right',
                    message: 'Favor preencher os campos de acordo com os requisitos.',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000
                })
            }
        },
        isModalActive(modalName) {
            return this.activeModal === modalName
        },
        activateModal(modalName, data='') {
            // console.log('Organizacao_activateModal Called for modal:', modalName, ' and data:',data)
            const rawData = toRaw(data)
            if (data!='') {
                // console.log('activateModal data recognised. Received data:',data)
                this.modalInputData = rawData
                // console.log('modalInputData:',this.modalInputData)
                this.modalInputID = rawData.pk
                // console.log('modalInputID:',this.modalInputID)
            }
            this.activeModal = modalName
            // console.log('activeModal set to:',this.activeModal)
        },
        activateModalBusinessUnitDelete(data) {
            this.activateModal('Modal_BusinessUnitDelete',data)
        },
        activateModalBusinessUnitDetails(data) {
            this.activateModal('Modal_BusinessUnitDetails',data)
        },
        activateModalBusinessUnitEdit(data) {
            this.activateModal('Modal_BusinessUnitEdit',data)
        },
        activateModalCompanyDetails(data){
            this.activateModal('Modal_CompanyDetails',data)
        },
        activateModalCompanyEdit(data){
            this.activateModal('Modal_CompanyEdit',data)
        },
        activateModalCompanyDelete(data){
            this.activateModal('Modal_CompanyDelete',data)
        },
        activateModalWorkAreaDelete(data) {
            this.activateModal('Modal_WorkAreaDelete',data)
        },
        activateModalWorkAreaDetails(data) {
            this.activateModal('Modal_WorkAreaDetails',data)
        },
        activateModalWorkAreaEdit(data) {
            this.activateModal('Modal_WorkAreaEdit',data)
        },
        closeModal() {
            this.activeModal = ''
        },
        closeDeleteModal() {
            setTimeout(() => {
                this.companiesDTKey++
                this.activeModal = ''
            }, 20);
        },
        closeModal_EditCompany() {
            setTimeout(() => {
                this.companiesDTKey++
            }, 20);
            this.closeModal()
        },
        closeModal_EditBusinessUnit() {
            setTimeout(() => {
                this.businessUnitsDTKey++
            }, 20);
            this.closeModal()
        },
        closeModal_EditWorkArea() {
            setTimeout(() => {
                this.workAreasDTKey++
            }, 20);
            this.closeModal()
        },
        isTabActive(tabItem) {
            return this.activeTab === tabItem
        },
        setActiveTab(tabItem) {
            this.activeTab = tabItem
        },
    }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rich-media-node {
  width: 80px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #f7c616;
  border-radius: 4px;
}

#companyDetailModalCard {
    width:75%
}

#companyDeleteModalCard {
    width:75%
}

#businessUnitDetailModalCard {
    width: 75%;
}

#WorkAreaDeleteModalCard {
    width: 75%;
}

#workAreaDetailModalCard {
    width: 75%;
}

#BusinessUnitDeleteModalCard {
    width:75%
}

#tabs {
    margin-left: -20px;
    margin-right: -20px;
}
</style>