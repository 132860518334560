<template>
  <div class="wrapper">
    <!-- {{this.exames_outros.length}}/131 -->
    <div class="columns">
      <div class="column">
        <nav class="panel">
          <p class="panel-heading">
            SELECIONE DENTRE OS EXAMES MAIS COMUNS
          </p>      
          <a class="panel-block is-active" v-for="exame in this.examQueryStore.listaTopExames" :key="exame.codigo" @click="selecionarExame(exame.codigo)">
            <span class="panel-icon">
              <i class="fas fa-book" aria-hidden="true"></i> <!--Nao esta funcionando -->
            </span>  
            {{exame.valor}}
          </a>
        </nav>
        <button class="button is-info is-fullwidth strong" @click="toggleModal">
          <strong> OU CLIQUE AQUI PARA BUSCAR UM EXAME NÃO LISTADO </strong>
        </button>
      </div>
      <div class="column">
        <nav class="panel">
          <p class="panel-heading">
            EXAMES SELECIONADOS
          </p>
          <a class="panel-block is-active" v-for="selecao in this.examQueryStore.examesDemandados" :key="selecao.codigo" @click="deSelecionarExame(selecao.codigo)">
            <span class="panel-icon">
              <i class="fas fa-book" aria-hidden="true"></i> <!--Nao esta funcionando -->
            </span>
            {{selecao.valor}}
          </a>
          <a class="panel-block" v-for="filler in panelFiller" :key="filler">
            <span class="panel-icon">
              <i class="fas fa-book" aria-hidden="true"></i> <!--Nao esta funcionando -->
            </span>
          </a>
        </nav>
      </div>
    </div>
    <!-- MODAL - EXAMES NAO LISTADOS -->
    <div class="modal" :class="{ 'is-active': modalIsActive }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Busca de Exames</p>
          <button class="delete" aria-label="close" @click="toggleModal"></button>
        </header>
        <section class="modal-card-body">
          <a class="panel-block is-active" v-for="exame_outro in exames_outros" :key="exame_outro.codigo" @click="selecionarExameOutros(exame_outro.codigo)">
            <span class="panel-icon">
              <i class="fas fa-book" aria-hidden="true"></i> <!--Nao esta funcionando -->
            </span>  
            {{exame_outro.valor}}
          </a>
        </section>
        <footer class="modal-card-foot">
          <div class="buttons is-right"> <!-----------------------------------------------------------Make it align to the right -->
            <button class="button is-success" @click="toggleModal">Confirmar</button>
          </div>
        </footer>
      </div>
    </div>
  </div>
  
</template>

<script>
import {useExamSearch} from '@/stores/examSearch.js'
import axios from 'axios'

export default {
  name: 'MultiSelectorWithModal',
  props: {
  },
  setup() {
    const examQueryStore = useExamSearch()
    return {examQueryStore}
  },
  data() {
    return {
      listaTopExames: this.examQueryStore.listaTopExames,
      exames_top: [],
      exames_outros: [],
      examesSelecionados: [],
      modalIsActive: false
    }
  },
  computed: {
    panelFiller() {
      if ((this.examQueryStore.listaTopExames.length - this.examQueryStore.examesDemandados.length)<(0)) {
        return 0
      }

      return (this.examQueryStore.listaTopExames.length - this.examQueryStore.examesDemandados.length)
    }
  },
  created() {
    this.getExamData()
  },
  methods: {
    getExamData() {
      axios
      .get("/api/v1/exams/getAll")
      .then(response=> {
          let examList = response.data
          for (let i = 0; i < examList.length; i++) {
            if (!examList[i].isTopExam) {
              this.exames_outros.push(examList[i])
            }
            else {
              this.exames_top.push(examList[i])
            }
          }
      })
      .catch(error => {
          console.log(JSON.stringify(error))
      });
    },
    deSelecionarExame(idExame) {
      let clickedExamIndex = null
      for (let i = 0; i < this.examQueryStore.examesDemandados.length; i++) {
        if (this.examQueryStore.examesDemandados[i].codigo === (idExame) ) {
          clickedExamIndex = i
          if (this.examQueryStore.examesDemandados[clickedExamIndex].isTopExam) {
            this.examQueryStore.listaTopExames = this.examQueryStore.listaTopExames.concat(this.examQueryStore.examesDemandados.splice(clickedExamIndex,1))
            this.examQueryStore.listaTopExames.sort((a, b) => {
                let fa = a.valor.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
                    fb = b.valor.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
          }
          else {
            this.exames_outros = this.exames_outros.concat(this.examQueryStore.examesDemandados.splice(clickedExamIndex,1))
            this.exames_outros.sort((a, b) => {
                let fa = a.valor.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
                    fb = b.valor.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
          }
        }
      }
      //this.examQueryStore.setExames(this.examesSelecionados)
    },
    selecionarExame(idExame) {
      let topExamesIndex = null
      for (let i = 0; i < this.examQueryStore.listaTopExames.length; i++) {
        if (this.examQueryStore.listaTopExames[i].codigo === (idExame) ) {
          topExamesIndex = i
          this.examQueryStore.examesDemandados = this.examQueryStore.examesDemandados.concat(this.examQueryStore.listaTopExames.splice(topExamesIndex,1))
          this.examQueryStore.examesDemandados.sort((a, b) => {
              let fa = a.valor.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
                  fb = b.valor.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
              if (fa < fb) {
                  return -1;
              }
              if (fa > fb) {
                  return 1;
              }
              return 0;
          });
        }
      }
      //this.examQueryStore.setExames(this.examesSelecionados)
    },
    selecionarExameOutros(idExame) {
      let outrosExamesIndex = null
      for (let i = 0; i < this.exames_outros.length; i++) {
        if (this.exames_outros[i].codigo === (idExame) ) {
          outrosExamesIndex = i
          this.examQueryStore.examesDemandados = this.examQueryStore.examesDemandados.concat(this.exames_outros.splice(outrosExamesIndex,1))
          this.examQueryStore.examesDemandados.sort((a, b) => {
              let fa = a.valor.toLowerCase(),
                  fb = b.valor.toLowerCase();
              if (fa < fb) {
                  return -1;
              }
              if (fa > fb) {
                  return 1;
              }
              return 0;
          });
        }
      }
    },
    toggleModal() {
      this.modalIsActive = !this.modalIsActive
      // console.log("modalIsActive = ", this.modalIsActive)
    },
  }
}

</script>

<style scoped>
.panel-heading {
  font-weight: 600;
  line-height: 0.4;
  background-color: rgb(232,237, 235);
}
a.panel-block {
  height: 1.4rem;
  /* line-height: 0.88; */
}

.panel {
  margin-bottom: 1rem;
}

.modal-background {
  background-color :rgb(130, 131, 131);
  opacity: 0.7
}
</style>
