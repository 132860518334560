<template>
      <div class="container">
        <!-- {{ availableExam }} -->
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title pb-0 mb-0 has-text-danger has-background-warning has-text-centered"><strong>Atenção!</strong></h1>
                <h2 class="has-text-danger has-background-warning has-text-centered"><strong>A ação que você está prestes a realizar é irreversível.</strong></h2>
            </div>
            <hr>
            <div class="column is-12">
                <div class="box">
                    <div class="content">
                        <h5 class="" style="margin-bottom:0"><strong> Exame:</strong> {{availableExam.Exame}}</h5>
                        <h5 class="" style="margin-top:1rem"><strong> Disponibilizado Inicialmente Em: </strong>{{ availableExam['Disponibilizado Em'] }}</h5>
                        <h5 class="has-text-centered has-text-danger has-background-warning"><strong>Ao excluir este exame, todas as ofertas em uso que o contém serão afetadas.</strong></h5>
                    </div>
                </div>
            </div>

            <div class="column is-12 is-centered">
                <label class="checkbox pb-2">
                    <input type="checkbox" v-model="checkConfirm">
                    Eu entendo que ao deletar o exame acima, as ofertas que o contém serão afetadas, e desejo continuar.
                </label>
                <p class="has-text-danger pb-4" v-show="submitDeclined">Você deve confirmar na caixa acima antes de prosseguir.</p> 
                <div class="buttons"> <!-----------------------------------------------------------Make buttons centered -->
                    <button class="button is-info" @click="$emit('closeDeleteModal')">Cancelar e Retornar</button>
                    <button class="button is-danger is-outlined" @click="submitDelete">Confirmar e Deletar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

// import DataTable from '@/components/dataTable/DataTable.vue'



export default {
    name: 'CompanyDeleteComponent',
    props: ['availableExam','modalIsActive']
    // {
    //     companyId: Integer
    // }
    ,
    components: {
        // DataTable
    },
    data() {
        return {
            businessUnitInner: '',
            tableAPIParams: {
                'companyId': this.businessUnitId
            },
            numeroWorkAreas: 0,
            numeroWorkAreas: 0,
            numeroRelacoesDeTrabalho: 0,
            responseBeforeDelete: {},
            checkConfirm: false,
            submitDeclined: false
        }
    },
    computed: {

    },
    watch: {

    },
    created() {
        // console.log('CACILDA!')
        // console.log('props inside CompanyDetails:',this.companyID)
        // console.log(this.tableAPIParams)
        // this.tableAPIParams.companyId = this.companyId
        this.getDataBeforeDelete()
    },
    methods: {
        async submitDelete() {
            if (this.checkConfirm) {
                // console.log('CompanyDelete_submitDelete called. Axios delete request about to be sent.')
                axios
                    .delete('/api/v1/clinics/available-exams/', { params: {'availableExamPK': this.availableExam.pk} })
                    .then(response => {
                        this.$emit('closeDeleteModalWithReload')
                    })
                    .catch(error => {
                        console.log(error.request.response)
                    })
            }
            else {
                this.submitDeclined = !this.submitDeclined
            }
        },
        getDataBeforeDelete() {
            axios
                .get('/api/v1/clinics/available-exams/', { params: {'availableExamId': this.availableExamId, 'querydependences':true} })
                .then(response => {
                    // console.log('Corporate_BusinessUnitDelete_getDataBeforeDelete: Get response.data:',response.data)
                    this.numeroWorkAreas = response.data.length
                    let numRT = 0
                    for (let i = 0; i < response.data.length; i++) {
                        numRT += response.data[i]['Func.']
                    }
                    this.numeroRelacoesDeTrabalho = numRT
                    this.responseBeforeDelete = response
                    // console.log('CompanyDelete_getDataBeforeDelete called.')
                    // console.log('response:',response)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

}
</script>

<style>

</style>