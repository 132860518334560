<template>
    <div class="page-login">
        <div class="columns">
            <div class="column is-4 is-offset-4">
                <h1 class="title">Registro realizado com sucesso! </h1>
                <h2>Um email foi lhe encaminhado com instruções para realizar a ativação de sua conta.</h2> 

                <hr>
                <p><router-link to="/">Clique aqui</router-link> para retornar à pagina inicial.</p>
                <p><router-link to="/log-in">Ou aqui</router-link> para ir à pagina de Login.</p>
                
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'

export default {
    name: 'SignedUp',
    data() {
        return {

        }
    },
    methods: {
        
    }
}
</script>

<style scoped>
.page-login {
    min-height: 50vh;
}
.columns {
    margin-top: 6rem;
    margin-bottom: auto;
}
</style>