<template>
    <div class="wrapper">
        <div class="page-organizacao">
            <!-- <nav class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                    <li><router-link to="/dashboard">Dashboard</router-link></li>
                    <li class="is-active"><router-link to="/dashboard/clients" aria-current="true">Clients</router-link></li>
                </ul>
            </nav> -->
            <h1 class="title" style="margin-bottom:24px">Gerenciar Time</h1>
            <div class="box pt-0 mt-0">
                <!-- TAB HEADERS -->
                <!-- <div class="tabs is-boxed is-centered is-fullwidth is-medium" id="tabs">
                    <ul>
                        <li :class="{ 'is-active': isTabActive('Visão_Geral') }" @click="setActiveTab('Visão_Geral')"><a>Visão Geral</a></li>
                        <li :class="{ 'is-active': isTabActive('Funcionários') }" @click="setActiveTab('Funcionários')"><a>Funcionários</a></li>
                    </ul>
                </div> -->
                <!-- TAB VISÃO GERAL -->
                <div class="columns is-multiline mt-0 pt-0" v-show="isTabActive('Visão_Geral')">
                    <div class="column is-6">
                        <h1 class="subtitle is-4"><strong>Visão Geral</strong></h1>
                    </div>
                    <div class="column is-6">
                        Column is-6
                    </div>
                    <div class="column is-12">
                        Column is-12
                    </div>
                </div>
                <!-- TAB FUNCIONÁRIOS -->
                <div class="columns is-multiline" v-show="isTabActive('Funcionários')">
                    <div class="column is-6">
                        <h1 class="subtitle is-4"><strong>Funcionários Registrados</strong></h1>
                    </div>
                    <div class="column is-6">
                        <ControlledButton :requiredTier="requiredTiers.time_adicionar_funcionario">
                            <button class="button is-info is-centered strong" @click="activateModal('Modal_EmployeeAdd')">
                                <strong> Adicionar Funcionário </strong>
                            </button>
                        </ControlledButton>
                    </div>
                    <div class="column is-12">
                        <DataTable
                            apiURL="/api/v1/corporate/employments/"
                            :apiParams="{'dataTableAPIParams':0}"
                            ref="employeeDataTableRef"
                            :hasActionsColumn="true"
                            :hasSearchField="true"
                            :fieldsToHide="['pk', 'person_pk','CPF','Data do Registro de Trabalho']"
                            :key="employeesDTKeys"
                            @toggleDeleteModal="activateModalEmployeeDelete"
                            @toggleDetailsModal="activateModalEmployeeDetails"
                            @toggleEditModal="activateModalEmployeeEdit"
                        />
                    </div>
                </div>
            </div>
        </div>
        <!-- MODAL - ADD Employee -->
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_EmployeeAdd') }" v-if="isModalActive('Modal_EmployeeAdd')">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Adicionar Funcionário</p>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                </header>
                <section class="modal-card-body">
                    <form-generic-serializer
                        ref="Modal_AddEmployee_Form"
                        :object="employee"
                        apiURL='/api/v1/corporate/employments/'
                        formValidationSchema="addEmployeeSchema"
                        @schemaIsValidChanged="formEvent_AddEmployee"
                    />
                </section>
                <footer class="modal-card-foot">
                    <div class="buttons"> <!-----------------------------------------------------------Make buttons centered -->
                        <button class="button is-danger" @click="closeModal()">Cancelar</button>
                        <button class="button is-success" @click="submitEmployeeForm()">Confirmar</button>
                    </div>
                </footer>
            </div>
        </div>
        <!-- MODAL - EMPLOYEE DETAILS -->
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_EmployeeDetails') }" v-if="isModalActive('Modal_EmployeeDetails')">
            <div class="modal-background"></div>
            <div class="modal-card" id="employeeDetailModalCard">
                <header class="modal-card-head">
                    <p class="modal-card-title">Detalhes do Funcionário</p>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                </header>
                <section class="modal-card-body" style="modal-content-width:1200px">
                    <EmployeeDetailsComponent
                    :employee="modalInputData"
                    :employeeId="modalInputID"
                    />
                </section>
                <footer class="modal-card-foot">
                    <div class="buttons"> <!-----------------------------------------------------------Make buttons centered -->
                        <button class="button is-info" @click="closeModal()">Concluir</button>
                    </div>
                </footer>
            </div>
        </div>
        <!-- MODAL - EDIT EMPLOYEE -->
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_EmployeeEdit') }" v-if="isModalActive('Modal_EmployeeEdit')">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Editar Funcionário</p>
                    <button class="delete" aria-label="close" @click="closeModal_EditEmployee()"></button>
                </header>
                <section class="modal-card-body">
                    <DetailedEditGenericForm
                        :object="modalInputData"
                        apiURL='/api/v1/corporate/employments/'
                        submitMethod = 'put'
                        :getData="true"
                        getDataURL='/api/v1/corporate/employments/details/'
                        formValidationSchema='employeeEditSchema'
                        @closeEditModal="closeModal_EditEmployee()"
                        @closeEditModalWithReload="closeModal_EditEmployee()"
                        />
                </section>
            </div>
        </div>
        <!-- MODAL - DELETE Employee -->
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_EmployeeDelete') }" v-if="isModalActive('Modal_EmployeeDelete')">
            <div class="modal-background"></div>
            <div class="modal-card" id="EmployeeDeleteModalCard">
                <header class="modal-card-head">
                    <p class="modal-card-title">Deletar Unidade de Negócio</p>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                </header>
                <section class="modal-card-body" style="modal-content-width:1200px">
                    <EmployeeDeleteComponent
                    :employee="modalInputData"
                    :employmentId="modalInputID"
                    @closeDeleteModal="closeModal()"
                    />
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import DataTable from '@/components/dataTable/DataTable.vue'
import FormGenericSerializer from '../../components/FormGenericSerializer.vue'
import EditGenericSerializer from '../../components/EditGenericSerializer.vue'
import DetailedEditGenericForm from '../../components/DetailedEditGenericForm.vue'
import EmployeeDetailsComponent from '../../components/viewComponents/EmployeeDetails.vue'
import EmployeeDeleteComponent from '../../components/viewComponents/EmployeeDelete.vue'
import { toRaw } from '@vue/reactivity'
import { toast } from 'bulma-toast'

import ControlledButton from '@/components/ControlledButton.vue'
import {requiredTiers} from '@/utilities/requiredTiers.js'

export default {
    name: 'Time',
    components: {
        DataTable,
        FormGenericSerializer, EditGenericSerializer, DetailedEditGenericForm,
        EmployeeDetailsComponent, EmployeeDeleteComponent,
        ControlledButton
    },
    data() {
        return {
            activeTab: 'Funcionários',
            activeModal: '',
            dataTableAPIParams: {},
            modalInputData: {},
            modalInputID: 0,
            employee: {EmailCorporativo:'',EmailPessoal:''},
            employee_hasValidSchema: false,
            employeesDTKeys: 0,
            requiredTiers: requiredTiers,
        }
    },
    mounted() {

    },
    methods: {
        activateModal(modalName, data='') {
            // console.log('Time_activateModal Called for modal:', modalName, ' and data:',data)
            const rawData = toRaw(data)
            if (data!='') {
                this.modalInputData = rawData
                // console.log('modalInputData:',this.modalInputData)
                this.modalInputID = rawData.pk
                // console.log('modalInputID:',this.modalInputID)
            }
            this.activeModal = modalName
        },
        activateModalEmployeeDelete(data) {
            this.activateModal('Modal_EmployeeDelete',data)
        },
        activateModalEmployeeDetails(data) {
            // console.log(data)
            this.activateModal('Modal_EmployeeDetails',data)
        },
        activateModalEmployeeEdit(data) {
            this.activateModal('Modal_EmployeeEdit',data)
        },
        closeModal() {
            this.employeesDTKeys++
            this.activeModal = ''
        },
        formEvent_AddEmployee(data) {
            this.employee_hasValidSchema = data
        },
        isModalActive(modalName) {
            return this.activeModal === modalName
        },
        reloadPage() {
            window.location.reload()
        },
        closeModal_EditEmployee() {
            setTimeout(() => {
                this.employeesDTKeys++
            }, 100);
            this.closeModal()
        },
        submitEmployeeForm() {
            if (this.employee_hasValidSchema) {
                axios
                    .post('/api/v1/corporate/employments/', this.employee)
                    .then(response => {
                        this.$router.push('/time')
                        // console.log('submitEmployeeForm Response:',response)
                        // this.businessUnits.unshift(response.data)
                        this.$refs.employeeDataTableRef.table.unfilteredRows.unshift(response.data)
                    })
                    .catch(error => {
                        // console.log(JSON.stringify(error))
                        console.log(error)
                    })
                    .then( () => { 
                        this.closeModal()
                    })
            }
            else {
                this.$refs.Modal_AddEmployee_Form.$refs.form.validate()
                toast({
                    position:'bottom-right',
                    message: 'Favor preencher os campos de acordo com os requisitos.',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000
                })
            }
        },
        isTabActive(tabItem) {
            return this.activeTab === tabItem
        },
        setActiveTab(tabItem) {
            this.activeTab = tabItem
        },
    }
}
</script>

<style scoped>
#employeeDetailModalCard {
    width:75%
}

#EmployeeDeleteModalCard {
    width:75%
}

.columns {
    margin-bottom: 0px;
    margin-top: 0px;
}
/*
#businessUnitDetailModalCard {
    width: 75%;
}

#WorkAreaDeleteModalCard {
    width: 75%;
}

#workAreaDetailModalCard {
    width: 75%;
}

#BusinessUnitDeleteModalCard {
    width:75%
}

#tabs {
    margin-left: -20px;
    margin-right: -20px;
} */
</style>