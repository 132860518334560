<template>
    <div class="card pl-4 pr-4 pt-2 mb-3 pb-4" id="card-wrapper">
        <div class="content is-small">
            <h1>{{titulo}}</h1>
            <hr>
            <div class="box" v-for="(pacote,index) in dataArrayMultiple" :key="index">
                <div class="container-header">
                    <div class="column pt-2">
                        <strong class="title is-6"> Pacote de {{pacote.totalDeExames}} exames em {{pacote.oferta.length}} clínicas </strong>
                    </div>
                    <div class="column pt-1">
                        
                    </div>
                    <div class="column is-flex is-vcentered pt-1" style="margin-left:auto">
                        <strong class="title is-6">Preço Total:</strong>
                    </div>
                    <div class="column is-flex is-vcentered pt-1">
                        <strong class="title is-6">R$ {{pacote.precoTotalPacote.toFixed(2)}}</strong>
                    </div>
                    <div class="chevronmulti is-multiline" @click="toggleLowerBox(index)">
                        <font-awesome-icon v-if="!pacote.detalhesAbertos" :icon="['fas', 'chevron-down']" style='font-size:25px;color:gray' />
                        <font-awesome-icon v-else :icon="['fas', 'chevron-up']" style='font-size:25px;color:gray' />
                    </div>
                </div>
                
                <div class="box-divider">
                    <div>
                        <div v-for="clinica in pacote.oferta" :key="clinica.nome">
                            <div class="box-upper mb-0" > 
                                <div class="column">
                                    <div><strong>{{clinica.markerLabel}}) {{clinica.nome}}</strong> </div>
                                    <div>{{clinica.endereco}}</div>
                                </div>
                                <div class="column is-vcentered">
                                    {{clinica.telefone}}
                                </div>
                                <div class="column is-vcentered">
                                    {{clinica.distancia}}
                                </div>
                                <div class="column is-vcentered has-text-centered">
                                    <strong> R$ {{clinica.totalexames.toFixed(2)}} </strong>
                                </div>
                            </div>

                            <div class="box-lower mb-2 mt-0 pt-0" v-if="pacote.detalhesAbertos">
                                <hr class="mt-0 pt-0 mb-0 pb-0">
                                <hr class="mt-0 pt-0 mb-0 pb-0">
                                <hr class="mt-0 pt-0 mb-0 pb-0">
                                <hr class="mt-0 pt-0 mb-0 pb-0">
                                <div class="column is-vcentered mt-0 pt-0" style="line-height:0px">
                                    <strong>Imagem da Clinica</strong> 
                                </div>
                                <div class="column is-vcentered mt-0 pt-0 has-background-light" style="line-height:0px">
                                    <strong>Horarios de abertura</strong>
                                </div>
                                <div class="column mt-0 pt-0">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Exame</th>
                                                <th class="has-text-centered">SLA</th>
                                                <th class="has-text-right">Preço</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, key, index2) in clinica.exames" :key="index2">
                                                <td >{{key}}</td>
                                                <td class="has-text-centered">{{item.sla}}</td>
                                                <td class="has-text-right">R$ {{item.preco.toFixed(2)}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="column is-vcentered mt-0 pt-0 is-fullwidth" style="line-height:0px" v-if="pacote.detalhesAbertos">
                        <ControlledButton :requiredTier="requiredTiers.card_pacote_clinica_multi">
                            <button class="button is-info" @click="emitOpenModal(pacote)" ><strong>Agendar</strong></button>
                        </ControlledButton>
                        <br>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useExamSearch} from '@/stores/examSearch.js'
import { toRaw } from 'vue'

import ControlledButton from '@/components/ControlledButton.vue'
import {requiredTiers} from '@/utilities/requiredTiers.js'

export default {
    name: 'CardPacoteClinica',
    components: {
        ControlledButton, requiredTiers
    },
    props: {
        titulo: String
    },
    setup() {
      const examQueryStore = useExamSearch()
      return {examQueryStore, ControlledButton, requiredTiers}
    },
    data() {
        return {
            // dataArrayMultiple: [
            //     {
            //         idPacote: 1,
            //         detalhesAbertos: false,
            //         totalDeExames: 6,
            //         precoTotalPacote: "R$ 197,50",
            //         oferta: 
            //         [
            //             {
            //                 nome: "Clinica Dois",
            //                 markerLabel: "B",
            //                 endereco: "Rua Priscila Amaral, 82",
            //                 distancia: "1,9 km",
            //                 telefone: "(17) 3225-2254",
            //                 exames: {
            //                     "Admissional C2": "R$ 38,50",
            //                     "Audiometria Ocupacional C2": "R$ 23,50",
            //                     "Eletrocardiograma (ECG) C2": "R$ 108,50",
            //                 },
            //                 totalexames: "R$ 137,50",
            //             },
            //             {
            //                 nome: "Clinica TRES",
            //                 markerLabel: "C",
            //                 endereco: "Rua Cacilda Gonçalves, 1200",
            //                 distancia: "0,3 km",
            //                 telefone: "(17) 3225-2254",
            //                 exames: {
            //                     "Admissional C3": "R$ 42,50",
            //                     "Audiometria Ocupacional C3": "R$ 31,50",
            //                     "Eletrocardiograma (ECG) C3": "R$ 87,00",
            //                 },
            //                 totalexames: "R$ 197,50",
            //                 open: false,
            //             }
            //         ]
            //     }, 
            //     {
            //         idPacote: 2,
            //         detalhesAbertos: false,
            //         totalDeExames: 6,
            //         precoTotalPacote: "R$ 208,00",
            //         oferta: [
            //             {
            //                 nome: "Clinica UM",
            //                 markerLabel: "A",
            //                 endereco: "Rua Deodoro da Fonseca, 123",
            //                 distancia: "1,2 km",
            //                 telefone: "(17) 3225-2254",
            //                 exames: {
            //                     "Admissional C1": "R$ 36,50",
            //                     "Audiometria Ocupacional C1": "R$ 28,50",
            //                     "Eletrocardiograma (ECG) C1": "R$ 93,50",
            //                 },
            //                 totalexames: "R$ 157,50",
            //             },
            //             {
            //                 nome: "Clinica Dois",
            //                 markerLabel: "B",
            //                 endereco: "Rua Priscila Amaral, 82",
            //                 distancia: "1,9 km",
            //                 telefone: "(17) 3225-2254",
            //                 exames: {
            //                     "Admissional C2": "R$ 38,50",
            //                     "Audiometria Ocupacional C2": "R$ 23,50",
            //                     "Eletrocardiograma (ECG) C2": "R$ 108,50",
            //                 },
            //                 totalexames: "R$ 137,50",
            //             }
            //         ]
            //     }
            // ]
        }
    },
    computed: {
        dataArrayMultiple() {
            return this.examQueryStore.pacotesExamesMultiClinica
        }
    },
    methods: {
        emitOpenModal(object) {
            // console.log('CardPacoteClinicaMulti>emitOpenModal called with object:', toRaw(object))
            this.$emit('openSchedulingModal', toRaw(object))
        },
        toggleLowerBox(index) {
            this.dataArrayMultiple[index].detalhesAbertos = !this.dataArrayMultiple[index].detalhesAbertos
        }
    }
}
</script>

<style scoped>
.column.is-vcentered {
  display: flex;
  flex-wrap: wrap;
  align-content: center; /* used this for multiple child */
  align-items: center; /* if an only child */
}

#card-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* grid-auto-rows: minmax(200px,auto); */
}

.content.is-small {
    margin-bottom: 0rem;
    padding-bottom: 0rem;
}

.box {
    box-shadow:0em;
    background-color: rgb(255, 255, 255);
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(238, 238, 238);
    padding-bottom: 0rem;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 0rem;
}

.container-header {
    background-color: rgb(240, 240, 240);
    height: 2rem;
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 0.2fr;
}

.box-divider {
    display: grid;
    grid-template-columns: 4.75fr 0.35fr;
}

.box-upper {
    box-shadow:0em;
    background-color: rgb(255, 255, 255);
    width: 100%;
    margin-top: 0rem;
    margin-left: 0rem;
    margin-right: 0rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    display: grid;
    grid-template-columns: 2.4fr 1fr 1fr 1fr;
}

.box-lower {
    box-shadow:0em;
    background-color: rgb(255, 255, 255);
    width: 100%;
    margin-left: 0rem;
    margin-right: 0rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr 0.01fr;
}

.chevronmulti {
    display: flex;
    align-items: center;
    
}
    
</style>