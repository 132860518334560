<template>
    <div class="generic-form">
        <!-- Object: {{object}} -->
        <!-- employeeObject: {{employeeObject}} -->
        <!-- schemaIsValid: {{schemaIsValid}} -->
        <!-- getData: {{getData}} -->
        <!-- formFieldsSelect: {{formFieldsSelect}} -->
        <div class="columns is-multiline">
            <div class="column is-2"></div>
            <div class="column is-8">
                <Form ref="form" class="form" :validation-schema="schema">
                    <div v-for="requiredField in formFieldsSelect" :key="requiredField.id">
                        <div class="field" v-if="requiredField.type='select'">
                            <label>{{requiredField.label}} vs. {{requiredField.name}}</label>
                            <!-- Required:{{requiredField.name}} -->
                            <div >
                                <div class="select is-info" >
                                    <!-- {{requiredField.name}} -->
                                    <!-- <select v-model="employeeObject[requiredField.name]"> -->
                                    <Field :name="requiredField.name" as="select" v-model="employeeObject[requiredField.name]">
                                        <option
                                        v-for="option in requiredField.options" :key="option.id"
                                        :value= "option.value"
                                        >
                                            {{option.name}}
                                        </option>
                                    <!-- </select> -->
                                    </Field>
                                </div>
                            </div>
                            <ErrorMessage :name="requiredField.name" style="color:red"/>
                        </div>
                    </div>
                    <div class="field" v-for="field in formFieldsText" :key="field.id">
                        <label>{{field.label}}</label>
                        <!-- V-model:{{employeeObject}} - Field: {{field.name}} -->
                        <div class="control">
                            <!-- <input
                            :type= "field.type"
                            :name= "field.name"
                            class= "input is-info"
                            :placeholder= "field.placeholder"
                            :disabled= "field.disabled"
                            v-model="employeeObject[field.name]"
                            > -->
                            <Field 
                                :name="field.name"
                                as="input"
                                :type= "field.type"
                                class= "input is-info"
                                :placeholder= "field.placeholder"
                                :disabled= "field.disabled"
                                v-model="employeeObject[field.name]"
                            />
                        </div>
                        <ErrorMessage :name="field.name" style="color:red"/>
                    </div>
                </Form>
            </div>
        </div>
        <div class="buttons"> <!-----------------------------------------------------------Make buttons centered -->
            <button class="button is-danger" @click="$emit('closeEditModalWithReload')">Cancelar</button>
            <button class="button is-success" @click="submitEditForm(apiURL)">Confirmar</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toRaw } from '@vue/reactivity'
//Form validation
import { toast } from 'bulma-toast'
import { Field, Form, ErrorMessage } from 'vee-validate';
import { validationSchemaDictionary } from '@/utilities/validations.js'

export default {
    name: 'DetailedEditGenericForm',
    props: {
        object: Object,
        apiURL: String,
        submitMethod: String,
        getDataURL: String,
        formValidationSchema: String,
        getData: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            schema: {},
            formFieldsText: [],
            formFieldsSelect: [],
            objectBackup: toRaw(this.object),
            employeeObject: {},
        }
    },
    computed: {
        schemaIsValid() {
            return validationSchemaDictionary[this.formValidationSchema].isValidSync(this.employeeObject)
        },
    },
    mounted() {
        this.queryFormFields(this.apiURL)
        setTimeout(() => {
            this.schema = validationSchemaDictionary[this.formValidationSchema]
        }, 200) // If the schema loads before the queryFormFields, the reactive property will identify a change and trigger the validation
        // console.log('EditGenericSerializer Mounted cycle')
        if (this.getData) {
            // console.log('if Loop entered')
            this.queryData(this.object.pk)
        }

    },
    methods: {
        queryFormFields(apiURL) {
            axios
                .post(apiURL, 'queryFormFields')
                .then(response => {
                    for (var i = 0; i < response.data.length; i++) {
                        if (response.data[i].type == 'text') {
                            this.formFieldsText.push(response.data[i])
                        } else if (response.data[i].type == 'select') {
                            this.formFieldsSelect.push(response.data[i])
                        }
                    } 
                    // Create dictionary of select fields and their values
                    // for (var i = 0; i < this.formFieldsSelect.length; i++) {
                    //     this.selectFieldValues[this.formFieldsSelect[i].label] = []
                    //     //Loop through the options
                    //     for (var j = 0; j < this.formFieldsSelect[i].options.length; j++) {
                    //         this.selectFieldValues[this.formFieldsSelect[i].label].append(this.formFieldsSelect[i].options[j].value)
                    //     }
                    // }
                })
                .catch(error => {
                    // console.log(JSON.stringify(error))
                    console.log(error.request.response)
                })
        },
        queryData(employeeId) {
            axios
                .get(this.getDataURL, {'params': {'employeeId':employeeId} })
                .then(response => {
                    // console.log('queryData successful. Response.data=',response.data)
                    this.employeeObject = response.data
                    // Loop through the formfieldsselect array
                    for (var i = 0; i < this.formFieldsSelect.length; i++) {
                        
                    }


                })
                .catch(error => {
                    console.log(error.request.response)
                })
        },
        submitEditForm(apiURL) {
            const objectID = this.object.pk
            if (this.schemaIsValid) {
                if (this.submitMethod=='patch') {
                    axios
                        .patch(apiURL+`${objectID}/`, this.employeeObject)
                        .catch(error => {
                            console.log(JSON.stringify(error))
                        })
                } else if (this.submitMethod=='put') {
                    axios
                        .put(apiURL, this.employeeObject)
                        .catch(error => {
                            console.log(JSON.stringify(error))
                        })
                }
                this.$emit('closeEditModal')
            }
            else {
                this.$refs.form.validate()
                toast({
                    position:'bottom-right',
                    message: 'Favor preencher os campos de acordo com os requisitos.',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000
                })
            }
        },
        closeEditModal() {
            this.object = this.objectBackup
            this.$emit('closeEditModal')
        }
    }
}
</script>

<style>

</style>