<template>
  <div id="wrapper" class="">
    <div v-if="!authentication.isAuthenticated"> <!-- If the user is NOT authenticated -->
      <div v-if="routeIsLandingPage">
        <navbar></navbar>
        <section class="section pt-0 pl-0 pr-0 pb-0" id="sectionBelowNavbar">
          <div>
            <router-view/>
          </div>
        </section>
        <Footer></Footer>
      </div>
      <div v-else-if="routeIsSurvey">
        <navbarDeactivated></navbarDeactivated>
        <section class="section pt-0 pl-0 pr-0 pb-0" id="sectionBelowNavbar">
          <div >
            <router-view/>
          </div>
        </section>
      </div>
      <div v-else>
        <navbar></navbar>
        <!-- <h1>Cenario deveria ser limitado pelo router. Erro n. APP1463</h1> -->
        <section class="section pt-0 pl-0 pr-0 pb-0" id="sectionBelowNavbar">
          <div>
            <router-view/>
          </div>
        </section>
        <Footer></Footer>
      </div>
    </div>
    <div v-else> <!-- If the user is authenticated -->
      <div v-if="routeIsLandingPage">
        <navbar></navbar>
        <section class="section pt-0 pl-0 pr-0 pb-0" id="sectionBelowNavbar">
          <div >
            <router-view/>
          </div>
        </section>
        <Footer></Footer>
      </div>
      <div v-else-if="routeIsSurvey">
        <navbarDeactivated></navbarDeactivated>
        <section class="section pt-0 pl-0 pr-0 pb-0" id="sectionBelowNavbar">
          <div >
            <router-view/>
          </div>
        </section>
      </div>
      <div v-else >
        <Sidebar/>
        <div id="content" :style="{ 'margin-left': pageWidth}">
          <section class="section pt-2 pl-4 pr-4 pb-0">
            <div >
              <router-view/>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Sidebar from '@/components/sidebar/Sidebar.vue'
  import navbar from '@/components/landingPage/navbar.vue'
  import navbarDeactivated from '@/components/landingPage/navbarDeactivated.vue'
  import Footer from '@/components/landingPage/Footer.vue'
  import { sidebarWidth } from '@/components/sidebar/state'
  import {useAuthenticationStore} from "@/stores/authentication"
  
  

  export default {
    name: 'App',
    components: {Sidebar, navbar, Footer, navbarDeactivated},
    setup() {
      const authentication = useAuthenticationStore()
      authentication.initializeAuthenticationStore()
      return {sidebarWidth, authentication}
    },
    beforeCreate() {
      // this.$store.commit('initializeStore')
      // const token = this.$store.state.token
      // if (token) {
      //   axios.defaults.headers.common['Authorization'] = "Token " + token
      // } else {
      //   axios.defaults.headers.common['Authorization'] = ""
      // }
      const access = this.authentication.access
      if (access) {
        axios.defaults.headers.common['Authorization'] = "JWT " + access
      } else {
        // axios.defaults.headers.common['Authorization'] = ""
      }
    },
    computed: {
      pageWidth() {
        // if (this.$store.state.isAuthenticated) {
        if (this.authentication.isAuthenticated) {
          return this.sidebarWidth
        } else {
          return 0
        }
      },
      routeIsLandingPage() {
        return this.$route.matched.some(({name}) => name==='Home' || name==='Artigos')
      },
      routeIsSurvey() {
        return this.$route.matched.some(({name}) => name==='Survey')
      }
    }
  }
</script>

<style lang="scss">
@import '../node_modules/bulma';

#sectionBelowNavbar {
  margin-top: 4rem;
}

#foot {
  padding: 2rem 1.5rem 2rem;
  //make the footer stick to the bottom of the content or to the bottom of the screen if the content is shorter than the screen
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #2f4685;
  color: white;
  text-align: center;

  // font-family: 'Roboto', sans-serif;
  // font-size: 1rem;
}

#wrapper {
  background-color: #2f46851d;
}

//format the content div to have full height and width
#content {
  height: 100vh;
}

</style>
