import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import {createPinia} from 'pinia';
import axios from 'axios';
// import "../node_modules/bulma/css/bulma.css";
import VueGoogleMaps from '@fawmi/vue-google-maps';
import FontAwesomeIcon from "@/assets/font-awesome";
import '@fortawesome/fontawesome-free/js/all'
import VueObserveVisibility from 'vue-observe-visibility'




// Configure Axios base URL to the backend gateway
axios.defaults.baseURL= process.env.VUE_APP_AXIOS_ROOT_API
// axios.defaults.baseURL= 'https://daedalus-back-end.herokuapp.com'

let pinia = createPinia()

const app = createApp(App)
const google_api_key = process.env.VUE_APP_GOOGLE_KEY

app
.use(pinia)
.use(router, axios)
.use(VueObserveVisibility)
.use(VueGoogleMaps, {
    load: {
        key: google_api_key,
    },
})
.component("font-awesome-icon", FontAwesomeIcon)
.config.globalProperties.$companyName = 'CliqueMed'

app.mount('#app')

