<template>
	<section class="section is-medium" >
		<div class="container">
			<div class="columns is-multiline is-centered">
				<div class="column is-12 has-text-centered" id="dataStreamTrigger" v-observe-visibility="{callback: dataStream, once: true, throttle: 100}">
					<h1 class="title is-1" id="sectionTitle">Nossos Preços</h1>
				</div>
				<div class="column is-12 has-text-centered">
					<h2 class="subtitle is-2" id="sectionSubTitle">
						Nosso modelo de precificação é 100% transparente. Nós acreditamos em alinhar os nossos interesses com o de nossos clientes, e agindo como uma plataforma, trabalhar para facilitar ao máximo a otimização da operação de nosso principal parceiro: <strong style="color:rgb(0,114,206)">você</strong>.
					</h2>
				</div>
				<div class="column is-4 has-text-centered">
					<div class="card">
						<div class="card-content">
							<div class="content has-text-centered">
								<div class="cardTitle">Plano Empresa</div>
								<div class="cardPrice">R$ 0,50</div>
								<div class="cardFrequency">mensais por colaborador</div>
								<div>+</div>
								<div class="cardPrice">10,0%</div>
								<div class="cardFrequency">do custo dos exames realizados</div>
								<div class="cardText"><strong> A redução de custos média de nossos clientes ante a outros fornecedores é de 26%!</strong></div>
							</div>
							<div class="buttonHolder">
								<router-link :to="{name:'SignUp'}" class="button" id="sideButton">Cadastre sua empresa</router-link>
							</div>
							
						</div>
					</div>
				</div>
				<div class="column is-4 has-text-centered">
					<div class="card" id="middleCard">
						<div class="card-content">
							<div class="content has-text-centered">
								<div class="cardTitle" id="middleWhite">Plano Clínica</div>
								<div class="cardPrice" id="middleWhite">R$ 0,00</div>
								<div class="cardFrequency" id="middleGray">mensais</div>
								<br>
								<br>
								<br>
								<br>
								<div class="cardText" ><strong id="middleWhite"> O uso da plataforma para oferta de exames é gratuito para clínicas!</strong></div>
							</div>
							<div class="buttonHolder">
								<router-link :to="{name:'SignUp'}" class="button" id="middleButton">Cadastre sua Clínica</router-link>
							</div>							
						</div>
					</div>
				</div>
				<div class="column is-4 has-text-centered">
					<div class="card">
						<div class="card-content">
							<div class="content has-text-centered">
								<div class="cardTitle">Plano Colaborador</div>
								<div class="cardPrice">R$ 0,00</div>
								<div class="cardFrequency">mensais</div>
								<br>
								<br>
								<br>
								<br>
								<div class="cardText"><strong> O uso individual da plataforma para acompanhamento de exames é gratuito! </strong></div>
							</div>
							<div class="buttonHolder">
								<router-link :to="{name:'SignUp'}" class="button" id="sideButton">Cadastre-se</router-link>
							</div>							
						</div>
					</div>
				</div>
				<div class="column is-3 has-text-centered"></div>
				<div class="column is-6 has-text-centered" style="margin-top:2rem;margin-bottom:2rem">
					<h2 class="subtitle is-2" id="sectionPostScript">
						Não está pronto para o compromisso? Não se preocupe: todos os nossos planos são mensais, e não há multa por cancelamento.
					</h2>
					<hr>
					<h3 class="subtitle is-2" id="sectionPostScript">
						Quer testar primeiro? Cadastre-se para uma conta de demonstração, e veja nossas funcionalidades em ação!
					</h3>
				</div>
				<div class="column is-3 has-text-centered"></div>
			</div>
		</div>
	</section>
</template>

<script>
import axios from 'axios'

export default {
	name: 'PricingSection',
	components: {
	},
	setup() {
	},
	data () {
	return {
	}
	},
	mounted() {
	},
	methods: {
		dataStream(argument) {
			if (argument) {
				axios
				.get('/api/v1/datastream/', {params: {
					'datastream': 'Landing_Page_Access:Pricing_Section',
					'window': {
						'userAgent': window.navigator.userAgent,
						'innerHeight': window.innerHeight,
						'innerWidth': window.innerWidth,
					}
				}})
				.then(response => {
				})
				.catch(error => {
					console.log(error)
				})
		}},
	},
	computed: {
	}
}
</script>

<style scoped>
.section {
	background-color: white;
	padding-top: 2rem;
	padding-bottom: 2rem;
}
#sectionTitle {
	color: rgb(49,53,62,0.7);
	font-size: 2.2rem;
	font-weight: 800;
}
#sectionSubTitle {
	padding-top: 0rem;
	padding-bottom: 2rem;
	color: rgb(118,134,146);
	font-size: 1.1rem;
	font-weight: 200;
	font-family: sans-serif;
}

.card {
	height: 100%;
	border-style: solid;
	border-radius: 0.5rem;
	border-color: rgb(118,134,146,0.5);
	margin-bottom: 1rem;
	margin-left: auto;
	margin-right: auto;
	background-color: white;
	max-width: 300px;
	min-width: 220px;
}
.card-content {
	height: 100%;
	padding-bottom: 1rem;
	padding-top: 2rem;
	text-align: justify;
}
.content {
	height: 80%;
}

.cardTitle {
	font-size: 1.2rem;
	font-weight: 600;
	color: rgb(35,41,32,0.9);
	padding-bottom: 1rem;
}
.cardPrice {
	font-size: 2rem;
	font-weight: 700;
	color: rgb(35,41,32,0.9);
}
.cardFrequency {
	font-size: 1rem;
	font-weight: 400;
	color: rgb(118,134,146);
}
.cardText {
	margin-top: 1rem;
	font-size: 0.8rem;
	font-weight: 400;
	color: rgb(118,134,146);
}
.buttonHolder {
	text-align:center;
	margin-top: 0rem;
}

#sideButton {
	background-color: rgb(0,114,206);
	color: white;
	font-size: 1rem;
	font-weight: 600;
	border-radius: 0.5rem;
	/* margin-top: 2rem;
	margin-bottom: 2rem; */
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	padding-right: 1rem;
	max-width: 100%;
}

#middleButton{
	background-color: white;
	color: rgb(0,114,206);
	font-size: 1rem;
	font-weight: 600;
	border-radius: 0.5rem;
	/* margin-top: 2rem;
	margin-bottom: 2rem; */
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	padding-right: 1rem;
	max-width: 100%;
}

#middleCard {
	background-color: rgb(0,114,206);
}
#middleWhite {
	color: white;
}
#middleGray {
	color: rgb(168,184,196);
}

#sectionPostScript {
	padding-top: 0rem;
	padding-bottom: 0rem;
	color: rgb(49,53,62,0.7);
	font-size: 1.1rem;
	font-weight: 200;
	font-family: sans-serif;
}
hr {
	margin-top: 0.8rem;
	margin-bottom: 1.2rem;
}

</style>

