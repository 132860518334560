<template>
  <div class="wrapper">
    <div class="page-planejarExames">
      <h1 class="title" style="margin-bottom:24px">Planejar Exames</h1>
      <!-- {{selectedCategory}} -->
      <div class="box pt-0 pb-0">
        <div class="columns is-multiline">
          <div class="column is-6">
            <label class="label is-medium">Selecione a categoria de interesse:</label>
            <div class="select is-link is-small">
              <select v-model="selectedCategory" @change="getEntitiesFromCategory(selectedCategory)">
                <option v-for="option in categoryOptions" v-bind:value="option.text" :key="option.value">
                  {{option.text}}
                </option>
              </select>
            </div>
          </div>
          <div class="column is-6">
            <label class="label is-medium">Selecione a entidade organizacional:</label>
            <!-- {{entityOptions}} -->
            <div class="select is-link is-small">
              <select v-model="selectedEntity" @change="getEntityData(selectedEntity)">
                <option v-for="option in entityOptions" v-bind:value="option.value" :key="option.value" >
                  {{option.text}}
                </option>
              </select>
            </div>
          </div>
          <hr />
          <br>
          <div class="column is-12" >
            <p class="label pl-4 mb-0"> Força de Trabalho </p>
            <div class="box ml-4 mr-6 bordered-box"> 
              <div class="columns is-multiline is-vcentered" >
                <div class="column is-2 is-multiline">
                  <div class="has-text-centered"><i class="fa-solid fa-users fa-2xl" aria-hidden="true"></i></div> 
                  <div class="has-text-centered"><strong># Funcionarios</strong></div> 
                  <div class="has-text-centered" v-if="workForceLoadingStatus!='loaded'"><strong>-</strong></div>
                  <div class="has-text-centered" v-else><strong>{{workForceData.data.workForce.numberOfEmployees}}</strong></div> 
                </div>
                <div class="column is-3 is-multiline">
                  <div class="has-text-centered"><i class="fa-solid fa-person-circle-check fa-2xl" aria-hidden="true"></i></div> 
                  <div class="has-text-centered"><strong># Em dia com exames</strong></div> 
                  <div class="has-text-centered" v-if="workForceLoadingStatus!='loaded'"><strong>-</strong></div>
                  <div class="has-text-centered" v-else><strong>{{workForceData.data.workForce.numberOfEmployees-workForceData.data.workForce.examStatusSummary.Vencido}}/{{workForceData.data.workForce.numberOfEmployees}}</strong></div> 
                </div>
                <div class="column is-7 is-multiline" v-if="workForceLoadingStatus=='loaded'">
                  <Bar
                    :chart-options="{
                      responsive: true,
                      plugins: {
                        title: {
                            display: true,
                            text: 'Status de funcionários e seus exames: Meses até o vencimento'
                        }
                      }
                    }"
                    :chart-data="{
                      labels: ['Vencido','3 meses','3-6 meses','6-9 meses','9-12 meses','>12 meses'],
                      datasets: [{
                        label: '# Funcionários',
                        data: [
                          workForceData.data.workForce.examStatusSummary.Vencido,
                          workForceData.data.workForce.examStatusSummary.within_3months,
                          workForceData.data.workForce.examStatusSummary.between_3_and_6months,
                          workForceData.data.workForce.examStatusSummary.between_6_and_9months,
                          workForceData.data.workForce.examStatusSummary.between_9_and_12months,
                          workForceData.data.workForce.examStatusSummary.over_12months,
                        ],
                        backgroundColor: [
                            'rgba(255, 85, 0, 0.7)',
                            'rgba(255, 153, 51, 0.7)',
                            'rgba(238, 209, 81, 0.7)',
                            'rgba(229, 255, 102, 0.7)',
                            'rgba(190, 255, 102, 0.7)',
                            'rgba(153, 255, 102, 0.7)'
                        ]}
                      ]
                    }"
                    :chart-id="'workforce-bar-chart'"
                    :dataset-id-key="'workforce-label'"
                    :plugins="[]"
                    :css-classes="''"
                    :styles="{}"
                    :width="40"
                    :height="15"
                  />
                </div>
                <div class="column is-7 is-multiline has-text-centered" v-else>
                  -
                </div>
              </div>
            </div>
            <p class="label pl-4 mb-0"> Exames e Compliance </p>
            <div class="box ml-4 mr-6 bordered-box">
              <div class="columns is-multiline is-vcentered">
                <div class="column is-2 is-multiline">
                  <div class="has-text-centered"><i class="fa-solid fa-stethoscope fa-2xl" aria-hidden="true"></i></div> 
                  <div class="has-text-centered"><strong>Exames Projetados</strong></div> 
                  <div class="has-text-centered" v-if="!chartData.loaded"><strong>-</strong></div>
                  <div class="has-text-centered" v-else><strong>{{apiResponse.data.examsAndCompliance.examSummary.aggregatedData.total_number_of_exams_required}}</strong></div> 
                </div>
                <div class="column is-1 is-multiline">
                  <div class="has-text-centered"><i class="fa-solid fa-clipboard-check fa-2xl" aria-hidden="true"></i></div> 
                  <div class="has-text-centered"><strong>Exames Na Rede</strong></div> 
                  <div class="has-text-centered" v-if="!chartData.loaded"><strong>-</strong></div>
                  <div class="has-text-centered" v-else><strong>{{apiResponse.data.examsAndCompliance.examSummary.aggregatedData.total_number_of_exams_mapped}}/{{apiResponse.data.examsAndCompliance.examSummary.aggregatedData.total_number_of_exams_required}}</strong></div> 
                </div>
                <div class="column is-2 is-multiline">
                  <div class="has-text-centered"><i class="fa-solid fa-house-medical-circle-exclamation fa-2xl" aria-hidden="true"></i></div> 
                  <div class="has-text-centered"><strong>Credenciamentos Necessários</strong></div> 
                  <div class="has-text-centered" v-if="!chartData.loaded"><strong>-</strong></div>
                  <div class="has-text-centered" v-else><strong>{{Object.keys(apiResponse.data.examsAndCompliance.examSummary.aggregatedData.cities_lacking_offer).length}} </strong><button class="button is-link is-outlined is-small ml-2" @click="activateModal">+Detalhes</button></div> 
                  
                </div>
                <div class="column is-7 is-multiline" v-if="chartData.loaded">
                  <Bar
                    :chart-options="{
                      responsive: true,
                      scales: {
                        x: {
                          stacked: true,
                        },
                        y: {
                          stacked: true
                        }
                      },
                      plugins: {
                        title: {
                            display: true,
                            text: 'Exames Projetados 12 meses: Disponibilidade na Rede Credenciada Atual'
                        }
                      }
                    }"
                    :chart-data="{
                      labels: apiResponse.data.examsAndCompliance.chartDataTotalExams.dateList,
                      datasets: [{
                        label: 'Exames Disponíveis',
                        data: apiResponse.data.examsAndCompliance.chartDataAvailableExams,
                        backgroundColor: [
                            'rgba(124, 185, 232, 0.7)'
                        ]},
                        {
                        label: 'Exames Indisponíveis',
                        data: apiResponse.data.examsAndCompliance.chartDataMissingExams.dataList,
                        backgroundColor: [
                            'rgba(255, 85, 0, 0.7)'
                        ]}
                      ]
                    }"
                    :chart-id="'exams-bar-chart'"
                    :dataset-id-key="'exam-label'"
                    :plugins="[]"
                    :css-classes="''"
                    :styles="{}"
                    :width="40"
                    :height="15"
                  />
                </div>
                <div class="column is-7 is-multiline has-text-centered" v-else>
                  -
                </div>
                
              </div>
            </div>
            <p class="label pl-4 mb-0"> Orçamento </p>
            <div class="box ml-4 mr-6 bordered-box" >
              <div class="columns is-multiline is-vcentered">
                <div class="column is-2 is-multiline">
                  <div class="has-text-centered"><i class="fa-solid fa-money-check-dollar fa-2xl" aria-hidden="true"></i></div> 
                  <div class="has-text-centered"><strong>Custo Total (12 meses)</strong></div> 
                  <div class="has-text-centered" v-if="!budgetData.loaded"><strong>-</strong></div>
                  <div class="has-text-centered" v-else><strong>{{budgetData.TotalExames}}</strong></div>
                </div>
                <div class="column is-1 is-multiline">
                  <div class="has-text-centered"><i class="fa-solid fa-file-circle-exclamation fa-2xl" aria-hidden="true"></i></div> 
                  <div class="has-text-centered"><strong>Total Atrasado</strong></div> 
                  <div class="has-text-centered" v-if="!budgetData.loaded"><strong>-</strong></div>
                  <div class="has-text-centered" v-else><strong>{{budgetData.TotalAtrasado}}</strong></div> 
                </div>
                <div class="column is-2 is-multiline">
                  <div class="has-text-centered"><i class="fa-solid fa-hand-holding-dollar fa-2xl" aria-hidden="true"></i></div> 
                  <div class="has-text-centered"><strong>Economia Potencial Total</strong></div> 
                  <div class="has-text-centered" v-if="!budgetData.loaded"><strong>-</strong></div>
                  <div class="has-text-centered" v-else><strong>{{budgetData.SobrePreco}}</strong> ({{budgetData.SobrePrecoPercentual}}%)</div> 
                  
                </div>
                <div class="column is-7 is-multiline" v-if="budgetData.loaded">
                    <Bar                      
                      :chart-options="chartOptions"
                      :chart-data="chartData"
                      :chart-id="'bar-chart'"
                      :dataset-id-key="'label'"
                      :plugins="[]"
                      :css-classes="''"
                      :styles="{}"
                      :width="40"
                      :height="15"
                    />                
                </div>
                <div class="column is-7 is-multiline has-text-centered" v-else>
                  -
                </div>                
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL - ONBOARDING REQUIREMENTS (+DETALHES) -->
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_ClinicOnboardingDetails') }" v-if="isModalActive('Modal_ClinicOnboardingDetails')">
            <div class="modal-background"></div>
            <div class="modal-card" id="companyDetailModalCard">
                <header class="modal-card-head">
                    <p class="modal-card-title">Detalhes dos Credenciamentos Necessários</p>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                </header>
                <section class="modal-card-body" style="modal-content-width:1200px">
                    <div class="content">
                      <table class="table is-bordered">
                        <thead>
                          <tr>
                            <th>Município</th>
                            <th>Exame</th>
                          </tr>
                        </thead>
                        <tbody v-for="city in Object.keys(apiResponse.data.examsAndCompliance.examSummary.aggregatedData.cities_lacking_offer)" :key="city">
                          <tr v-for="exam in apiResponse.data.examsAndCompliance.examSummary.aggregatedData.cities_lacking_offer[city]" :key="exam">
                            <td>{{city}}</td>
                            <td>{{exam}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- <h3><strong>{{city}}</strong></h3>
                      <ul>
                        <li class="ml-6" v-for="exam in apiResponse.data.examsAndCompliance.examSummary.aggregatedData.cities_lacking_offer[city]" :key="exam">{{exam}}</li>
                      </ul> -->
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <div class="column is-10">
                    </div>
                    <div class="column is-2">
                      <div class="buttons">
                        <button class="button is-info" @click="closeModal()">Fechar</button>
                      </div>
                    </div>
                </footer>
            </div>
        </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'PlanejarExames',
  components: {
    Bar
  },
  data() {
    return {
      activeModal: '',
      apiURL: "/api/v1/bus/",
      apiParams: {'companyId':53},
      budgetData: {loaded:false},
      entityExamPlannerData: {},
      apiResponse: {data: {examsAndCompliance:{examSummary: {aggregatedData:{cities_lacking_offer:{}}}}}},
      selectedCategory: '',
      selectedEntity: '',
      categoryOptions: [
          { text: 'Grupo Corporativo', value: 0 },
          { text: 'Companhia', value: 1 },
          { text: 'Unidade de Negócio', value: 2 },
          { text: 'Work Area', value: 3 },
      ],
      entityOptions: [],
      chartData: {
        labels: [],
        datasets: [],
        loaded: false,
      },
      chartOptions: {
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        }
      },
      workForceLoadingStatus: 'notLoaded' ,
      workForceData: {},
    }
  },
  // created() {
  //   // console.log('created')
  //   // this.preLoadExamCatalogue()
  // },
  methods: {
    activateModal() {
      this.activeModal = 'Modal_ClinicOnboardingDetails'
    },
    closeModal() {
      this.activeModal = ''
    },
    getEntitiesFromCategory(category) {
      // console.log('PlanejarExames_getEntitiesFromCategory called. Category: ' + category)
      this.entityOptions=[]
      axios
        .get('/api/v1/corporate/entities/', { params: {'category': category} })
        .then(response=> {
          // console.log('PlanejarExames_getEntitiesFromCategory-response.data',response.data)
          for (let i = 0; i < response.data.length; i++) {
            this.entityOptions.push(response.data[i])
          }
        })
        .catch(error => {
            console.log(JSON.stringify(error))
        });
    },
    fetchWorkForceData(entityPK) {
      // console.log('PlanejarExames_fetchWorkForceData called. entityPK: ' + entityPK)
      this.workForceLoadingStatus= 'loading'
      let categoryName = this.selectedCategory
      axios
        .get('/api/v1/corporate/examPlanner/workforce/', { params: {'category': categoryName, 'entityPK': entityPK} })
        .then(response=> {
          // console.log(response.data)
          this.workForceData = response
          this.workForceLoadingStatus = 'loaded'
        })
        .catch(error => {
            console.log(JSON.stringify(error))
        });
    },
    getEntityData(entityPK) {
      // console.log('PlanejarExames_getEntityData called. entityPK: ' + entityPK)
      this.fetchWorkForceData(entityPK)
      this.entityExamPlannerData = {}
      let categoryName = this.selectedCategory
      this.chartData.labels = []
      this.chartData.datasets = []
      this.chartData.loaded = false
      this.budgetData= {loaded:false}
      axios
        .get('/api/v1/corporate/examPlanner/', { params: {'category': categoryName, 'entityPK': entityPK} })
        .then(response=> {
          // console.log(response.data)
          this.apiResponse = response
          this.chartData.labels = response.data.budget.dateList
          this.chartData.datasets.push({
            label: 'Preços',
            data: response.data.budget.dataList,
            backgroundColor: [
                'rgba(102, 153, 255, 0.7)'
            ],
          })
          this.chartData.datasets.push({
            label: 'Taxa Serv.',
            data: response.data.budget.daedalusFee,
            backgroundColor: [
                'rgba(136,255,179, 0.7)'
            ]
          })
          this.chartData.datasets.push({
            label: 'Sobrepreço atual',
            data: response.data.budget.estimatedSavings,
            backgroundColor: [
                'rgba(255,64,25, 0.7)'
            ]
          })
          // Prepare Budget Data - Start
          let sum1 = 0
          let itemArray1 = Object.assign({}, this.chartData.datasets[1].data)
          for (let item in itemArray1) {
            sum1 = sum1 + itemArray1[item]
          }
          this.budgetData['Taxa Serv.'] = sum1.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          
          let sum2 = 0
          let itemArray2 = Object.assign({}, this.chartData.datasets[0].data)
          for (let item in itemArray2) {
            sum2 = sum2 + itemArray2[item]
          }
          this.budgetData['Precos'] = sum2.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          
          let sum3 = 0
          let itemArray3 = Object.assign({}, this.chartData.datasets[2].data)
          for (let item in itemArray3) {
            sum3 = sum3 + itemArray3[item]
          }
          this.budgetData['SobrePreco'] = sum3.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          this.budgetData['SobrePrecoPercentual'] = ((sum3/(sum1+sum2+sum3))*100).toFixed(1)
          this.budgetData['TotalExames'] = (sum1+sum2+sum3).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          this.budgetData['TotalAtrasado'] = (itemArray1[0]+itemArray2[0]+itemArray3[0]).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          this.budgetData.loaded = true
          // Prepare Budget Data - End
          this.chartData.loaded = true
        })
        .catch(error => {
            console.log(JSON.stringify(error))
        });
    },
    isModalActive(modalName) {
      return this.activeModal === modalName
    },
    preLoadExamCatalogue() {
      axios
        .get('/api/v1/corporate/examPlanner/preLoadCatalogue')
        .then(response=> {
        })
        .catch(error => {
            console.log(JSON.stringify(error))
        });
    }
  },
}
</script>

<style scoped>

.bordered-box {
  border-style: groove;
  border-width: 1px;
  border-color: #2f4685;
  height: auto;

}

hr {
      width: 95%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

.columns {
margin-bottom: 0px;
margin-top: 0px;
}
</style>