import {defineStore} from "pinia"
import {topExames} from '@/assets/topExames.js'

export const useExamSearch = defineStore('examSearch', {
    state: () => {
        return {
            municipio: '',
            raioBusca: null,
            listaTopExames: topExames,
            examesDemandados: [],
            pacotesExamesClinicaUnica: [],
            pacotesExamesMultiClinica: []
        }
    },
    actions: {
        resetExames() {
            this.listaTopExames = topExames
            this.examesDemandados= []
        },
        setMunicipio(item) {
            this.municipio = item
        },
        setRaioBusca(raio) {
            this.raioBusca = raio
        },
        setExames(examList) {
            let listaExames = []
            for (let i = 0; i < examList.length; i++) {
                listaExames.push(examList[i])
            }
            this.examesDemandados = listaExames
        },

    },
    getters: {
        // getMunicipio(state) {
        //     return state.municipio
        // },
        // getRaioBusca(state) {
        //     return state.raioBusca
        // },
        // getExamesDemandados(state) {
        //     return state.examesDemandados
        // }
    }
})