<template>
  <div class="add-company">
      <div>
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1>Test Page</h1>
            </div>
            Authentication
            <div class="column is-12">
                {{authentication}}
            </div>
            <br>
            <div class="column is-12">
                SearchStore: {{ examQueryStore }}
            </div>
            <div class="column is-6">
              <strong>apiURL:</strong> {{apiURL}}
            </div>
            <div class="column is-6">
              <strong>apiParams:</strong> {{apiParams}}
            </div>
            <div class="column is-12">
                <div class="box">
                  <div class="is-full-width">
                    <strong>Response.data:</strong>
                  </div>
                  {{ response.data }}
                  <!-- <li v-for="item in response.data">
                    {{item}}
                  </li> -->
                </div>
            </div>
            <div class="column is-12">
              <div class="box">
                <Survey :questions="questions" :surveyDescription="surveyDescription" />
              </div>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios'
import {useAuthenticationStore} from "@/stores/authentication"
import {useExamSearch} from '@/stores/examSearch.js'
import Survey from '@/components/survey/Survey.vue'

export default {
  name: 'TestPage',
  components: {
    Survey,
  },
  setup() {
        const authentication = useAuthenticationStore()
        const examQueryStore = useExamSearch()
        return {
            authentication, examQueryStore
        } 
    },
  data() {
    return {
      apiParams: {},
      apiURL: '/api/v1/user-credits/exam-search/',
      objectToSend: {
        'target_clinic_pk': 913,
        'target_date': {
          'year': 2023,
          'month': 7,
          'day': 1
      }},
      show: false,
      surveyDescription: {
        title: 'Survey Title',
        description: 'Thank you for participating in our survey! Your feedback is incredibly valuable to us as we strive to improve our services. This survey should take approximately 10-15 minutes to complete. In this survey, we aim to understand your experiences with our product and services. We would appreciate your honest opinions and feedback on various aspects of our offerings. Your responses will be kept confidential and used solely for the purpose of improving our services. Please answer each question to the best of your ability. Thank you again for taking the time to complete this survey.',
      },
      questions: [
        {
          number: 1,
          prompt: 'What is your name?',
          type: 'input',
          answer: '',
        },
        {
          number: 2,
          prompt: 'What is your favorite color?',
          type: 'select',
          options: ['Red', 'Green', 'Blue'],
          answer: '',
        },
        {
          number: 3,
          prompt: 'What is your favorite animal?',
          type: 'select',
          options: ['Dog', 'Cat', 'Bird'],
          answer: '',
        },
        {
          number: 4,
          prompt: 'What is your favorite food?',
          type: 'input',
          answer: '',}
      ],
      response: {}
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      // console.log('DataTable getData being called with apiURL||params:',this.apiURL,'||',{ params: this.apiParams })
      //API Call
      console.log('this.objectToSend',this.objectToSend)
      axios
      // .get(this.apiURL, { params: this.apiParams })
      .get('/api/v1/user-credits/exam-search/', { params: {
        data: ''
      }})
      .then(response=> {
        console.log('TestPage response.data',response.data)
        this.response = response
          // this.objectToSend = response.data
          // axios
          // .post('/api/v1/medicalrequests/api', this.objectToSend)
          // .then(response=> {
          //     this.response = response
          // })
      })
      .catch(error => {
          console.log(JSON.stringify(error))
      });
    },
  },
}
</script>

<style>
.testTransition-enter-active,
.testTransition-leave-active {
  transition: all 0.2s ease;
}

.testTransition-enter-from {
  opacity: 0;
  transform: translateX(20px);
}
.testTransition-leave-to {
  opacity: 0;
  transform: translateX(-20px);
}
</style>