<template>
    <div class="page-my-account">
        <h1 class="title" style="margin-bottom:24px">Gerir Exames</h1>                       
        <!-- <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li class="is-active"><router-link to="/dashboard/my-account" aria-current="true">My account</router-link></li>
            </ul>
        </nav> -->
        <div class="columns is-multiline">
            <div class="column is-12 is-multiline pt-0">
                <div class="box pt-0 pb-4" id="box-sumario"> <!-- BOX GERIR OFERTAS  -->
                    <h1 class="subtitle is-4 mb-0"><strong>Sumário de Ofertas</strong></h1>
                    <hr />
                    <div class="columns is-multiline" v-if="!clinic_has_public_offers"> <!-- Alert in case the user has no public offers -->
                        <div class="column is-2"></div>
                        <div class="column is-8">
                            <h3 class="has-text-danger has-background-warning has-text-centered"><strong>
                                <p>Sua clínica não possui nenhuma oferta registrada como pública.</p>                                
                                <p>Para registrar uma nova oferta, clique no botão "Criar Nova Oferta" abaixo.</p>
                                <p>Para tornar uma oferta já existente pública, clique no botão "Gerenciar" ao lado da oferta desejada.</p>
                            </strong></h3>
                        </div>
                        <div class="column is-2"></div>
                    </div>                    
                </div>                
            </div>
            <div class="column is-12 is-multiline pt-0">
                <div class="box pt-0 pb-4" id="box-gerir-ofertas-de-exames"> <!-- BOX GERIR OFERTAS  -->
                    <GerirOfertas/>
                </div>                
            </div>        
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { onBeforeMount } from 'vue';
import GerirOfertas from '../../components/clinics/GerirOfertas.vue';

export default {
    name: 'GerenciarOfertas',
    components: {
        GerirOfertas
        
    },
    setup() {
        // const authentication = useAuthenticationStore()
        // return {
        //     authentication
        // } 
    },
    data() {
        return {
            clinicOffers: [],
            clinic_has_public_offers: true,
        }
    },
    async beforeMount() {
        // make an axios call to get the clinic's details
        // console.log('beforeMount')
        this.getClinicOffers()
        
    },
    computed: {
        // schemaIsValid() {
        //     return validationSchemaDictionary[this.formValidationSchema].isValidSync(this.clinicFormData)
        // },
    },
    methods: {
        getClinicOffers() {
            axios
                .get('/api/v1/clinics/exam-offers/')
                .then(response => {
                    // console.log('getClinicOffers response.data:',response.data)
                    this.clinicOffers = response.data
                    // Copilot, check the array of offers and see if there's at least one public offer, changing the boolean value if that's the case
                    let public_offers = false
                    this.clinicOffers.forEach(offer => {
                        if (offer['Oferta Pública']=="Sim") {
                            public_offers = true
                        }
                    this.clinic_has_public_offers = public_offers
                    })
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },

    }
}
</script>

<style scoped>
.columns {
    margin-top: 0px;
}

.tabs {
    margin-bottom: 0px;
}
.tabs a {
    color: rgba(128, 128, 128, 0.802);
}

.tabs li.is-active a {
    color: #000;
    font-weight: 600;
}
.tab {
    padding-top: 2rem;
    padding-bottom: 2rem;
    /* padding-right: 1rem; */
    border-color: hsl(0deg, 0%, 86%);
    border-style: solid;
    border-width: 1px;
    border-top-color: transparent;
    margin-bottom: 0;
}

hr {
      width: 95%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 2px;
      margin-bottom: 1rem;
    }

#box-meuPerfil {
    height: 100%;
}

li {
    color: gray;
}

/* .tabs.is-boxed li.is-active a {
    font-weight: 500;
} */

#box-perfilCorporativo {
    height: 100%;
}
</style>