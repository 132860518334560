<template>
    <div class="container">
        <div class="columns is-multiline"> 
            <div class="column is-12"> <!------------------------ Título ------------------------>
                <h4 class="title is-4" style="padding-top:0;margin-bottom:0.5rem">{{modalObject.Nome}}</h4>
                <hr style="margin-bottom:0;margin-top:0">
            </div>
            <div class="column is-6"> <!------------------------ Detalhes da Oferta ------------------------>
                <div class="box">
                    <div class="content">
                        <div class="columns">
                            <div class="column is-6">
                                <h4 class="subtitle"><strong>Detalhes da Oferta</strong></h4>
                            </div>
                            <div class="column is-6">
                                <button
                                    class="button is-danger"
                                    style="float:right"
                                    @click="activateModalExamOfferDelete()"
                                >Excluir Oferta</button>
                            </div>

                        </div>
                        <hr>
                        <!-- {{ modalObject }} -->
                        <edit-generic-serializer
                            :object="modalExamOffer"
                            apiURL='/api/v1/clinics/exam-offers/'
                            :extraParams = {}
                            submitMethod = 'put'
                            formValidationSchema = 'addOfferedExamSchema' 
                            :withButtons = false
                            @closeEditModal="closeModal_EditOfferedExam()"
                            @closeEditModalWithReload="closeModal_EditOfferedExam()"
                        />
                        <br>
                        <div class="buttons" style="float:right">
                            <button class="button is-warning" @click="restoreExamOffer()">Cancelar Alterações</button>
                            <button class="button is-info"  @click="submitEditOfferForm()">Salvar Alterações</button>
                        </div>
                        <br>
                    </div>
                </div>
            </div>
            <div class="column is-6"> <!------------------------ Clientes com acesso a oferta ------------------------>
                <div class="box">
                    <div class="content">
                        <h4 class="subtitle"><strong>Clientes com acesso a esta oferta</strong></h4>
                        <ul>
                            <li>Grupo Demonstração</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="column is-12 is-centered"> <!------------------------ Exames Contidos (DataTable) ------------------------>
                <div class="box">
                        <div style="margin:2rem;margin-top: 0;">
                            <div class="columns is-multiline">
                                <div class="column is-6"> <!-- Box Title -->
                                    <h4 class="title is-4" style="margin-bottom:0">{{modalObject['Nº Exames']}} Exames contidos nesta oferta</h4>
                                </div>
                                <div class="column is-6"> <!-- Box Button -->
                                    <button 
                                        class="button is-info is-centered strong"
                                        @click="activateModal('Modal_AddOfferedExam')"
                                        style="float:right"
                                    >
                                        <strong> Adicionar Exame à Oferta </strong>
                                    </button>
                                </div>
                                <div class="column is-12"> <!-- Box Data Table -->
                                    <!-- {{ modalObject.pk }} -->
                                    <DataTable
                                        apiURL='/api/v1/clinics/exams-offered/'
                                        :apiParams="{ 'examOfferPK': modalObject.pk}"
                                        ref="dataTableExamesOfertadosRef"
                                        :hasSearchField="true"
                                        :hasActionsColumn="true"
                                        :actionButtons="{
                                            'Edit': 'Editar',
                                            'Delete': 'Deletar'
                                        }"
                                        @toggleEditModal="activateModalEditOfferedExam"
                                        @toggleDeleteModal="activateModalExamOfferedDelete"
                                        :fieldsToHide="[
                                            'pk',
                                            'availableExamPK',
                                            'exam_offered_pk',
                                            'availableExam_pk',
                                            'examePadrao_pk',
                                            'clinica_pk',
                                        ]"
                                        :fieldNameManager = " {
                                            'nome': 'Exame',
                                            'preco': 'Preço',
                                            'sla': 'SLA',
                                        }"
                                        :key="ExamsOfferedDataTableKey"
                                        />
                                </div>
                            </div>
                        </div>
                        <br>
                </div>
            </div>
        </div>
    </div>
    <section><!------------------------ MODAL: DELETE EXAM OFFER ------------------------> 
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_ExamOfferDelete') }" v-if="isModalActive('Modal_ExamOfferDelete')">
            <div class="modal-background"></div>
            <div class="modal-card" id="Modal_ExamOfferDelete">
                <header class="modal-card-head">
                    <p class="modal-card-title">Deletar Oferta de Exames</p>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                </header>
                <section class="modal-card-body" style="modal-content-width:1200px">
                    <ExamOfferDelete
                        :examOffer="modalExamOffer"
                        :modalIsActive="isModalActive('Modal_ExamOfferDelete')"
                        @closeDeleteModal="closeModal()"
                        @closeDeleteModalWithReload="closeModal_ExamOfferDelete"
                    />
                </section>
            </div>
        </div>
    </section>
    <section><!------------------------ MODAL: ADD OFFERED EXAM ------------------------>
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_AddOfferedExam') }" v-if="isModalActive('Modal_AddOfferedExam')">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Adicionar Exame à Oferta</p>
                    <button class="delete" aria-label="close" @click="closeModal()"></button>
                </header>
                <section class="modal-card-body">
                    <form-generic-serializer 
                        ref="Modal_AddOfferedExam_Form"
                        :object="offeredExamToAdd"
                        apiURL='/api/v1/clinics/exams-offered/'
                        formValidationSchema="addOfferedExamSchema"
                        @schemaIsValidChanged="formEvent_OfferedExam_changed"
                    />
                </section>
                <footer class="modal-card-foot">
                    <div class="buttons">
                        <button class="button is-danger" @click="closeModal()">Cancelar</button>
                        <button class="button is-success" @click="submitAddOfferedExamForm()">Confirmar</button>
                    </div>
                </footer>
            </div>
        </div>
    </section>
    <section><!------------------------ MODAL: EDIT OFFERED EXAM ------------------------>
        <div class="modal" :class="{ 'is-active': isModalActive('Modal_OfferedExamDetails') }" v-if="isModalActive('Modal_OfferedExamDetails')">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Editar Exame Ofertado</p>
                    <button class="delete" aria-label="close" @click="closeModal_EditOfferedExam()"></button>
                </header>
                <section class="modal-card-body">
                    <edit-generic-serializer
                        :object="modalInputData"
                        apiURL='/api/v1/clinics/exams-offered/'
                        :extraParams = {}
                        submitMethod = 'put'
                        formValidationSchema = 'addOfferedExamSchema' 
                        @closeEditModal="closeModal_EditOfferedExam()"
                        @closeEditModalWithReload="closeModal_EditOfferedExam()"
                    />
                </section>
            </div>
        </div>
    </section>
    <section><!------------------------ MODAL: DELETE OFFERED EXAM ------------------------>
    <div class="modal" :class="{ 'is-active': isModalActive('Modal_OfferedExamDelete') }" v-if="isModalActive('Modal_OfferedExamDelete')">
        <div class="modal-background"></div>
        <div class="modal-card" id="Modal_OfferedExamDelete">
            <header class="modal-card-head">
                <p class="modal-card-title">Deletar Exame Ofertado</p>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body" style="modal-content-width:1200px">
                <OfferedExamDelete
                    :offeredExam="modalInputData"
                    :modalIsActive="isModalActive('Modal_OfferedExamDelete')"
                    :examOfferName = "examOfferName"
                    @closeDeleteModal="closeModal()"
                    @closeDeleteModalWithReload="closeModal_OfferedExamDelete()"
                />
            </section>
        </div>
    </div>    
    </section>
</template>

<script>
import axios from 'axios'

import DataTable from '@/components/dataTable/DataTable.vue'
import ExamOfferDelete from '@/components/clinics/ExamOfferDelete.vue'
import FormGenericSerializer from '@/components/FormGenericSerializer.vue'
import EditGenericSerializer from '@/components/EditGenericSerializer.vue'
import OfferedExamDelete from '@/components/clinics/OfferedExamDelete.vue'
import { toRaw , ref} from 'vue'
import { toast } from 'bulma-toast'


export default {
    name: 'ExamOfferDetailsComponent',
    props: ['modalObject','modalIsActive'],
    components: {
        DataTable,
        ExamOfferDelete,
        FormGenericSerializer,
        EditGenericSerializer,
        OfferedExamDelete,
    },
    emits: ['closeManageOfferModalWithReload'],
    data() {
        return {
            activeModal: '',
            checkConfirm: false,
            ExamsOfferedDataTableKey: ref(0),
            modalInputData: {},
            modalInputID: 0,
            examOfferName: this.modalObject.Nome,
            modalExamOffer: {},
            offeredExamToAdd: {},
            offeredExam_HasValidSchema: false,
            responseBeforeDelete: {},
            submitDeclined: false,
            tableAPIParams: {
                'examOfferPK': this.modalObject.pk
            },
        }
    },
    computed: {

    },
    watch: {

    },
    created() {
        this.setInnerModalObject()
    },
    methods: {
        activateModal(modalName, data='') {
            // console.log('ExamOfferDetails_activateModal Called for modal:', modalName, ' and data:',data)
            const rawData = toRaw(data)
            if (data!='') {
                // console.log('activateModal data recognised. Received data:',data)
                this.modalInputData = rawData
                // console.log('modalInputData:',this.modalInputData)
                this.modalInputID = rawData.pk
                // console.log('modalInputID:',this.modalInputID)
            }
            this.activeModal = modalName
            // console.log('activeModal set to:',this.activeModal)
        },
        activateModalEditOfferedExam(data) {
            // Add a field to the data object to match the field query response in the API
            let rawData = toRaw(data)
            rawData['exame'] = rawData.availableExam_pk
            // console.log('activateModalExamOfferDetails Called with data:', rawData)
            this.activateModal('Modal_OfferedExamDetails',rawData)
        },
        activateModalExamOfferDelete(data) {
            this.activateModal('Modal_ExamOfferDelete',data)
        },
        activateModalExamOfferedDelete(data) {
            this.activateModal('Modal_OfferedExamDelete',data)
        },
        closeModal() {
            this.activeModal = ''
        },
        closeModal_EditOfferedExam() {
            setTimeout(() => {
                this.ExamsOfferedDataTableKey++
            }, 20);
            this.closeModal()
        },
        closeModal_ExamOfferDelete() {
            this.closeModal()
            this.$emit('closeManageOfferModalWithReload')
        },
        closeModal_OfferedExamDelete() {
            this.ExamsOfferedDataTableKey++
            this.modalObject['Nº Exames']--
            this.closeModal()
        },
        isModalActive(modalName) {
            return this.activeModal === modalName
        },
        formEvent_OfferedExam_changed(data) {
            this.offeredExam_HasValidSchema = data
        },
        getDataBeforeDelete() {
            axios
                .get('/api/v1/clinics/exams-offered/', { params: {'examOfferedPK': this.examOffer.pk} })
                .then(response => {
                    // console.log('prop examOffer.pk :',this.examOffer.pk)
                    // console.log('getDataBeforeDelete response.data:',response.data)
                    // this.numeroUnidadesNegocio = response.data.length
                    // let numWAs = 0
                    // let numRT = 0
                    // for (let i = 0; i < response.data.length; i++) {
                    //     numWAs += response.data[i]['#WAs']
                    //     numRT += response.data[i]['#Func.']
                    // }

                    // this.numeroWorkAreas = numWAs
                    // this.numeroRelacoesDeTrabalho = numRT
                    // this.responseBeforeDelete = response
                    // console.log('response:',response)
                    // console.log('CompanyDelete_getDataBeforeDelete called.')
                })
                .catch(error => {
                    console.log(error.request.response)
                })
        },
        restoreExamOffer() {
            this.setInnerModalObject()
        },
        setInnerModalObject() {
            const condicoesPgto = toRaw(this.modalObject['Condições Pgto'].split(/(\s+)/))
            this.modalExamOffer = {
                pk: this.modalObject.pk,
                nome: toRaw(this.modalObject.Nome),
                ofertaPublica: toRaw(this.modalObject['Oferta Pública']),
                condicoes_pgto: condicoesPgto[0],
            }
        },
        submitAddOfferedExamForm() {
            // If this.offeredExamToAdd.preco contains a comma, convert it to a dot
            if ('preco' in this.offeredExamToAdd && this.offeredExamToAdd.preco.includes(',')) {
                this.offeredExamToAdd.preco = this.offeredExamToAdd.preco.replace(',','.')
            }

            if (this.offeredExam_HasValidSchema) {
                this.offeredExamToAdd.examOffer = this.modalObject.pk
                // console.log('submitAddOfferedExamForm called. Submitting form with data:',this.offeredExamToAdd)
                axios
                    .post('/api/v1/clinics/exams-offered/', this.offeredExamToAdd)
                    .then(response => {
                        // this.$router.push('/organizacao')
                        // console.log(response)
                        // this.businessUnits.unshift(response.data)
                        // this.$refs.dataTableExamesOfertadosRef.table.rows.unshift(response.data)
                    })
                    .catch(error => {
                        // console.log(JSON.stringify(error))
                        console.log(error)
                    })
                    .then( () => { 
                        this.ExamsOfferedDataTableKey++
                        this.modalObject['Nº Exames']++
                        this.closeModal()
                    })
            }
            else {
                this.$refs.Modal_AddOfferedExam_Form.$refs.form.validate()
                toast({
                    position:'bottom-right',
                    message: 'Favor preencher os campos de acordo com os requisitos.',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000
                })
            }
        },
        submitEditOfferForm() {
            // console.log('submitEditOfferForm called. Submitting form with data:',this.modalExamOffer)
            axios
                .put('/api/v1/clinics/exam-offers/', this.modalExamOffer)
                .then(response => {
                    this.$emit('closeManageOfferModalWithReload')
                    toast({
                        position:'bottom-right',
                        message: 'Oferta de exame editada com sucesso.',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000
                    })
                })
                .catch(error => {
                    console.log(error)
                    toast({
                        position:'bottom-right',
                        message: 'Erro ao editar oferta.',
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000
                    })
                })
                .then( () => { 
                    this.setInnerModalObject()
                })
        },
        async submitDelete() {
            if (this.checkConfirm) {
                console.log('submitting delete request for:',this.examOffer.pk)
                axios
                    .delete('/api/v1/clinics/exam-offers/', { params: {'examOfferPK': this.examOffer.pk} })
                    .then(response => {
                        // this.$router.push('/organizacao')
                        // this.companies.unshift(response.data)
                        // this.$refs.dataTableRef.table.rows.unshift(response.data)
                        // console.log('CompanyDelete_submitDelete called.')
                        // Emit event to parent component to close modal
                        // this.$emit('closeDeleteModalWithReload')
                    })
                    .catch(error => {
                        console.log(error.request.response)
                    })
            }
            else {
                this.submitDeclined = !this.submitDeclined
            }
        },

    }

}
</script>

<style>
#Modal_ExamOfferDelete {
    width: 65%;
}
</style>