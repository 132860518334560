<template>
      <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title pb-0 mb-0 has-text-danger has-background-warning has-text-centered"><strong>Atenção!</strong></h1>
                <h2 class="has-text-danger has-background-warning has-text-centered"><strong>A ação que você está prestes a realizar é irreversível.</strong></h2>
            </div>
            <hr>
            <div class="column is-6">
                <div class="box">
                    <h2 class="subtitle"><strong>{{company.nome}}</strong></h2>
                    <p><strong>Razão Social: </strong> {{company.razaoSocial}} </p>
                    <p><strong>CNPJ: </strong> {{company.cnpj}} </p>
                    <p><strong>Código Interno: </strong>{{company.codigo}}</p>
                    <p><strong>Grupo Corporativo: </strong>{{company.corporateGroup}}</p>
                    <p><strong>Criado em: </strong>Data</p>
                    <p><strong>Criado por: </strong>Pessoa</p>
                </div>
            </div>

            <div class="column is-6">
                <div class="box">
                    <div class="content">
                        <h3 class="subtitle"><strong>A empresa ao lado possui:</strong></h3>
                        <ul>
                            <li>{{numeroUnidadesNegocio}} Unidades de negócio registradas</li>
                            <li>{{numeroWorkAreas}} Work Areas cadastradas</li>
                            <li>{{numeroRelacoesDeTrabalho}} Relações de trabalho ativas</li>
                        </ul>
                        <h5 class="has-text-centered has-text-danger has-background-warning"><strong>Ao excluir a empresa, todos estes itens dependentes serão excluídos em conjunto.</strong></h5>
                    </div>
                </div>
            </div>

            <div class="column is-12 is-centered">
                <label class="checkbox pb-2">
                    <input type="checkbox" v-model="checkConfirm">
                    Eu entendo que ao deletar a companhia acima, todos os itens dependentes serão excluídos, e desejo continuar.
                </label>
                <p class="has-text-danger pb-4" v-show="submitDeclined">Você deve confirmar na caixa acima antes de prosseguir.</p> 
                <div class="buttons"> <!-----------------------------------------------------------Make buttons centered -->
                    <button class="button is-info" @click="$emit('closeDeleteModal')">Cancelar e Retornar</button>
                    <button class="button is-danger is-outlined" @click="submitDelete">Confirmar e Deletar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

// import DataTable from '@/components/dataTable/DataTable.vue'



export default {
    name: 'CompanyDeleteComponent',
    props: ['company','companyId','modalIsActive']
    // {
    //     companyId: Integer
    // }
    ,
    components: {
        // DataTable
    },
    data() {
        return {
            companyInner: '',
            tableAPIParams: {
                'companyId': this.companyId
            },
            numeroUnidadesNegocio: 0,
            numeroWorkAreas: 0,
            numeroRelacoesDeTrabalho: 0,
            responseBeforeDelete: {},
            checkConfirm: false,
            submitDeclined: false
        }
    },
    computed: {

    },
    watch: {

    },
    created() {
        // console.log('CACILDA!')
        // console.log('props inside CompanyDetails:',this.companyID)
        // console.log(this.tableAPIParams)
        // this.tableAPIParams.companyId = this.companyId
        this.getDataBeforeDelete()
    },
    methods: {
        async submitDelete() {
            if (this.checkConfirm) {
                axios
                    .delete('/api/v1/companies/', { params: {'companyId': this.company.pk} })
                    .then(response => {
                        // this.$router.push('/organizacao')
                        // this.companies.unshift(response.data)
                        // this.$refs.dataTableRef.table.rows.unshift(response.data)
                        // console.log('CompanyDelete_submitDelete called.')
                        // Emit event to parent component to close modal
                        this.$emit('closeDeleteModalWithReload')
                    })
                    .catch(error => {
                        console.log(error.request.response)
                    })
            }
            else {
                this.submitDeclined = !this.submitDeclined
            }
        },
        getDataBeforeDelete() {
            axios
                .get('/api/v1/bus/', { params: {'companyId': this.company.pk} })
                .then(response => {
                    this.numeroUnidadesNegocio = response.data.length
                    let numWAs = 0
                    let numRT = 0
                    for (let i = 0; i < response.data.length; i++) {
                        numWAs += response.data[i]['#WAs']
                        numRT += response.data[i]['#Func.']
                    }

                    this.numeroWorkAreas = numWAs
                    this.numeroRelacoesDeTrabalho = numRT
                    this.responseBeforeDelete = response
                    // console.log('response:',response)
                    // console.log('CompanyDelete_getDataBeforeDelete called.')
                })
                .catch(error => {
                    console.log(error.request.response)
                })
        }
    }

}
</script>

<style>

</style>