<template>
  <head>

  </head>
  <div id="content-ex-navbar"> 
    <HeroSection id="home"/>
    <USPSection id="quem-somos"/>
    <CounterSection/>
    <OfferSection id="beneficios" />
    <HowItWorksSection id="como-funciona"/>
    <PricingSection id="nossos-precos"/>
  </div>

</template>

<script>
import HeroSection from '@/components/landingPage/HeroSection'
import USPSection from '@/components/landingPage/USPSection'
import CounterSection from '@/components/landingPage/CounterSection'
import OfferSection from '@/components/landingPage/OfferSection'
import HowItWorksSection from '@/components/landingPage/HowItWorksSection'
import PricingSection from '@/components/landingPage/PricingSection'

export default {
  name: 'LandingPage',
  props: {
    
  },
  components: {
    HeroSection,
    USPSection,
    CounterSection,
    OfferSection,
    HowItWorksSection,
    PricingSection
  },
  data() {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
#content-ex-navbar {
  margin-top: 4rem;
}

</style>
