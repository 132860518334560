<template>
	<section class="section is-medium">
		<div class="container">
			<div class="columns is-multiline is-centered">
				<div class="column is-12 has-text-centered">
					<h1 class="title is-1" id="sectionTitle">Como Funciona</h1>
				</div>
				<div class="column is-12 has-text-centered">
					<h2 class="subtitle is-2" id="sectionSubTitle">
						A <strong style="color:rgb(0,114,206)">{{$companyName}}</strong> 
						trabalha para simplificar todos os processos que trazem custos desnecessários para nossos clientes.
						Veja como é fácil iniciar sua jornada ao futuro da saúde ocupacional.
					</h2>
				</div>
				<div class="column is-4 has-text-centered"> </div>
				<div class="column is-4 has-text-centered">
					<div class="tabs is-centered is-large">
						<ul>
							<li :class="{ 'is-active': isTabActive('Empresas') }" @click="setActiveTab('Empresas')"><a>Empresas</a></li>
							<li :class="{ 'is-active': isTabActive('Clinicas') }" @click="setActiveTab('Clinicas')"><a>Clínicas</a></li>
						</ul>
					</div>
				</div>
				<div class="column is-4 has-text-centered"> </div>
				<!-- TAB Empresas -->
				<div class="column is-8 has-text-centered is-multiline" v-if="isTabActive('Empresas')">
					<div class="card" v-for="step in companySteps">
						<header class="card-header" @click="toggleActiveStep(step.slug)">
							<p class="card-header-title">
								{{step.title}}
							</p>
							<button class="card-header-icon" aria-label="more options">
								<span class="icon">
									<i class="fas fa-angle-down" aria-hidden="true"></i>
								</span>
							</button>
						</header>
						<div class="card-content" v-if="isStepActive(step.slug)">
							<div class="content">
								{{step.details}}
							</div>
						</div>
					</div>
				</div>
				<!-- TAB Clinicas -->
				<div class="column is-8 has-text-centered is-multiline" v-if="isTabActive('Clinicas')">
					<div class="card" v-for="step in clinicSteps">
						<header class="card-header" @click="toggleActiveStep(step.slug)">
							<p class="card-header-title">
								{{step.title}}
							</p>
							<button class="card-header-icon" aria-label="more options">
								<span class="icon">
									<i class="fas fa-angle-down" aria-hidden="true"></i>
								</span>
							</button>
						</header>
						<div class="card-content" v-if="isStepActive(step.slug)">
							<div class="content">
								{{step.details}}
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</section>
</template>

<script>

export default {
  name: 'HowItWorksSection',
  components: {
  },
  setup() {
    },
  data () {
    return {
		activeSteps: [],
		activeTab: 'Empresas',
		companySteps: [
			{
				slug: 'empresa-1',
				title: '1. Crie um usuário pessoal',
				details: 'Clique no botão "Registre-se" acima e siga as etapas para criar sua conta.'
			},
			{
				slug: 'empresa-2',
				title: '2. Cadastre sua empresa',
				details: 'Registre os dados básicos da empresa contratante. Para grupos corporativos, é importante que a empresa registrada seja a matriz, para que todos os funcionários do grupo possam ser gerenciados de forma centralizada. Mas caso queira começar por apenas uma das filiais, não há problema!'
			},
			{
				slug: 'empresa-3',
				title: '3. Descreva a estrutura de sua empresa',
				details: 'Aqui você irá informar como sua empresa está organizada: unidades ne negócio (ou filiais), departamentos e work areas. Essa informação é importante para que nossos relatórios sejam customizados para você e sua equipe.'
			},
			{
				slug: 'empresa-4',
				title: '4. Registre o perfil de risco de cada work area',
				details: 'Aqui você deve informar quais exames são obrigatórios para cada work area, e com que frequência os colaboradores devem realizá-los. Esses requisitos estão presentes no PCMSO do local de trabalho. Nós possuímos uma ferramenta de importação de PCMSOs, para facilitar esse processo.'
			},
			{
				slug: 'empresa-5',
				title: '5. Cadastre os colaboradores',
				details: 'Aqui você poderá cadastrar colaboradores individualmente, ou utilizar nossa ferramenta de importação de folhas de pagamento. Caso seus dados não estejam no formato de importação, entre em contato com nossa equipe que iremos te ajudar. Seu tempo é valioso demais para realizar atividades repetitivas!'
			},
			{
				slug: 'empresa-6',
				title: '6. Inclua o histórico de exames dos colaboradores',
				details: 'Para que nossa plataforma saiba quando cada colaborador deve realizar cada exame, é necessário que você informe o histórico de exames de cada um. Você pode fazer isso individualmente, ou entrar em contato com nossa equipe para que possamos automatizar o processo para você e sua equipe. Nossa equipe pode trabalhar com qualquer formato digital que sua empresa possua. Entre em contato e evite o retrabalho!'
			},
			{
				slug: 'empresa-7',
				title: '7. Escolha uma política de seleção de clínicas e exames',
				details: 'Caso você e sua equipe estejam interessados na automação de agendamento de exames, é possível registrar sua preferência por qualidade, preço, local, ou tempo de retorno de resultados. Nossa plataforma cuidará do resto, e você poderá acompanhar o processo de agendamento e realização de exames em tempo real.'
			},
			{
				slug: 'empresa-8',
				title: 'Pronto! Sua empresa já faz parte do futuro da saúde ocupacional',
				details: 'Você terá uma gestão integrada de todos os exames necessários, com previsão orçamentária, agendamento automático e relatórios de gestão. Alertas automáticos podem ser configurados para que você e sua equipe sempre se mantenham no controle.'
			},
			{
				slug: 'empresa-9',
				title: 'Bônus: Escolha serviços adicionais para otimizar sua operação.',
				details: 'De forma completamente opcional, você pode contratar serviços adicionais de nossos parceiros. Todos integrados com a plataforma! Os serviços mais comuns são: Guarda de documentos, Revisão médica de resultados, Consultas médicas de acompanhamento, Gestão de agendamentos através de central de relacionamento com colaboradores, Otimização de indicadores, Padronização de PCMSO, Consultas por telemedicina.'
			},
		],
		clinicSteps: [
			{
				slug: 'clinica-1',
				title: '1. Crie um usuário pessoal',
				details: 'Clique no botão "Registre-se" acima e siga as etapas para criar sua conta.'
			},
			{
				slug: 'clinica-2',
				title: '2. Cadastre sua clínica',
				details: 'Registre os dados básicos da clínica, como nome, CNPJ, endereço e afins. É importante que cada clínica tenha um único registro. Caso você faça parte de um grupo de clínicas, entre em contato com nossa equipe que lhe apresentaremos nossa ferramenta de gestão de redes de clínicas.'
			},
			{
				slug: 'clinica-3',
				title: '3. Registre todos os exames que sua clínica oferece',
				details: 'Estes são os exames que as empresas contratantes poderão agendar. Neste momento, registre todos os exames disponíveis. Em um segundo momento, você poderá escolher quais clientes terão acesso a cada exame.'
			},
			{
				slug: 'clinica-4',
				title: '4. Faça o cadastro de seus clientes',
				details: 'Nossa plataforma possui uma ferramenta de importação para facilitar seu trabalho. Entre em contato caso possua uma base extensa em formato digital diferenciado. Não perca tempo com atividades repetitivas, nossa equipe está aqui para te ajudar!'
			},
			{
				slug: 'clinica-5',
				title: '5. Registre uma tabela base de exames',
				details: 'Essa é aquela tabela geral de exames, preços e prazos de entrega de resultados que sua clínica oferece para qualquer demanda pontual de um novo cliente. Essa é a que nós chamamos de tabela pública, pois é aquela a qual novos clientes terão acesso.'
			},
			{
				slug: 'clinica-6',
				title: '6. Registre as tabelas diferenciadas',
				details: 'Essas são as tabelas negociadas. Cada cliente pode ter uma tabela de preço diferente, prazos mais ou menos acelerados, e você pode registrar quantas tabelas quiser. Essas tabelas são privadas, e só podem ser acessadas pelos clientes que você autorizar.'
			},
			{
				slug: 'clinica-7',
				title: 'Pronto! Sua clínica está apta a receber pedidos de exames',
				details: 'Você e sua equipe terão acesso a um painel completo com os indicadores sobre sua operação e volume financeiro, além de ter total controle sobre oferta de exames e preços. Você também receberá gratuitamente um relatório periódico sobre a demanda ocupacional em sua região, e uma previsão de demanda para o próximo mês.'
			},
			{
				slug: 'clinica-8',
				title: 'Bônus: Escolha serviços adicionais para otimizar sua operação.',
				details: 'De forma completamente opcional, você pode contratar serviços adicionais de nossos parceiros. Todos integrados com a plataforma! Os serviços mais comuns são: Integração financeira para gestão de recebíveis de clientes, Digitalização da gestão de clientes, Maximização de receita, Guarda de documentos, Prontuário eletrônico, Envio de resultados para clientes e pacientes, Adiantamento de recebíveis, dentre outros.'
			},
		]
    }
  },
  methods: {
	isStepActive(stepItem) {
            return this.activeSteps.includes(stepItem);
        },
	isTabActive(tabItem) {
            return this.activeTab === tabItem
        },

	setActiveTab(tabItem) {
		this.activeTab = tabItem
	},
	toggleActiveStep(stepItem) {
		if (this.activeSteps.includes(stepItem)) {
			const index = this.activeSteps.indexOf(stepItem);
			if (index > -1) { // only splice array when item is found
				this.activeSteps.splice(index, 1); // 2nd parameter means remove one item only
			}
		} else {
			this.activeSteps.push(stepItem)
		}
	},
  },
  computed: {
  }
}
</script>

<style scoped>
.section {
	background-color: white;
	padding-top: 2rem;
	padding-bottom: 2rem;
}
#sectionTitle {
	color: rgb(49,53,62,0.7);
	font-size: 2.2rem;
	font-weight: 800;
}
#sectionSubTitle {
	padding-top: 0rem;
	padding-bottom: 0rem;
	color: rgb(118,134,146);
	font-size: 1.1rem;
	font-weight: 200;
	font-family: sans-serif;
}

.tabs {
	padding-top: 1rem;
}
.tabs li.is-active a {
	border-bottom-width: 3px;
}

.title{
	color: rgb(49,53,62);
	font-weight: 700;
	position: relative;
	/* top: 20%; */
	padding-bottom: 1rem;
}

.content {
	color: rgb(49,53,62);
	font-weight: 400;
	font-family: sans-serif;
}

.card {
	margin-bottom: 1rem;
}
.card-header {
	background-color: rgb(232,237,238);
	color: rgb(35,41,32,0.9);
}
.card-header :hover{
	color: rgb(0,114,206);
	cursor: pointer;
}
.card-content {
	background-color: rgb(232,237,238);
	padding-bottom: 1rem;
	padding-top: 0rem;
	text-align: justify;
}
</style>

