<template>
    <div class="criar-requisicao" style="min-height:10rem">
        <div class="columns is-multiline">
            <div class="column is-6"><!---------------------- Details box ---------------------->
                <div class="box" id="box-detalhes">
                    <h2 class="subtitle"><strong>Detalhes da Requisição</strong></h2>
                    <div class="columns is-multiline">
                        <div class="column is-12">
                            <div class="field" >
                                <label>Clínica</label>
                                <div class="control" >
                                    <Field 
                                        name="clinica"
                                        as="input"
                                        type= "text"
                                        class= "input is-info"
                                        :placeholder= "object.nome"
                                        :disabled= "true"
                                        v-model="newRequestData['clinica']"
                                    />
                                </div>
                                <!-- <ErrorMessage name="clinica" style="color:red"/> -->
                            </div>
                        </div>
                        <div class="column is-12">
                            Selecione uma data e horário
                            <Datepicker
                                v-model="datePickerModel"
                                :min-date="new Date()"
                                :format="format"
                                :auto-position="true"
                            ></Datepicker>
                        </div>
                        <div class="column is-12">
                            <div class="field">
                                <label>Selecione um colaborador</label>
                                <div>
                                    <div class="select is-info is-fullwidth" >
                                        <Field name="colaborador" as="select" v-model="object.newRequestData['person_pk']">
                                            <option
                                            v-for="option in employeeOptions" :key="option.person_pk"
                                            :value= "option.person_pk"
                                            >
                                                {{option.Nome}}
                                            </option>
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column is-6"><!---------------------- Exams box ---------------------->
                <div class="box" id="box-exames">
                    <h2 class="subtitle"> <strong>Exames solicitados</strong> </h2>
                    <div class="table is-fullwidth is-hoverable is-striped" style="padding-left:auto;padding-right:auto">
                        <thead>
                            <tr>
                            <th>Exame</th>
                            <th>SLA</th>
                            <th>Preço</th>
                            <th>
                                <button class="button is-info is-small" @click="activateModal('addExam')">
                                    <span class="icon is-small">
                                        <i class="fas fa-add"></i>
                                    </span>
                                </button>
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="exame,index in object.newRequestData.exames_selecionados" :key="exame.nome" @click="removeExamFromSelection(index)">
                                <td>{{exame.nome}}</td>
                                <td class="has-text-centered">{{exame.sla}}</td>
                                <td class="has-text-centered">{{exame.preco}}</td>
                                <td class="has-text-centered">
                                    <button class="button is-danger is-outlined is-small" @click="">
                                        <span class="icon is-small">
                                            <i class="fas fa-times"></i>
                                        </span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </div>
                    <br>
                    <p><strong>ESTIMATIVA CONCLUSÃO: </strong> {{ estimatedCompletionDateString }} <strong>TOTAL:</strong> R$ {{ totalPrice.toFixed(2) }}  </p>
                </div>
            </div>
        </div>
    </div>
    <!-- MODAL - Adicionar Exame -->
    <div class="modal" :class="{ 'is-active': isModalActive('addExam') }" v-if="isModalActive('addExam')">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Adicionar exame à requisição</p>
            <button class="delete is-medium" aria-label="close" @click="closeModal()"></button>
        </header>
        <section class="modal-card-body">
            <p>Escolha entre os exames disponíveis abaixo:</p>
            <div class="table is-fullwidth is-hoverable is-striped" style="padding-left:auto;padding-right:auto">
                <thead>
                    <tr>
                    <th>Exame</th>
                    <th>SLA</th>
                    <th>Preço</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="exame,index in object.exames_disponiveis" :key="exame.nome" style="cursor:pointer" @click="addExamToSelection(index)">
                        <td>{{exame.nome}}</td>
                        <td class="has-text-centered">{{exame.sla}}</td>
                        <td class="has-text-centered">{{exame.preco}}</td>

                    </tr>
                </tbody>
            </div>
        </section>
        <footer class="modal-card-foot">
            <div class="buttons" style="margin-left:auto;margin-right: auto;">
                <button class="button is-danger" @click="closeModal()">Cancelar</button>
                <!-- <button class="button is-success" @click="">Confirmar</button> -->
            </div>
        </footer>
    </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toRaw } from '@vue/reactivity'
//Form validation
import { Field, Form, ErrorMessage } from 'vee-validate';
import { validationSchemaDictionary } from '@/utilities/validations.js'

// import Datepicker from '@/internalizedLibraries/@vuepic/vue-datepicker'
// import '@/internalizedLibraries/@vuepic/vue-datepicker/dist/main.css'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { string } from 'yup';

export default {
    name: 'ModalCriarRequisicao',
    props: {
        object: Object,
        
        // formValidationSchema: String,
    },
    components: {
        Form,
        Field,
        ErrorMessage,
        Datepicker,
    },
    data() {
        return {
            activeModal: '',
            apiURL: 'http://localhost:8000/api/',
            datePickerModel: new Date(),
            employeeOptions: [],
            // schema: {},
            formFieldsText: [],
            formFieldsSelect: [],
            newRequestData: {
                'clinica': '',
                'date': {},
                'person_pk': '',
            },
            objectBackup: toRaw(this.object),
        }
    },
    beforeMount() {
        this.getEmployeeList()
        this.getAvailableExams()
        this.prepareFieldsWithinObject()
        // this.queryFormFields(this.apiURL)
        // setTimeout(() => {
        //     this.schema = validationSchemaDictionary[this.formValidationSchema]
        // }, 200) // If the schema loads before the queryFormFields, the reactive property will identify a change and trigger the validation
    },
    computed: {
        format() {
            return  this.datePickerModel.getDate()+ '/'+(this.datePickerModel.getMonth()+1)+'/'+this.datePickerModel.getFullYear()+' '+this.datePickerModel.getHours()+':'+this.datePickerModel.getMinutes()
        },
        totalPrice() {
            let total = 0
            this.object['newRequestData']['exames_selecionados'].forEach(exame => {
                total += exame.preco
            })
            return total
        },
        estimatedCompletionDateString() {
            let date = new Date(this.datePickerModel)
            let sla = 0
            this.object['newRequestData']['exames_selecionados'].forEach(exame => {
                if (exame.sla > sla) {
                    sla = exame.sla
                }
            })
            sla = sla + 2
            date.setDate(date.getDate() + sla)
            return date.getDate()+'/'+((date.getMonth()+1))+'/'+date.getFullYear()
        },
        // schemaIsValid() {
        //     return validationSchemaDictionary[this.formValidationSchema].isValidSync(this.object)
        // },
    },
    watch: {
        // schemaIsValid(newValue, oldValue) {
        //     // console.log('schemaIsValid changed from', oldValue, 'to ' ,newValue)
        //     this.$emit('schemaIsValidChanged', newValue)
        // },
        datePickerModel (newValue, oldValue) {
            // this.object['newRequestData']['date']['year'] = newValue.getFullYear()
            // this.object['newRequestData']['date']['month'] = newValue.getMonth()
            // this.object['newRequestData']['date']['day'] = newValue.getDate()
            this.object['newRequestData']['date'] = {
                'year': newValue.getFullYear(),
                'month': newValue.getMonth()+1,
                'day': newValue.getDate(),
                'hour': newValue.getHours(),
                'minute': newValue.getMinutes(),
            }
            // console.log('date changed', this.object['newRequestData']['date'])
        },
    },
    methods: {
        // Modal Functions - START
        activateModal(modalName) {
            this.activeModal = modalName
        },
        closeModal() {
            this.activeModal = ''
        },
        isModalActive(modalName) {
            return this.activeModal === modalName
        },
        // Modal Functions - END
        // Logic Functions - START
        addExamToSelection(index) {
            this.object['newRequestData']['exames_selecionados'].push(this.object['exames_disponiveis'][index])
            this.object['exames_disponiveis'].splice(index,1)
            // console.log('CriarRequisicao.vue>addExamToSelection concluded')
            // console.log('CriarRequisicao.vue>addExamToSelection> this.object[newRequestData]:',this.object['newRequestData'])
            // console.log('CriarRequisicao.vue>addExamToSelection> this.object[exames_disponiveis]:',this.object['exames_disponiveis'])
            this.sortArrayOfObjectsByNome(this.object.newRequestData.exames_selecionados)
            this.closeModal()
        },
        async getAvailableExams() {
            // console.log('CriarRequisicao.vue>getAvailableExams called for apiURL:',apiURL)
            await axios
                .get('api/v1/clinics/public-exams-offered/', {
                    params: {
                        'clinic_pk': this.object.clinica_pk
                    }
                })
                .then(response => {
                    // console.log('CriarRequisicao.vue>getAvailableExams> response.data:',response.data)
                    this.object['exames_disponiveis'] = response.data
                    this.prepopulateSelectedExams()
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getEmployeeList() {
            // console.log('CriarRequisicao.vue>getEmployeeList called for apiURL:',apiURL)
            await axios
                .get('api/v1/corporate/employments/')
                .then(response => {
                    // console.log('CriarRequisicao.vue>getEmployeeList>response.data:',response.data)
                    this.employeeOptions = response.data
                })
                .catch(error => {
                    console.log(error.request.response)
                })
        },
        prepareFieldsWithinObject() {
            const fillerDate = new Date()
            this.object['exames_disponiveis'] = []
            this.object['newRequestData']= {
                'clinica': this.object.nome,
                'clinica_pk': this.object.clinica_pk,
                'date': {
                    'year': fillerDate.getFullYear(),
                    'month': fillerDate.getMonth()+1,
                    'day': fillerDate.getDate(),
                    'hour': fillerDate.getHours(),
                    'minute': fillerDate.getMinutes(),
                    },
                    'person_pk': '',
                    'exames_selecionados': [],
            }
            
        },
        prepopulateSelectedExams() {
            let preSelectedExams = this.object.exames // This is the list of exams that were sent by the metaPage.
                //This derives from the legacy code, therefore it lacks examOffered_pk
            // console.log('CriarRequisicao.vue>prepopulateSelectedExams>preSelectedExams:',preSelectedExams)
            for (const examToAdd in preSelectedExams) {
                // Copilot, use the examToAdd examePadrao_pk property to find the exam in the object this.object.exames_disponiveis and save its pk and index
                let examOffered_pk = ''
                let examOfferedIndex = ''
                this.object.exames_disponiveis.forEach((exam, index) => {
                    if (exam['examePadrao_pk'] == preSelectedExams[examToAdd]['exame_padrao_pk']) {
                        examOffered_pk = exam['exam_offered_pk']
                        examOfferedIndex = index
                    }
                })
                // Add the exam to the selected exams list
                this.object.newRequestData.exames_selecionados.push({
                    'clinica_pk': this.object.clinica_pk,
                    'nome': preSelectedExams[examToAdd]['nome'],
                    'preco': preSelectedExams[examToAdd]['preco'],
                    'sla': preSelectedExams[examToAdd]['sla'],
                    'exame_padrao_pk': preSelectedExams[examToAdd]['exame_padrao_pk'],
                    'exam_offered_pk': examOffered_pk,
                })
                // Delete the exam from the available exams list
                this.object.exames_disponiveis.splice(examOfferedIndex, 1)
                // Sort the lists
                this.sortArrayOfObjectsByNome(this.object.exames_disponiveis)
                this.sortArrayOfObjectsByNome(this.object.newRequestData.exames_selecionados)
            }
            // console.log('prepopulateSelectedExams performed:')
            // console.log('this.object.newRequestData.exames_selecionados:',this.object.newRequestData.exames_selecionados)
            // console.log('this.object.exames_disponiveis:',this.object.exames_disponiveis)
        },
        removeExamFromSelection(index) {
            this.object.exames_disponiveis.push(this.object.newRequestData.exames_selecionados[index])
            this.object.newRequestData.exames_selecionados.splice(index, 1)
            this.sortArrayOfObjectsByNome(this.object.exames_disponiveis)
        },
        sortArrayOfObjectsByNome(array) {
            array.sort((a, b) => {
                if (a.nome < b.nome) {
                    return -1
                }
                if (a.nome > b.nome) {
                    return 1
                }
                return 0
            })
        },

    }
}
</script>

<style>
.table {
    margin-left: auto;
    margin-right: auto;
}

</style>